import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { defaultLayoutJson } from "../config/defaultValues";
import { reduxHelper } from "../config/reduxHelper";
import { HTTP_REST_URLS } from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export function useUser()
{

    const [user, setUser] = useState(null);

    const dispatch = useDispatch();

    const getUserData = async () => {

        const url = HTTP_REST_URLS.GET_PROFILE;

        dispatch({
            type: reduxHelper.UPDATE_AUTH_LOADING,
            payload: true
        })
        
        fetch(url)
            .then((res) => (res.json()))
            .then(async (response) => {
                const middlewareResult = await useApiMiddleware(response)
                setUser(middlewareResult?.data || null)
                dispatch({
                    type: reduxHelper.UPDATE_AUTH_LOADING,
                    payload: false
                })
                dispatch({
                    type: reduxHelper.UPDATE_USER_DATA,
                    payload: middlewareResult?.data
                })
            })
            .catch((error) => {
                setUser(null)
                dispatch({
                    type: reduxHelper.UPDATE_AUTH_LOADING,
                    payload: false
                })
            })
    }

    useEffect(() => {
        getUserData()
    }, [])

    return useMemo(() => ({
        user: user
    }), [user])

}