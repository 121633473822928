import { Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { TypeAnimation } from "react-type-animation";
import useDevice from "../hooks/useDevice";
import { getString } from "../functions/getString";

let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

const CustomTypography = ({children = "Title", item, maxLines, listType = null, ...props }) => {
     // List type: 'bullet' for unordered list, 'number' for ordered list    

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder } = reducer;

    const theme = useTheme();
    const { push } = useRouter();
    
    const { isMobile, isTablet } = useDevice();

    // Function to handle rendering lists
    const renderList = (items, listType) => {
        // Render unordered list if 'bullet' is selected
        if (listType === 'bullet') {
            return (
                <ul style={{ paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                    {items.map((child, index) => (
                        <li key={index} style={props.style}>{getString(child)}</li>
                    ))}
                </ul>
            );
        }
        // Render ordered list if 'number' is selected
        else if (listType === 'number') {
            return (
                <ol style={{ paddingLeft: '1.5rem', listStyleType: 'decimal' }}>
                    {items.map((child, index) => (
                        <li key={index} style={props.style}>{getString(child)}</li>
                    ))}
                </ol>
            );
        }
    };

    // Handling text animation if children is an array
    if (Array.isArray(children)) {
        let arr = [];
        children.map((i) => {
            arr.push(i);
            arr.push(props?.animationProps?.duration);
        });

        return (
            <TypeAnimation
                {...props}
                sequence={arr}
                repeat={Infinity}
                style={props.style}
            />
        );
    } else {
        // Handling HTML content
        if (isHTML(children)) {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: children
                    }}
                />
            );
        }

        // Render lists based on listType prop
        if (Array.isArray(children) && (listType === 'bullet' || listType === 'number')) {
            return renderList(children, listType);
        }

        // console.log(props, 'props');
        
      
        return (
            <Typography
                {...props}
                style={{
                    ...props.style,
                    fontSize: isMobile ? props?.style?.fontSize / 2 : isTablet ? props?.style?.fontSize / 1.5 : props?.style?.fontSize
                }}
                sx={{
                    ...(!isSiteBuilder && item?.url && {
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                            textDecorationLine: 'underline',
                        },
                    }),
                    ...(maxLines && {
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: maxLines,
                        fontWeight: 400,
                    }),
                }}
                onClick={() => {
                    if (!isSiteBuilder && item?.url !== "") {
                        if (typeof item.url === 'object') {
                            push(item.url.path);
                        } else {
                            push(item.url);
                        }
                    }
                }}
            >
                {typeof children === 'string' ? getString(children) : children}
            </Typography>
        );
    }
};

export default CustomTypography;
