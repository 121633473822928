// routes

import { HTTP_DOMAIN } from "./urls";

export const ADMIN_PATH = 'admin-beta'

// API
// ----------------------------------------------------------------------

export const FIREBASE_API = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
    userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    clientId: process.env.AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    // DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_HEIGHT: 92 - 32,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 365,
    DASHBOARD_COLLAPSE_WIDTH: 260,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
    themeMode: 'themeMode',
    themeDirection: 'themeDirection',
    themeColorPresets: 'themeColorPresets',
    themeLayout: 'themeLayout',
    themeStretch: 'themeStretch',
};

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColorPresets: 'default',
    themeLayout: 'horizontal',
    themeStretch: false,
};

export const devices = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP',
    TABLET: 'TABLET'
}

export const NO_IMAGE      = 'https://cdn.dribbble.com/users/15687/screenshots/17729147/media/4526ed7e22949d0c635009ff63ca457e.png?compress=1&resize=1000x750&vertical=top'
export const DEFAULT_IMAGE = 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/program/default.jpg'

export const CART_DEFAULT_JSON = {
    items: [],
    gross_amount: 0,
    sub_total_price: 0,
    total_tax: 0,
    coupon_applied: false,
    coupon_id: '',
    coupon_discount: 0,
    total_price: 0,
    sold_by: '',
    payment_gateway: '',
    user_details: {},
    payment_success_callback: HTTP_DOMAIN + 'dashboard',
    payment_error_callback: HTTP_DOMAIN + 'cart',
    payment_mode: 1,
    theme: 'red',
    referral_by: '',
    payment_due_date: '',
    addonPrice: 0,
    deliveryCharges: 0
}

export const loginHistoryPaths = {
    '/course/[slug]': 'checkout'
}