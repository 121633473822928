import { useEffect, useState } from "react"

const useDevice = () => {

    if(typeof window != 'object')
    {
        return ({
            isMobile: false,
            isTablet: false
        })
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width >= 320 && width < 576;
    const isTablet = width >= 577 && width < 1024;

    return ({
        isMobile,
        isTablet,
        isDesktop: !isMobile && !isTablet
    })

};

export default useDevice;