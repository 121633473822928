export const getString = (string) => {

    if(typeof window === 'object')
    {
        let languageJson = window.ek_lang;

        if(languageJson && languageJson[string])
        {
            return languageJson[string]
        }
    }
    
    return string
}