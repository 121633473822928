import { CategoryOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useAllCategories } from '../../hooks/useAllcategories';

const TagCategory = () => {

    const [anchorEl, setAnchorEl]           = useState(null)
    const [allcategories, setAllcategories] = useState([])

    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getAllcategoryData = async() =>{
        const result = await useAllCategories()
        setAllcategories(result?.category)

        const uniqueArray = Array.from(
            new Map(result?.category.map((obj) => [obj.ct_tag, obj])).values()
        );
        
          setAllcategories(uniqueArray)
    }

   

    useEffect(() =>{
        getAllcategoryData()
    }, [])
    

    return (
        <div>
            <Box display={'flex'} alignItems={'center'} gap={0.5} sx={{cursor:'pointer'}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2l-5.5 9h11z" fill="#FFD700"/><circle cx="17.5" cy="17.5" r="4.5" fill="#000080"/><path d="M3 13.5h8v8H3z" fill="red"/></svg>
                <Typography                
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}  
                    sx={{color:'black'}}             
                >
                    Faculty
                    <KeyboardArrowDown/>
                </Typography>
            </Box>
            
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {
                    Array.isArray(allcategories) && allcategories?.map((item)=>{
               
                        return(
                            <>
                                <MenuItem >{item?.ct_tag}</MenuItem>                            
                            </>
                        )
                    })
                }
               
            </Menu>
        </div>
    );
}
export default TagCategory;
