// import axios from "axios";
// import Cookies from "js-cookie";
// import { OLD_PERCENTAGE_DATE_KEY, PERCENTAGE_CACHE } from "../config/key";
// import { HTTP_REST_URLS } from "../config/urls";
// import { useApiMiddleware } from "./useApiMiddleware"

// export const useUpdatePercentage = (headers) => new Promise(async (resolve, reject) => {
   
//     const url = HTTP_REST_URLS.UPDATE_PERCENTAGE;

//     const result = localStorage.getItem(PERCENTAGE_CACHE);

// console.log(window.currentCacheData,'window.currentCacheData')

// if(typeof window.currentCacheData != 'undefined'){

// let item = window.currentCacheData;

// item['material_id'] = item?.material_id || item?.id
//             item['topic_id'] = ''
//             item['medium'] = ''
//     axios.post(url, window.currentCacheData, {
//         headers: headers
//     })
//         .then((response) => {
    
//             if(!response.error)
//             {
//                 Cookies.set(OLD_PERCENTAGE_DATE_KEY, new Date())
//             }
    
//         })
// }





//     // if(result != null)
//     // { 
//     //     const parsedOldResult = JSON.parse(result);

//     //     if(Array.isArray(parsedOldResult))
//     //     {
//     //         parsedOldResult?.map((item) => {

//     //             item['material_id'] = item?.material_id || item?.id
//     //             // item['program_id']  = item?.program_id
//     //             item['topic_id'] = ''
//     //             item['medium'] = ''

//     //             axios.post(url, item, {
//     //                 headers: headers
//     //             })
//     //                 .then((response) => {
            
//     //                     if(!response.error)
//     //                     {
//     //                         Cookies.set(OLD_PERCENTAGE_DATE_KEY, new Date())
//     //                     }
            
//     //                 })

//     //         })
//     //     }
//     // }

// })





// import axios from "axios";
// import Cookies from "js-cookie";
// import { OLD_PERCENTAGE_DATE_KEY, PERCENTAGE_CACHE } from "../config/key";
// import { HTTP_REST_URLS } from "../config/urls";
// import { useApiMiddleware } from "./useApiMiddleware";

// export const useUpdatePercentage = (headers) => new Promise(async (resolve, reject) => {
   
//     const url = HTTP_REST_URLS.UPDATE_PERCENTAGE;

//     const result = localStorage.getItem(PERCENTAGE_CACHE);

//     if (typeof window.currentCacheData !== "undefined") {
//         let item = { ...window.currentCacheData };
//         item.material_id = item.material_id || item.id;
//         item.topic_id = "";
//         item.medium = "";
        
//         try {
//             const response = await axios.post(url, item, {
//                 headers: headers,
//             });
//             if (!response.error) {
//                 Cookies.set(OLD_PERCENTAGE_DATE_KEY, new Date());
//             }
//             resolve(response);
//         } catch (error) {
//             reject(error);
//         }
//     }
// });

import axios from "axios";
import Cookies from "js-cookie";
import { OLD_PERCENTAGE_DATE_KEY, PERCENTAGE_CACHE } from "../config/key";
import { HTTP_REST_URLS } from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export const useUpdatePercentage = (headers) => new Promise(async (resolve, reject) => { 
   
    const url = HTTP_REST_URLS.UPDATE_PERCENTAGE; 

    const result = localStorage.getItem(PERCENTAGE_CACHE); 
    console.log( window.currentCacheData, ' window.currentCacheData');
 

    if (typeof window.currentCacheData !== "undefined") { 
        // console.log(window.currentCacheData, 'window.currentCacheData');
        let item = { ...window.currentCacheData }; 
        item.material_id = item.material_id || item.id; 
        item.topic_id = ""; 
        item.medium = ""; 
        // item.material_percentage = 100;
        item.material_percentage = "0"

        // console.log(item.type,'item-percentage-check')

        if(item.material_type == '1')
        {   
            item.material_percentage = window.currentCacheData?.material_percentage;

            // item.material_percentage = item.material_percentage;
        }else if(item.material_type == '2' &&( item.type == "Docx"  || item.type == "Pdf"   ) ){
            item.material_percentage = window.currentCacheData?.material_percentage;
        }
        else if(item.material_type == '3' ){
        item.material_percentage = window.currentCacheData?.material_percentage;
        }
        else if(item.material_type == '8' ){
            item.material_percentage = window.currentCacheData?.material_percentage;
        }

        else if(item.material_type == '19' ){
            item.material_percentage = window.currentCacheData?.material_percentage;
        }
        else if(item.material_type == '5' ){
            item.material_percentage = window.currentCacheData?.material_percentage;
        }
        else if(item.material_type == '12' ){
            item.material_percentage = window.currentCacheData?.material_percentage;
        }
        // else if(item.material_type == '10' ){
        //     item.material_percentage = window.currentCacheData?.material_percentage;
        // }
        // else if(item.material_type == '17' ){
        //     item.material_percentage = window.currentCacheData?.material_percentage;
        // }
        else{
            item.material_percentage = "100";
        }
        // item.material_percentage = (item.material_type !== '1' || item.material_type != 2 ? 100 : item.material_percentage);

        // console.log(item.material_type, 'item.material_type'); 
        // console.log(item.material_percentage, 'item.material_percentage');

        
        try {
            // Check if headers object is defined before encoding it
            const encodedHeaders = headers ? Object.entries(headers).reduce((acc, [key, value]) => {
              acc[key] = encodeURIComponent(value);
              return acc;
            }, {}) : {};

            const response = await axios.post(url, item, {
                headers: encodedHeaders,
            });
         
            if (!response.data.error) 
            {
                if(response?.data?.total_percentage != undefined)
                {
                    let updatedPercetange = response.data.total_percentage;
                    window.updateMaterialProgress(updatedPercetange);
                    Cookies.set(OLD_PERCENTAGE_DATE_KEY, new Date());
                }
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    }
});
