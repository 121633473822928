//NEXT
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect } from "react";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { OLD_PERCENTAGE_DATE_KEY } from "../../config/key";
import { reduxHelper } from "../../config/reduxHelper";

//CONFIG
import { unAuthorizedRoutes } from "../../config/routes";
import { useTimeDifference } from "../../hooks/useTimeDifference";
import { useUpdatePercentage } from "../../hooks/useUpdatePercentage";
import { useUser } from "../../hooks/useUser"

//COMPONENTS
import AccessDenied from "../error/AccessDenied";
import Loading from "../Loading/loading"


function AuthProvider({ children }) 
{
    const { reducer } = useSelector((state) => state);
    const { authLoading, headers, pageJson,user } = reducer;
    const { route, query, pathname } = useRouter();
    const slug     = query?.slug 
    // const { user } = useUser();
    const dispatch = useDispatch();
    
    const reduxUser = reducer?.user;
    window.userData = reduxUser

    const checkUpdatePercentage = async () => {

        const oldDate = Cookies.get(OLD_PERCENTAGE_DATE_KEY)
       
        if (oldDate) 
        {
            const difference = useTimeDifference(oldDate, new Date())
            if (difference > 5) {
                const result = await useUpdatePercentage(headers)
            }
        }
        else 
            {
                const result = await useUpdatePercentage(headers)
            }

    }

    useEffect(() => {

        const splittedPath = route?.split('/')
        // const currentRoute = splittedPath[1];
        const currentRoute = pathname;
 
        if (!unAuthorizedRoutes.includes(currentRoute)){
                if (typeof window === 'object') {
                    window.sessionpopup = (data) => {
                        // window.hasAccessDenied === false;                      
                        dispatch({
                            type: reduxHelper.UPDATE_ALERT,
                            payload: data
                        })
                    }
                }
            }
            else {
                dispatch({
                    type: reduxHelper.UPDATE_ALERT,
                    payload: false
                })
            }

        checkUpdatePercentage()
    }, [])

    useEffect(() => {
        if(!reduxUser)
        {
            // const headerObject = { 'Authorization': user?.us_token ? user?.us_token : '' }
            const headerObject = { 'Authorization': user?.us_token ? user?.us_token + `{{=EKDEL=}}` + 'mobile' : '' };

            dispatch({
                type: reduxHelper.UPDATE_USER_DATA,
                payload: user
            })
            dispatch({
                type: reduxHelper.UPDATE_HEADERS,
                payload: headerObject
            })
        }
    }, [user])

    const url = window.location.href;
    const splited = url.split('/')
    const splitedData = splited[3] + '/' + splited[4]

    if(pageJson != undefined || pageJson != null || pageJson != '')
    {
        if(splitedData == `category/${slug}` && !user && pageJson?.needLogin == true)
        {
            return <AccessDenied/>
        }
    }
    
    const splittedPath = route?.split('/');
    const currentRoute = pathname //splittedPath[1];

    if(!user)
    {
      
        if(!currentRoute)
        {            
            return children
        }
        else if(!unAuthorizedRoutes.includes(currentRoute))
        {      
            if(authLoading)
            {
                return <Loading/>
            }            
            return <AccessDenied/>
        }
        else
        {
            const redirectData = user?.redirection
            const redirectTrue = user?.redirection_required
            const redirectUrl = redirectData?.url
        
            const splittedPath = route?.split('/');
            // const currentRoute = splittedPath[1];
            const currentRoute = pathname;
        
            const splittedRedirectUrl = redirectUrl?.split('/');
        
            const redirectUrlPath = splittedRedirectUrl?.length > 2 ? splittedRedirectUrl[3] : '';   

            if(redirectUrlPath != currentRoute && redirectTrue == true && !unAuthorizedRoutes.includes(currentRoute))
            {
                return <AccessDenied/>
            }
        }

    }
    else if(user)
    {       
        if(user?.us_role_id != 2)
        {
            if(!currentRoute)
            {
                return children
            }
            else if(!unAuthorizedRoutes.includes(currentRoute))
            {
                if(authLoading)
                {
                    return <Loading/>
                }
                return <AccessDenied/>
            }
        }

        const redirectData = user?.redirection
        const redirectTrue = user.redirection_required
        const redirectUrl = redirectData?.url
        
        const splittedRedirectUrl = redirectUrl?.split('/');
      
        const redirectUrlPath = splittedRedirectUrl?.length > 2 ? splittedRedirectUrl[3] : '';   

      if(redirectUrlPath != currentRoute && redirectTrue == true && !unAuthorizedRoutes.includes(currentRoute)){

        return <AccessDenied/>

      }
        
    }
    
    return children;
};

export default AuthProvider;