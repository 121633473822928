import axios from "axios";
import { HTTP_REST_URLS } from "../config/urls"

export const useSubmitForm = (data) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.SUBMIT_FORM_DATA;

    axios.post(url, {
        data: data
    })
        .then((response) => {
            resolve(!response?.data?.error)
        })
        .catch((error) => {
            resolve(false)
        })

})