import { useState } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_IMAGE } from "../../config/config";
import useDevice from "../../hooks/useDevice";

const EkhoolImage = ({ layout = 'fill',height, src, objectFit, ...props }) => {
  const { reducer } = useSelector((state) => state);
  const { appData } = reducer;
  const { isMobile, isTablet } = useDevice();
  const BrokenImage = DEFAULT_IMAGE;

  const imageOnError = (event) => {
    event.currentTarget.src = BrokenImage;
    event.currentTarget.className = "error";
  };
console.log(height,"height")
  return (
    <img
      {...props}
      src={src}
      style={{
        height: isMobile ? height?height:'' : '100%',
        objectFit: objectFit, // Apply objectFit using inline styles
        top: 0,
        left: 0,
        ...props.style
      }}
      onError={imageOnError}
      layout={layout}
    />
  );
}

export default EkhoolImage;
