import { AddCircleRounded, AddRounded } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material"
import { slice } from "lodash";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomComponent from './CustomComponent';
import ComponentAdder from '../components/themebuilder/componentAdder'
import { reduxHelper } from "../config/reduxHelper";

interface ComponentSectionProps {
    
    sectionKey: 
        "header1" | "header2"| "header3" | "header4" | "header5" | "header6" | "header7" | "header8" | "header9" | "header10" | "header12" | "banner1" | "hero1" | "hero2" | "hero3" | "hero4" | "hero5" |"programs4"|
        "hero6" | "hero7" | "feature1" | "feature2" | "feature3" | "feature4" | "footer1" | "footer2" | "footer3" | "footer4" | "footer5" | "footer6" | "footer7" | "footer8" | "footer9" |
        "footer10" | "footer15"  | "contact1" | "contact2" | "list1" | "list2" | "list3" | "team1" | "team2" | "statistic1" | "statistic2" | "statistic3" | "testimonial1" | "testimonial2" | "testimonial3" |
        "cta1" | "promotion1" | "promotion2" | "content1" | "about1" | "youtube1" | "youtube2" | "youtube3" | "programs1" | "layoutGrid" | "LayoutFooter" |  "layoutFooter1" |  "layoutFooter2" |  "layout2" |   "layout3" | "columnLayout" | "cardLayout" | "rowLayout" | "cardRowLayout" |
        "tab1" | "categoryPrograms1" | "cardRowLayout" | "programCard" | "banner2" | "articles1" | "achievers1" | "ticker1" | "statistics1" | 'image1' | 'icon1'
    
    remove?: 'button' | 'icon' | 'image' | 'accordion' | 'typography' | 'content' | 'popover' | 'spacer' | 'content2' | 'tab' | 'content3'| 'list' | 'form'| 'feature1'| 'image1' | 'icon1'

    only?: 'button' | 'icon' | 'image' | 'accordion' | 'typography' | 'content' | 'popover' | 'spacer' | 'content2' | 'tab' | 'content3'| 'list' | 'form'| 'feature1'| 'image1' | 'icon1'

    limit?: number,

start?:number,

    end?:number,

    onChangeStye(style: object): void,
    
}

const ComponentSection: FC<ComponentSectionProps> = ({ sectionKey, remove, only, limit, onChangeStye, start, end}) => {

    const  reducer  = useSelector((state: any) => state.reducer);
    const { pageJson, layoutJson, isSiteBuilder, themebuilderSelectedItem } = reducer || {};

    const themeJson = pageJson?.components?.filter((i: any) => (i.key === sectionKey));
    let themeItem = themeJson?.length > 0 && themeJson[0];

    if(!isSiteBuilder)
    {

        const headerItem = layoutJson?.components?.filter((i: any) => (i.key === sectionKey))
        if(headerItem?.length > 0)
        {
            themeItem = headerItem[0]
        }

        const footerItem = layoutJson?.components?.filter((i: any) => (i.key === sectionKey))
        if(footerItem?.length > 0)
        {
            themeItem = footerItem[0]
        }

    }

    const style = themeItem && themeItem?.style ? themeItem.style : {};
    let { children } = themeItem;

    if(only)
    {
        children = children?.filter((i: any) => (i.type == only))
    }

    if(remove)
    {
        children = children?.filter((i: any) => (i.type !== remove))
    }

    if(start)
    {
        children = children?.slice(start, end)
    }

    if(limit)
    {
        children = children?.slice(0, limit)        
    }

    useEffect(() => {
        if(onChangeStye)
        {
            if(typeof style === 'object')
            {
                onChangeStye(style)
            }
        }
    }, [themeItem]);

    const dispatch = useDispatch();

    const updateComponent = () => {
        dispatch({
            type: reduxHelper.UPDATE_COMPONENT
        })
    }

    const onAddNewComponent = (val: any) => {

        const children = themebuilderSelectedItem?.children;
        const { defaultConfig } = val;

        if(Array.isArray(children))
        {
            var i = {}
            i['id'] = Math.random() * 10
            i = {...i, ...defaultConfig}

            themebuilderSelectedItem?.children?.push(i)
            updateComponent();
        }
        else
        {
            let array = []
            defaultConfig['id'] = 1
            array.push(defaultConfig)
            themebuilderSelectedItem['children'] = array
            updateComponent();  
        }

    }

    return (
        <>
            {
                children?.map((item: any, index: number) => {
                    return (
                        <CustomComponent item={item} {...item.props} type={item?.type} index={index} parentItem={themeItem} key={index} >
                            {item.children}
                        </CustomComponent>
                    )
                })
            }

            {/* {
                isSiteBuilder && themebuilderSelectedItem?.key == sectionKey &&
                <Stack
                    height={40}
                    width={40}
                >
                    <ComponentAdder
                        addNewComponent={(val: any) => {
                            onAddNewComponent(val)
                        }}
                    >
                        <Tooltip title={'Add new element'} >
                            <IconButton color={'info'} >
                                <AddCircleRounded/>
                            </IconButton>
                        </Tooltip>
                    </ComponentAdder>
                </Stack>
            } */}

        </>
    )
};

export default ComponentSection;