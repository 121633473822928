import { APPLICATION_DETAILS, GET_COURSE_DETAIL, GET_HOME_PROGRAMS, GET_ID_CARD_STATUS, GET_INVOICES, GET_MY_PROGRAMS, GET_POPULAR_PROGRAMS, GET_PROFILE, GET_PROGRAM_MATERIALS, LOGIN, REGISTER, REQUEST_ID_CARD, UPDATE_PROFILE, UPDATE_USER_IMAGE, VERIFY_OTP, GET_ALLBUNDLE_DETAILS } from "./apiSlugs";

export let HTTP_DOMAIN = 'https://tut.e-khool.org/'

if(typeof window === 'object')
{
    let hostname =  window.location.hostname;

    let  baseUrl = (window.location.protocol + '//' + window.location.host+'/');

    if (hostname === 'localhost') 
    {
        baseUrl     = 'https://tut.e-khool.org/';
        HTTP_DOMAIN = 'https://tut.e-khool.org/';
    }
    else
    {
        HTTP_DOMAIN = baseUrl
    }
}
const detectOS = () => {
    if (typeof navigator === 'undefined') {
        console.warn('navigator is not available. Defaulting to "unknown" OS.');
        return 'unknown';
    }

    const platform = navigator.platform.toLowerCase();
    console.log(platform, "platform");

    if (platform.includes('win')) 
    {
        return 'windows';
    } else if (platform.includes('mac')) 
    {
        return 'mac';
    } else if (platform.includes('linux')) 
    {
        return 'linux';
    } else {
        return 'unknown';
    }
};



const currentOS=detectOS()
export const appendOSQuery = (url) => {
    if (url.includes('?')) 
    {
        return `${url}&os=${currentOS}`;
    } else 
    {
        return `${url}?os=${currentOS}`;
    }
};
export let HTTP_REST_DOMAIN         = `${HTTP_DOMAIN}api/v3/`;
export let HTTP_REST_DOMAIN_ADMIN   = `${HTTP_DOMAIN}api/admin/`;

export const HTTP_REST_URLS = {

    //ACCOUNT
    LOGIN                       : HTTP_REST_DOMAIN + LOGIN,
    REGISTER                    : HTTP_REST_DOMAIN + REGISTER,
    GET_MOBILE_OTP              : HTTP_REST_DOMAIN + LOGIN,
    VERIFY_OTP                  : HTTP_REST_DOMAIN + VERIFY_OTP,
    FORGOT_PASSWORD             : HTTP_REST_DOMAIN + 'forgotPassword?email=',
    VERIFY_RESET_PASSWORD_OTP   : HTTP_REST_DOMAIN + 'checkMailPasswordOtp',
    UPDATE_PASSWORD             : HTTP_REST_DOMAIN + 'updateLearnerPassword',

    //PROFILE
    GET_PROFILE                 : HTTP_REST_DOMAIN + GET_PROFILE,
    GET_INVOICES                : HTTP_REST_DOMAIN + GET_INVOICES,
    UPDATE_PROFILE              : HTTP_REST_DOMAIN + UPDATE_PROFILE,
    UPDATE_USER_IMAGE           : HTTP_REST_DOMAIN + UPDATE_USER_IMAGE,
    REQUEST_ID_CARD             : HTTP_REST_DOMAIN + REQUEST_ID_CARD,
    GET_ID_CARD_STATUS          : HTTP_REST_DOMAIN + GET_ID_CARD_STATUS,

    //APP DATA
    APPLICATION_DETAILS         : appendOSQuery(HTTP_REST_DOMAIN + APPLICATION_DETAILS),

    //PROGRAMS & DETAILS
    GET_MY_PROGRAMS             : HTTP_REST_DOMAIN + GET_MY_PROGRAMS,
    GET_POPULAR_PROGRAMS        : HTTP_REST_DOMAIN + GET_POPULAR_PROGRAMS,
    GET_HOME_PROGRAMS           : HTTP_REST_DOMAIN + GET_HOME_PROGRAMS,
    GET_COURSE_DETAIL           : HTTP_REST_DOMAIN + GET_COURSE_DETAIL,
    GET_PROGRAM_MATERIALS       : HTTP_REST_DOMAIN + GET_PROGRAM_MATERIALS,
    GET_ALLBUNDLE_DETAILS       : HTTP_REST_DOMAIN + GET_ALLBUNDLE_DETAILS,
    GET_CATEGORY_PROGRAMS       : HTTP_REST_DOMAIN + 'search_bundle_details_new?category_slugs=',
    GET_ALL_PROGRAMS            : HTTP_REST_DOMAIN + 'getAllItems',
    GET_ALLPROGRAMS_DETAILS     : HTTP_REST_DOMAIN + 'getAllPrograms?get_bundles=true&basic_detail=true',
    GET_SUBSCRIPTION_VALIDATE   : HTTP_REST_DOMAIN + 'userSubscriptionValidate',
    GET_CART_ITEMS              : HTTP_REST_DOMAIN + 'getCartItems',

    SEARCH_COURSE               : HTTP_REST_DOMAIN + 'getAllItems?search_key=',
    GET_SIMILAR_PROGRAMS        : HTTP_REST_DOMAIN + 'similar_programs?program_id=',
    GET_PROGRAM_RATING          : HTTP_REST_DOMAIN + 'overview?program_id=',
    ADD_PROGRAM_RATING          : HTTP_REST_DOMAIN + 'save_my_rating_review',
    GET_PROGRAM_DETAIL          : HTTP_REST_DOMAIN + 'program_details?slug=',
    GET_PROGRAM_MATERIALS       : HTTP_REST_DOMAIN + 'program_materials_topic?slug=',
    GET_PROGRAM_ALL_MATERIALS   : HTTP_REST_DOMAIN + 'program_all_materials',
    GET_LESSON_USING_MATERIAL   : HTTP_REST_DOMAIN + 'getLessonUsingMaterial?material_id=',

    //HOME PAGE
    GET_TRENDING_VIDEOS         : HTTP_REST_DOMAIN + 'trending_videos',
    GET_LAST_PLAYED_VIDEOS      : HTTP_REST_DOMAIN + 'last_play_videos',
    GET_ACHIEVERS_DATA          : HTTP_REST_DOMAIN + 'getTestimonials',

    //ARTICLE
    GET_HUB_DATA                : HTTP_REST_DOMAIN + 'hub_details',
    GET_CURRENT_AFFAIRS         : HTTP_REST_DOMAIN + 'current_affairs',

    //EXAM
    GET_SUBJECT_EXAMS           : HTTP_REST_DOMAIN + 'all_exam',
    GET_MY_EXAMS                : HTTP_REST_DOMAIN + 'get_exam_percentage',
    GET_EXAM_DATA               : HTTP_REST_DOMAIN + 'single_exam?exam_id=',
    GET_EXAM_REPORT             : HTTP_REST_DOMAIN + 'render_exam_graph_pdf_exam?attempt_id=',
    START_EXAM                  : HTTP_REST_DOMAIN + 'start_exam?exam_id=',
    GET_RECENTLY_TAKEN_EXAM     : HTTP_REST_DOMAIN + 'recently_taken_exam?program_id=',
    SUBMIT_EXAM                 : HTTP_REST_DOMAIN + 'save_exam',
    GET_CATEGORY_EXAMS          : HTTP_REST_DOMAIN + 'exams',
    GET_TRENDING_QUESTIONS      : HTTP_REST_DOMAIN + 'questions_trending',
    GET_LIVE_EXAMS              : HTTP_REST_DOMAIN + 'live_exam',
    UPLOAD_EXAM_MEDIA           : HTTP_REST_DOMAIN + 'exam_media',
    GET_PROGRAM_EXAMS           : HTTP_REST_DOMAIN + 'getProgramExams?program_id=',

    //ASSIGNMENT
    GET_MY_ASSIGNMENTS          : HTTP_REST_DOMAIN + 'my_assignment',
    GET_ASSIGNMENT_SUBJECT      : HTTP_REST_DOMAIN + 'my_assignment_single_list?program_id=',
    GET_ASSIGNMENTS             : HTTP_REST_DOMAIN + 'assignments?program_id=',
    SUBMIT_ASSIGNMENT           : HTTP_REST_DOMAIN + 'assignmentSubmission',
    GET_ASSIGNMENT_REPORT       : HTTP_REST_DOMAIN + 'materialAssignment',
    GET_ASIGNMENT_DETAILS       : HTTP_REST_DOMAIN + 'assignment_detail',
    GET_PROGRAM_ASSIGNMENTS     : HTTP_REST_DOMAIN + 'getProgramAssignments?program_id=',

    //IMAGE UPLOAD
    UPLOAD_GROUP_CHAT_IMAGE     : HTTP_REST_DOMAIN + 'upload_program_chat',
    GROUP_CHAT_IMAGE_UPLOAD     : HTTP_REST_DOMAIN + 'upload_program_chat',
    ASSIGNMENT_FILE_UPLOAD      : HTTP_REST_DOMAIN + 'my_assignment_submission_file_upload',

    //FILE UPLOAD
    UPLOAD_FILE                 : HTTP_REST_DOMAIN_ADMIN + 'upload_media',

    //FORM BUILDER
    UPDATE_FORM_JSON            : HTTP_REST_DOMAIN_ADMIN + 'updateFormTemplate',
    GET_FORM_DATA               : HTTP_REST_DOMAIN + 'getFormTemplates',
    SUBMIT_FORM_DATA            : HTTP_REST_DOMAIN + 'updateFormData',

    //LIVE CLASS
    GET_LIVE_CLASSES            : HTTP_REST_DOMAIN + 'live_class_dashboard',
    GET_LIVE_CLASS_VOTING       : HTTP_REST_DOMAIN + 'subject_votion',

    //SITE BUILDER
    UPLOAD_COMPONENT_JSON       : HTTP_REST_DOMAIN_ADMIN + 'save_page_theme_content',
    UPLOAD_THEME_BUILDER_IMAGE  : HTTP_REST_DOMAIN + 'save_themebuilder_image',
    GET_THEME_BUILDER_JSON      : HTTP_REST_DOMAIN + 'templatePage?slug=',
    UPDATE_THEME_PAGE_JSON      : HTTP_REST_DOMAIN_ADMIN + 'page_data',

    //USER REPORTS
    ANALYTICS_OVERALL_GRAPH     : HTTP_DOMAIN + 'graph/graph_overall',
    GET_OVERALL_REPORT          : HTTP_REST_DOMAIN + 'full_program_percentage',
    ANALYTICS_OVERALL_GRAPH     : HTTP_DOMAIN + 'api/graph/graph_overall/',
    GET_DASHBOARD_REPORT        : HTTP_REST_DOMAIN + 'dashboardReport',
    GET_SUBJECT_ANALYSIS        : HTTP_REST_DOMAIN + 'single_program_percentage?program_id=',
    GET_WEEKLY_REPORT           : HTTP_REST_DOMAIN + 'weeklyReportData',
    GET_OVER_ALL_ANALYSIS_GRAPH : HTTP_REST_DOMAIN+ 'videoViewReport',

    //BUNDLES
    GET_BUNDLE_DETAIL           : HTTP_REST_DOMAIN + 'single_bundle?slug=',
    GET_MY_BUNDLES              : HTTP_REST_DOMAIN + 'my_own_programs',
    GET_ALL_BUNDLE              : HTTP_REST_DOMAIN + 'allBundle',

    //TRAINER
    GET_INSTRUCTOR_DETAIL       : HTTP_REST_DOMAIN + 'single_trainer?id=',
    REGISTER_AS_TUTOR           : HTTP_REST_DOMAIN + 'registerAsTutor',
    FEATURE_ACCESS              : HTTP_REST_DOMAIN + 'settings?key=feature_access',

    //SUBSCRIPTION
    ENROLL_FREE_PROGRAM         : HTTP_REST_DOMAIN + 'buyProgram?program_id=',
    ENROLL_FREE_BUNDLE          : HTTP_REST_DOMAIN + 'buy_bundle?bundle_id=',
    GET_PUBLIC_COUPONS          : HTTP_REST_DOMAIN + 'public_coupons',
    GET_ADDON_PROGRAM_PRODUCTS  : HTTP_REST_DOMAIN + 'addon_products?program_id=',
    GET_ADDON_BUNDLE_PRODUCTS   : HTTP_REST_DOMAIN + 'addon_products?bundle_id=',

    //ADMIN
    VERIFY_ADMIN_USER           : HTTP_REST_DOMAIN + 'verifyAdminUser',
    POST_ARTICLE                : HTTP_REST_DOMAIN_ADMIN + 'save_article',

    //VIDEO NOTES
    ADD_VIDEO_NOTE              : HTTP_REST_DOMAIN + 'savMaterialNote',
    GET_VIDEO_NOTES             : HTTP_REST_DOMAIN + 'videoNote?MaterialId=:materialId&programId=:programId',
    DELETE_VIDEO_NOTE           : HTTP_REST_DOMAIN + 'deleteNote',

    //MATERIAL
    GET_ALL_BOOKMARKS           : HTTP_REST_DOMAIN + 'marked_data_view',
    BOOKMARK_VIDEO              : HTTP_REST_DOMAIN + 'marked_data',
    REMOVE_BOOKMARK_VIDEO       : HTTP_REST_DOMAIN + 'marked_data_removed',
    GET_LAST_PLAYED_MATERIAL    : HTTP_REST_DOMAIN + 'last_played_material?program_id=',
    GET_VIDEO_EXAM              : HTTP_REST_DOMAIN + 'load_video_exam?material_id=',

    //TRACKING
    UPDATE_PERCENTAGE           : HTTP_REST_DOMAIN + 'update_percentage',
    UPDATE_DAILY_PERCENTAGE     : HTTP_REST_DOMAIN + 'dailyPercentageTrack',
    GET_CIRCLE_PERCENTAGE       : HTTP_REST_DOMAIN + "getItemPercentage",
    

    //EXTRAS
    GET_NOTIFICATIONS           : HTTP_REST_DOMAIN + 'notice_board_program_wise',
    SEND_CONTACT_MAIL           : HTTP_REST_DOMAIN + 'siteContactMail',
    GET_CURRENT_COUNTRY         : 'https://ipapi.co/json/',
    GET_PROMOTIONS              : HTTP_REST_DOMAIN + 'mobile_banner',
    GET_TESTIMONIALS            : HTTP_REST_DOMAIN + 'getTestimonials',
    GET_ALERT_NOTIFICATIONS     : HTTP_REST_DOMAIN + 'getNotifications',

    //ANALYTICS
    GET_ANALYTICS_DATA          : HTTP_REST_DOMAIN + 'website_analytics?key=website_analytics',

    //CATEGORY
    GET_CATEGORY                : HTTP_REST_DOMAIN + 'students_category',
    GET_PAGES                   : HTTP_REST_DOMAIN + 'pages',
    GET_ARTICLE_CATEGORIES      : HTTP_REST_DOMAIN + 'categories?for=article&except_id=1',
    GET_ARTICLES                : HTTP_REST_DOMAIN + 'articles',
    GET_ALL_CATEGORIES          : HTTP_REST_DOMAIN + 'all_categories',

    //PAYMENT
    PAYMENT_URL                 : HTTP_DOMAIN + 'api/secure_payment/payment_process',

    VALIDATE_COUPON             : HTTP_REST_DOMAIN + 'coupon_code_validate',
    COUPON_CODE_VALIDATE        : HTTP_REST_DOMAIN + 'coupon_code_validate_for_user',
    CATEGORY_DATA               : HTTP_REST_DOMAIN + 'categories?slugs=',

    // SUBSCRITION DETAILS
    MY_SUBSCRIPTION             : HTTP_REST_DOMAIN + 'my_subscription?category_ids=',

    GET_VIDEO_PERCENTAGE_EXAM   : HTTP_REST_DOMAIN + 'video_exam?material_id=',
    OVERALL_GRAPH               : HTTP_DOMAIN + 'admin/analysisgraph?id=',
    YERLY_GRAPH                 : HTTP_DOMAIN + 'admin/analysisgraph/overall_graph_category_based?user_id={{==USER_ID==}}&type={{==GRAPH_TYPE==}}',
    GET_OVERALL_ANALYSIS        : HTTP_REST_DOMAIN + 'program_overall_subject_wise',
    OVERALL_SUBJECT_GRAPH       : HTTP_DOMAIN + `admin/analysisgraph/graph1?program_id={{==PROGRAM_ID==}}&user_id={{==USER_ID==}}&type={{==GRAPH_TYPE==}}`,
    GET_LEADERBOARD_DETAILS     : HTTP_REST_DOMAIN + 'leaderboard_details',
    GET_CATEGORY_BLOGS          : HTTP_REST_DOMAIN + 'articles?category_slugs=',
    GET_OVERALL_DATA            : HTTP_REST_DOMAIN + 'getOverallData',
    POST_NOTIFICATION           : HTTP_REST_DOMAIN + 'postNotifications',
    GET_ROLE_BASED_USER_SETTINGS: HTTP_REST_DOMAIN + 'get_rolebased_user_settings',
    


}
