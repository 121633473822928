import { IconButton, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { RadioButtonCheckedRounded, RadioButtonUncheckedRounded } from "@mui/icons-material";

const CustomFormRadioFields = ({ item, index }) => {

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        item['value'] = inputValue?.value
    }, [inputValue?.value]);

    return (
        <Stack>
            <Typography variant="body1" >{item?.placeHolder}</Typography>
            <Stack mt={1.5} gap={0.5} >
                {
                    item?.options?.map((i, ind) => {
                        
                        const isSelected = inputValue?.id == i?.id

                        return (
                            <Stack direction={'row'} alignItems={'center'} >
                                <IconButton
                                    onClick={() => { 
                                        setInputValue(i) 
                                    }} 
                                    size={'small'}
                                >
                                    {
                                        isSelected ? <RadioButtonCheckedRounded color={'primary'} fontSize={'medium'} /> : <RadioButtonUncheckedRounded fontSize="medium" color={'inherit'} />
                                    }
                                </IconButton>
                                <Typography variant="body2" >{i?.value}</Typography>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </Stack>
    )
}

export default CustomFormRadioFields