
export const routes = {
    
    HOME: '/',
    ARTICLE: 'a/',
    ABOUT_US: 'about-us',
    ADMIN_BETA: 'admin-beta',
    CREATE_ARTICE: 'admin-beta/article/',
    PAGE_PREVIEW: 'admin-beta/page_preview',
    SITE_BUILDER: 'admin-beta/site-builder',
    ARTICLES: 'articles/',
    HOME: '/home',
    DASHBOARD: '/dashboard',
    MY_COURSES: '/my-learn',
    REPORTS: '/reports',
    LIVE_CLASS: '/liveclasses',
    EXAM_DASHBOARD: '/exams',
    ASSIGNMENTS_DASHBOARD: '/assignments',
    BOOKMARKS: '/bookmarks',
    SUBSCRIBE: '/all-courses',
    TUTOR: '/tutor',
    INVOICE: '/invoices'

}

export const allRoutes = [
    '',
    '/',
    '/a/[slug]',
    '/about-us',
    '/admin-beta',
    '/admin-beta/article/[id]',
    '/admin-beta/page_preview',
    '/admin-beta/site-builder',
    '/admin-beta/app-builder',
    '/admin-beta/form-builder/[id]',
    '/articles',
    '/articles/[slug]',
    '/assignments/dashboard',
    '/assignments/[id]',
    '/assignments/report/[slug]/[id]',
    '/bookmarks',
    '/bundle/[slug]',
    '/bundle/learn/[slug]',
    '/cancellation-policy',
    '/cart',
    '/category/[slug]',
    '/chat',
    '/contact-us',
    '/cookie',
    '/course/[slug]',
    '/currentaffair',
    '/currentaffair/[id]',
    '/exam',
    '/exam/[slug]',
    '/exam/[slug]/[id]',
    '/exams',
    '/exams/[id]',
    '/exams/dashboard',
    '/exams/report/[id]',
    '/dashboard',
    '/hub',
    '/hub/[id]',
    '/instructor',
    '/instructor/[id]',
    '/instructors',
    '/invoice',
    '/invoice/[id]',
    '/liveclasses',
    '/login',
    '/meeting/[id]',
    '/my-courses',
    '/my-learn',
    '/notification/[id]',
    '/notifications',
    '/p/[slug]',
    '/policy',
    '/privacy',
    '/profile',
    '/program',
    '/course/[slug]',
    '/course/[slug]/learn/[id]',
    '/course/[slug]/learn',
    '/course/[slug]/topic/[id]',
    '/register',
    '/reports',
    '/search',
    '/subscribe',
    '/terms',
    '/trending',
    '/trending/question/[id]',
    '/tutor',
    '/youtube',
    '/youtube/watch',
    '/youtube/watch/[id]',
    '/404',
    '/all-courses',
   ' /admin-beta/form-builder/[id]',
   '/landing',
   '/checklogin'
   
]

export const unAuthorizedRoutes = [
    '',
    '/',
    '/login', 
    '/register', 
    '/category', 
    '/test', 
    '/about-us', 
    '/contact-us', 
    '/course/[slug]', 
    '/privacy', 
    '/terms', 
    '/cookie', 
    '/cancellation-policy', 
    '/search', 
    '/tutor', 
    '/cart', 
    '/course', 
    '/geeks', 
    '/subscribe', 
    '/a/[slug]', 
    '/articles', 
    '/articles/[slug]', 
    '/admin-beta/article/[id]',
    '/p',
    '/p/[slug]', 
    '/a',
    '/bundle',
    '/bundle/[slug]',
    '/admin-beta',
    '/admin-beta/article',
    '/admin-beta/page_preview',
    '/admin-beta/site-builder',
    '/admin-beta/app-builder',
    '/admin-beta/form-builder/[id]',
    '/404',
    '/all-courses',
    '/demo',
    '/landing',
    '/category/[slug]',
   '/checklogin'
];

export const sideBarPaths = [
    '/dashboard', 
    '/home',
    '/my-courses',
    '/reports', 
    '/liveclasses', 
    '/exams/dashboard', 
    '/assignments/dashboard', 
    '/bookmarks', 
    '/program/[slug]', 
    '/exams', 
    '/exams/[id]',
    '/assignments',
    '/assignments/[id]',
    '/subscribe',
    '/program/[slug]/topic/[id]',
    '/course/[slug]/topic/[id]',
    routes.INVOICE
]

export const homeHeaderPaths = [
    '/admin-beta/site-builder',
    '/program/[slug]/learn/[id]',
    '/program/[slug]/learn',
    '/',
    '/admin-beta/form-builder/[id]',
    '/admin-beta/app-builder'
]

export const SITE_BUILDER_PATH = '/admin-beta/site-builder'