import { createStore, combineReducers } from 'redux';
import { createWrapper } from "next-redux-wrapper";
import { reduxHelper } from "../config/reduxHelper";
import {colors} from "../config/colors";
import {extraComponents, homepageJson, loginPageJson, registerPageJson} from "../config/themes";
import { CART_DEFAULT_JSON, devices } from '../config/config';
import { defaultAppHomeJson, defaultLayoutJson } from '../config/defaultValues';

//editorConfig: [ 'banner', 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],


// screens: ['login', 'register', 'homepage']

const lightTheme = {
    PRIMARY_BACKGROUND: '#ffffff',
    SECONDARY_BACKGROUND: '#fcfcfc',
    PRIMARY_TEXT: '#000000',
    SECONDARY_TEXT: '#818181',
    APP_PRIMARY_COLOR: '#08bd80',
    APP_SECONDARY_COLOR: '#1effaf',
    BORDER_COLOR: '#e0e0e0',
    HOVER_COLOR: '#efefef'
}

const darkTheme = {
    PRIMARY_BACKGROUND: '#0C111B',
    SECONDARY_BACKGROUND: '#171E2F',
    PRIMARY_TEXT: '#ffffff',
    SECONDARY_TEXT: '#9EA0A4',
    APP_PRIMARY_COLOR: '#08bd80',
    APP_SECONDARY_COLOR: '#1effaf',
    BORDER_COLOR: '#2b3455',
    HOVER_COLOR: '#232c47'
}

const selectedThemeColors = {
    lighter: '#F9D0FE',
    light: '#DC73FE',
    main: '#A917FE',
    dark: '#620BB6',
    darker: '#310479',
};

const homePageThemeBackground = {
    id: 1,
    theme: 'https://raw.githubusercontent.com/bavith-ekhool/demo/main/mvbjsdvgsd.png'
}

const initialState = {
    user: null,
    snackBar: false,
    snackBarMessage: 'Hello world',
    snackBarType: 'SUCCESS',
    appData: null,
    myPrograms: [],
    trendingVideos: [],
    topPrograms: [],
    hubData: [],
    programDetail: [],
    themeBuilderJson: {
        components: [],
        pageSettings: {}
    },
    themebuilderSelectedItem: null,
    themeBuilderPage: 'index',
    updateComponent: 0,
    allCourses: [],
    currentAffairs: [],
    courseDetails: [],
    themeBuilderSelectedStyle: null,
    extraComponents: extraComponents,
    themeColors: lightTheme,
    themebuilderSelectedComponent: null,
    exams: [],
    assignments: [],
    showBannerEditor: false,
    categoryPrograms: [],
    achievementData: [],
    customCss: '',
    selectedTheme: 'light',
    youtubeVideos: [],
    themeBuilderConfirmPopup: false,
    cartItems: [],
    showNotifications: false,
    notifications: [],
    showBannerPopup: true,
    searchPrograms: [],
    overallReport: null,
    bookmarkData: [],
    homepageJson: homepageJson,
    loginPageJson: loginPageJson,
    registerPageJson: registerPageJson,
    homePageThemeBackground: homePageThemeBackground,
    playerCurrentMaterial: null,
    darkTheme: false,
    allBundles: [],
    myBundles: [],
    youtubeDetail: null,
    currentThemeJson: {
        components: []
    },
    drawerOpened: true,
    mobileDrawerOpened: false,
    activeGroupChat: null,
    activityTimer: 0,
    videoMaterials: [],
    currentPlayerProgramId: null,
    apiQueue: [],
    mobileDrawerOpen: false,
    showSomethingWentWrong: false,
    playerCurrentProgram: null,
    playerNextMaterial: null,
    playerPreviousMaterial: null,
    allCategories: [],
    selectedCategory: null,
    videoNotes: [],
    selectedThemeColors: selectedThemeColors,
    allArticleCategories: [],
    pageJson: null,
    showLogoutPopup: false,
    showAnnouncementBar: false,
    isLoggedIn: false,
    device: devices.DESKTOP,
    authLoading: true,
    showAlert: false,
    shareModal: null,
    playerCurrentLesson: null,
    showMiniPlayer: false,
    isSiteBuilder: false,
    copiedSiteBuilderChild: null,
    siteBuilderSelectedChild: null,
    headers: { Authorization: '' },
    cartJson: CART_DEFAULT_JSON,
    showBlockProperties: false,
    layoutJson: defaultLayoutJson,
    siteBuilderHoveredChild: null,
    showSiteBuilderPreview: false,
    showChildAnimations: false,
    siteBuilderActiveSidebarMenu: 0,
    formJson: {
        inputs: []
    },
    formBuilderSelectedIndex: null,
    formBuilderConfirmPopup: false,
    formBuilderFocusedOptionIndex: null,
    accessDeniedData: null,
    siteBuilderSelectedPage: null,
    appBuilderJson: defaultAppHomeJson,
    appBuilderConfirmPopup: false,
    pauseExamTime: false,
    courseUpdatePercentage: 0,
    playerAllMaterialData:[],
    allSubCategories: [],
    review: []

}

const reducer = function reducer(state= initialState, action) {
    switch (action.type) {

        case reduxHelper.UPDATE_USER_DATA:
            return {
                ...state,
                user: action.payload
            }

    case reduxHelper.UPDATE_REVIEW_DATA:
        return {
            ...state,
            review: action.payload
        }    

        case reduxHelper.UPDATE_SNACK_BAR:
            return {
                ...state,
                snackBar: action.payload,
                snackBarMessage: action.message
            }

        case reduxHelper.UPDATE_APPLICATION_DATA:
            return {
                ...state,
                appData: action.payload
            }

        case reduxHelper.UPDATE_MY_PROGRAMS:
            return {
                ...state,
                myPrograms: action.payload
            }

        case reduxHelper.UPDATE_TRENDING_VIDEOS:
            return {
                ...state,
                trendingVideos: action.payload
            }

        case reduxHelper.UPDATE_POPULAR_COURSES:
            return {
                ...state,
                topPrograms: action.payload
            }

        case reduxHelper.UPDATE_HUB_DATA:
            return {
                ...state,
                hubData: action.payload
            }

        case reduxHelper.UPDATE_THEME_BUILDER_JSON:
            return {
                ...state,
                themeBuilderJson: action.payload
            }

        case reduxHelper.UPDATE_THEME_BUILDER_ITEM:
            return {
                ...state,
                themebuilderSelectedItem: action.payload
            }

        case reduxHelper.UPDATE_COMPONENT:
            return {
                ...state,
                updateComponent: 1
            }

        case reduxHelper.UPDATE_ALL_PROGRAMS:
            return {
                ...state,
                allCourses: action.payload
            }

        case reduxHelper.UPDATE_CURRENT_AFFAIRS_DATA:
            return {
                ...state,
                currentAffairs: action.payload
            }

        case reduxHelper.UPDATE_THEME_BUILDER_SELECTED_COMPONENT:
            return {
                ...state,
                themebuilderSelectedComponent: action.payload
            }

        case reduxHelper.UPDATE_MY_EXAMS:
            return {
                ...state,
                exams: action.payload
            }

        case reduxHelper.UPDATE_BANNER_EDITOR:
            return {
                ...state,
                showBannerEditor: action.payload
            }

        case reduxHelper.UPDATE_CATEGORY_PROGRAMS:
            return {
                ...state,
                categoryPrograms: action.payload
            }

        case reduxHelper.UPDATE_ACHIEVEMENT_DATA:
            return {
                ...state,
                achievementData: action.payload
            }

        case reduxHelper.UPDATE_CUSTOM_CSS:
            return {
                ...state,
                customCss: action.payload
            }

        case reduxHelper.UPDATE_THEME :
            return {
                ...state,
                darkTheme: action.payload
            }

        case reduxHelper.UPDATE_YOUTUBE_VIDEOS:
            return {
                ...state,
                youtubeVideos: action.payload
            }

        case reduxHelper.UPDATE_THEME_BUILDER_CONFIRM_POPUP:
            return {
                ...state,
                themeBuilderConfirmPopup: action.payload
            }

        case reduxHelper.UPDATE_CART_ITEMS:
            return {
                ...state,
                cartItems: action.payload
            }

        case reduxHelper.UPDATE_NOTIFICATION_POPUP:
            return {
                ...state,
                showNotifications: action.payload
            }

        case reduxHelper.UPDATE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }

        case reduxHelper.UPDATE_BANNER_POPUP:
            return {
                ...state,
                showBannerPopup: action.payload
            }

        case reduxHelper.UPDATE_ASSIGNMENT_DATA:
            return {
                ...state,
                assignments: action.payload
            }

        case reduxHelper.UPDATE_SEARCH_PROGRAMS:
            return {
                ...state,
                searchPrograms: action.payload
            }

        case reduxHelper.UPDATE_OVERALL_REPORT:
            return {
                ...state,
                overallReport: action.payload
            }

        case reduxHelper.UPDATE_BOOKMARK_DATA:
            return {
                ...state,
                bookmarkData: action.payload
            }

        case reduxHelper.UPDATE_HOMEPAGE_THEME_BACKGROUND:
            return {
                ...state,
                homePageThemeBackground: action.payload
            }

        case reduxHelper.UPDATE_PLAYER_CURRENT_MATERIAL:
            return {
                ...state,
                playerCurrentMaterial: action.payload
            }

        case reduxHelper.UPDATE_PROGRAM_DETAIL:
            return {
                ...state,
                programDetail: action.payload
            }

        case reduxHelper.UPDATE_ALL_BUNDLES:
            return {
                ...state,
                allBundles: action.payload
            }

        case reduxHelper.UPDATE_MY_BUNDLES:
            return {
                ...state,
                myBundles: action.payload
            }

        case reduxHelper.UPDATE_HOME_PAGE_JSON:
            return {
                ...state,
                homepageJson: action.payload
            }

        case reduxHelper.UPDATE_YOUTUBE_DETAIL:
            return {
                ...state,
                youtubeDetail: action.payload
            }

        case reduxHelper.UPDATE_CURRENT_THEME_JSON:
            return {
                ...state,
                currentThemeJson: action.payload
            }

        case reduxHelper.UPDATE_DRAWER:
            return {
                ...state,
                drawerOpened: action.payload
            }

        case reduxHelper.UPDATE_MOBILE_DRAWER_OPENED:
            return {
                ...state,
                mobileDrawerOpened: action.payload
            }

        case reduxHelper.UPDATE_ACTIVE_GROUP_CHAT:
            return {
                ...state,
                activeGroupChat: action.payload
            }

        case reduxHelper.UPDATE_ACTIVITY_TIMER: 
            return {
                ...state,
                activityTimer: action.payload
            }

        case reduxHelper.UPDATE_VIDEO_MATERIALS: 
            return {
                ...state,
                videoMaterials: action.payload
            }

        case reduxHelper.UPDATE_CURRENT_PLAYER_PROGRAM_ID:
            return {
                ...state,
                currentPlayerProgramId: action.payload
            }

        case reduxHelper.UPDATE_API_QUEUE:
            return {
                ...state,
                apiQueue: action.payload
            }

        case reduxHelper.UPDATE_MOBILE_DRAWER:
            return {
                ...state,
                mobileDrawerOpen: action.payload
            }

        case reduxHelper.UPDATE_SOMETHING_WENT_WRONG:
            return {
                ...state,
                showSomethingWentWrong: action.payload
            }

        case reduxHelper.UPDATE_THEME_BUILDER_PAGE:
            return {
                ...state,
                themeBuilderPage: action.payload
            }

        case reduxHelper.UPDATE_PLAYER_CURRENT_PROGRAM:
            return {
                ...state,
                playerCurrentProgram: action.payload
            }

        case reduxHelper.UPDATE_PLAYER_NEXT_MATERIAL:
            return {
                ...state,
                playerNextMaterial: action.payload
            }

        case reduxHelper.UPDATE_PLAYER_PREVIOUS_MATERIAL:
            return {
                ...state,
                playerPreviousMaterial: action.payload
            }

        case reduxHelper.UPDATE_ALL_CATEGORIES:
            return {
                ...state,
                allCategories: action.payload
            }

        case reduxHelper.UPDATE_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload
            }

        case reduxHelper.UPDATE_VIDEO_NOTES:
            return {
                ...state,
                videoNotes: action.payload
            }

        case reduxHelper.UPDATE_SELECTED_THEME_COLORS:
            return {
                ...state,
                selectedThemeColors: action.payload
            }

        case reduxHelper.UPDATE_ALL_ARTICLE_CATEGORIES:
            return {
                ...state,
                allArticleCategories: action.payload
            }

        case reduxHelper.UPDATE_PAGE_JSON:
            return {
                ...state,
                pageJson: action.payload
            }

        case reduxHelper.UPDATE_LOGOUT_POPUP:
            return {
                ...state,
                showLogoutPopup: action.payload
            }

        case reduxHelper.UPDATE_ANNOUNCEMENT_BAR:
            return {
                ...state,
                showAnnouncementBar: action.payload
            }
            
        case reduxHelper.UPDATE_LOGGED_STATUS:
            return {
                ...state,
                isLoggedIn: action.payload
            }

        case reduxHelper.UPDATE_DEVICE:
            return {
                ...state,
                device: action.payload
            }

        case reduxHelper.UPDATE_AUTH_LOADING:
            return {
                ...state,
                authLoading: action.payload
            }

        case reduxHelper.UPDATE_ALERT:
            return {
                ...state,
                showAlert: action.payload
            }

        case reduxHelper.UPDATE_SHARE_MODAL:
            return {
                ...state,
                shareModal: action.payload
            }
            
        case reduxHelper.UPDATE_PLAYER_CURRENT_LESSON:
            return {
                ...state,
                playerCurrentLesson: action.payload
            }

        case reduxHelper.UPDATE_MINI_PLAYER:
            return {
                ...state,
                showMiniPlayer: action.payload
            }

        case reduxHelper.UPDATE_IS_SITE_BUILDER: 
            return {
                ...state,
                isSiteBuilder: action.payload
            }

        case reduxHelper.UPDATE_COPIED_SITE_BUILDER_CHILD:
            return {
                ...state,
                copiedSiteBuilderChild: action.payload
            }

        case reduxHelper.UPDATE_SITE_BUILDER_SELECTED_CHILD:
            return {
                ...state,
                siteBuilderSelectedChild: action.payload
            }

        case reduxHelper.UPDATE_HEADERS: 
            return {
                ...state,
                headers: action.payload
            }

        case reduxHelper.UPDATE_CART_JSON:
            return {
                ...state,
                cartJson: action.payload
            }

        case reduxHelper.UPDATE_BLOCK_PROPERTIES:
            return {
                ...state,
                showBlockProperties: action.payload
            }

        case reduxHelper.UPDATE_LAYOUT_JSON:
            return {
                ...state,
                layoutJson: action.payload
            }

        case reduxHelper.UPDATE_SITE_BUILDER_HOVERED_CHILD:
            return {
                ...state,
                siteBuilderHoveredChild: action.payload
            }

        case reduxHelper.UPDATE_SITE_BUILDER_PREVIEW:
            return {
                ...state,
                showSiteBuilderPreview: action.payload
            }

        case reduxHelper.UPDATE_CHILD_ANIMATIONS:
            return {
                ...state,
                showChildAnimations: action.payload
            }

        case reduxHelper.UPDATE_SITE_BUILDER_SIDEBAR_MENU:
            return {
                ...state,
                siteBuilderActiveSidebarMenu: action?.payload
            }

        case reduxHelper.UPDATE_FORM_JSON:
            return {
                ...state,
                formJson: action.payload
            }

        case reduxHelper.UPDATE_FORM_BUILDER_SELECTED_INDEX:
            return {
                ...state,
                formBuilderSelectedIndex: action.payload
            }

        case reduxHelper.UPDATE_FORM_BUILDER_CONFIRM_POPUP:
            return {
                ...state,
                formBuilderConfirmPopup: action.payload
            }

        case reduxHelper.UPDATE_FORM_BUILDER_FOCUSED_OPTION_INDEX:
            return {
                ...state,
                formBuilderFocusedOptionIndex: action.payload
            }

        case reduxHelper.UPDATE_ACCESS_DENIED:
            return {
                ...state,
                accessDeniedData: action?.payload
            }

        case reduxHelper.UPDATE_SITE_BUILDER_SELECTED_PAGE:
            return {
                ...state,
                siteBuilderSelectedPage: action.payload
            }

        case reduxHelper.UPDATE_APP_BUILDER_JSON:
            return {
                ...state,
                appBuilderJson: action.payload
            }
        
        case reduxHelper.UPDATE_APP_BUILDER_CONFIRM_POPUP:
            return {
                ...state,
                appBuilderConfirmPopup: action.payload
            }

        case reduxHelper.PAUSE_VIDEO_TIME:
            return{
                ...state,
                pauseExamTime: action.payload
            }    

        case reduxHelper.COURSE_UPDATE_PERCENTAGE:
            return{
                ...state,
                courseUpdatePercentage: action.payload
            } 

        case reduxHelper.ALL_MATERIAL_DATA:
            return{
                ...state,
                playerAllMaterialData: action.payload
            } 

        case reduxHelper.UPDATE_ALL_SUB_CATEGORIES:
            return{
                ...state,
                allSubCategories: action.payload
            } 
            

        default:
            return state;

    }
};

const combineReducer = combineReducers({
    reducer
});

const initialStore = () => {
    return createStore(combineReducer)
};

export const wrapper = createWrapper(initialStore)
