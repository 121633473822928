import { DarkModeRounded, LightModeRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { reduxHelper } from "../../config/reduxHelper";
import { useSetTheme } from "../../hooks/useSetTheme";

const ThemeSwicth = () => {

    const { reducer } = useSelector((state) => state);
    const { darkTheme } = reducer;

    const dispatch = useDispatch();

    const updateTheme = async () => {
        dispatch({
            type: reduxHelper.UPDATE_THEME,
            payload: !darkTheme
        })
        useSetTheme(!darkTheme)
    }

    return (
        <>
            <IconButton
                size="small"
                onClick={() => {
                    updateTheme()
                }}
            >
                {
                    darkTheme ? <DarkModeRounded/> : <LightModeRounded/>
                }
            </IconButton>
        </>
    )
};

export default ThemeSwicth;