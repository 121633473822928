import {HTTP_REST_URLS} from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export const useAnalyticsData = () => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.GET_ANALYTICS_DATA;
   
    fetch(url)
        .then((res) => (res.json()))
        .then(async(response) => {

            const resultValue = await useApiMiddleware(response)  
            if(!resultValue.error)
            {
                
                const { data } = resultValue;
                
                if(data)
                {
                    const arrayData = Object.values(data);
                    const filterResult = arrayData?.filter((item) => (item?.basic?.active == 1));
                    
                    resolve(filterResult)   
                }
            }
            else{
                return data;
            }
           
        })
})