import PropTypes from 'prop-types';

import {useEffect, useState} from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Link,
  IconButton,
 
} from '@mui/material';
import {IconButtonAnimate} from "../animate";
import Iconify from "../Iconify";
import MenuPopover from "../extras/MenuPopover";
import Scrollbar from "../extras/ScrollBar";
import Cookies from "js-cookie";
import {useRouter} from "next/router";
import {useDispatch, useSelector} from "react-redux";
import {useNotifications} from "../../hooks/useNotifications";
import {reduxHelper} from "../../config/reduxHelper";
import NoDataComponent from '../nodata/nodata';
import ComingSoonIllustration from '../../assets/illustration_coming_soon';
import { TOKEN_KEY } from '../../config/key';
// utils


// ----------------------------------------------------------------------

export default function NotificationsPopover(props) {

  const userToken = Cookies.get(TOKEN_KEY)
  const headers = { 'Authorization': userToken ? userToken : '' };

  const router = useRouter();
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {

    const result = await useNotifications(headers)
    setNotifications(result)
    dispatch({
      type: reduxHelper.UPDATE_NOTIFICATIONS,
      payload: result
    })

  }

  useEffect(() => {
    getNotifications()
  }, [])

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }} {...props} >
        <Badge badgeContent={0} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {notifications?.length} Notifications
            </Typography>
          </Box>

          {0 > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {
          notifications.length > 1 &&
          <Box sx={{ height: { xs: 340, sm: 'auto', maxHeight: 340, overflowY: 'scroll' } }}>
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  All
                </ListSubheader>
              }
            >
              {notifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>
          </Box>
        }

        {/* {
            notifications.length < 1 &&
            <Box
              height={300}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <ComingSoonIllustration
                sx={{
                  my: 3, 
                  height: 150
                }}
              />
              <Typography>No Notifications found</Typography>
            </Box>
          } */}

          {
              notifications.length < 1 &&
              <NoDataComponent
                title={
                  <Typography>No notifications till now</Typography>
                }
                height={250}
                alignItems={'center'}
                justifyContent={'center'}
              />
          }

          {
            notifications?.length > 0 &&
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />

              <Box sx={{ p: 1 }}>
                <Link href='/notifications' underline='none'>
                    <Button fullWidth disableRipple onClick={() => { router.push('/notifications') }} >
                      View All
                    </Button>
                </Link>                
              </Box>
            </>
          }
        
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {

  const router = useRouter()

  return (

    <Link href={`/notification/${notification.id}`} underline='none'>  
        <ListItemButton
          sx={{
            py: 1.5,
            px: 2.5,
            mt: '1px',
            ...(notification.isUnRead && {
              bgcolor: 'action.selected',
            }),
          }}
          onClick={() => { router.push(`/notification/${notification.id}`) }}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'background.neutral' }}>A</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={notification?.an_title}
            secondary={
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled',
                }}
              >
                {/*<Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />*/}
                {notification?.pb_title}
              </Typography>
            }
          />
        </ListItemButton>
    </Link>
  );
}
