import { Box, useScrollTrigger, useTheme, Zoom } from "@mui/material";

const ScrollTop = (props) => {

    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
            sx={{ 
                    position: 'fixed', 
                    bottom: 16, 
                    right:15,
                    backgroundColor: `${PRIMARY_MAIN} !important`,
                    borderRadius: 150,
                    '&:hover': {
                        backgroundColor: `${PRIMARY_DARK} !important`
                    },
                    zIndex: 10000
                }}
            >
                {children}
            </Box>
        </Zoom>
    );
};

export default ScrollTop