import { PROGRAM_LAST_MATERIAL } from "../config/key";


export const useProgramCacheData = () => new Promise((resolve, reject) => {

    const result = localStorage.getItem(PROGRAM_LAST_MATERIAL);

    if(result != null && result != '')
    {
        const parsedData = JSON.parse(result)
        if(Array.isArray(parsedData))
        {
            resolve(parsedData)
        }
        else
        {
            resolve([])
        }
    }
    else
    {
        resolve([])
    }

})