import { Card, CardContent, CardMedia, Chip, Grid, Link, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NO_IMAGE } from "../config/config";
import { useSubCategoryArticles } from '../hooks/useSubCategoryArticles'
import { useBlogs } from '../hooks/useBlogs'

import useMediaQuery from '@mui/material/useMediaQuery';
import { getString } from "../functions/getString";
import { useArticleCategories } from "../hooks/useArticleCategories";
import Loading from '../components/Loading/loading'
import NoDataComponent from '../components/nodata/nodata'
import { SearchRounded } from "@mui/icons-material";

const CustomArticles = ({ item, parentItem, ...props }) => {

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder } = reducer;

    const [isLoading, setLoading] = useState(true);
    const [allArticles, setAllArticles] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)

    const { articles } = parentItem || {};

    const getArticleCategories = async () => {
        const result = await useArticleCategories()
        setAllCategories(result)
        if(Array.isArray(result) && result?.length > 0)
        {
            setSelectedCategory(result[0])
        }
    }

    useEffect(() => {
        getArticleCategories()
    }, [])
    
    const getBlogData = async () => {
        setLoading(true)
        const result = await useBlogs(selectedCategory?.ac_slug)
        setAllArticles(result)
        setLoading(false)
    }

    useEffect(() => {
        getArticleData()
    }, [])
   
    const theme = useTheme();
    const { push } = useRouter();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const DIVIDER = theme.palette.divider;

    const [searchText, setSearchText] = useState('')

    const [filterdData, setFilteredData] = useState([])

    const getArticleData = async () => {
        setLoading(true)
        const result = await useSubCategoryArticles(`?slugs=${articles?.toString()}`)
        setAllArticles(result?.data)
        setFilteredData(result?.data)
        setLoading(false)
    }

    
function searchCourses(title){
        
    const newData = allArticles.filter(item => {
        const itemData = item.a_title.toUpperCase()
        const textData = searchText.toUpperCase()
        return itemData.indexOf(textData) > -1
    })

    setFilteredData(newData)

}

useEffect(() => {
    searchCourses()
}, [searchText])

    
    return (
        <Stack>

<TextField
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value)
                }}
                fullWidth
                placeholder={getString('Search articles')}
                InputProps={{
                    startAdornment: <SearchRounded/>
                }}
                sx={{
                    width: '92vw',
                    mt: 4
                }}
            />

            {/* <Stack direction={'row'} gap={2} my={2} overflow={'hidden'} sx={{ overflowX: 'scroll' }} className={'hide-scrollbar'} px={2} alignSelf={'center'} maxWidth={'100vw'} >
                {
                    allCategories?.map((item, index) => {

                        const isSelected = item?.id == selectedCategory?.id

                        return(
                            <Chip 
                                label={item?.label} 
                                sx={{
                                    bgcolor: isSelected ? '#000' : 'grey.200',
                                    color: isSelected ? '#fff' : 'grey.800'
                                }}
                                onClick={() => {
                                    setSelectedCategory(item)
                                }}
                            />
                        )
                    })
                }
            </Stack> */}

            {
                !isLoading && !isSiteBuilder && filterdData?.length < 1 &&
                <NoDataComponent
                    title={'No articles found'}
                />
            }

            

            <Grid container spacing={3} mt={0} mb={4} >

                {/* {
                    isSiteBuilder && Array(5)?.fill(1)?.map((item, index) => {
                        return (
                            <Grid item xl={2.4} lg={3} md={4} sm={6} xs={12} key={index} >
                                <Card 
                                    sx={{ 
                                        borderRadius: "0 0 8px 8px",
                                        '&:hover': {
                                            boxShadow: 'none'
                                        },
                                        border: `1px solid ${DIVIDER}`,
                                        // height: '100%'
                                    }}
                                >
                                    <CardMedia
                                        image={NO_IMAGE}
                                        component="img"
                                        height={200}
                                    />
                                    <CardContent>
                                        <Typography mb={1} fontSize={12} >{getString('Article')}</Typography>
                                        <Typography>{getString('Article name here')}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                } */}

                {
                    !isSiteBuilder && filterdData?.map((item, index) => {
                        return (
                            <Grid item xl={2.4} lg={3} md={4} sm={6} xs={12} key={index} >
                                <Link href={`/a/${item?.a_slug}`} underline="none">
                                    <Card 
                                        sx={{ 
                                            borderRadius: "0 0 8px 8px",
                                            cursor: 'pointer',
                                            '&:hover': {
                                                boxShadow: 'none'
                                            },
                                            border: `none`,
                                            // height: '100%'
                                        }}
                                    >
                                        
                                        <Stack height={185} >
                                            <img src={item?.a_image} style={{ height: '100%', width: '100%' }} />
                                        </Stack>
                                        <CardContent style={{textAlign: isSmScreen ? "center" : "",}}>
                                            <Typography mb={1} fontSize={12} >{getString('Article')}</Typography>
                                            <Typography>{item?.a_title}</Typography>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        )
                    })
                }

            </Grid>
        
        </Stack>
    )
};

export default CustomArticles;