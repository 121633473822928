import { HTTP_REST_URLS } from "../config/urls";

export const useBlogs = (id) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.GET_CATEGORY_BLOGS + id

    fetch(url, {
        method: 'GET'
    })
        .then((res) => (res.json()))
        .then((response) => {
            
            if(Array.isArray(response?.articles))
            {
                response?.articles?.map((i) => {
                    i['a_image'] = i?.a_image?.replace('[CLOUDFRONT_URL]', response?.cloudfront_url)
                })

                resolve(response?.articles)
            }
            else
            {
                resolve([])
            }

        })

})