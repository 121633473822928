export const useJsonData = (url) => new Promise((resolve, reject) => {

    let responseObject = {};

    if(!url)
    {
        responseObject['error'] = true;
        responseObject['message'] = 'Something went wrong.';
        resolve(responseObject)
    }

    fetch(url)
        .then((res) => (res.json()))
        .then((response) => {

            responseObject['error'] = false;
            responseObject['message'] = 'Data found';
            responseObject['data'] = response;
            resolve(responseObject)

        })
        .catch((error) => {

            responseObject['error'] = true;
            responseObject['message'] = 'Something went wrong.';
            resolve(responseObject)

        })

})