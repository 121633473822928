import { allComponents } from "../siteThemes/allComponents"
import { NO_IMAGE } from "./config";

export const defaultSearchJson = {
    "components": [],
    "pageSettings": {},
    "pageStyle": {
        "backgroundColor": "#ffffff00"
    }
}

export const defaultCategoryJson = {
    "components": [
        {
            "key": "hero5",
            "name": "Hero",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero5.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components",
                "images"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "backgroundColor": "#000000",
                "boxShadow": "0px 0px 0px 0px #000000",
                "backgroundImage": "url(https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_1842f1007e91baa66e4c59d0fdc383a0.png)",
                "paddingTop": 70,
                "paddingBottom": 70,
                "backgroundSize": "cover",
                "backgroundRepeat": "no-repeat",
                "paddingLeft": 120,
                "paddingRight": 120
            },
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#ffffff",
                            "fontWeight": 600,
                            "fontSize": 48
                        }
                    },
                    "url": "",
                    "children": "Certified online courses for {{CATEGORY_NAME}}",
                    "type": "typography",
                    "name": "Text",
                    "key": "hero5"
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 20,
                            "color": "#fdfeff"
                        }
                    },
                    "url": "",
                    "children": "Top instructore from millions of students on us. We provide the tools and skills to study what you love.",
                    "type": "typography",
                    "name": "Text",
                    "key": "hero5"
                }
            ],
            "id": 4,
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "name": "Category Programs3",
            "key": "categoryPrograms3",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            },
            "style": {
                "backgroundColor": "#e7e7e7",
                "boxShadow": "0px 0px 0px 0px #000000"
            }
        }
    ],
    "pageSettings": {},
    "pageStyle": {
        "backgroundColor": "#ffffff00"
    }
}

export const defaultContactUsJson = {
  "components": [],
  "pageSettings": {},
  "pageStyle": {
      "backgroundColor": "#ffffff00"
  }
}

export const defaultPageJson = {
    "components": [
        {
            "key": "header4",
            "name": "Header",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": -2,
                        "name": "Home",
                        "path": "/",
                        "parentId": 1
                    },
                    "children": "Home",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false
                },
                {
                    "id": 8.677769614650007,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal",
                    "showEditor": true
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": -2,
                        "name": "After login",
                        "path": "/all-courses",
                        "parentId": 1
                    },
                    "children": "Courses",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "id": 4,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": "12",
                        "name": "contact us",
                        "path": "/p/contact-us",
                        "parentId": 1
                    },
                    "children": "Contact",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "id": 9,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": "13",
                        "name": "about us",
                        "path": "/p/about-us",
                        "parentId": 1
                    },
                    "children": "About",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "id": 6,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "/cart"
                    },
                    "children": "cart",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "id": 5,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "variant": "contained",
                        "visibilityporps": {
                            "visibleTo": {
                                "name": "Logged out users",
                                "value": "logged_out"
                            }
                        }
                    },
                    "url": "/register",
                    "children": "Register",
                    "type": "button",
                    "name": "Button",
                    "label": "Register"
                },
                {
                    "props": {
                        "variant": "contained",
                        "visibilityporps": {
                            "visibleTo": {
                                "name": "Logged out users",
                                "value": "logged_out"
                            }
                        }
                    },
                    "url": "/login",
                    "children": "Login",
                    "type": "button",
                    "name": "Button",
                    "label": "Login"
                }
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #cac5d8",
                "paddingTop": 0,
                "paddingBottom": 0,
                "paddingLeft": 10,
                "paddingRight": 10
            },
            "id": 1
        },
        {
            "key": "columnLayout",
            "name": "Layout",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/columnLayout.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#ffffff",
                            "fontSize": 48,
                            "fontWeight": 600,
                            "textAlign": "left"
                        },
                        "animation": false
                    },
                    "url": "",
                    "children": "{{PAGE_TITLE}}",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#3a0002",
                            "fontWeight": 800
                        }
                    },
                    "url": "",
                    "children": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                    "type": "typography",
                    "name": "Text"
                }
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingLeft": 120,
                "paddingRight": 120,
                "backgroundImage": "url(https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_0b7b4fbfd1bf3bfff23ca975bdc5bb76.jpg)",
                "backgroundSize": "cover",
                "backgroundRepeat": "no-repeat",
                "paddingTop": 90,
                "paddingBottom": 90,
                "alignItems": "auto",
                "justifyContent": "auto"
            },
            "id": 3,
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "id": 4,
            "name": "Layout",
            "key": "cardLayout",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/layoutGrid.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingTop": 70,
                "paddingBottom": 70,
                "paddingLeft": 120,
                "paddingRight": 120,
                "alignItems": "center"
            },
            "children": [
                {
                    "props": {
                        "style": {
                            "fontSize": 40,
                            "fontWeight": 600,
                            "color": "#212B36",
                            "textAlign": "center"
                        }
                    },
                    "url": "",
                    "children": "Title for this section",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "variant": "vertical",
                        "sx": {
                            "height": 20
                        }
                    },
                    "variant": "vertical",
                    "type": "spacer",
                    "name": "Spacer",
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "variant": "vertical",
                        "sx": {
                            "height": 20
                        }
                    },
                    "variant": "vertical",
                    "type": "spacer",
                    "name": "Spacer",
                    "id": 10
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 24,
                            "fontWeight": 600,
                            "color": "#212B36",
                            "textAlign": "left"
                        }
                    },
                    "url": "",
                    "children": "Where does it come from?",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, \"Lorem ipsum dolor sit amet..\", comes from a line in section 1.10.32.",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "variant": "vertical",
                        "sx": {
                            "height": 20
                        }
                    },
                    "variant": "vertical",
                    "type": "spacer",
                    "name": "Spacer",
                    "id": 9
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 24,
                            "fontWeight": 600,
                            "color": "#212B36",
                            "textAlign": "left"
                        }
                    },
                    "url": "",
                    "children": "Why do we use it?",
                    "type": "typography",
                    "name": "Text",
                    "id": 7,
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false
                },
                {
                    "props": {
                        "variant": "vertical",
                        "sx": {
                            "height": 20
                        }
                    },
                    "variant": "vertical",
                    "type": "spacer",
                    "name": "Spacer",
                    "id": 9
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 24,
                            "fontWeight": 600,
                            "color": "#212B36",
                            "textAlign": "left"
                        }
                    },
                    "url": "",
                    "children": "Where can I get some?",
                    "type": "typography",
                    "name": "Text",
                    "id": 9,
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false,
                    "id": 2
                }
            ],
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "key": "hero5",
            "name": "Hero",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero5.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components",
                "images"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingTop": 0,
                "paddingBottom": 0,
                "backgroundSize": "cover",
                "backgroundRepeat": "no-repeat",
                "paddingLeft": 120,
                "paddingRight": 120
            },
            "children": [
                {
                    "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_645a2c61656f17a18539016be7ac3dca.png",
                    "type": "image",
                    "id": 1,
                    "style": {
                        "height": 362,
                        "width": 362
                    },
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 600,
                            "fontSize": 48
                        }
                    },
                    "url": "",
                    "children": "Investing in knowledge and your future",
                    "type": "typography",
                    "id": 2
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 20
                        }
                    },
                    "url": "",
                    "children": "Top instructore from millions of students on us. We provide the tools and skills to study what you love.",
                    "type": "typography",
                    "id": 3
                },
                {
                    "props": {
                        "color": "info",
                        "variant": "contained"
                    },
                    "url": null,
                    "children": "Lets go",
                    "type": "button",
                    "name": "Button",
                    "label": "Lets go",
                    "id": 4
                }
            ],
            "id": 6,
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "key": "hero6",
            "name": "Hero",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero6.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components",
                "images"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "children": [
                {
                    "props": {
                        "style": {
                            "fontSize": 48,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "One platform many courses for you",
                    "type": "typography",
                    "id": 4,
                    "showEditor": false
                },
                {
                    "props": {},
                    "url": "",
                    "children": "Increasingly many people use digital media for learning and for continuing their education. E-learning content formats that are individually modified to meet each learner’s needs are fundamental for successful learning.",
                    "type": "typography",
                    "id": 2,
                    "showEditor": false
                },
                {
                    "props": {
                        "color": "info",
                        "variant": "contained"
                    },
                    "url": null,
                    "children": "Search Courses",
                    "type": "button",
                    "name": "Button",
                    "label": "Search Courses",
                    "id": 3,
                    "showEditor": false
                },
                {
                    "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_6b91e2f4c014127ff56d02d8d25763b5.png",
                    "type": "image",
                    "id": 1,
                    "style": {
                        "height": 373,
                        "width": 374
                    },
                    "showEditor": false
                }
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingTop": 0,
                "paddingBottom": 0,
                "paddingLeft": 120,
                "paddingRight": 120
            },
            "id": 5
        },
        {
            "id": 5,
            "name": "Footer",
            "key": "footer3",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "content": {
                "content": ""
            },
            "style": {
                "backgroundColor": "#ECEEF9",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingTop": 70,
                "paddingBottom": 70,
                "paddingLeft": 120,
                "paddingRight": 120
            },
            "children": [
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.facebook.com/"
                    },
                    "children": "facebook",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://twitter.com/"
                    },
                    "children": "twitter",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.instagram.com/"
                    },
                    "children": "instagram",
                    "type": "icon",
                    "name": "Icon",
                    "showEditor": false
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.youtube.com/"
                    },
                    "children": "youtube",
                    "type": "icon",
                    "name": "Icon",
                    "showEditor": false
                },
                {
                    "id": 0.38514678468029695,
                    "props": {},
                    "url": "",
                    "children": "An innovative educational Learning Management System with more than 100 interactive functions, a capacity for more than 1 million students.",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontSize": 16,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "Social links",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.facebook.com/"
                    },
                    "children": "Facebook",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.youtube.com/"
                    },
                    "children": "Youtube",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://twitter.com/"
                    },
                    "children": "Twitter",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.instagram.com/"
                    },
                    "children": "Instagram",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontSize": 16,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "Quick links",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": -3,
                        "name": "Search",
                        "path": "/search",
                        "parentId": 1
                    },
                    "children": "All courses",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "12",
                        "name": "contact us",
                        "path": "/p/contact-us",
                        "parentId": 1
                    },
                    "children": "Contact us",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "13",
                        "name": "about us",
                        "path": "/p/about-us",
                        "parentId": 1
                    },
                    "children": "About us",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "Articles",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontSize": 16,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "Policies",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "14",
                        "name": "privacy",
                        "path": "/p/privacy",
                        "parentId": 1
                    },
                    "children": "Privacy policy",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "15",
                        "name": "refund",
                        "path": "/p/refund",
                        "parentId": 1
                    },
                    "children": "Refund policy",
                    "type": "typography",
                    "name": "Text"
                }
            ],
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        }
    ],
    "pageSettings": {},
    "pageStyle": {
        "backgroundAttachment": "fixed",
        "backgroundRepeat": "no-repeat",
        "backgroundSize": "cover",
        "backgroundPosition": "100%"
    }
}

const headerData = allComponents?.filter((i) => (i?.name == 'Header'))[0]?.children[0];
const footerData = allComponents?.filter((i) => (i?.name == 'Footer'))[0]?.children[0];

export const defaultLayoutJson = {
    "components": [
        // {
        //     "key": "header4",
        //     "name": "Header",
        //     "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png",
        //     "editorConfig": [
        //         "dimensions",
        //         "spacing",
        //         "borderRadius",
        //         "shadow",
        //         "color",
        //         "border",
        //         "components"
        //     ],
        //     "screens": [
        //         "login",
        //         "register",
        //         "home"
        //     ],
        //     "children": [
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontWeight": 500
        //                 }
        //             },
        //             "url": {
        //                 "id": -2,
        //                 "name": "After login",
        //                 "path": "/dashboard",
        //                 "parentId": 1
        //             },
        //             "children": "Home",
        //             "type": "typography",
        //             "name": "Text",
        //             "showEditor": false
        //         },
        //         {
        //             "id": 8.677769614650007,
        //             "props": {},
        //             "type": "spacer",
        //             "name": "Spacer",
        //             "variant": "horizontal"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontWeight": 500
        //                 }
        //             },
        //             "url": {
        //                 "id": -2,
        //                 "name": "After login",
        //                 "path": "/dashboard",
        //                 "parentId": 1
        //             },
        //             "children": "Courses",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "id": 4,
        //             "props": {},
        //             "type": "spacer",
        //             "name": "Spacer",
        //             "variant": "horizontal"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontWeight": 500
        //                 }
        //             },
        //             "url": {
        //                 "id": "12",
        //                 "name": "contact us",
        //                 "path": "/p/contact-us",
        //                 "parentId": 1
        //             },
        //             "children": "Contact",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "id": 9,
        //             "props": {},
        //             "type": "spacer",
        //             "name": "Spacer",
        //             "variant": "horizontal"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontWeight": 500
        //                 }
        //             },
        //             "url": {
        //                 "id": "13",
        //                 "name": "about us",
        //                 "path": "/p/about-us",
        //                 "parentId": 1
        //             },
        //             "children": "About",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "id": 6,
        //             "props": {},
        //             "type": "spacer",
        //             "name": "Spacer",
        //             "variant": "horizontal"
        //         },
        //         {
        //             "props": {},
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "/cart"
        //             },
        //             "children": "cart",
        //             "type": "icon",
        //             "name": "Icon"
        //         },
        //         {
        //             "id": 5,
        //             "props": {},
        //             "type": "spacer",
        //             "name": "Spacer",
        //             "variant": "horizontal"
        //         },
        //         {
        //             "props": {
        //                 "variant": "contained",
        //                 "visibilityporps": {
        //                     "visibleTo": {
        //                         "name": "Logged out users",
        //                         "value": "logged_out"
        //                     }
        //                 }
        //             },
        //             "url": "/register",
        //             "children": "Register",
        //             "type": "button",
        //             "name": "Button",
        //             "label": "Register"
        //         },
        //         {
        //             "props": {
        //                 "variant": "contained",
        //                 "visibilityporps": {
        //                     "visibleTo": {
        //                         "name": "Logged out users",
        //                         "value": "logged_out"
        //                     }
        //                 }
        //             },
        //             "url": "/login",
        //             "children": "Login",
        //             "type": "button",
        //             "name": "Button",
        //             "label": "Login"
        //         }
        //     ],
        //     "style": {
        //         "backgroundColor": "#ffffff00",
        //         "boxShadow": "0px 0px 0px 0px #cac5d8",
        //         "paddingTop": 0,
        //         "paddingBottom": 0,
        //         "paddingLeft": 10,
        //         "paddingRight": 10
        //     },
        //     "id": 1
        // },
        // {
        //     "id": 5,
        //     "name": "Footer",
        //     "key": "footer3",
        //     "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png",
        //     "editorConfig": [
        //         "dimensions",
        //         "spacing",
        //         "borderRadius",
        //         "shadow",
        //         "color",
        //         "border",
        //         "components"
        //     ],
        //     "screens": [
        //         "login",
        //         "register",
        //         "home"
        //     ],
        //     "content": {
        //         "content": ""
        //     },
        //     "style": {
        //         "backgroundColor": "#ECEEF9",
        //         "boxShadow": "0px 0px 0px 0px #000000",
        //         "paddingTop": 70,
        //         "paddingBottom": 70,
        //         "paddingLeft": 120,
        //         "paddingRight": 120
        //     },
        //     "children": [
        //         {
        //             "props": {},
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://www.facebook.com/"
        //             },
        //             "children": "facebook",
        //             "type": "icon",
        //             "name": "Icon"
        //         },
        //         {
        //             "props": {},
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://twitter.com/"
        //             },
        //             "children": "twitter",
        //             "type": "icon",
        //             "name": "Icon"
        //         },
        //         {
        //             "props": {},
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://www.instagram.com/"
        //             },
        //             "children": "instagram",
        //             "type": "icon",
        //             "name": "Icon"
        //         },
        //         {
        //             "props": {},
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://www.youtube.com/"
        //             },
        //             "children": "youtube",
        //             "type": "icon",
        //             "name": "Icon",
        //             "showEditor": false
        //         },
        //         {
        //             "id": 0.38514678468029695,
        //             "props": {},
        //             "url": "",
        //             "children": "An innovative educational Learning Management System with more than 100 interactive functions, a capacity for more than 1 million students.",
        //             "type": "typography",
        //             "name": "Text",
        //             "showEditor": false
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontSize": 16,
        //                     "fontWeight": 600
        //                 }
        //             },
        //             "url": "",
        //             "children": "Social links",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://www.facebook.com/"
        //             },
        //             "children": "Facebook",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://www.youtube.com/"
        //             },
        //             "children": "Youtube",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://twitter.com/"
        //             },
        //             "children": "Twitter",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": 0,
        //                 "name": "Url",
        //                 "path": "https://www.instagram.com/"
        //             },
        //             "children": "Instagram",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontSize": 16,
        //                     "fontWeight": 600
        //                 }
        //             },
        //             "url": "",
        //             "children": "Quick links",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": -3,
        //                 "name": "Search",
        //                 "path": "/search",
        //                 "parentId": 1
        //             },
        //             "children": "All courses",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": "12",
        //                 "name": "contact us",
        //                 "path": "/p/contact-us",
        //                 "parentId": 1
        //             },
        //             "children": "Contact us",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": "13",
        //                 "name": "about us",
        //                 "path": "/p/about-us",
        //                 "parentId": 1
        //             },
        //             "children": "About us",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": "",
        //             "children": "Articles",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36",
        //                     "fontSize": 16,
        //                     "fontWeight": 600
        //                 }
        //             },
        //             "url": "",
        //             "children": "Policies",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": "14",
        //                 "name": "privacy",
        //                 "path": "/p/privacy",
        //                 "parentId": 1
        //             },
        //             "children": "Privacy policy",
        //             "type": "typography",
        //             "name": "Text"
        //         },
        //         {
        //             "props": {
        //                 "style": {
        //                     "color": "#212B36"
        //                 }
        //             },
        //             "url": {
        //                 "id": "15",
        //                 "name": "refund",
        //                 "path": "/p/refund",
        //                 "parentId": 1
        //             },
        //             "children": "Refund policy",
        //             "type": "typography",
        //             "name": "Text"
        //         }
        //     ],
        //     "properties": {
        //         "devices": [
        //             "pc",
        //             "tab",
        //             "mobile"
        //         ],
        //         "visibleTo": null
        //     }
        // },
    ],
    "pageSettings": {},
    "pageStyle": {
        "backgroundColor": "#ffffff00"
    },
    isDefault: true,
    type: 'layout'
}

export const defaultHomeJson = {
    "components": [
        {
            "key": "header4",
            "name": "Header",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": -2,
                        "name": "Home",
                        "path": "/",
                        "parentId": 1
                    },
                    "children": "Home",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false
                },
                {
                    "id": 8.677769614650007,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": -2,
                        "name": "After login",
                        "path": "/all-courses",
                        "parentId": 1
                    },
                    "children": "Courses",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "id": 4,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": "12",
                        "name": "contact us",
                        "path": "/p/contact-us",
                        "parentId": 1
                    },
                    "children": "Contact",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "id": 9,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 500
                        }
                    },
                    "url": {
                        "id": "13",
                        "name": "about us",
                        "path": "/p/about-us",
                        "parentId": 1
                    },
                    "children": "About",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "id": 6,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "/cart"
                    },
                    "children": "cart",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "id": 5,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                },
                {
                    "props": {
                        "variant": "contained",
                        "visibilityporps": {
                            "visibleTo": {
                                "name": "Logged out users",
                                "value": "logged_out"
                            }
                        }
                    },
                    "url": "/register",
                    "children": "Register",
                    "type": "button",
                    "name": "Button",
                    "label": "Register"
                },
                {
                    "props": {
                        "variant": "contained",
                        "visibilityporps": {
                            "visibleTo": {
                                "name": "Logged out users",
                                "value": "logged_out"
                            }
                        }
                    },
                    "url": "/login",
                    "children": "Login",
                    "type": "button",
                    "name": "Button",
                    "label": "Login"
                }
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #cac5d8",
                "paddingTop": 0,
                "paddingBottom": 0,
                "paddingLeft": 10,
                "paddingRight": 10
            },
            "id": 1
        },
        {
            "name": "Ticker",
            "key": "ticker1",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/ticker_1.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "id": 7,
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "An innovative educational Learning Management System with more than 100 interactive functions, a capacity for more than 1 million students.",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "sx": {
                            "width": 50
                        }
                    },
                    "variant": "horizontal",
                    "type": "spacer",
                    "name": "Spacer"
                }
            ],
            "style": {
                "paddingLeft": 0,
                "paddingRight": 0,
                "backgroundColor": "#ECEEF9",
                "boxShadow": "0px 0px 0px 0px #000000"
            }
        },
        {
            "key": "hero5",
            "name": "Hero",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero5.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components",
                "images"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000",
                "backgroundImage": "url(https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_27457ae2f3357f5c44d1b3e0cdc82a45.png)",
                "paddingTop": 70,
                "paddingBottom": 70,
                "backgroundSize": "cover",
                "backgroundRepeat": "no-repeat",
                "paddingLeft": 120,
                "paddingRight": 120
            },
            "children": [
                {
                    "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_134d8d1b8e9e61e086c6b984793bcd0b.png",
                    "type": "image",
                    "id": 1,
                    "style": {
                        "height": 362,
                        "width": 362
                    },
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 600,
                            "fontSize": 48
                        }
                    },
                    "url": "",
                    "children": "Obtaining easier Quality Education in branded platform",
                    "type": "typography",
                    "id": 2,
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 20
                        }
                    },
                    "url": "",
                    "children": "Gives you access to the top online learning tools and resources to further gain your education.",
                    "type": "typography",
                    "id": 3,
                    "showEditor": false
                },
                {
                    "props": {
                        "color": "primary",
                        "variant": "contained"
                    },
                    "url": null,
                    "children": "Get started",
                    "type": "button",
                    "name": "Button",
                    "label": "Get started"
                }
            ],
            "id": 4
        },
        {
            "key": "programGridCard",
            "name": "Program Listing",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "paddingTop": 90,
                "paddingBottom": 90,
                "paddingLeft": 120,
                "paddingRight": 120,
                "backgroundColor": "#ECEEF9",
                "boxShadow": "0px 0px 0px 0px #000000"
            },
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 600,
                            "fontSize": 36
                        }
                    },
                    "url": "",
                    "children": "Top picked courses for you.",
                    "type": "typography",
                    "name": "Text",
                    "id": 1,
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "fontSize": 20
                        }
                    },
                    "url": "",
                    "children": "Get the finest curriculum at affordable cost with top-notch instructors.",
                    "type": "typography",
                    "name": "Text",
                    "id": 2,
                    "showEditor": false
                },
                {
                    "props": {
                        "variant": "vertical",
                        "sx": {
                            "height": 40
                        }
                    },
                    "variant": "vertical",
                    "type": "spacer",
                    "name": "Spacer",
                    "showEditor": false
                },
                {
                    "props": {},
                    "type": "customProgramListing",
                    "showEditor": false,
                    "name": "Programs"
                }
            ],
            "id": 3,
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "key": "testimonial2",
            "name": "Testimonial",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/testimonial2.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "paddingTop": 70,
                "paddingBottom": 70,
                "paddingLeft": 120,
                "paddingRight": 120,
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000"
            },
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 600,
                            "fontSize": 36
                        }
                    },
                    "url": "",
                    "children": "Comments from our students",
                    "type": "typography",
                    "name": "Text",
                    "id": 1,
                    "showEditor": false
                },
                {
                    "props": {},
                    "url": "",
                    "children": "Learning today will lead to earning tomorrow. Although education has bitter roots, it bears pleasant fruits. Take a look toward what our learners are expressing,",
                    "type": "typography",
                    "name": "Text",
                    "id": 2,
                    "showEditor": false
                },
                {
                    "props": {
                        "variant": "vertical",
                        "sx": {
                            "height": 40
                        }
                    },
                    "variant": "vertical",
                    "type": "spacer",
                    "name": "Spacer",
                    "showEditor": false
                },
                {
                    "props": {},
                    "type": "testimonials1",
                    "showEditor": false,
                    "name": "Testimonials"
                }
            ],
            "id": 4
        },
        {
            "key": "columnLayout",
            "name": "Layout",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/columnLayout.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontWeight": 600,
                            "fontSize": 48
                        }
                    },
                    "url": "",
                    "children": "We build experience",
                    "type": "typography",
                    "id": 1
                },
                {
                    "props": {
                        "animation": true,
                        "animationProps": {
                            "type": "Typing",
                            "duration": 3000
                        },
                        "style": {
                            "color": "#d12f75",
                            "fontWeight": 600,
                            "fontSize": 32
                        }
                    },
                    "url": "",
                    "children": [
                        "Learning.",
                        "Teaching.",
                        "Business."
                    ],
                    "type": "typography",
                    "id": 4
                },
                {
                    "props": {},
                    "url": "",
                    "children": "Leading companies use the same courses to help employees keep their skills fresh.",
                    "type": "typography",
                    "id": 3
                },
                {
                    "props": {
                        "color": "primary",
                        "variant": "contained"
                    },
                    "url": null,
                    "children": "Start now",
                    "type": "button",
                    "name": "Button",
                    "label": "Start now"
                }
            ],
            "style": {
                "backgroundColor": "#ffffff00",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingLeft": 120,
                "paddingRight": 120,
                "paddingTop": 70,
                "paddingBottom": 70
            },
            "id": 6,
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "id": 5,
            "name": "Footer",
            "key": "footer3",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "content": {
                "content": ""
            },
            "style": {
                "backgroundColor": "#ECEEF9",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingTop": 70,
                "paddingBottom": 70,
                "paddingLeft": 120,
                "paddingRight": 120
            },
            "children": [
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.facebook.com/"
                    },
                    "children": "facebook",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://twitter.com/"
                    },
                    "children": "twitter",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.instagram.com/"
                    },
                    "children": "instagram",
                    "type": "icon",
                    "name": "Icon"
                },
                {
                    "props": {},
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.youtube.com/"
                    },
                    "children": "youtube",
                    "type": "icon",
                    "name": "Icon",
                    "showEditor": false
                },
                {
                    "id": 0.38514678468029695,
                    "props": {},
                    "url": "",
                    "children": "An innovative educational Learning Management System with more than 100 interactive functions, a capacity for more than 1 million students.",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontSize": 16,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "Social links",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.facebook.com/"
                    },
                    "children": "Facebook",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.youtube.com/"
                    },
                    "children": "Youtube",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://twitter.com/"
                    },
                    "children": "Twitter",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": 0,
                        "name": "Url",
                        "path": "https://www.instagram.com/"
                    },
                    "children": "Instagram",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontSize": 16,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "Quick links",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": -3,
                        "name": "Search",
                        "path": "/search",
                        "parentId": 1
                    },
                    "children": "All courses",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "12",
                        "name": "contact us",
                        "path": "/p/contact-us",
                        "parentId": 1
                    },
                    "children": "Contact us",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "13",
                        "name": "about us",
                        "path": "/p/about-us",
                        "parentId": 1
                    },
                    "children": "About us",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "Articles",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36",
                            "fontSize": 16,
                            "fontWeight": 600
                        }
                    },
                    "url": "",
                    "children": "Policies",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "14",
                        "name": "privacy",
                        "path": "/p/privacy",
                        "parentId": 1
                    },
                    "children": "Privacy policy",
                    "type": "typography",
                    "name": "Text"
                },
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": {
                        "id": "15",
                        "name": "refund",
                        "path": "/p/refund",
                        "parentId": 1
                    },
                    "children": "Refund policy",
                    "type": "typography",
                    "name": "Text"
                }
            ],
            "properties": {
                "devices": [
                    "pc",
                    "tab",
                    "mobile"
                ],
                "visibleTo": null
            }
        },
        {
            "id": 8,
            "name": "Layout",
            "key": "cardLayout",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/layoutGrid.png",
            "editorConfig": [
                "dimensions",
                "spacing",
                "borderRadius",
                "shadow",
                "color",
                "border",
                "components"
            ],
            "screens": [
                "login",
                "register",
                "home"
            ],
            "style": {
                "backgroundColor": "#ECEEF9",
                "boxShadow": "0px 0px 0px 0px #000000",
                "paddingTop": 0,
                "paddingBottom": 0,
                "paddingLeft": 120,
                "paddingRight": 120,
                "alignItems": "auto",
                "justifyContent": "flex-start"
            },
            "children": [
                {
                    "props": {
                        "style": {
                            "color": "#212B36"
                        }
                    },
                    "url": "",
                    "children": "Copyright e-khool 2023, All right Reserved",
                    "type": "typography",
                    "name": "Text"
                }
            ]
        }
    ],
    "pageSettings": {},
    "pageStyle": {
        "backgroundColor": "#FAFAFC"
    },
    "animationProperties": {
        "src": null,
        "color": "#ffffff00",
        "pageAnimation": "NONE"
    }
}


export const defaultAppHomeJson = {
    "layout": "plain",
    "banner": "https://djplwyevze4v1.cloudfront.net/default/sponsor.png",
    "components": [
        {
            "id": 1,
            "key": "header1",
            "props": {},
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/app_builder/preview/header1.png",
            "name": "Header"
        },
        {
            "id": 1,
            "key": "myCourses1",
            "props": {},
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/app_builder/preview/myCourses1.png",
            "name": "My Courses",
        },
        {
            "id": 2,
            "key": "banner1",
            "name": "Banner",
            "props": {},
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/app_builder/preview/banner1.png"
        },
        {
            "id": 3,
            "key": "extras1",
            "name": "Extras",
            "props": {},
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/app_builder/preview/extras1.png"
        },
        {
            "id": 4,
            "key": "achievers1",
            "name": "Achievers",
            "props": {},
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/app_builder/preview/achievers1.png"
        }
    ],
    bottomTab: [
        {
            id: 1,
            name: 'LMS Home',
            icon: 'home',
            path: 'LMSDashboard',
            label: 'Home'
        },
        {
            id: 2,
            name: 'Search',
            icon: 'search',
            path: 'Search',
            label: 'Search'
        },
        {
            id: 3,
            name: 'Learning',
            icon: 'play-circle',
            path: 'Learning',
            label: 'Learning'
        },
        {
            id: 4,
            name: 'Cart',
            icon: 'shopping-cart',
            path: 'Cart',
            label: 'Cart'
        },
        {
            id: 5,
            name: 'Products',
            icon: 'grid',
            path: 'Products',
            label: 'Products'
        }
    ]
}