import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, Stack } from '@mui/material';
// layouts

// components
import Page from '../Page';
// import { MotionContainer, varBounce } from '../components/animate';
import { MotionContainer, varBounce } from '../animate'
import PageNotFoundIllustration from '../../assets/illustration_404'
import MotivationIllustration from '../../assets/illustration_motivation';
import { HomeRounded, LoginRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { reduxHelper } from '../../config/reduxHelper';
import Iconify from '../Iconify';
import { icons } from '../../config/icons';
import { getString } from '../../functions/getString';
import { useRouter } from 'next/router';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

const AccessDenied = () => {

    const { reducer }= useSelector((state) => state);
    const { user, accessDeniedData, appData} = reducer;
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const { push } = useRouter();

    const redirectData  = user?.redirection
    const redirectUrl   = user?.redirection.url
       
    const onExam = () =>{
        window.location.replace(redirectUrl)
    }

    const homeUrl  = '';

    if(appData?.home_url)
    {
        if(appData?.home_url != "" && appData?.home_url != false)
        {
            homeUrl = appData?.home_url
        }
        else{
            homeUrl = '/'
        }
    }
    else{
        homeUrl = '/'
    }

    useEffect(()=>{
        dispatch({
            type: reduxHelper.UPDATE_ALERT,
            payload: false
        })
        
    }, [])


    return (
        <Page title= {getString("Access Denied")} >
            <RootStyle>
                <Container component={MotionContainer}>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <m.div variants={varBounce().in}>
                            <Typography variant="h3" paragraph>
                                {accessDeniedData?.heading || getString('No permission')}
                            </Typography>
                        </m.div>
                        <Typography sx={{ color: 'text.secondary' }}>
                        {redirectData?.message || accessDeniedData?.message}
                        </Typography>
                        <m.div variants={varBounce().in}>
                            <MotivationIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                        </m.div>

                        {
                            accessDeniedData?.actions || (
                                <>
                                {
                                    user?.us_role_id && user?.us_role_id != 2 &&
                                    <NextLink href="/admin" passHref>
                                        <Button variant="outlined" startIcon={<HomeRounded/>} >{getString('Go to dashboard')}</Button>
                                    </NextLink>
                                }

                                {
                                    !user &&
                                    <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'center'} >
                                        {/* <NextLink > */}
                                            <Button variant="outlined" startIcon={<HomeRounded/>} onClick={() => {push(`${homeUrl}`)}}>
                                            {getString( 'Home')}
                                            </Button>
                                        {/* </NextLink> */}

                                        {
                                            !redirectData?.label&&

                                            <NextLink href="/login" passHref >
                                                <Button variant={'contained'} startIcon={<LoginRounded/>} >
                                                {getString('Login')}
                                                </Button>
                                            </NextLink>
                                        
                                        }

                                        {
                                            redirectData?.label&& 
                                            // <NextLink  passHref onClick={() => onExam()}>
                                                <Button variant={'contained'} startIcon={<LoginRounded/>} onClick={() => {onExam()}}>
                                                    {redirectData?.label} 
                                                </Button>
                                            // </NextLink>
                                        }
                                        
                                    </Stack>
                                }

                                {
                                    user?.us_role_id == 2 &&
                                    <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'center'} >
                                        {/* <NextLink > */}
                                            <Button variant="outlined" startIcon={<HomeRounded/>} onClick={() => {push(`${homeUrl}`)}}>
                                                {getString( 'Home')}
                                            </Button>
                                        {/* </NextLink> */}

                                        {
                                            !redirectData?.label&&

                                            <NextLink href="/login" passHref >
                                                <Button variant={'contained'} startIcon={<LoginRounded/>} >
                                                {getString('Login')}
                                                </Button>
                                            </NextLink>
                                        
                                        }

                                        {
                                            redirectData?.label&& 
                                            // <NextLink  passHref onClick={() => onExam()}>
                                                <Button variant={'contained'} startIcon={<LoginRounded/>} onClick={() => {onExam()}}>
                                                    {redirectData?.label} 
                                                </Button>
                                            // </NextLink>
                                        }
                                        
                                    </Stack>
                                }
                                </>
                            )
                        }

                    </Box>
                </Container>
            </RootStyle>
        </Page>
    );
};

export default AccessDenied;
