//ACCOUNT
export const LOGIN = 'login';
export const REGISTER = 'userRegister';
export const VERIFY_OTP = 'verifyOTP';

//PROFILE
export const GET_PROFILE = 'my_details';
export const GET_INVOICES = 'purchase_history';
export const UPDATE_PROFILE = 'save_my_profile';
export const UPDATE_USER_IMAGE = 'change_user_image';
export const REQUEST_ID_CARD = 'idCardRequest';
export const GET_ID_CARD_STATUS = 'idCard';

//APP DATA
export const APPLICATION_DETAILS = 'application_details';

//PROGRAMS
export const GET_MY_PROGRAMS = 'my_programs';
export const GET_POPULAR_PROGRAMS = 'popular_programs';
export const GET_HOME_PROGRAMS = 'home_programs';
export const GET_COURSE_DETAIL = 'program_materials_topic?slug=';
export const GET_PROGRAM_MATERIALS = 'program_materials?slug=';
export const GET_ALLBUNDLE_DETAILS = 'single_bundle?slug=';

//EXAMS
export const GET_EXAM_DATA = 'single_exam?exam_id=';

//ADMIN
export const GET_FORM_DATA = 'getFormTemplates'
