import {useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Toolbar, Typography,Menu ,Container,Button, MenuItem, useTheme, Stack, IconButton, Divider, Link, Drawer} from '@mui/material';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import CustomComponent from '../../../customComponents/CustomComponent';
import useResponsive from '../../../hooks/useResponsive';
import EkhoolImage from '../../ekhoolImage/ekhoolImage';
import AppLogo from '../../logo/Logo';
import ComponentSection from '../../../customComponents/ComponentSection';
import { devices } from '../../../config/config';
import AccountPopover from '../../homeHeader/AccountPopover';
import AccountData from '../../account/AccountData';
import Iconify from '../../Iconify';
import { icons } from '../../../config/icons';
import CustomIcon from '../../../customComponents/CustomIcon';

const key = 'header4'

const Header4 = () => {

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState();

  const theme = useTheme()

  const route = useRouter();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { reducer } = useSelector((state) => state);
  const { pageJson, appData, user, device, isSiteBuilder } = reducer;

  const themeJson = pageJson?.components?.filter((i) => (i.key === key));
  const themeItem = themeJson?.length > 0 && themeJson[0];
  const style = themeItem && themeItem?.style ? themeItem.style : {};

  const isMobile  = device === devices.MOBILE
  const isTablet  = device === devices.TABLET
   
  return (
    <Stack style={{
      ...style,
          gap: undefined,                
          paddingLeft: isMobile || isTablet ? 2 : style?.paddingLeft,
          paddingRight: isMobile || isTablet ? 2 : style?.paddingRight,
          paddingTop: isMobile || isTablet ? 2 : style?.paddingTop,
          paddingBottom: isMobile || isTablet ? 2 : style?.paddingBottom,
          borderBottom: `1px solid ${theme.palette.divider}`
    }} {...themeItem?.props} >

      {/* <Container maxWidth="xl"> */}
        
        <Toolbar disableGutters >

          <Box  sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} alignItems={'center'} justifyContent={'space-between'} height={50} >             

                <Box>
                  <IconButton
                    size="small"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >

                      <MenuIcon/>

                  </IconButton>
                </Box>
                
                <AppLogo height={'100%'}/>

                <Drawer 
                    anchor={"left"} 
                    anchorEl={anchorElNav}  
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        "&:hover":{
                            color:'primary.main',
                        }, 
                    }}
                > 
                
                  <Box boxShadow={3} flexDirection={'row'} height={80} width={'75vw'}  alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                   
                  <AppLogo height={'100%'}/>
                    
                      <IconButton onClick={handleCloseNavMenu} >
                        <Iconify icon={icons.CLOSE} />
                      </IconButton>
                  </Box>  
                  <Box display={'flex'} width={'100%'} flexDirection={'column'} alignItems={'left'} gap={1} px={4} py={2} onClick={handleCloseNavMenu}>
                      
                      <ComponentSection
                          sectionKey={'header4'}
                          remove={'image'}
                      />
                      
                  </Box>

              </Drawer>
             
            </Box>

         
          
          <Stack  direction={{xl:'row', md:'row'}}  display={{xs:'none', md:'flex'}} justifyContent={'space-between'} py={1} px={1} width={'100%'} alignItems={'center'} >


                <AppLogo/>
              
                
                <Box display={'flex'} alignItems={'center'} flexDirection={'row'} gap={2} onClick={handleCloseNavMenu}>
                  
                  <ComponentSection
                      sectionKey={'header4'}
                      remove={'image'}
                  />   
                  
                  {
                    !isSiteBuilder && <AccountPopover/>
                  }

                </Box>
                
          </Stack>
        
        </Toolbar>
      
      {/* </Container> */}
    </Stack>
  );
};
export default Header4;


