import { ShoppingCartCheckoutRounded, ShoppingCartRounded } from "@mui/icons-material";
import { alpha, LinearProgress, Skeleton, Stack, Typography, Fab, Tooltip, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useProgramPricing } from "../../hooks/useProgramPricing";
import { useCheckCart } from "../../hooks/useCheckCart";
import { useAddToCart } from "../../hooks/useAddToCart";
import EkhoolImage from "../ekhoolImage/ekhoolImage";
import { useSnackbar } from "notistack";
import { useRouter } from "next/router";
import { reduxHelper } from "../../config/reduxHelper";
import {useProgramCacheData} from "../../hooks/useProgramCacheData"
import Label from '../extras/Label'
import { getString } from "../../functions/getString";
import Iconify from "../Iconify";
import { icons } from "../../config/icons";
import { convertToDateString } from "../../functions/convertToDateString";
import moment from 'moment';



// const theme = createTheme({
//     typography: {
//         fontFamily: 'Poppins',
//     }
// })

// const key = 'program_card_1'

// const ProgramCard = ({ item, editable, light = false }) => {

//     const { reducer }                     = useSelector((state) => state);
//     const { appData, themeColors }        = reducer;
//     const { amount_details }              = appData || {};
//     const { symbol }                      = amount_details || {};
//     const priceSymbol                     = appData?.amount_details?.symbol;
//     const router                          = useRouter();

//     const { PRIMARY_BACKGROUND, SECONDARY_BACKGROUND, PRIMARY_TEXT, SECONDARY_TEXT, APP_PRIMARY_COLOR, APP_SECONDARY_COLOR } = themeColors;



//     const [isMonthlyPay, setIsMonthlyPay] = useState();

//     const title                           = item.pb_title ? item.pb_title : item.program_name;
//     const enrolled                        = item.enrolled;
//     const thumbnail                       = item.pb_image ? item.pb_image : item.program_image;
//     const percentage                      = item.percentage;
//     const totalLearners                   = item?.enrolled_learners;
//     const shortDescription                = item?.pb_short_description;
//     const description                     = item?.pb_description;
//     const totalRating                     = item?.ratting === "" ? 0 : item?.ratting;
//     const createdDate                     = item?.created_date;
    
//     const discountData                    = item && item.pb_discount_json;
//     const parsedDiscountData              = discountData && JSON.parse(discountData);
//     const discountType                    = parseInt(parsedDiscountData && parsedDiscountData.discount_type);
//     const discountPrice                   = parsedDiscountData && parsedDiscountData.price_after_discount;
//     const programPrice                    = item && item.pb_price;
//     const discountValue                   = parseInt(parsedDiscountData && parsedDiscountData.discount_value);
//     const hasDiscount                     = discountType && discountType === 1 || discountType === 2 && discountValue > 0;
//     const Price                           = hasDiscount && discountValue > 0 ? discountPrice : programPrice;
    
//     const monthPayment                    = item && item.pb_month_payment ? item.pb_month_payment : null
//     const parsedMonthPayment              = monthPayment && JSON.parse(monthPayment);
//     const filteredData                    = parsedMonthPayment && parsedMonthPayment.filter((i) => ( i.show == '1' ));
//     const priceToShow                     = filteredData && filteredData.length > 0 && filteredData[0].price;
   

//     const getProgramPricingForMonth = () =>{
//         if(parsedMonthPayment != null){
//             parsedMonthPayment.map((i) => 
//             {
//                 if(i.show == true){
//                     setIsMonthlyPay(i)
//                 }
//             }
//           )
//         }  
//     } 

//     const monthlypayments = isMonthlyPay?.price / isMonthlyPay?.months;
//     const monthlypaymentDivde = monthlypayments.toFixed(2) 

//     useEffect(() => {
//         getProgramPricingForMonth()
//     }, [])


//     const _navigate = () => {
//         if(enrolled)
//         {
            
//             if(item?.ps_last_played_material!== undefined && item?.ps_last_played_material != 0){
               
//                 router.push(`/program/${item?.pb_slug}/learn/${item?.ps_last_played_material}`)
              
//             } else{
//                 router.push(`/program/${item?.pb_slug}/learn`)
//             }
        
//         }
//         else
//         {
//             router.push(`/course/${item?.pb_slug}`)
//         }
//     }
    
//     const theme = useTheme();

//     const PRIMARY_MAIN = theme.palette.primary.main;
//     const PRIMARY_WARNING = theme.palette.warning.main;
//     const PRIMARY_ERROR = theme.palette.error.main;
//     const DIVIDER = theme.palette.divider

//     return (
//         <Card className={styles.root} sx={{ backgroundColor: light ? 'background.paper' : 'background.default' }} onClick={() => { _navigate() }} >
//             <Box className={styles.boxRelative} >

//                 <Box className={styles.box} >
//                     <EkhoolImage src={thumbnail} layout={'fill'} objectFit={'cover'} />
//                 </Box>
//             </Box>
//             <div className={styles.contentWrapper} >
//                 <Typography
//                     sx={{
//                         display: '-webkit-box',
//                         overflow: 'hidden',
//                         WebkitBoxOrient: 'vertical',
//                         WebkitLineClamp: 2,
//                         fontWeight: 400,
//                         height: '53px',
//                         width: '205px',
//                         color: 'text.primary',
//                     }}
//                     >{title}</Typography>
//                 <div className={styles.priceContainer} >

//                     {
//                         enrolled &&
//                         <>
//                             <div className={styles.progressBarContainer} style={{ backgroundColor: SECONDARY_BACKGROUND }} >
//                                 <div className={styles.progressBarWrapper} style={{ backgroundColor: PRIMARY_MAIN}} />
//                                 <div className={styles.progressBar} style={{ backgroundColor: PRIMARY_MAIN, width: `${percentage}%` }} >

//                                 </div>
//                             </div>
//                             <Typography sx={{ color: 'text.primary' }} variant={'caption'} mr={1} ml={2} >{percentage}%</Typography>
//                         </>
//                     }

//                     {
//                         !enrolled &&
//                         <>
//                             <div className={styles.extrasItemContainer} >
//                                 <div className={styles.extrasItem} >
//                                     <div className={styles.extrasIconContainer} >
//                                         <Person sx={{ padding: 0, height: '15px', width: '15px', color: 'text.secondary' }} />
//                                     </div>
//                                     <Typography style={{ fontWeight: 500, marginLeft: 5 }} variant={'caption'} color={'text.primary'} >{totalLearners}</Typography>
//                                 </div>

//                                 <div className={styles.extrasItem} >
//                                     <div className={styles.extrasIconContainer} >
//                                         <StarOutline sx={{ padding: 0, height: '15px', width: '15px', color: 'text.secondary' }} />
//                                     </div>
//                                     <Typography style={{ fontWeight: 500, marginLeft: 5 }} variant={'caption'} color={'text.primary'} >{totalRating}</Typography>
//                                 </div>
//                             </div>
//                         </>
//                     }

//                     {
//                         !enrolled &&
//                         <>
//                             {
//                                 priceToShow ?
//                                     <Stack direction={'row'}>
//                                         <Typography style={{ fontWeight: 600, padding: 0, fontFamily: 'Roboto' }} variant={'caption'} color={'text.primary'} >{symbol}{monthlypaymentDivde}/</Typography>
//                                         <Typography style={{ fontWeight: 600, padding: 0, fontFamily: 'Roboto' }} variant={'caption'} color={'text.primary'}  >{isMonthlyPay?.months} Months</Typography>                        
//                                     </Stack>
//                                     :
//                                     <>
//                                         {
//                                             programPrice &&
//                                             <Typography style={{ fontWeight: 600, padding: 0, fontFamily: 'Roboto' }} variant={'caption'} color={'text.primary'} >{programPrice < 1 ? '': priceSymbol} {programPrice < 1 ? 'Free' : programPrice}</Typography>
//                                         }
//                                     </>
//                             }
//                         </>
//                     }
//                 </div>
//             </div>
//         </Card>        
//     )
// };

// export default ProgramCard;

function ProgramCard({ item, index, program })
{     


    const programId             = item?.pb_program_id;   
    const title                 = item.pb_title ? item.pb_title : item.program_name;
    const enrolled              = item.enrolled;
    const selfEnrolled          = item.pb_has_self_enroll;
    const selfEnrolledDate      = item.pb_self_enroll_date;

    const thumbnail             = item.pb_image ? item.pb_image : item.program_image;
    const percentage            = item.percentage;
    const totalLearners         = item?.enrolled_learners; 
    const shortDescription      = item?.pb_short_description;
    const description           = item?.pb_description;
    const totalRating           = item?.ratting === "" ? 0 : item?.ratting;
    const createdDate           = item?.created_date;
    const totalLessons          = item?.lessons?.length;
    const unlimitedProgram      = item.unlimited_validity;
    var subscriptionEndData   = item?.subscription_end_date; 
    const userSuspended         = item?.learner_suspended
    const lessonCount           = item?.lessons_count
    const courseSubscriptionExpired   = item?.expired; 
    const addonsCount           = item?.pb_products != undefined ? item?.pb_products : null;
    const courseExpired         = item?.validity_expired;  
    
    const certifiedCourse       = item?.pb_has_certificate
    const CourseDuration        = item?.pb_preview_time
    const totalEnrolledLearners = item?.pb_total_enrollments ?? '0';
    const courseRating          = item?.pb_rating
    const currentDate           = new Date().toISOString().split('T')[0];
   
    const strickoutDetail               = item?.pb_discount_value;
    const CoursePrice                   = item?.pb_price;
    const percentageValue               = parseInt(strickoutDetail) - parseInt(CoursePrice)
    const percentageAmount              = (parseInt(percentageValue ) / parseInt(strickoutDetail)) * 100;
    const percentageRound               = Math.round(percentageAmount);

    const [priceData, setPriceData] = useState(null);
    const [itemOnCart, setItemOnCart] = useState(true)
    const [isMonthlyPay, setIsMonthlyPay] = useState();

    const { reducer } = useSelector((state) => state);
    const { appData, cartItems, headers } = reducer;

    if(subscriptionEndData && appData.isEthiopia){
        if(window.convertToUserTimeZone  ){
            
            
            var formatedSubscriptionDate = convertToDateString(subscriptionEndData,"dmy","-",":")
            var subscriptionEndDatass = window.convertToUserTimeZone(formatedSubscriptionDate)
            const [datePart,timepart] = subscriptionEndDatass.split(' ');
            subscriptionEndData=datePart;
        }
    }


    const dispatch      = useDispatch()
    const priceSymbol   = appData?.amount_details?.symbol;

    const monthPayment                    = item && item.pb_month_payment ? item.pb_month_payment : null
    const parsedMonthPayment              = monthPayment && JSON.parse(monthPayment);
    const filteredData                    = parsedMonthPayment && parsedMonthPayment.filter((i) => ( i.show == '1' ));
    const priceToShow                     = filteredData && filteredData.length > 0 && filteredData[0].price;

    const totalExamCount            = item?.exam_count ? item?.exam_count : "0";
    const totalNumberofAssignment   = item?.assignment_count ? item?.assignment_count : "0";
    const totalNumberofMaterial     = item?.material_count ? item?.material_count : "0";
    const totalNumberofTopics       = item?.topic_count ? item?.topic_count : "0";
   
    const getPriceData = async () => {        
        let courseObject = {
            program_detail: item
        }
        const result = useProgramPricing(courseObject)
        setPriceData(result);
    }

    const enrolledPath          = `/course/${item?.pb_slug}/learn`
    const notEnrolledPath       = `/course/${item?.pb_slug}`
    const pathWithLastPlayed    = `/course/${item?.pb_slug}/learn/${item?.ps_last_played_material}`
    const hasLastPlayed         = item?.ps_last_played_material!== undefined && item?.ps_last_played_material != 0

    let navigatePath = enrolled && courseSubscriptionExpired ? notEnrolledPath : enrolled && hasLastPlayed ? pathWithLastPlayed : enrolled ? enrolledPath : notEnrolledPath ;
  
    const showPricing = appData?.enable_learner_purchase

    const checkItemOnCart = async () => {
        const result = await useCheckCart(`p-${item?.id ?? item?.program_id }`)
        setItemOnCart(result)
    }

    const getProgramPricingForMonth = () =>{
        if(parsedMonthPayment != null){
            parsedMonthPayment.map((i) => 
            {
                if(i.show == true){
                    setIsMonthlyPay(i)
                }
            }
            )
        }  
    } 

    const getProgramLastMaterial = async () => { 

        const ProgramLastMaterial = await useProgramCacheData();
        let LastViewedMaterial     = ProgramLastMaterial?.filter((i) => (i.program_id == programId));

        if(LastViewedMaterial.length > 0){
            item['ps_last_played_material'] = LastViewedMaterial[0].material_id;
        }
    //     const result = Object.values(ProgramLastMaterial.reduce((acc, cur) => { 
    //         acc[cur.id] = cur;
    //         return acc;
    //     }, {}))
    //   console.log(result,"result");
    //    const programIds = result?.map((i) => (i.id));
    //    const filteringProgramId = result?.filter((i) => i?.id == programId);
    //    if(programIds){
    //        result.map((i) =>{ 
    //         console.log(filteringProgramId,'filteringProgramId');
    //         // console.log(i,"i");
    //         if(filteringProgramId){
                
    //         }  
    //        })
        
    //    }

    }

    useEffect(() =>{
        getProgramLastMaterial()
    }, [item, program])

    const monthlypayments = isMonthlyPay?.price / isMonthlyPay?.months ? isMonthlyPay?.price / isMonthlyPay?.months : 0;
    const monthlypaymentDivde = monthlypayments.toFixed(2) 
          
    useEffect(() => {
        getProgramPricingForMonth()
    }, [])

    useEffect(() => {
        getPriceData()
        checkItemOnCart()
    }, [item]);

    useEffect(() =>{
        checkItemOnCart()
    }, [])

    const { enqueueSnackbar } = useSnackbar();

    const addToCart = async () => {
        if(itemOnCart)
        {            
            push('/cart')
        }
        else
        {
            const result = await useAddToCart(`p-${item?.id ?? item?.program_id}`)
            enqueueSnackbar(getString('Added to cart'), {
                variant: 'success'
            })
            setItemOnCart(true)
            dispatch({
                type: reduxHelper.UPDATE_CART_ITEMS,
                payload: [...cartItems, `p-${item?.id ?? item?.program_id}`]
            })
        }
    }  


    const { push} = useRouter()

    useEffect(()=>{
        if (typeof translateScript === "function") 
        {
            translateScript();
        }
    },[])
    
    
    return (
        <Stack 
            mb={2}
            // width={{
            //     xs: '100%',
            //     md: 250
            // }}

            sx={{
                width: '100%',
                maxWidth: '400px',
                height: 'auto',
               
            }}
        >
                <Stack
                    // height={'auto'}
                    // minHeight={{
                    //     xs: 110,
                    //     sm: 152
                    // }}

                    // maxHeight={{
                    //     xs: 110,
                    //     sm: 152
                    // }}

                    bgcolor={'background.neutral'}
                    borderRadius={1}
                    position={'relative'}
                    overflow={'hidden'}
                    mb={2}
                    sx={{
                            top:0,
                             '.program-card-overlay': {
                                                    position: 'absolute',
                                top: '100%',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: '100%',
                                width: '100%',
                                opacity: 0,
                                transition: '.5s ease',
                                backgroundColor: alpha('#000000', 0.8),
                            },
                            '&:hover': {
                                '.program-card-overlay':{
                                    opacity: 1,
                                    top:0
                                }
                            },
                            '.programcard-fab-icon': {
                                display: 'flex'
                            }
                        
                    }}
                >

                    {
                        enrolled && courseSubscriptionExpired&&
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.06)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            sx={{ 
                                cursor:'pointer',
                                // display: 'none'
                            }}     
                            onClick={() => {
                                push(navigatePath)
                            }}                       
                        >
                            {
                                appData.isEthiopia ?
                                <Box border={'1px solid'} color={'white'} p={1} gap={2}> 
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.DOCUMENT} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of topics')} : {totalNumberofTopics}</Typography>
                                    </Box>
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.EXAM} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of test')} : {totalExamCount}</Typography>
                                    </Box>                                    
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.FLIPBOOK} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of materials')} : {totalNumberofMaterial}</Typography>
                                    </Box>
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.ASSIGNMENT} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of assignments')} : {totalNumberofAssignment}</Typography>
                                    </Box>
                                </Box>  
                                :
                                <Box border={'1px solid'} color={'white'} p={1} gap={2}>                                
                                                              
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.RATING} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Course rating')} : {courseRating}</Typography>
                                    </Box>
                                
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.GROUP_PERSON_FILLED} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Total enrollment')} : {totalEnrolledLearners}</Typography>
                                    </Box>                              
                                    {
                                        certifiedCourse == 1 &&
                                        <Box display={'flex'} gap={1}  alignItems={'center'}>
                                            <Iconify icon={icons.CERTIFICATE_OUTLINE} sx={{height:25, width:25}}/>
                                            <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Certificate available')}</Typography>
                                        </Box>
                                    }
                                </Box> 
                            }
                        </Stack>
                    }

                    {
                        !enrolled &&
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.06)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            sx={{ 
                                cursor:'pointer',
                                // display: 'none'
                            }}   
                            onClick={() => {
                                push(navigatePath)
                            }}                         
                        >
                            {
                                appData.isEthiopia ?
                                <Box border={'1px solid'} color={'white'} p={1} gap={2}> 
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.DOCUMENT} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of topics')} : {totalNumberofTopics}</Typography>
                                    </Box>
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.EXAM} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of test')} : {totalExamCount}</Typography>
                                    </Box>                                    
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.FLIPBOOK} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of materials')} : {totalNumberofMaterial}</Typography>
                                    </Box>
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.ASSIGNMENT} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of assignments')} : {totalNumberofAssignment}</Typography>
                                    </Box>
                                </Box>  
                                :
                                <Box border={'1px solid'} color={'white'} p={1} gap={2}>                                
                                                              
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.RATING} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Course rating')} : {courseRating}</Typography>
                                    </Box>
                                
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.GROUP_PERSON_FILLED} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Total enrollment')} : {totalEnrolledLearners}</Typography>
                                    </Box>                              
                                    {
                                        certifiedCourse == 1 &&
                                        <Box display={'flex'} gap={1}  alignItems={'center'}>
                                            <Iconify icon={icons.CERTIFICATE_OUTLINE} sx={{height:25, width:25}}/>
                                            <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Certificate available')}</Typography>
                                        </Box>
                                    }
                                </Box> 
                            }
                        </Stack>
                    }

                    {
                        enrolled && !courseSubscriptionExpired &&
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.7)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            
                            sx={{ 
                                cursor:'pointer',
                                overflow: 'hidden',   
                                // display:{s:'none', sm:'none'}
                            }}
                            // sx={{
                            //     position: 'absolute',
                            //     bottom: 0,
                            //     left: 0,
                            //     right: 0,
                            //     // backgroundColor:' #008CBA',
                            //     overflow: 'hidden',                              
                            //     height: 0,
                            //     transition: '.5s ease'
                            //   }}

                            onClick={() => {
                                if (percentage > 0) {
                                    try {
                                        push(navigatePath);
                                    } catch (error) {
                                        console.error('Navigation error:', error);
                                    }
                                } else {
                                    console.warn('Percentage is not greater than 0. Navigation aborted.');
                                }
                            }}                        
                        >   
                            {
                                appData.isEthiopia ?
                                <Box border={'1px solid'} color={'white'} p={1} gap={2}> 
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.DOCUMENT} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of topics')} : {totalNumberofTopics}</Typography>
                                    </Box>
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.EXAM} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of test')} : {totalExamCount}</Typography>
                                    </Box>                                    
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.FLIPBOOK} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of materials')} : {totalNumberofMaterial}</Typography>
                                    </Box>
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.ASSIGNMENT} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of assignments')} : {totalNumberofAssignment}</Typography>
                                    </Box>
                                </Box>  
                                :
                                <Box border={'1px solid'} color={'white'} p={1} gap={2}>                                
                                                              
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.RATING} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Course rating')} : {courseRating}</Typography>
                                    </Box>
                                
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.GROUP_PERSON_FILLED} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Total enrollment')} : {totalEnrolledLearners}</Typography>
                                    </Box>                              
                                    {
                                        certifiedCourse == 1 &&
                                        <Box display={'flex'} gap={1}  alignItems={'center'}>
                                            <Iconify icon={icons.CERTIFICATE_OUTLINE} sx={{height:25, width:25}}/>
                                            <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Certificate available')}</Typography>
                                        </Box>
                                    }
                                </Box> 
                            }

                            {/* <PlayCircleRounded
                                sx={{
                                    height: 50,
                                    width: 50,
                                    color: '#fff',
                                    position:"absolute"
                                }}
                            /> */}

                        </Stack>
                    }
                    {
                       ( (enrolled && courseSubscriptionExpired) ||  (enrolled && courseSubscriptionExpired && courseExpired)) &&
                        
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.5)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            sx={{ 
                                cursor:'not-allowed',
                            }} 
                            onClick={() => {
                                push(navigatePath)
                            }}
                        >
                            <Typography color={'background.paper'} variant={'subtitle1'}>{getString('Expired Course')}</Typography>
                        </Stack>   
                        
                    }


                    <EkhoolImage src={thumbnail}  onClick={() => {
                            push(navigatePath)
                    }}/>
                   
                    {
                        !isNaN(percentageRound) && !enrolled && priceData?.hasStrike && 
                        <Stack
                            position={'absolute'}
                            right={10}
                            top={15}
                            zIndex={999}
                        >
                           
                            <Label sx={{ bgcolor: 'warning', color: '#000', borderRadius:0}} > {getString(' You save')} {percentageRound}%</Label>
                        </Stack>
                    }

                    {
                        !enrolled  && (priceData?.originalPrice > 0 || priceToShow || addonsCount != null)  &&  showPricing &&  (selfEnrolled == '0' || (  selfEnrolled == '1' &&  selfEnrolledDate <= currentDate )) &&
                        
                        <Tooltip
                            title={itemOnCart ? getString('Go to cart') : getString('Add to cart')}
                            onMouseEnter={()=>translateScript()}
                        >
                            <Fab
                                size={'small'}
                                sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 10,
                                    zIndex: 999,
                                    display: 'none'
                                }}
                                className={'programcard-fab-icon'}
                                onClick={() => {
                                    addToCart()
                                }}
                            >
                                {
                                    itemOnCart ? <ShoppingCartCheckoutRounded fontSize="small" /> : <ShoppingCartRounded fontSize="small" />
                                }
                            </Fab>
                        </Tooltip>
                    }

                    {
                        enrolled && courseSubscriptionExpired && !courseExpired && (priceData?.originalPrice > 0 || priceToShow || addonsCount != null) && showPricing &&
                        <Tooltip
                            title={itemOnCart ? getString('Go to cart') : getString('Add to cart')}
                        >
                            <Fab
                                size={'small'}
                                sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 10,
                                    zIndex: 999,
                                    display: 'none'
                                }}
                                className={'programcard-fab-icon'}
                                onClick={() => {
                                    addToCart()
                                }}
                            >
                                {
                                    itemOnCart ? <ShoppingCartCheckoutRounded fontSize="small" /> : <ShoppingCartRounded fontSize="small" />
                                }
                            </Fab>
                        </Tooltip>
                    }

                </Stack>
                
                <Stack
                    onClick={() => !courseSubscriptionExpired && percentage > 0 ?  push(navigatePath) : (courseSubscriptionExpired && !courseExpired ? push(notEnrolledPath) : (courseSubscriptionExpired && courseExpired) && '')}  sx={{ cursor: enrolled && courseSubscriptionExpired && courseExpired ? 'not-allowed' :  'pointer'}}
                    // onClick={() => {push(navigatePath)}}   sx={{ cursor:'pointer'}}
                >
                <Tooltip placement="top-start" arrow title={title}>
                    <Typography variant="subtitle1" sx={{ minHeight: 50, overflow: 'hidden', WebkitBoxOrient: 'vertical', display: '-webkit-box', WebkitLineClamp: 2}}>{title}</Typography>
                </Tooltip>
                {/* <Typography variant="caption" color={'text.secondary'} >{totalLessons || lessonCount} {totalLessons || lessonCount > 1 ? 'Lessons' : 'Lesson'}</Typography> */}

                {
                    enrolled && !courseSubscriptionExpired &&
                    <>
                        {
                            percentage < 1 ?
                            <Stack mt={0.5} gap={0.75} >
                                <LinearProgress value={percentage} variant={'determinate'} sx={{ height: 2 }} color={'inherit'} />
                                <Box display={'flex'} flexDirection={{sm:'row', xs:'column',}} justifyContent={'space-between'}>
                                    <Button size="small" color="success" onClick={() => push(`/course/${item?.pb_slug}/learn`)} sx={{textDecoration:'underline'}}>Start Learning</Button>
                                    <Button size="small"  color="info" onClick={() => push(`/course/${item?.pb_slug}`)} sx={{textDecoration:'underline'}}>Overview</Button>
                                </Box>
                            </Stack>
                            :
                            <Stack mt={1.5} gap={0.75}   >
                                <LinearProgress value={percentage} variant={'determinate'} sx={{ height: 2 }} color={'inherit'} />
                                <Box display={'flex'} flexDirection={{sm:'row', xs:'column',}} justifyContent={'space-between'}>
                                <Typography variant="caption" color={'text.primary'}>
                                    {percentage > 0 ? (
                                        <>
                                            {percentage}% <span>{getString('completed')}</span>
                                        </>
                                    ) : (
                                        getString('Start learning')
                                    )}
                                </Typography>
                                    {
                                        !userSuspended 
                                            ?  
                                            <Typography color={unlimitedProgram && !courseSubscriptionExpired ? 'green' : 'green'} variant={'caption'} fontWeight={500}>{unlimitedProgram && !courseSubscriptionExpired ?  getString('Full Lifetime Access') : (!courseSubscriptionExpired ? `${getString('Expire on')} ${subscriptionEndData}` : getString('Expired'))}</Typography>
                                            :
                                            <Typography color={'error'} variant={'caption'} fontWeight={500}> {getString('Suspended')}</Typography>
                                    }
                                </Box>
                            </Stack>
                        }
                    </>
                }

{/* 
                {
                    enrolled && !courseSubscriptionExpired &&
                    <Stack mt={1.5} gap={0.75}   >
                        <LinearProgress value={percentage} variant={'determinate'} sx={{ height: 2 }} color={'inherit'} />
                        <Box display={'flex'} flexDirection={{sm:'row', xs:'column',}} justifyContent={'space-between'}>
                            <Typography variant="caption" color={'text.primary'} >{percentage > 0 ? `${percentage}% ${getString('completed')}` : getString('Start learning')}</Typography>
                            {
                                !userSuspended 
                                    ?  
                                    <Typography color={unlimitedProgram && !courseSubscriptionExpired ? 'green' : 'green'} variant={'caption'} fontWeight={500}>{unlimitedProgram && !courseSubscriptionExpired ?  getString('Full Lifetime Access') : (!courseSubscriptionExpired ? `${getString('Expire on')} ${subscriptionEndData}` : getString('Expired'))}</Typography>
                                    :
                                    <Typography color={'error'} variant={'caption'} fontWeight={500}> {getString('Suspended')}</Typography>
                            }
                        </Box>
                    </Stack>
                } */}
               

                {
                    (!enrolled ||  enrolled && courseSubscriptionExpired ) && showPricing &&
                    <Stack mt={1} >
                        
                        {
                            !priceData && 
                            <Skeleton
                                height={40}
                                width={65}
                                sx={{ borderRadius: 0.5 }}
                            />
                        }
                        {
                            priceData && !priceToShow &&
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={1.5} >
                                <Box display={'flex'} gap={1.5}>
                                {
                                    priceData?.originalPrice > 0 ?
                                    <Typography variant="subtitle2" >{priceSymbol} {priceData?.originalPrice}</Typography>
                                    :
                                    // <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                                    !appData?.isEthiopia ? <Typography variant="subtitle2" >{getString('Free')}</Typography> : ""
                                       
                                    // </Stack>
                                }                                 
                                {
                                    priceData?.hasStrike &&                                     
                                    <Typography variant="body2" sx={{ textDecorationLine: 'line-through' }} >{priceSymbol} {priceData?.strikeOutPrice}</Typography>
                                }
                                </Box>

                                {/* <Typography color={unlimitedProgram && !courseSubscriptionExpired ? 'green' : (courseSubscriptionExpired ? 'red' : 'green') } variant={'caption'} fontWeight={500}>{unlimitedProgram && !courseSubscriptionExpired ?  getString('Full Lifetime Access') : (!courseSubscriptionExpired ? `${getString('Expire on')} ${subscriptionEndData}` : getString('Expired'))}</Typography> */}
                            </Stack>
                        }
                        {
                            ( selfEnrolled == '1' &&   selfEnrolledDate > currentDate ) &&     <Typography color={ 'red'} variant={'caption'} fontWeight={500}> Available from  {selfEnrolledDate} </Typography>

                        }
                    
                        {
                            priceToShow &&
                                <Stack direction={'row'}>
                                    <Typography  variant="subtitle2">{priceSymbol} {monthlypaymentDivde}/</Typography>
                                    <Typography  variant="subtitle2">{isMonthlyPay?.months} {getString('Months')}</Typography>                        
                                </Stack>
                                
                        }

                    </Stack>
                }
            
                </Stack>

            </Stack>
    )
};

export default ProgramCard;
