import { AddLinkRounded, CancelRounded, CheckCircle, CheckCircleRounded, CheckRounded, ComputerRounded, FilterListRounded, PhotoLibraryRounded, UploadRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Fade, Grid, IconButton, Input, LinearProgress, Stack, Tab, Tabs, Typography } from "@mui/material"
import axios from "axios";
import Cookies from "js-cookie";
import Image from "next/image";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOUD_FRONT_URL_SLUG } from "../../config/defaultSlugs";
import { TOKEN_KEY } from "../../config/key";
import { reduxHelper } from "../../config/reduxHelper";
import { HTTP_REST_URLS } from "../../config/urls";
import { getString } from "../../functions/getString";
import { useFileUpload } from "../../hooks/useFileUpload";
import { useUnsplashImages } from "../../hooks/useUnsplashImages";
import EkhoolImage from "../ekhoolImage/ekhoolImage";
import MenuPopover from "../extras/MenuPopover"
import Iconify from "../Iconify";

const unsplashColors = [
    {
        color: '#000000',
        name: 'black'
    },
    {
        color: '#ffffff',
        name: 'white'
    },
    {
        color: '#fdc500',
        name: 'yellow'
    },
    {
        color: '#ff5400',
        name: 'orange'
    },
    {
        color: '#d00000',
        name: 'red'
    },
    {
        color: '#6e44ff',
        name: 'purple'
    },
    {
        color: '#ff0054',
        name: 'magenta'
    },
    {
        color: '#007f5f',
        name: 'green'
    },
    {
        color: '#07beb8',
        name: 'teal'
    },
    {
        color: '#0096c7',
        name: 'blue'
    }
]

const ekhoolImageGallery = [
    {
        image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/image_gallery/slanted-gradient.svg'
    },
    {
        image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/image_gallery/liquid-cheese.svg'
    },
    {
        image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/image_gallery/rose-petals.svg'
    }
]

const ImageItem = ({ item, index, onAddImage = () => {}, handleClose = () => {}, ...props }) => {

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        // Check if item is not null or undefined
        if (item) {
            setLoading(false); // Set loading to false when item data is available
        }
    }, [item]);

    return (
        <Box
            height={130}
            width={200}
            sx={{ backgroundColor: 'background.default', cursor: 'pointer' }}
            borderRadius={1}
            position={'relative'}
            overflow={'hidden'}
            onClick={() => {
                onAddImage(item?.urls?.full)
                handleClose()
            }}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            {...props}
        >

            {
                isLoading &&
                <Box
                    position={'absolute'}
                    width={'30%'}
                >
                    <LinearProgress/>
                </Box>
            }

            <EkhoolImage 
                src={item?.urls?.thumb}
                layout={'fill'}
                objectFit={'cover'}
            />
        </Box>
    )
}

const ImageGallery = ({ showImagePicker = null, onReset = () => {}, replaceIndex = -1, onAddImage = () => {}, children, ...props }) => {

    const [open, setOpen] = useState(showImagePicker);
    const [value, setValue] = useState(0);

    const [queryText, setQueryText] = useState('');
    const [unsplashImages, setUnsplashImages] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [imageUploadSucess, setImageUploadSuccess] = useState(false)
    const [imageUploadError, setImageUploadError] = useState(false)

    const [selectedColor, setSelectedColor] = useState('white');

    const { reducer } = useSelector((state) => state);
    const { themebuilderSelectedItem, appData } = reducer;

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };
    
    const handleClose = () => {
        setOpen(null);
        onReset()
    };

    const getUnsplashImages = async (color) => {
        const result = await useUnsplashImages(queryText ? queryText : 'landscape', color ? color : selectedColor)
        setUnsplashImages(result)
    };

    useEffect(() => {
        if(showImagePicker != null)
        {
            setOpen(showImagePicker)
        }
    }, [showImagePicker])


    const dispatch = useDispatch();

    const userToken = Cookies.get(TOKEN_KEY)
    const headers = { 'Authorization': userToken ? userToken : '' };

    const updateComponent = () => {
        dispatch({
            type: reduxHelper.UPDATE_COMPONENT
        })
    }

    const _onPickImage = async (e) => {
        
        setLoading(true)
        const files = e.target.files;
        const file = files[0];
        
        const data = new FormData();
        data.append("file", file, file.name);
        data.append('action', 'page_builder')

        const url = HTTP_REST_URLS.UPLOAD_FILE

        axios.post(url, data, {
            headers: headers,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                setUploadPercentage(percentage)
            }
        })
        .then((res) => {
            
            const resData = res.data;

            if(!resData.error)
            {

                setUploadPercentage(0)
                setImageUploadSuccess(true)
                const cloudfrontUrl = appData?.cloudfrontUrl;
                const imageUrl = resData?.url?.replace(CLOUD_FRONT_URL_SLUG, cloudfrontUrl)
                
                setTimeout(() => {
                    setLoading(false)
                    onAddImage(imageUrl)
                    setImageUploadSuccess(false)
                    handleClose()
                }, 3000)

            }
            else
            {
                setUploadPercentage(0)
                setImageUploadError(true)
                setTimeout(() => {
                    setLoading(false)
                    setImageUploadSuccess(false)
                    setImageUploadError(false)
                }, 3000)
            }

        })

        // const url = await useFileUpload(headers, data)
        // const cloudfrontUrl = appData?.cloudfrontUrl;
        // const imageUrl = url?.replace(CLOUD_FRONT_URL_SLUG, cloudfrontUrl)

        // onAddImage(imageUrl);
        // setLoading(false);
        // handleClose()

    };
    
    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                p: 0,
                ...(open && {
                    '&:before': {
                        zIndex: 1,
                        content: "''",
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        position: 'absolute'
                    },
                }),
                }}
                disableRipple
            >
                
                {children}

            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 2,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                    width: 710,
                    px: 3,
                    height: 550
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                arrow={'right-top'}
                {...props}
            >
                
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={'My device'} icon={<ComputerRounded/>} />
                        <Tab label={'Link'} icon={<AddLinkRounded/>} />
                        <Tab label={'Free images'} icon={<Iconify icon={'bxl:unsplash'} sx={{ height: 22, width: 22 }} /> } onClick={() => { getUnsplashImages() }} />
                        <Tab label={'Ekhool gallery'} icon={<PhotoLibraryRounded/>} />
                    </Tabs>
                </Box>

                <Box mt={2} >
                    {
                        value === 0 &&
                        <Box
                            height={450}
                            width={'100%'}
                            border={2}
                            borderColor={'divider'}
                            sx={{ borderStyle: 'dashed' }}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            borderRadius={1}
                            position={'relative'}
                        >

                            <Fade in={!isLoading}>
                                <Box
                                    height={!isLoading ? '100%' : 0}
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    flexDirection={'column'}
                                    position={'relative'}
                                    sx={{
                                        opacity: 1,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.3
                                        }
                                    }}
                                >

                                    <UploadRounded
                                        sx={{
                                            height: 80,
                                            width: 80
                                        }}
                                    />

                                    <input onChange={_onPickImage} accept="image/*" multiple type="file" style={{ height: '100%', width: '100%', position: 'absolute', opacity: 0, cursor: 'pointer' }} />

                                    <Typography>{getString(' Click to browse images')}</Typography>

                                </Box>
                            </Fade>

                            <Fade in={isLoading && !imageUploadSucess && !imageUploadError} >
                                <Box
                                    height={isLoading && !imageUploadSucess && !imageUploadError ? '100%' : 0}
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    flexDirection={'column'}
                                    position={'relative'}
                                >
                                    
                                    <LinearProgress variant="determinate" value={uploadPercentage} color={'primary'} sx={{ width: '60%' }} />
                                    <Typography variant={'caption'} mt={2} >{getString('uploading')}... ({uploadPercentage}%)</Typography>

                                </Box>
                            </Fade>

                            <Fade in={imageUploadSucess} >
                                <Box
                                    height={imageUploadSucess ? '100%' : 0}
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    flexDirection={'column'}
                                    position={'relative'}
                                >
                                
                                    <Stack alignItems={'center'} gap={1} >
                                        <CheckCircleRounded sx={{ height: 75, width: 75 }} color={'success'} />
                                        <Box>
                                            <Typography>{getString('Upload succes')}</Typography>
                                        </Box>
                                    </Stack>

                                </Box>
                            </Fade>

                            <Fade in={imageUploadError} >
                                <Box
                                    height={imageUploadError ? '100%' : 0}
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    flexDirection={'column'}
                                    position={'relative'}
                                >
                                
                                    <Stack alignItems={'center'} gap={1} >
                                        <CancelRounded sx={{ height: 75, width: 75 }} color={'error'} />
                                        <Stack alignItems={'center'} justifyContent={'center'} >
                                            <Typography>{getString(' Upload failed')}</Typography>
                                            <Typography variant={'caption'} color={'error'} >{getString('Please try again')}</Typography>
                                        </Stack>
                                    </Stack>

                                </Box>
                            </Fade>

                        </Box>
                    }

                    {
                        value === 1 &&
                        <Box
                            height={450}
                            width={'100%'}
                        >
                            
                            <Stack direction={'row'} gap={3} alignItems={'center'} mt={3} >
                                <Box
                                    height={40}
                                    sx={{ backgroundColor: 'divider' }}
                                    width={'100%'}
                                    borderRadius={1}
                                    px={2}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <Input
                                        placeholder={'Add link to import your image'}
                                        fullWidth
                                        disableUnderline
                                        sx={{
                                            fontSize: 13
                                        }}
                                    />
                                </Box>
                                <Button variant={'contained'} >{getString('Import')}</Button>
                            </Stack>

                        </Box>
                    }

                    {
                        value === 2 &&
                        <Box
                            height={450}
                            width={'100%'}
                            overflow={'hidden'}
                        >

                            <Box
                                height={45}
                                width={'96%'}
                                mb={2}
                            >

                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} >

                                    <Stack>
                                        <Box
                                            height={40}
                                            width={275}
                                            sx={{ backgroundColor: 'background.default' }}
                                            display={'flex'}
                                            alignItems={'center'}
                                            pl={2}
                                            pr={1}
                                            borderRadius={1}
                                            justifyContent={'space-between'}
                                        >
                                            <Input
                                                placeholder={'Search for images'}
                                                disableUnderline={true}
                                                sx={{ fontSize: 14 }}
                                                value={queryText}
                                                onChange={(e) => {
                                                    setQueryText(e.target.value)
                                                }}
                                                onKeyDown={(e) => {
                                                    const keyCode = e.keyCode;
                                                    if(keyCode === 13)
                                                    {
                                                        getUnsplashImages()
                                                    }
                                                }}
                                            />

                                            <IconButton size={'small'} onClick={() => { getUnsplashImages() }} >
                                                <FilterListRounded fontSize={'small'} />
                                            </IconButton>

                                        </Box>
                                    </Stack>

                                    <Stack direction={'row'} gap={1.2} >

                                        {
                                            unsplashColors.map((item, index) => {
                                                return (
                                                    <Box
                                                        height={24}
                                                        width={24}
                                                        sx={{ backgroundColor: item.color, outline: selectedColor === item.name && `2px solid ${item.color}`, cursor: 'pointer' }}
                                                        borderRadius={26}
                                                        border={2}
                                                        borderColor={'background.paper'}
                                                        onClick={() => {
                                                            setSelectedColor(item.name)
                                                            getUnsplashImages(item.name)
                                                        }}
                                                    >

                                                    </Box>
                                                )
                                            })
                                        }

                                    </Stack>

                                </Stack>

                            </Box>

                            <Box overflow={'hidden'} sx={{ overflowY: 'scroll' }} height={'100%'} pb={10} >
                                <Grid container spacing={2} >
                                    {
                                        unsplashImages.map((item, index) => {
                                            return (
                                                <Grid item >
                                                    <ImageItem 
                                                        item={item} 
                                                        index={index} 
                                                        onAddImage={(url) => {
                                                            onAddImage(url)
                                                            handleClose()
                                                        }}
                                                        handleClose={() => {
                                                            handleClose()
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>

                        </Box>
                    }

                    {
                        value === 3 &&
                        <Box
                            height={450}
                            width={'100%'}
                            overflow={'hidden'}
                        >

                            <Box overflow={'hidden'} sx={{ overflowY: 'scroll' }} height={'100%'}  >
                                <Grid container spacing={2} >
                                    {
                                        ekhoolImageGallery.map((item, index) => {

                                            item['urls'] = {}
                                            item['urls']['full'] = item.image;
                                            item['urls']['thumb'] = item.image;

                                            return (
                                                <Grid item >
                                                    <ImageItem 
                                                        item={item} 
                                                        index={index} 
                                                        onAddImage={(url) => {
                                                            onAddImage(url)
                                                            handleClose()
                                                        }}
                                                        width={208}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                            
                        </Box>
                    }

                </Box>
                
            </MenuPopover>
        </>
    )
};

export default ImageGallery;