import { Box, Link } from '@mui/material'
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import EkhoolImage from '../ekhoolImage/ekhoolImage';

const AppLogo = ({ height , ...props }) => {
console.log(height,"height1")
    const { reducer } = useSelector((state) => state);
    const { appData, user } = reducer;

    const homeUrl  = '';

    if(appData?.home_url)
    {
        if(appData?.home_url != "" && appData?.home_url != false)
        {
            homeUrl = appData?.home_url
        }
        else{
            homeUrl = '/'
        }
    }
    else{
        homeUrl = '/'
    }

    const { push } = useRouter();

    return (
        <Link underline='none'sx={{display:"flex",justifyContent:'flex-start'}}>
            <Box
                height={40} 
                // width={150}
                position={'relative'}
                sx={{
                    cursor: 'pointer',                    
                }}
                onClick={() => {push(`${homeUrl}`)}}
               
                {...props}
            >

                <EkhoolImage
                    src={appData?.logo}
                    layout={'fill'}
                    objectFit={'contain'}
                    height={height}
                />

            </Box>
        </Link>
    )
};

export default AppLogo;