import { AdjustRounded } from "@mui/icons-material";
import { Box, Card, CardContent, Icon, Stack, Typography } from "@mui/material"
import Image from "next/image";
import EkhoolImage from "../components/ekhoolImage/ekhoolImage";

const CustomContent2 = ({ item, ...props }) => {

    const { content } = item

    if(!content)
    {
        return <></>
    }

    const { title, icon } = content;

    return (
        <Card
            sx={{
                height: '100%'
            }}
        >
            <CardContent>

                <Stack {...props} >
                    {
                        content?.src &&
                        <Box   
                            position={'relative'}
                            overflow={'hidden'}
                            display={'flex'}
                            justifyContent={'center'} 
                            minWidth={300}
                            height={300}
                        >
                            <EkhoolImage layout={'fill'} objectFit={'contain'} src={content?.src}/>

                        </Box>
                    }

                    {
                        icon &&
                        <Box>
                            <Icon fontSize={'medium'} className={`mdi mdi-${icon}`} sx={{ height: '100%' }} />
                        </Box>
                    }

                    <Typography variant="h6" color={'text.primary'} >{title}</Typography>
                    <Typography color={'text.primary'} variant={'body2'}>{content?.content}</Typography>

                    
                </Stack>

            </CardContent>

        </Card>
    )
};

export default CustomContent2;