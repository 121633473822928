import { Box, CardContent, Grid, Skeleton, Stack } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProgramCardSkeleton from '../components/programcard/ProgramCardSkeleton'
import ProgramCard from '../components/programcard/ProgramCard'
import BundleCard from '../components/bundlecard/bundlecard'
import { useAllPrograms } from '../hooks/useAllPrograms'
import NoDataComponent from "../components/nodata/nodata";
import { getString } from "../functions/getString";

const SkeletonData = () =>{
    return(
        <Box>
            <Skeleton variant="rectangular" width={'100%'} height={150}/> 
            <Skeleton variant="rectangular" width={'100%'} height={12} sx={{mt:3, borderRadius:0.5}}/> 
            <Skeleton variant="rectangular" width={'70%'} height={12} sx={{mt:3, borderRadius:0.5}}/> 
        </Box>
    )
}

const CustomProgramListing = ({ item, ...props }) => {

    const [allPrograms, setAllPrograms] = useState([]);
    const [isLoading, setLoading]       = useState(true);

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder, headers } = reducer;

    const getAllPrograms = async () => {
        try{
            const result = await useAllPrograms(headers, '?get_bundles=true', true)
            setAllPrograms(result)
            setLoading(false)
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if(!isSiteBuilder)
        {
            getAllPrograms()
        }
    }, [isSiteBuilder]);

    const allProgramsLength = allPrograms?.length;

    return (
        <Stack {...props} >
        
            {
                isSiteBuilder && 
                <Grid container spacing={2} >
                    {
                        Array(5).fill(1).map((item,index) =>{
                            return(
                                <Grid item xl={2.4} lg={3} md={4} sm={6} xs={12}  key={index}>
                                    <ProgramCardSkeleton/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }

            {
                !isLoading && allProgramsLength < 1 &&                
            
                <Stack height={'30vh'} width={'100%'} alignItems={'center'} justifyContent={'center'} >
                    <NoDataComponent
                        title={getString('No courses found')}
                        description={`${getString('No courses found')}`}
                        sx={{
                            height: '60vh',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    />
                </Stack>
            }

            {
                isLoading && !isSiteBuilder &&
                <Grid container  spacing={3} mb={5} >
                    {
                        Array(15). fill(1).map((item) =>{
                            return(
                                <Grid item xl={2.4} lg={2.4} md={4} sm={6} xs={12} gap={4} >
                                    <SkeletonData/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }

            {
                !isSiteBuilder && allProgramsLength > 0 &&

                <Grid container spacing={2} >
                    {
                        allPrograms?.map((item, index) => {                           
                            return (                                           
                                <Grid item={true} xl={2.4} lg={3} md={4} sm={6} xs={6} key={index} >
                                    {
                                        item?.pb_type == 1 || item?.pb_type == 3? 
                                        (
                                            <ProgramCard
                                                item={item}
                                                index={index}
                                            />
                                        )
                                        :
                                        (
                                            item?.bb_title &&
                                            <BundleCard
                                                item={item}
                                                index={index}
                                            />
                                        )
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }

        </Stack>
    )
};

export default CustomProgramListing