import { customComponentTypes } from './types'
import CustomButton from './CustomButton'
import { Icon } from '@mui/material';
import CustomAccordion from './CustomAccordion';
import CustomTypography from './CustomTypography';
import CustomTypography1 from './CustomTypography1';
import CustomImage from './customImage';
import CustomContent from './customContent';
import CustomPopover from './CustomPopover';
import Cookies from 'js-cookie';
import { TOKEN_KEY } from '../config/key';
import { useSelector } from 'react-redux';
import CustomSpacer from './CustomSpacer';
import CustomContent2 from './customContent2';
import CustomContent3 from './customContent3';

import CustomFeatureCard from './CustomFeatureCard';
import CustomImageCard from './CustomImageCard';
import CustomIconCard from './CustomIconCard';



import CustomComponentWrapper from './CustomComponentWrapper';
import CustomIcon from './CustomIcon';
import CustomProgramListing from './customProgramListing'
import CustomTailwindComponent from './CustomTailwindComponent'
import CustomForm from './customForm'
import CustomArticles from './customArticles'
import CustomLogo from './CustomLogo'
import CustomTestimonials1 from './testimonials/customTestimonials1'

const CustomComponent = ({ children = 'Button', subKey,item = {}, index, parentItem, ...props }) => {

    const type = item?.type || ''

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder, user, authLoading } = reducer;
    
    const visibilityporps = item?.props?.visibilityporps;
    const visibleTo = visibilityporps?.visibleTo?.value;

    const visibleToLoggedInUsers = visibleTo && visibleTo === 'logged_in';
    const visibleToLoggedOutUsers = visibleTo && visibleTo === 'logged_out';
    const visibleToAllUsers = !visibleTo || visibleTo === 'all';

    const emptyComponent = <></>;
    const isLoggedIn = typeof user == 'object';

    const validateComponent = (component) => {

        if(isSiteBuilder)
        {
            return component
        }

        if(visibleToLoggedInUsers)
        {
            if(isLoggedIn)
            {
                return component;
            }
            else
            {
                return emptyComponent
            }
        }
        else if(visibleToLoggedOutUsers)
        {
            if(authLoading)
            {
                return <></>
            }
            if(isLoggedIn)
            {
                return emptyComponent;
            }
            else
            {
                return component
            }
        }
        else
        {
            return component
        }

    }

    let component = <></>

    switch(type) {
        
        case customComponentTypes.button :

            component = validateComponent(<CustomButton {...props} item={item} >{children}</CustomButton>);
            break;
            
        case customComponentTypes.icon :

            component = validateComponent(<CustomIcon {...props} item={item} />);
            break;

        case customComponentTypes.accordion :

            component = validateComponent(<CustomAccordion item={item} {...props} />);
            break;

        case customComponentTypes.typography :

            component = validateComponent(<CustomTypography item={item} {...props} >{children}</CustomTypography>);
            break;

        case customComponentTypes.image :

            component = validateComponent(<CustomImage item={item} {...props} />);
            break;

        case customComponentTypes.content :

            component = validateComponent(<CustomContent item={item} {...props} />);
            break;

        case customComponentTypes.content2 :

            component = validateComponent(<CustomContent2 item={item} {...props} />);
            break;

        case customComponentTypes.content3 :
            
            component = validateComponent(<CustomContent3 item={item} {...props} />);
            break;


        case customComponentTypes.feature1 :
            
            component = validateComponent(<CustomFeatureCard item={item} {...props} > {children}</CustomFeatureCard>);
            break;

        case customComponentTypes.image1 :
        
            component = validateComponent(<CustomImageCard item={item} {...props} > {children}</CustomImageCard>);
            break;
        
        case customComponentTypes.typography1 :
        
            component = validateComponent(<CustomTypography1 item={item} {...props} > {children}</CustomTypography1>);
            break;
        
        case customComponentTypes.icon1 :
        
            component = validateComponent(<CustomIconCard item={item} {...props} > {children}</CustomIconCard>);
            break;

        case customComponentTypes.popover :

            component = validateComponent(<CustomPopover item={item} {...props} />);
            break;

        case customComponentTypes.list :

            component = validateComponent(<CustomList item={item} {...props} />);
            break;

        case customComponentTypes.spacer :

            component = validateComponent(<CustomSpacer item={item} {...props} />);
            break;

        case customComponentTypes.customProgramListing :

            component = validateComponent(<CustomProgramListing item={item} {...props} key={index} />);
            break;

        case customComponentTypes.form:
            
            component = validateComponent(<CustomForm item={item} {...props} />);
            break;

        case customComponentTypes.articles:

            component = validateComponent(<CustomArticles item={item} {...props} parentItem={parentItem} />)
            break;

        case customComponentTypes.testimonials.type1:

            component = validateComponent(<CustomTestimonials1 item={item} {...props} />)
            break;

        case customComponentTypes.html:
            component = validateComponent(<CustomTailwindComponent item={item} {...props} />)
            break;

        case customComponentTypes.logo:
            component = validateComponent(<CustomLogo item={item} {...props} />);
            break;
            
        default:
            component = <></>
    }

    return (
        <CustomComponentWrapper item={item} index={index} parentItem={parentItem} >
            {component}
        </CustomComponentWrapper>
    )

};

export default CustomComponent;