const editorConfig = {

    DIMENSIONS: 'dimensions',

    SPACING: 'spacing',

    BORDER_RADIUS: 'borderRadius',

    SHADOW: 'shadow',

    COLOR: 'color',

    ICONS: 'icons',

    BORDER: 'border',

    COMPONENTS: 'components',

    IMAGES: 'images',

    CONTENT: 'content',

    COLOR: 'color',

    TABS: 'tabs',

    YOUTUBE: 'youtube',

    EMBEDDED: 'embedded',

    PROGRAMS: 'programs',

    ARTICLES: 'articles'

};

export {
    editorConfig
}