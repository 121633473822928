import { HTTP_REST_URLS } from "../config/urls"
import { useApiMiddleware } from "./useApiMiddleware";

export const useAllCategories = (headers) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.GET_ALL_CATEGORIES;

 
    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((res) => (res.json()))
        .then(async (response) => {

            const resultValue = await useApiMiddleware(response)

            if(!resultValue.error)
            {
                resolve(resultValue)
            }

        })
})
    
   