import {styled} from "@mui/material/styles";
import {HEADER, NAVBAR} from "../config/config";

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
    flexGrow: 1,
    // paddingTop: HEADER.MOBILE_HEIGHT ,
    [theme.breakpoints.up('md')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT ,
        marginLeft: '310px',
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: 50,
            width: '100%'
        }),
    },
    [theme.breakpoints.down('sm')]: {
        paddingTop: HEADER.MOBILE_HEIGHT ,
    },
    [theme.breakpoints.only('sm')]: {
        marginLeft: 60,
        width: `calc(100% - 25px)`,
    },
    backgroundColor: theme.palette.background.paper
}));

export default MainStyle;
