import dynamic from "next/dynamic";

const DynamicHeroWrapper = ({ heroKey }) => {
    const HeroComponent5      = dynamic(() => import(`../components/siteComponents/heros/hero5`));
    const HeroComponent1      = dynamic(() => import(`../components/siteComponents/heros/hero1`));
    const HeroComponent2      = dynamic(() => import(`../components/siteComponents/heros/hero2`));
    const HeroComponent3      = dynamic(() => import(`../components/siteComponents/heros/hero3`));
    const HeroComponent4      = dynamic(() => import(`../components/siteComponents/heros/hero4`));
    const HeroComponent6      = dynamic(() => import(`../components/siteComponents/heros/hero6`));
    const HeroComponent7      = dynamic(() => import(`../components/siteComponents/heros/hero7`));
    const HeroComponent8      = dynamic(() => import(`../components/siteComponents/heros/hero8`));
    const HeroComponent9      = dynamic(() => import(`../components/siteComponents/heros/hero9`));
    const HeroComponent10     = dynamic(() => import(`../components/siteComponents/heros/hero10`));
    const HeroComponent11     = dynamic(() => import(`../components/siteComponents/heros/hero11`));
    const AboutComponent1     = dynamic(() => import('../components/siteComponents/about/about1'));
    const AboutComponent2     = dynamic(() => import('../components/siteComponents/about/about2'));
    const InfoHeader1         = dynamic(() => import('../components/siteComponents/infoHeader/infoHeader1'));
    const Banner1             = dynamic(() =>import('../components/siteComponents/banners/banner1'));
    const Banner2             = dynamic(() =>import('../components/siteComponents/banners/banner2'));
    const Banner3             = dynamic(() =>import('../components/siteComponents/banners/banner3'));
    const Banner4             = dynamic(() =>import('../components/siteComponents/banners/banner4'));
    const Banner5             = dynamic(() =>import('../components/siteComponents/banners/banner5'));
    const Banner6             = dynamic(() =>import('../components/siteComponents/banners/banner6'));
    const Banner7             = dynamic(() =>import('../components/siteComponents/banners/banner7'));
    const Banner8             = dynamic(() =>import('../components/siteComponents/banners/banner8'));
    const Contact1            = dynamic(() =>import('../components/siteComponents/contact/contact1'));
    const Contact2            = dynamic(() =>import('../components/siteComponents/contact/contact2'));
    const Ticket1             = dynamic(() =>import('../components/siteComponents/ticker/ticker1'));
    const LayoutGrid          = dynamic(() =>import('../components/siteComponents/layout/layoutGrid'));
    const LayoutFooter        = dynamic(() =>import('../components/siteComponents/layout/layoutFooter'));
    const LayoutFooter1       = dynamic(() =>import('../components/siteComponents/layout/layoutFooter1'));
    const LayoutFooter2       = dynamic(() =>import('../components/siteComponents/layout/layoutFooter2'));
    const Layout1             = dynamic(() =>import('../components/siteComponents/layout/layout1'));
    const Layout2             = dynamic(() =>import('../components/siteComponents/layout/layout2'));
    const Layout3             = dynamic(() =>import('../components/siteComponents/layout/layout3'));
    const ColumnLayout        = dynamic(() =>import('../components/siteComponents/layout/columnLayout'));
    const CardLayout          = dynamic(() =>import('../components/siteComponents/layout/cardLayout'));
    const RowLayout           = dynamic(() =>import('../components/siteComponents/layout/rowLayout'));
    const CardRowLayout       = dynamic(() =>import('../components/siteComponents/layout/cardRowLayout'));


    

     


  
    

    const componentsMap = {
        hero5: HeroComponent5,
        hero1: HeroComponent1,
        hero2: HeroComponent2,
        hero3: HeroComponent3,
        hero4: HeroComponent4,
        hero6: HeroComponent6,
        hero7: HeroComponent7,
        hero8: HeroComponent8,
        hero9: HeroComponent9,
        hero10: HeroComponent10,
        hero11: HeroComponent11,
        about1: AboutComponent1,
        about2: AboutComponent2,
        infoheader1:InfoHeader1,
        banner1:Banner1,
        banner2:Banner2,
        banner3:Banner3,
        banner4:Banner4,
        banner5:Banner5,
        banner6:Banner6,
        banner7:Banner7,
        banner8:Banner8,
        contact1:Contact1,
        contact2:Contact2,
        ticker1:Ticket1,
        layoutGrid:LayoutGrid,
        layoutFooter:LayoutFooter,
        layoutFooter1:LayoutFooter1,
        layoutFooter2:LayoutFooter2,
        layout1:Layout1,
        layout2:Layout2,
        layout3:Layout3,
        columnLayout:ColumnLayout,
        cardLayout:CardLayout,
        cardRowLayout:CardRowLayout,
    };

    // Loop through the keys to find the matching component
    let Component = null;
    for (let i = 0; i <= 20; i++) {
        const heroKeyBase = heroKey.split('.')[0];
        const fullKey = `${heroKeyBase}${i > 0 ? `.${i}` : ''}`;
        
        if (componentsMap[heroKeyBase] && (heroKey === fullKey)) {
            Component = componentsMap[heroKeyBase];
            break;
        }
    }

    return Component ? <Component key={heroKey} heroKey={heroKey} /> : null;
};

export default DynamicHeroWrapper;
