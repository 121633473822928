import { styled } from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton, Badge} from '@mui/material';

import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import cssStyles from "../../utils/cssStyles";
import {HEADER, NAVBAR} from "../../config/config";
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
import {IconButtonAnimate} from "../animate";
import Iconify from "../Iconify";
import {ShoppingCart} from "@mui/icons-material";
import {useRouter} from "next/router";
import {useDispatch, useSelector} from "react-redux";
import { reduxHelper } from '../../config/reduxHelper';
import CartPopover from './CartPopover';
import Cookies from 'js-cookie';
import { ANNOUNCEMENT_KEY } from '../../config/key';
import ThemeSwicth from './ThemeSwitch';
import AppLogo from '../logo/Logo';
import HomeAccountPopover from './HomeAccountPopover';
import NotificationSidePopover from './NotificationSidePopup';
import useDevice from '../../hooks/useDevice';
import LanguagePopover from './LanguagePopover';
import { useUser } from '../../hooks/useUser';
import Script from 'next/script'


const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  minWidth: '100vw',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
  borderBottom: `2px solid ${theme.palette.divider}`
}));

const DashboardHeader = ({ onOpenSidebar, isCollapse = false, verticalLayout = false }) => {

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const router = useRouter();
  const { isMobile, isTablet } = useDevice();

  const { reducer } = useSelector((state) => state);
  const { cartItems, drawerOpened, mobileDrawerOpen,mobileDrawerOpened, showAnnouncementBar,appData ,user} = reducer;

  const dispatch = useDispatch();

  const openDrawer = () => {

    dispatch({
      type: reduxHelper.UPDATE_MOBILE_DRAWER,
      payload: true
    })

  }

  // const { user } = useUser()

  const { assets_url } = user || {}
  const { product_menu_url } = user || {}


  const expandDrawer = () => {

    if(isMobile || isTablet)
    {
      dispatch({
        type: reduxHelper.UPDATE_MOBILE_DRAWER_OPENED,
        payload: !mobileDrawerOpened
      })
    }
    else{
      dispatch({
        type: reduxHelper.UPDATE_DRAWER,
        payload: !drawerOpened
      })
    }
   
  }

  const announcementBarCookie = Cookies.get(ANNOUNCEMENT_KEY);

  return (
    <RootStyle 
      isCollapse={isMobile || isTablet ? mobileDrawerOpened : drawerOpened} 
      isOffset={isOffset} 
      verticalLayout={verticalLayout}
      sx={{
        top: !announcementBarCookie && showAnnouncementBar ? 75 : undefined,       
      }}
    >
      <Toolbar
        sx={{         
          minHeight: '100% !important',
          pl: { lg: 5 },
        }}
        style={{
          paddingRight: 0
        }}
      >
        {/*{isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}*/}

        {
            (isMobile || isTablet) &&
            <IconButtonAnimate onClick={() => { expandDrawer() }} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
        }

        {
          // isDesktop &&
          // <IconButtonAnimate onClick={() => { expandDrawer() }} sx={{ mr: 1, color: 'text.primary' }}>
          //   <Iconify icon="eva:menu-2-fill" />
          // </IconButtonAnimate>
        }
        
        <AppLogo height={'100%'}/>

        <Box sx={{ flexGrow: 1}} />

        <Stack direction="row" alignItems="center" gap={0.5} spacing={{ xs: 0.5, sm: 1.5 }}>

          {/* <IconButton
              onClick={() => {
                router.push('/cart')
              }}
          >
            <Badge badgeContent={cartItems?.length} color={'primary'} >
              <ShoppingCart/>
            </Badge>
          </IconButton> */}

          {appData?.isEthiopia  && (
            <>
        <Script src={`${product_menu_url}&productDivId=product-div-id`} />
  

        <div id="ekVendorLogo" class="" ></div>
          </>
          )}

           {!isMobile ? <Searchbar /> : ''}
          {/* <ThemeSwicth/> */}
          {/* {!isMobile ? <CartPopover/> : ''} */}

          {appData?.isEthiopia  && (

          <div class="active-details w-auto" >
            
                  {/* <img  src={baseUrl}"/assets/images/learningmanagemntsysytem.png"  alt="lms" class="product-images-active img-fluid" width="18px" height="18px" /> */}
                  <img
                        src={`${assets_url}assets/images/learningmanagemntsysytem.png`}
                        alt="lms"
                        className="product-images-active img-fluid"
                        width="18px"
                        height="18px" 
                      />

                  <div class=""> 
                      <span class="active-poduct-name" id="activeProduct">Lms</span> 
                  </div>
            </div>
          )}
          {
            isMobile &&
            <Stack direction={'row'} pr={6} alignItems={'baseline'} mt={-1} >
                {/* <NotificationSidePopover/>                
                <LanguagePopover/> */}
                <HomeAccountPopover/>
            </Stack>
          }
          {
            !isMobile &&
              <Box display={'flex'} alignItems={'center'} gap={2}>
                {/* <NotificationSidePopover/>
                <LanguagePopover/> */}
                <HomeAccountPopover/>
              </Box>
          }
          
        </Stack>
      </Toolbar>
    </RootStyle>
  );
};

export default DashboardHeader;
