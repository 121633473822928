let reduxHelper = {

    UPDATE_USER_DATA:                           'UPDATE_USER_DATA',
    UPDATE_SNACK_BAR:                           'UPDATE_SNACK_BAR',
    UPDATE_APPLICATION_DATA:                    'UPDATE_APPLICATION_DATA',
    UPDATE_MY_PROGRAMS:                         'UPDATE_MY_PROGRAMS',
    UPDATE_TRENDING_VIDEOS:                     'UPDATE_TRENDING_VIDEOS',
    UPDATE_POPULAR_COURSES:                     'UPDATE_POPULAR_COURSES',
    UPDATE_HUB_DATA:                            'UPDATE_HUB_DATA',
    UPDATE_THEME_BUILDER_JSON:                  'UPDATE_THEME_BUILDER_JSON',
    UPDATE_THEME_BUILDER_ITEM:                  'UPDATE_THEME_BUILDER_ITEM',
    UPDATE_COMPONENT:                           'UPDATE_COMPONENT',
    UPDATE_ALL_PROGRAMS:                        'UPDATE_ALL_PROGRAMS',
    UPDATE_CURRENT_AFFAIRS_DATA:                'UPDATE_CURRENT_AFFAIRS_DATA',
    UPDATE_COURSE_DETAIL:                       'UPDATE_COURSE_DETAIL',
    UPDATE_THEME_BUILDER_ITEM_STYLE:            'UPDATE_THEME_BUILDER_ITEM_STYLE',
    UPDATE_THEME_BUILDER_SELECTED_COMPONENT:    'UPDATE_THEME_BUILDER_SELECTED_COMPONENT',
    UPDATE_MY_EXAMS:                            'UPDATE_MY_EXAMS',
    UPDATE_BANNER_EDITOR:                       'UPDATE_BANNER_EDITOR',
    UPDATE_CATEGORY_PROGRAMS:                   'UPDATE_CATEGORY_PROGRAMS',
    UPDATE_ACHIEVEMENT_DATA:                    'UPDATE_ACHIEVEMENT_DATA',
    UPDATE_CUSTOM_CSS:                          'UPDATE_CUSTOM_CSS',
    UPDATE_THEME:                               'UPDATE_THEME',
    UPDATE_YOUTUBE_VIDEOS:                      'UPDATE_YOUTUBE_VIDEOS',
    UPDATE_THEME_BUILDER_CONFIRM_POPUP:         'UPDATE_THEME_BUILDER_CONFIRM_POPUP',
    UPDATE_CART_ITEMS:                          'UPDATE_CART_ITEMS',
    UPDATE_NOTIFICATION_POPUP:                  'UPDATE_NOTIFICATION_POPUP',
    UPDATE_NOTIFICATIONS:                       'UPDATE_NOTIFICATIONS',
    UPDATE_BANNER_POPUP:                        'UPDATE_BANNER_POPUP',
    UPDATE_ASSIGNMENT_DATA:                     'UPDATE_ASSIGNMENT_DATA',
    UPDATE_SEARCH_PROGRAMS:                     'UPDATE_SEARCH_PROGRAMS',
    UPDATE_OVERALL_REPORT:                      'UPDATE_OVERALL_REPORT',
    UPDATE_BOOKMARK_DATA:                       'UPDATE_BOOKMARK_DATA',
    UPDATE_HOMEPAGE_THEME_BACKGROUND:           'UPDATE_HOMEPAGE_THEME_BACKGROUND',
    UPDATE_PLAYER_CURRENT_MATERIAL:             'UPDATE_PLAYER_CURRENT_MATERIAL',
    UPDATE_PROGRAM_DETAIL:                      'UPDATE_PROGRAM_DETAIL',
    UPDATE_ALL_BUNDLES:                         'UPDATE_ALL_BUNDLES',
    UPDATE_MY_BUNDLES:                          'UPDATE_MY_BUNDLES',
    UPDATE_HOME_PAGE_JSON:                      'UPDATE_HOME_PAGE_JSON',
    UPDATE_YOUTUBE_DETAIL:                      'UPDATE_YOUTUBE_DETAIL',
    UPDATE_CURRENT_THEME_JSON:                  'UPDATE_CURRENT_THEME_JSON',
    UPDATE_DRAWER:                              'UPDATE_DRAWER',
    UPDATE_MOBILE_DRAWER_OPENED                 :'UPDATE_MOBILE_DRAWER_OPENED',
    UPDATE_ACTIVE_GROUP_CHAT:                   'UPDATE_ACTIVE_GROUP_CHAT',
    UPDATE_ACTIVITY_TIMER:                      'UPDATE_ACTIVITY_TIMER',
    UPDATE_VIDEO_MATERIALS:                     'UPDATE_VIDEO_MATERIALS',
    UPDATE_CURRENT_PLAYER_PROGRAM_ID:           'UPDATE_CURRENT_PLAYER_PROGRAM_ID',
    UPDATE_API_QUEUE:                           'UPDATE_API_QUEUE',
    UPDATE_MOBILE_DRAWER:                       'UPDATE_MOBILE_DRAWER',
    UPDATE_SOMETHING_WENT_WRONG:                'UPDATE_SOMETHING_WENT_WRONG',
    UPDATE_THEME_BUILDER_PAGE:                  'UPDATE_THEME_BUILDER_PAGE',
    UPDATE_PLAYER_CURRENT_PROGRAM:              'UPDATE_PLAYER_CURRENT_PROGRAM',
    UPDATE_PLAYER_NEXT_MATERIAL:                'UPDATE_PLAYER_NEXT_MATERIAL',
    UPDATE_PLAYER_PREVIOUS_MATERIAL:            'UPDATE_PLAYER_PREVOIUS_MATERIAL',
    UPDATE_ALL_CATEGORIES:                      'UPDATE_ALL_CATEGORIES',
    UPDATE_SELECTED_CATEGORY:                   'UPDATE_SELECTED_CATEGORY',
    UPDATE_VIDEO_NOTES:                         'UPDATE_VIDEO_NOTES',
    UPDATE_SELECTED_THEME_COLORS:               'UPDATE_SELECTED_THEME_COLORS',
    UPDATE_ALL_ARTICLE_CATEGORIES:              'UPDATE_ALL_ARTICLE_CATEGORIES',
    UPDATE_PAGE_JSON:                           'UPDATE_PAGE_JSON',
    UPDATE_LOGOUT_POPUP:                        'UPDATE_LOGOUT_POPUP',
    UPDATE_ANNOUNCEMENT_BAR:                    'UPDATE_ANNOUNCEMENT_BAR',
    UPDATE_LOGGED_STATUS:                       'UPDATE_LOGGED_STATUS',
    UPDATE_DEVICE:                              'UPDATE_DEVICE',
    UPDATE_AUTH_LOADING:                        'UPDATE_AUTH_LOADING',
    UPDATE_ALERT:                               'UPDATE_ALERT',
    UPDATE_SHARE_MODAL:                         'UPDATE_SHARE_MODAL',
    UPDATE_PLAYER_CURRENT_LESSON:               'UPDATE_PLAYER_CURRENT_LESSON',
    UPDATE_MINI_PLAYER:                         'UPDATE_MINI_PLAYER',
    UPDATE_IS_SITE_BUILDER:                     'UPDATE_IS_SITE_BUILDER',
    UPDATE_COPIED_SITE_BUILDER_CHILD:           'UPDATE_COPIED_SITE_BUILDER_CHILD',
    UPDATE_SITE_BUILDER_SELECTED_CHILD:         'UPDATE_SITE_BUILDER_SELECTED_CHILD',
    UPDATE_HEADERS:                             'UPDATE_HEADERS',
    UPDATE_CART_JSON:                           'UPDATE_CART_JSON',
    UPDATE_BLOCK_PROPERTIES:                    'UPDATE_BLOCK_PROPERTIES',
    UPDATE_LAYOUT_JSON:                         'UPDATE_LAYOUT_JSON',
    UPDATE_SITE_BUILDER_HOVERED_CHILD:          'UPDATE_SITE_BUILDER_HOVERED_CHILD',
    UPDATE_SITE_BUILDER_PREVIEW:                'UPDATE_SITE_BUILDER_PREVIEW',
    UPDATE_CHILD_ANIMATIONS:                    'UPDATE_CHILD_ANIMATIONS',
    UPDATE_SITE_BUILDER_SIDEBAR_MENU:           'UPDATE_SITE_BUILDER_SIDEBAR_MENU',
    UPDATE_FORM_JSON:                           'UPDATE_FORM_JSON',
    UPDATE_FORM_BUILDER_SELECTED_INDEX:         'UPDATE_FORM_BUILDER_SELECTED_INDEX',
    UPDATE_FORM_BUILDER_CONFIRM_POPUP:          'UPDATE_FORM_BUILDER_CONFIRM_POPUP',
    UPDATE_FORM_BUILDER_FOCUSED_OPTION_INDEX:   'UPDATE_FORM_BUILDER_FOCUSED_OPTION_INDEX',
    UPDATE_ACCESS_DENIED:                       'UPDATE_ACCESS_DENIED',
    UPDATE_SITE_BUILDER_SELECTED_PAGE:          'UPDATE_SITE_BUILDER_SELECTED_PAGE',
    UPDATE_APP_BUILDER_JSON:                    'UPDATE_APP_BUILDER_JSON',
    UPDATE_APP_BUILDER_CONFIRM_POPUP:           'UPDATE_APP_BUILDER_CONFIRM_POPUP',
    PAUSE_VIDEO_TIME :                          'PAUSE_VIDEO_TIME' ,
    COURSE_UPDATE_PERCENTAGE:                   'COURSE_UPDATE_PERCENTAGE',
    ALL_MATERIAL_DATA:                          'ALL_MATERIAL_DATA',
    UPDATE_ALL_SUB_CATEGORIES:                  'UPDATE_ALL_SUB_CATEGORIES',
    UPDATE_REVIEW_DATA:                         'UPDATE_REVIEW_DATA',

};

export {
    reduxHelper
}
