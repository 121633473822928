import { HTTP_REST_URLS } from "../config/urls"
import { useApiMiddleware } from "./useApiMiddleware";

export const useArticleCategories = (needSub = false) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.GET_ARTICLE_CATEGORIES;

    fetch(url)
        .then((res) => (res.json()))
        .then(async(response) => {

            const resultValue = await useApiMiddleware(response)

            if(!resultValue.error)
            {
                const { categories } = resultValue;
                
                if(Array.isArray(categories))
                {
                    
                    if(needSub)
                    {
                        let arr = []

                        categories?.filter((i) => (i.ac_parent_id == 0))?.map((item, index) => {

                            let obj = {}
                            obj = {...item}
                            obj['subCategories'] = categories?.filter((i) => (i.ac_parent_id === item.id))

                            arr.push(obj)

                        })

                        resolve(arr)
                    }
                    else
                    {
                        let arr = []
                        categories.map((i) => {

                            let obj = {}
                            obj['label'] = i.ac_title
                            obj['id'] = i.id
                            obj['ac_parent_id'] = i.ac_parent_id
                            obj['ac_slug'] = i.ac_slug

                            arr.push(obj)
                        })

                        resolve(arr)

                    }

                }
            }

        })

})