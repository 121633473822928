// export const convertToDateString = (dateString, format = 'ymd' ,splitDate = ':', splitTime = ":" ) => {
//     const [datePart, timePart] = dateString.split(' ');
//     let year, month, date;

//     if(splitTime == "no"){
//         timePart=null;

//     }
    
//     switch (format) {
//         case 'mdy':
//             [month, date, year] = datePart.split(splitDate);
//             break;
//         case 'dym':
//             [date, year, month] = datePart.split(splitDate);
//             break;
//         case 'dmy':
//             [date, month, year] = datePart.split(splitDate);
//             break;
//         case 'ymd':
//         default:
//             [year, month, date] = datePart.split(splitDate);
//             break;
//     }
//     if(splitTime != "no"){
//     let hours = '12', minutes = '00', seconds = '00';
//     }
//     if (timePart) {
//         [hours, minutes, seconds] = timePart.split(splitTime);
    
//     return {
//         year,
//         month,
//         date,
//         hours,
//         minutes,
//         seconds
//     };

//     }else{
//         return {
//             year,
//             month,
//             date

//         };

//     }

// };


export const convertToDateString = (dateString, format = 'ymd', splitDate = '-', splitTime = ":") => {
    const [datePart, timePart] = dateString.split(' ');
    let year, month, date;
    
    switch (format) {
        case 'mdy':
            [month, date, year] = datePart.split(splitDate);
            break;
        case 'dym':
            [date, year, month] = datePart.split(splitDate);
            break;
        case 'dmy':
            [date, month, year] = datePart.split(splitDate);
            break;
        case 'ymd':
        default:
            [year, month, date] = datePart.split(splitDate);
            break;
    }

    let hours = '12', minutes = '00', seconds = '00';

    if (splitTime !== "no" && timePart) {
        [hours, minutes, seconds] = timePart.split(splitTime);
    }

    const result = {
        year,
        month,
        date
    };

    if (splitTime !== "no") {
        result.hours = hours;
        result.minutes = minutes;
        result.seconds = seconds;
    }

    return result;
};
