import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, InputAdornment, ClickAwayListener, Link } from '@mui/material';
import {IconButtonAnimate} from "../animate";
import Iconify from "../Iconify";
import cssStyles from "../../utils/cssStyles";
import SearchCourse from "../search/searchcourse";
import {useSearchCourse} from "../../hooks/useSearchCourse";
import Cookies from "js-cookie";
import {useDispatch} from "react-redux";
import {reduxHelper} from "../../config/reduxHelper";
import { useRouter } from 'next/router';
import { TOKEN_KEY } from '../../config/key';
// utils

// components


// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {

  const [isOpen, setOpen]             = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const userToken = Cookies.get(TOKEN_KEY)
  const headers = { 'Authorization': userToken ? userToken : '' };

  const dispatch = useDispatch();
  const router   = useRouter();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchCourse = async () => {

    const result = await useSearchCourse(headers, searchQuery)
    dispatch({
      type: reduxHelper.UPDATE_SEARCH_PROGRAMS,
      payload: result
    })

  }

  useEffect(() => {
    searchCourse()
  }, [searchQuery])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>

        {/* {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )} */}

        {!isOpen && (
          <Link href='/search' underline='none'>
              <IconButtonAnimate onClick={() => { router.push("/search") }}>
                  <Iconify icon={'eva:search-fill'} width={20} height={20} />
              </IconButtonAnimate>
          </Link>
          
        )}

        {/* <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide> */}

        {/* {
          isOpen && <SearchCourse onClose={handleClose} />
        } */}

      </div>
    </ClickAwayListener>
  );
}
