import { ChevronRight } from "@mui/icons-material";
import { Box, Icon, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

const CustomContent3 = ({ item, ...props }) => {
  const { content } = item;

  const { reducer } = useSelector((state) => state);
  const { isSiteBuilder, siteBuilderSelectedChild } = reducer;

  const { push } = useRouter();

  if (!content) {
    return <></>;
  }

  const { title, icon } = content;

  return (
    <Stack width={"100%"} boxShadow={4}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        gap={0}
        width={"100%"}
        onClick={() => {
          if (!isSiteBuilder) {
            if (typeof item?.props?.path === "object") {
              push(item?.props?.path?.path);
            } else {
              // push(item?.url)
            }
          }
        }}
        sx={{
          ...(!isSiteBuilder &&
            item?.props?.path && {
              cursor: "pointer",
              "&:hover": {},
            }),
        }}
      >
        <Stack direction={"row"} width={"100"} gap={2} p={2}>
          {icon && (
            <Box>
              <Icon
                fontSize={"medium"}
                className={`mdi mdi-${icon}`}
                sx={{ height: "100%", color: item?.props?.style?.color }}
              />
            </Box>
          )}

          <Stack direction={"column"} width={"100%"}>
            <Typography
              variant="h6"
              display={"flex"}
              justifyContent={props?.style?.alignItems}
              alignitems={props?.style?.alignItems}
              textAlign={props?.style?.alignItems}
              color={"text.primary"}
            >
              {title}
            </Typography>
            <Typography variant={"body2"} color={"text.primary"}>
              {content?.content}
            </Typography>
          </Stack>
        </Stack>

        {props?.path !== "" && (
          <Stack width={"4%"} justifyContent={"center"} alignContent={"center"}>
            <Box>
              <ChevronRight fontSize="large" color="black" />
            </Box>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CustomContent3;
