import { styled } from '@mui/material/styles';
import { Box, Button, Stack, Container, Typography, InputAdornment, Grid, useTheme, IconButton, Card, CardContent } from '@mui/material';
import ComingSoonIllustration from "../../assets/illustration_coming_soon";
import { ErrorOutline } from '@mui/icons-material';
import { getString } from '../../functions/getString';
import { useEffect } from 'react';

const RootStyle = styled(Stack)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    width: '100%'
    // backgroundColor: '#F9FAFB'
}));

const NoDataComponent = ({ title, description, imageHeight = undefined, ...props }) => {
    useEffect(()=>{
        if (typeof translateScript === "function") 
        {
            translateScript();
        }
    },[])
    return (
        
            // <CardContent>
                <RootStyle {...props} >
                    <Container sx={{minHeight:'30vh', display:'flex', alignItems:'center', justifyContent:'center'}}>

                {/* <Box container sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}{...props} >                    
                        <ComingSoonIllustration sx={{ my: 10, height: imageHeight ? imageHeight : 240 }} />                    
                    <Box>
                        <Typography variant="h3" paragraph>
                            {title}
                        </Typography>
                        {description}
                    </Box>
                        
                </Box> */}

                {/* <Stack container display={'flex'} direction={{xl:'column', md:'column', sm:'column', xs:'column'}} sx={{alignItems:'center', justifyContent:'center'}} mb={5} >
                    <Box> 
                        <IconButton
                            sx={{
                                color:`${PRIMARY_DARK} !important`
                            }} 
                           
                        >
                            <ErrorOutline fontSize='large' />  
                                
                            
                        </IconButton>                                   
                                     
                    </Box> 
                    <Box display={'flex'} alignItems={'center'}flexDirection={'column'}>
                        <Typography variant="h3" paragraph textAlign={'center'}>{title}</Typography>
                            {description}
                    </Box>

                </Stack> */}

                        <Stack {...props} alignItems={'center'} >

                            <Box>
                                <IconButton
                                >
                                    <ErrorOutline fontSize='large' />
                                </IconButton>
                            </Box>

                            <Stack alignItems={'center'} justifyContent={'center'} p={2} gap={1} >
                                <Typography variant="subtitle1" textAlign={'center'}>{typeof title === 'string' ? getString(title) : title}</Typography>
                                <Typography color={'text.secondary'} variant="body2">{typeof description === 'string' ? getString(description) : description}</Typography> 
                            </Stack>

                        </Stack>

                    </Container>

                </RootStyle>
            // </CardContent>
        
    )
}

export default NoDataComponent;
