import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, CardHeader, DialogActions, DialogTitle, IconButton, Link, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { ArrowBack, CloseRounded, Cookie } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { reduxHelper } from '../../config/reduxHelper';
import { CALL_BACK_URL_KEY } from '../../config/key';
import Cookies from "js-cookie";
import { loginHistoryPaths } from '../../config/config';
import { getString } from '../../functions/getString';

const FullscreenModel = ({ open }) => {
    
    const { push, replace, query, pathname } = useRouter();
    const dispatch = useDispatch()

    const { reducer } = useSelector((state) => state)
    const { showAlert } = reducer;

    const closeModal = () => {
      dispatch({
        type: reduxHelper.UPDATE_ALERT,
        payload: false
      })
    };

    const onLogin = () => {

      const currentUrl = window.location.pathname;

      const callBackObject = {
        url: currentUrl,
        type: loginHistoryPaths[pathname] || ''
      }
      Cookies.set(CALL_BACK_URL_KEY, JSON.stringify(callBackObject))

      replace(`/login?callback=${encodeURI(currentUrl)}`)
      
    }

    return (
      <Dialog 
        open={query?.debug ? false : showAlert}
        sx={{
          zIndex: 99999
        }}
      >
        <CardHeader
          title={ getString('Want to view this page?')}
        />

        <DialogContent sx={{ pb: 0 }} >
          <DialogContentText id="alert-dialog-description" sx={{display:'flex', flexDirection:'column'}}>
            <Typography variant='caption'>{getString("You are not authorised to view this page. Register or Login to continue")}.</Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
        
          <Button 
              variant={'contained'} 
              color={'inherit'} 
              onClick={() => {
                closeModal()
                push('/')
              }}
              size={'small'}
          >{getString("Back to home")}</Button>

          <Button 
              variant={'contained'}  
              onClick={() => {
                onLogin()
              }}
              size={'small'}
          >{getString("Login")}</Button>

        </DialogActions>

      </Dialog>
    );
}
export default FullscreenModel;
