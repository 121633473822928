import { ArrowBackRounded, ArrowUpwardRounded } from "@mui/icons-material";
import { Box, Container, Fab, IconButton, Stack, Typography ,Link,Button} from "@mui/material";
import dynamic from "next/dynamic";
import Router, { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultLayoutJson } from "../../config/defaultValues";
import { reduxHelper } from "../../config/reduxHelper";
import { routes, SITE_BUILDER_PATH } from "../../config/routes";
import { dynamicComponents } from "../../siteThemes/components";
import MainStyle from "../../style/MainStyle";
import AnnouncementBar from "../announcement/AnnouncementBar";
import HomeFooter from "../header/HomeFooter";
import HomeHeader from "../header/HomeHeader";
import DashboardHeader from "../homeHeader";
import ScrollTop from "../ScrollTop";
// import SideBar from "../sidebar/sidebar";
import ProductList from "../products/productList";
import useResponsive from "../../hooks/useResponsive";
import useDevice from "../../hooks/useDevice";
import AppLogo from "../logo/Logo";
import AccountPopover from "../homeHeader/AccountPopover";
import { getString } from "../../functions/getString";
import { getStyles } from "../../functions/getStyles";
import AccessDenied from "../error/AccessDenied";
import Loading from "../Loading/loading";
import Header11 from "../siteComponents/headers/header11";
import Header4 from "../siteComponents/headers/header4";
import RtlStyle from "../../style/RtlStyle";

const SideBar = dynamic(() => import('../sidebar/sidebar'), {
    ssr: false
})

const sideBarPaths = [
    '/dashboard', 
    '/home',
    '/my-courses',
    '/my-learn',
    '/reports', 
    '/liveclasses', 
    '/exams/dashboard', 
    '/assignments/dashboard', 
    '/bookmarks', 
    '/program/[slug]', 
    '/exams', 
    '/assignments',
    '/subscribe',
    '/program/[slug]/topic/[id]',
    '/assignment/report/[id]',
    routes.INVOICE
]

const homeHeaderPaths = [
    '/admin-beta/site-builder',
    '/program/[slug]/learn/[id]',
    '/program/[slug]/learn',
    '/',
    '/admin-beta/form-builder/[id]',
    '/course/[slug]/learn',
    '/course/[slug]/learn/[id]',
    '/admin-beta/app-builder',
    '/admin-beta/page_preview',
]

const withoutSidebarPaths = [
    '/exams/[id]',
    '/assignments/[id]'
]


const SiteLayout = ({ children, ...props }) => {
    const routers = useRouter();
    
    const {query, pathname, push, ...others  } = useRouter();
  
    let v = pathname?.split('/')[2]
    const { reducer } = useSelector((state) => state);
    const { showAnnouncementBar, user, appData, drawerOpened, device, layoutJson , isSiteBuilder, pageJson} = reducer;
    const { product_menu_url } = user || ''

    const [isLoading, setLoading] = useState(true)
    const [direction, setDirection] = useState("rtl");

    const dispatch = useDispatch()

    const {isMobile,isTablet} = useDevice()
    
    const themeJson = (typeof key != 'undefined')?layoutJson?.components?.filter((i) => (i.key === key)):[];
    const themeItem = themeJson?.length > 0 && themeJson[0];
    const style = getStyles(themeItem && themeItem?.style ? themeItem.style : {})
    const links = [
        { text: getString('Home'), url: '/p/landing' },
        // { text: getString('My Account'), url: '/about' },
        { text: getString('Course Catalog'), url: '/all-courses' },
        { text: getString('Site Map'), url: '/p/site-map' },
      
      ];

      useEffect(() => {
        const updateDirection = () => {
            const dir = document.documentElement.dir || "ltr";
            console.log(dir, "direction updated");
            setDirection(dir);
        };
    
        updateDirection(); // Initial check
    
        const observer = new MutationObserver(updateDirection);
        observer.observe(document.documentElement, { attributes: true, attributeFilter: ["dir"] });
    
        router.events.on("routeChangeComplete", updateDirection);
    
        return () => {
            observer.disconnect();
            router.events.off("routeChangeComplete", updateDirection);
        };
    }, [query, pathname]);
    
    
    
    
    console.log(direction, "direction"); // Example: "rtl" or "ltr"
        
        
    const getLayoutJson = async () => {

        setLoading(true)

        const url = appData?.cloudfrontUrl + 'uploads/' + appData?.app_id + '/data/' + 'site_builder/layout/bGF5b3V0.json?v=' + Math.random() * 100;

        fetch(url)
            .then((res) => res.json())
            .then((response) => {
                if (response?.isDefault) {
                    let obj = { ...response };
                    obj['components'] = [];
        
                    response?.components?.map((i) => {
                        if (i.name == 'Header') {
                          
        
                            // if (appData?.allow_signup==true) {
                            //     const registerButton = {
                            //         "props": {
                            //             "variant": "contained",
                            //             "visibilityporps": {
                            //                 "visibleTo": {
                            //                     "name": "Logged out users",
                            //                     "value": "logged_out"
                            //                 }
                            //             }
                            //         },
                            //         "url": "/register",
                            //         "children": "Register",
                            //         "type": "button",
                            //         "name": "Button",
                            //         "label": "Register"
                            //     };
        
                            //     i.children.push(registerButton);
                            // }
                            if (appData?.allow_signup !=true){
                                // Remove the button by filtering out the button component
                                i.children = i.children.filter(child => child.name !== 'Button' || child.url !== '/register');
                            }
        
                            obj.components.unshift(i);
                        }
                        if (i.name == 'Footer') {
                            obj.components.push(i);
                        }
                    });
        
                    if (response?.theme) {
                        dispatch({
                            type: reduxHelper.UPDATE_SELECTED_THEME_COLORS,
                            payload: response?.theme
                        });
                    }
        
                    dispatch({
                        type: reduxHelper.UPDATE_LAYOUT_JSON,
                        payload: obj
                    });
        
                    // dispatch({
                    //     type: reduxHelper.UPDATE_PAGE_JSON,
                    //     payload: response
                    // });
        
                    setLoading(false);
                }
            
                else
                {
                    setLoading(false)
                    // dispatch({
                    //     type: reduxHelper.UPDATE_LAYOUT_JSON,
                    //     payload: null
                    // })

                    // dispatch({
                    //     type: reduxHelper.UPDATE_PAGE_JSON,
                    //     payload: response
                    // })
                }

            })
            .catch((error) => {
                setLoading(false)
                dispatch({
                    type: reduxHelper.UPDATE_LAYOUT_JSON,
                    payload: defaultLayoutJson
                })
            })

    }

    useEffect(() => {
        if(appData)
        {
            getLayoutJson()
        }
    }, [appData?.app_id,]);

    const checkIsSiteBuilder = () => {
        if (pathname == SITE_BUILDER_PATH) {
            if (!isSiteBuilder) {
                dispatch({
                    type: reduxHelper.UPDATE_IS_SITE_BUILDER,
                    payload: true
                })
            }
        }
        else {
            dispatch({
                type: reduxHelper.UPDATE_IS_SITE_BUILDER,
                payload: false
            })
        }
    }

    useEffect(() => {
        checkIsSiteBuilder()
    }, [pathname])

    if(user)
    {
        if(!sideBarPaths.includes('/subscribe'))
        {
            sideBarPaths.push('/subscribe')
        }
    }

    const { replace, } = useRouter()
    
    const headerResult = layoutJson?.isDefault ? layoutJson?.components?.filter((i) => (i.name == 'Header')) : []

    const footerResult = layoutJson?.isDefault ? layoutJson?.components?.filter((i) => (i.name == 'Footer')) : []
    
    const headerItem  = headerResult?.length > 0 ? headerResult[0] : {};
    const footerItem  = footerResult?.length > 0 ? footerResult[0] : {};

    const Header = headerItem?.key ? dynamicComponents[headerItem.key] : <></>;
    const Footer = footerItem?.key ? dynamicComponents[footerItem.key] : <></>;
    
    const isHomeHeader = homeHeaderPaths.includes(pathname)

    const isDesktop = useResponsive('up', 'lg');

    // const css = `
    // .list-item-active {
    //     background-color: red!important
    // }`
    const { back,...router } = useRouter()

    const [pageLoading, setPageLoading] = useState(false)
    const [showDeined, setShowDenied] = useState(false)

    const validatePath = () => {

        setShowDenied(false)
        setPageLoading(true)

        if(router.asPath == '/p/landing/')
        {
            if(!user)
            {
                setShowDenied(true)
                setPageLoading(false)
            }
        }
        else
        {
            setShowDenied(false)
            setPageLoading(false)
        }
        
    }

    if(isLoading) {
        return <Loading/>
    }

    if(showDeined){
        return <AccessDenied/>
    }
    
    if(!sideBarPaths?.includes(pathname) && !withoutSidebarPaths?.includes(pathname))
    {
        return (
            <>
                <AnnouncementBar/>

                {
                    !isHomeHeader && !(query.header_footer == 'hide') &&
                    <>
                        {
                            !headerItem?.key ? 
                            <Stack  width={'100%'} bgcolor={'background.default'} >
                                {
                                    !isLoading &&
                                    <>
                                        {
                                            
                                            pathname == '/a/[slug]' ? 
                                            <>
                                                {/* <Stack height={60}/>                                     */}
                                                <Stack
                                                    height={60}
                                                    bgcolor={'#fff'}
                                                    mb={2}
                                                    direction={'row'}
                                                    pr={2}
                                                    alignItems={'center'}
                                                    gap={2}
                                                    borderBottom={1}
                                                    position={'fixed'}
                                                    width={'100vw'}
                                                    borderColor={'grey.600'}
                                                    zIndex={9}
                                                    display={"flex"}
                                                    justifyContent={"space-between"}
                                                    style={{
                                                        ...style,
                                                        // gap: undefined,                
                                                        paddingLeft: isMobile || isTablet ? '15px' : "60px",
                                                        paddingRight: isMobile || isTablet ? '15px' : "60px"
                                                        // paddingTop: isMobile || isTablet ? '15px' : style?.paddingTop,
                                                        // paddingBottom: isMobile || isTablet ? '15px' : style?.paddingBottom,
                                                        }}
                                                        {...themeItem?.props} 
                                                    >

                                                        <Box display={"flex"} gap={3}>  
                                                            {
                                                                isMobile  &&   
                                                                <IconButton onClick={() => {back()}} >
                                                                    <ArrowBackRounded/>
                                                                </IconButton>
                                                            }

                                                            {
                                                                !isMobile &&
                                                                <IconButton onClick={() => {back() }}>
                                                                    <ArrowBackRounded/>
                                                                </IconButton>
                                                            }

                                                            <AppLogo/>
                                                        </Box> 
                                                    {
                                                        !isMobile &&    
                                                        <>            
                                                            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" gap={"10px"}>
                                                                <AccountPopover/>
                                                            </Box>
                                                        </>
                                                    }
                                                </Stack>
                                            </>
                                            :
                                            <>
                                                {appData?.app_id === '530' ? <Header11/> : <Header4/>}
                                            </>
                                        }
                                    </>
                                }

                            </Stack>
                            :
                            <Stack  width={'100%'} height={'8%'} bgcolor={'background.default'} >
                                {
                                    headerItem?.key ? 

                                        pathname == '/a/[slug]' ? 
                                        <>
                                                    {/* <Stack height={60}/>                                     */}
                                                    <Stack
                                                        height={60}
                                                        bgcolor={'#fff'}
                                                        mb={2}
                                                        direction={'row'}
                                                        pr={2}
                                                        alignItems={'center'}
                                                        gap={2}
                                                        borderBottom={1}
                                                        position={'fixed'}
                                                        width={'100vw'}
                                                        borderColor={'grey.600'}
                                                        zIndex={9}
                                                        display={"flex"}
                                                        justifyContent={"space-between"}
                                                        style={{
                                                            ...style,
                                                            // gap: undefined,                
                                                            paddingLeft: isMobile || isTablet ? '15px' : "60px",
                                                            paddingRight: isMobile || isTablet ? '15px' : "60px"
                                                            // paddingTop: isMobile || isTablet ? '15px' : style?.paddingTop,
                                                            // paddingBottom: isMobile || isTablet ? '15px' : style?.paddingBottom,
                                                            }}
                                                            {...themeItem?.props} 
                                                        >

                                                            <Box display={"flex"} gap={3}>  
                                                                {
                                                                    isMobile  &&   
                                                                    <IconButton onClick={() => {back()}} >
                                                                        <ArrowBackRounded/>
                                                                    </IconButton>
                                                                }

                                                                {
                                                                    !isMobile &&
                                                                    <IconButton onClick={() => {back() }}>
                                                                        <ArrowBackRounded/>
                                                                    </IconButton>
                                                                }

                                                                <AppLogo/>
                                                            </Box> 
                                                        {
                                                            !isMobile &&    
                                                            <>            
                                                                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" gap={"10px"}>
                                                                    <AccountPopover/>
                                                                </Box>
                                                            </>
                                                        }
                                                    </Stack>
                                        </>
                                        :
                                        <Header/> 
                                    
                                    : 
                                    <HomeHeader/>
                                }
                            </Stack>
                        } 
                    </>
                }

                <Stack 
                    minHeight={650} 
                    // my={{
                    //     xs: 0,
                    //     sm: !isHomeHeader ? 4 : 0
                    // }} 
                >
                    <Stack direction={'row'}>
                        <Stack maxWidth={'100vw'} minWidth={'100vw'} >
                            {children}
                        </Stack>
                        {/* <ProductList/> */}
                    </Stack>
                </Stack>

                {
                    !isHomeHeader && !(query.header_footer == 'hide') &&
                    <>
                        {
                            !footerItem?.key ? 
                            <Stack  width={'100%'} bgcolor={'background.default'} >

                            </Stack>
                            :
                            <Stack  width={'100%'} bgcolor={'background.default'} >
                                {
                                    footerItem?.key ? <Footer/> : <HomeFooter/>
                                }
                            </Stack>
                        } 
                    </>
                }
                
            </>
        )
    }

    if(withoutSidebarPaths?.includes(pathname))
    {
        return(
            <>
                {
                    <>
                    {
                        !(query.header_footer == 'hide') &&
                        <DashboardHeader/>
                    }

                        <Container maxWidth={'xl'} sx={{mt:{md:7, xs:4}}}>
                            <Box minHeight={700} py={{md:4, xs:2}} width={'100%'} overflow={'hidden'} sx={{ overflowY:  'auto' }} className={'hide-scrollbar'} >
                                {children}
                            </Box>
                                                    
                            {
                                !isMobile &&
                                    <HomeFooter/>
                            }
                        </Container>
                    </>
                }
            </>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: { sm: 'flex' },
                    minHeight: { sm: 1 },
                }}
            >
                <SideBar/>

                {
                    direction&&direction== 'rtl' 
                    ?
                    <>
                   
                        <RtlStyle  collapseClick={!drawerOpened} >
                            {
                                !(query.header_footer == 'hide') &&
                                <DashboardHeader/>
                            }
                            

                            <Container maxWidth={'lg'}>
                                <Box minHeight={700} py={{md:4, xs:2}} width={'100%'} overflow={'hidden'} sx={{ overflowY:  'auto' }} className={'hide-scrollbar'} >
                                    {children}
                                </Box>
                                                        
                                {
                                    !isMobile &&
                                        <HomeFooter/>
                                }
                            </Container>
                        </RtlStyle>
                    </>
                    :
                    <>
                       
                        <MainStyle  collapseClick={!drawerOpened} >
                            {
                                !(query.header_footer == 'hide') &&
                                <DashboardHeader/>
                            }
                            

                            <Container maxWidth={'lg'}>
                                <Box minHeight={700} py={{md:4, xs:2}} width={'100%'} overflow={'hidden'} sx={{ overflowY:  'auto' }} className={'hide-scrollbar'} >
                                    {children}
                                </Box>
                                                        
                                {
                                    !isMobile &&
                                        <HomeFooter/>
                                }
                            </Container>
                        </MainStyle>
                    </>
                }



                <ProductList/>

                    {
                        isMobile &&
                        
                        <>
                            <Stack id={'account-data-menu'} >
                
                            </Stack>

                            <Stack 
                                id={'product-div-id'} 
                                style={{
                                    marginTop: '70px'
                                }}
                            >
                            </Stack>
                        </>
                    }
                   
                

                {/* <Script
                    src={`${product_menu_url}&userDivId=account-data-menu&productDivId=product-div-id`}
                /> */}

                <Box>
                    <ScrollTop {...props} >
                        <Fab
                        
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                })
                            }}
                            size={'medium'}
                            color={'inherit'}
                        >
                            <ArrowUpwardRounded />
                        </Fab>
                    </ScrollTop>
                </Box>  

            </Box>
        </>
    )
};

export default SiteLayout;
