import { AdjustRounded } from "@mui/icons-material";
import { Avatar, Box, Icon, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { useSelector } from "react-redux";
import { customComponentTypes } from "./types";
import CustomTypography from "./CustomTypography";
import useDevice from "../hooks/useDevice";

// const CustomJumpCard = ({ item,maxLines, ...props }) => {
const CustomIconCard = ({ item, ...props }) => {
  const { content } = item;

  const { push } = useRouter();

  const maxLines = 2;

  const { reducer } = useSelector((state) => state);
  const [isHovered, setIsHovered] = useState(false);
  const { isSiteBuilder, pageJson, siteBuilderSelectedChild } = reducer;

  const { isMobile, isTablet } = useDevice();

  if (!content) {
    return <></>;
  }

  const { icon } = content;

  const stackStyles = {
    backgroundColor: props?.style2?.color,

    padding: "1rem",
    transition: "transform 0.2s",
    borderRadius: (() => {
      switch (props?.borderRadius) {
        case "Full":
          return "0px";
        case "Medium":
          return "0px";
        case "Low":
          return "0px";
        case "None":
          return "0px";
        default:
          return "  0px";
      }
    })(),
  };



  // circle   icon background
  const boxStyles = {
    width: (() => {
      switch (props.fontSize) {
        case "large":
          return "25%";
        case "medium":
          return "21%";
        case "small":
          return "15%";
        case "none":
          return "0%";
        default:
          return "25%";
      }
    })(),

    background: props?.style3?.color,
    backgroundColor: props?.style3?.color,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center !important",
    // margin: "auto",
    height: (() => {
      switch (props.fontSize) {
        case "large":
          return "110px";
        case "medium":
          return "90px";
        case "small":
          return "70px";
        default:
          return "110px";
      }
    })(),
    // background: props?.style?.color,
  };

  const textStyles = {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  };

  const shadowStyles = {

    // backgroundColor:props?.style2?.color,

    // backgroundColor:'red'
    // padding: '1rem',
  };

  const nonShadowStyles = {
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)", // Standard shadow color

    // backgroundColor:props?.style2?.color,
    borderRadius: (() => {
      switch (props?.borderRadius) {
        case "Full":
          return "0px";
        case "Medium":
          return "0px";
        case "Low":
          return "0px";
        case "None":
          return "0px";
        default:
          return "  0px";
      }
    })(),
    // backgroundColor:'red'
    // padding: '1rem',
  };

  return (
    <Stack
      // {...props}
      width={"100%"}
      height={"100%"}
      onClick={() => {
        if (!isSiteBuilder) {
          if (typeof item?.props?.path === "object") {
            push(item?.props?.path?.path);
          } else {
            // push(item?.props?.path)
          }
        }
      }}


      sx={{
        ...(isHovered ? nonShadowStyles : shadowStyles),
        ...(!isSiteBuilder &&
          item?.props?.path && {
            cursor: "pointer",
          }),
      }}
    >
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          ...stackStyles,
          ...(!isSiteBuilder &&
            item?.props?.path && {
              cursor: "pointer",
              // '&:hover': {
              // },
            }),
        }}
        // direction={"row"}
        gap={3}
        width={"100%"}
        height={"100%"}

      >

          {/* <Avatar  sx={boxStyles}> */}
            <Stack>
              <Icon


                fontSize={
                  props.fontSize === "large"
                    ? "4.25rem"
                    : props.fontSize === "medium"
                    ? "4.25rem"
                    : "2.25rem"
                }
                className={`mdi mdi-${icon}`}
                sx={{
                  height: "100%",
                  color: `${props?.style4?.color}`,
                  fontSize: (theme) =>
                    ({
                      large: theme.typography.pxToRem(58),
                      medium: theme.typography.pxToRem(48),
                      small: theme.typography.pxToRem(38),
                    }[props.fontSize] || "2.25rem"),
                }}
              />
            </Stack>
            {/* </Box> */}
          {/* </Avatar> */}


      </Stack>
    </Stack>
  );
};

export default CustomIconCard;
