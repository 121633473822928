import moment from "moment/moment"

export const useTimeDifference = (oldDate, newDate) => {

    const momentOldDate = moment(oldDate);
    const momentNewDate = moment(newDate);

    const difference = momentNewDate.diff(momentOldDate, 'minutes')

    return difference;

}