//CSS
import './styles.css';
import '../styles/globals.css'
import '../styles/feather.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "react-color-palette/lib/css/styles.css";
import 'react-quill/dist/quill.snow.css'

//NEXT PACKAGES
import Head from "next/head";
import Script from "next/script"

//REACT
import { useEffect } from "react"

//REDUX
import { wrapper } from "../app/store";

//PROVIDERS
import AuthProvider from "../components/auth/AuthProvider";
import ThemeProvider from "../theme";
import NotistackProvider from "../utils/NotistackProvider";

//COMPONENTS
import FullscreenModel from "../components/dialog/dialog";
import ProgressLoading from "../components/Loading/progressloading";
import ShareModal from "../components/modals/ShareModal";
import SiteLayout from "../components/layout/siteLayout";
import Page404 from "./404";

//MUI THEME
import ThemeColorPresets from "../components/theme/ThemeColorPresets";

//CONFIG
import ERRORS from "../config/errorSlugs";
import { HTTP_REST_URLS } from "../config/urls";
import { useFeatureAccess } from '../hooks/useFeatureAccess';
import { featureAccessKeys } from '../config/featureAccessKeys';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { routes } from '../config/routes';
import Loading from '../components/Loading/loading';
import { useDispatch, useSelector } from 'react-redux';
import { reduxHelper } from '../config/reduxHelper';
import useApplicationDetails from '../hooks/useApplicationDetails';
import { CART_KEY, LANGUAGE_KEY } from '../config/key';
import Cookies from 'js-cookie';
import { useUser } from '../hooks/useUser';
import { useAnalyticsData } from '../hooks/useAnalyticsData';
function Page404Component()
{
    return (
        <ThemeProvider>
            <ThemeColorPresets>
                <Page404/>
            </ThemeColorPresets>
        </ThemeProvider>
    );
};

function CustomApp({ Component, pageProps, ...props }) {

    const { error = false, errorCode = '' } = props || {};

    const { appData } = useApplicationDetails();
    const { reducer } = useSelector((state) => state);
    const [direction, setDirection] = useState("");
    const [pixelId, setPixelId] = useState(null);
    const [customInputValue, setcustomInputValue] = useState(null);
    const [analyticsId, setAnalyticsId] = useState(null)
    const [googleTagId, setGoogleTag] = useState(null)

    const { pageJson} = reducer;
    const { user }    = useUser();

    // if (user) {
    //     const style = document.createElement('style');
    //     style.innerHTML = `
    //         .taskiconpadding, .topNavRightBtn {
    //         display: none;
    //         }
    //         `;
    //     document.head.appendChild(style);
    // }


    const calendar_asset_url = `${user?.assets_url}admin/js/ethiopian_calendar_script.js` ;
    const widget_url = `${appData?.product_menu_url}?userDivId=account-without-log&&productDivId=product-without-log&active=lms`;
  
    const [routeAllowed, setRouteAllowed] = useState({
        isLoading: true,
        allowRoute: false
    });
    const router = useRouter();
    const { pathname, query, replace } = router;
    const [scriptLoaded, setScriptLoaded] = useState(false); 
    const { clearcart } = query || ''
    const dispatch = useDispatch()
    
    const getAnalytics = async () => {
       
        const result            = await useAnalyticsData();
       
        const credentialsItem   = result.map((item) => (item?.credentials));
        const customItem        = result.map((item) => (item?.customData));
        // console.log(credentialsItem);

        const credentialsPixel      = credentialsItem.map((item) => (item?.pixelsId));
        const credentialsPixelId    = (credentialsPixel[0]?.input.value)? credentialsPixel[0].input.value :'';
              
        const credentialsAnalytics      = credentialsItem.map((item) => (item?.analyticsId));
        const credentialsAnalyticsId    =  (credentialsAnalytics[1]?.input.value)? credentialsAnalytics[1].input.value : "";

        const credentialsTag      = credentialsItem.map((item) => (item?.tagManagerId));
        const credentialsTagId    =  (credentialsTag[2]?.input.value)? credentialsTag[2].input.value : "";

            setGoogleTag(credentialsTagId)
        
            setAnalyticsId(credentialsAnalyticsId)
        
            setPixelId(credentialsPixelId);

        const  customName= [];

            setcustomInputValue(customName);   
        
        for(var custom1 in customItem){
                var customIndexValue   =  customItem[custom1]; 

            for(var custom2 in customIndexValue){
                var customIn          = customIndexValue[custom2];
                var customInput       = (customIn?.input != undefined)? customIn?.input : "" ;
                var customInputValue  = (customInput?.value == '1')?customInput?.value == '1' : "";

               
                if(customInputValue == '1')
                {
                    customName.push(custom2)
                }        
                
            }
            
        }

    }
    const handleRouteChange = () => {
        console.log("🔄 Route changed:", pathname);
        
    }
     useEffect(() => {
        getAnalytics()
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
          router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])
   
    useEffect(() => {
        if (appData) {
            const scriptId = 'account-url-script';
            let existingScript = document.getElementById(scriptId);
    
            // Remove existing script if it exists
            if (existingScript) {
                existingScript.remove();
            }
    
            // Create and append the new script
            const accountScript = document.createElement('script');
            accountScript.type = 'text/javascript';
            accountScript.src = widget_url;
            accountScript.id = scriptId;
            accountScript.async = false;
            accountScript.defer = false;
    
            accountScript.onload = () => {
                console.log("Script loaded successfully.");
    
              
                setTimeout(() => {
                    if (typeof translateScript === "function") {
                        translateScript();
                    }
                }, 1000); 
            };
    
            document.body.appendChild(accountScript);
        }
    
        return () => {
            // Cleanup: Remove the script on unmount
            const scriptToRemove = document.getElementById('account-url-script');
            if (scriptToRemove) {
                scriptToRemove.remove();
            }
        };
    }, [appData]);
    
    useEffect(() => {
        if (typeof translateScript === "function") {
            console.log("Pathname changed, calling translateScript...");
            translateScript();
        }
    }, [pathname]);
    
    
    

     useEffect(() => {
        const updateDirection = () => {
            const dir = document.documentElement.dir || "ltr";
            console.log(dir, "direction updated");
            setDirection(dir);
        };
    
        updateDirection(); // Initial check
    
        const observer = new MutationObserver(updateDirection);
        observer.observe(document.documentElement, { attributes: true, attributeFilter: ["dir"] });
    
        router.events.on("routeChangeComplete", updateDirection);
    
        return () => {
            observer.disconnect();
            router.events.off("routeChangeComplete", updateDirection);
        };
    }, [query, pathname]);
    //   console.log(direction, "direction");
    // const direction = appData?.reading_direction ? appData?.reading_direction == 'rtl' ?  appData?.reading_direction : "" : "";

    if(error && errorCode == ERRORS.APPLICATION_DATA_ERROR.code)
    {
        return <Page404Component/>;
    };

    const getTutorFeatureAccess = async () => {     
        setRouteAllowed((oldState) => ({
            ...oldState,
            isLoading: true
        }))
        const result = await useFeatureAccess(featureAccessKeys.TUTOR);
        setRouteAllowed(() => ({
            isLoading: false,
            allowRoute: result
        }));
    }

    const getLanguageJson = async () => {

        if(typeof window === 'object')
        {

            const currentLang = Cookies.get(LANGUAGE_KEY)
            const url = appData?.cloudfrontUrl + 'uploads/' + appData?.app_id + '/data/translations/' + (currentLang || 'en') + '.json?v=' + Math.random()

            let languageJson = window?.ek_lang;
            if(!languageJson)
            {
                
                try {
        
                    const result = await fetch(url);
                    const responseJson = await result.json();
        
                    languageJson = responseJson;
                    window.ek_lang = responseJson;
                    
                } catch (error) {
        
                    console.log(error)
                    
                }
        
            }

        }

    }

    useEffect(() => {

        if(appData)
        {
            window.appData = appData
            getTutorFeatureAccess()
            dispatch({
                type: reduxHelper.UPDATE_APPLICATION_DATA,
                payload: appData
            })
        }

        if(window.feather)
        {
            window.feather.replace()
        }

        getLanguageJson()

        window.getLanguageJson = getLanguageJson

    }, [appData]);
   
   
      

    useEffect(() => {

        if(clearcart == 'true')
        {
            localStorage.removeItem(CART_KEY)
            replace({
                pathname: pathname,
                query: {
                    
                }
            })
        }

    }, [clearcart]);

    if(pathname == routes.TUTOR)
    {
        if(routeAllowed.isLoading)
        {
            return <Loading/>
        }
        else if(!routeAllowed.allowRoute)
        {
            return <Page404Component/>;
        }
    }
   

    if(!appData)
    {
        return <></>
    }

  
    if(appData.isMaintenance){
        return <div className="maintenance-container">
                <h1>Site Under Maintenance</h1>
                <p>We apologize for the inconvenience. Our site is currently undergoing maintenance. Please check back later.</p>
                </div>
    }
   
    return (
        <>
        <main dir={direction}>
            <Head>
                {/* <link rel="icon" type="file" accept="image/webp" href={appData?.favicon} /> */}
                <link rel="icon" type="file" accept="image/webp" href={`${appData?.favicon}?v=${Math.random()}`} />
                {/* 
                <script src="https://d1n2g0pp9xoy51.cloudfront.net/themes/ilam/flow/ekhoolPlayer.js?v=5" />
                <script src="https://d1n2g0pp9xoy51.cloudfront.net/themes/ilam/flow/hlsjs.light.js?v=5" /> */}
                <script src="https://cdn.jsdelivr.net/npm/feather-icons/dist/feather.min.js"></script>
                <>   
                             
                             <script id="facebook-pixel" >
                                 {
                                      
                                     `
                                         !function(f,b,e,v,n,t,s)
                                         {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                         n.queue=[];t=b.createElement(e);t.async=!0;
                                         t.src=v;s=b.getElementsByTagName(e)[0];
                                         s.parentNode.insertBefore(t,s)}(window, document,'script',
                                         'https://connect.facebook.net/en_US/fbevents.js');
                                         fbq('init', '${pixelId}');
                                         fbq('track', 'PageView');
                                         fbq('track', '${customInputValue}')
                                     `
                                 }
                             </script> 
                         </>
                        
                        
                         <>
 
                             <script async src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}></script>
                             
                             <script id='google-analytics'>
                                 { 
                                     `
                                         window.dataLayer = window.dataLayer || [];
                                         function gtag(){dataLayer.push(arguments);}
                                         gtag('js', new Date());
 
                                         gtag('config', '${analyticsId}');
                                     `
                                 }
                             </script>
 
                         </>
 
                         <>
 
                             {/* <!-- Google Tag Manager --> */}
                             <script id='google-tag'>
                                 {
                                     `
                                         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','${googleTagId}');
 
                                     `
                                 }
                             </script>

                            
 
                         </>
            </Head>

            <Script type="text/javascript" src={calendar_asset_url} />
            <Script src="https://cdn.plyr.io/3.7.2/plyr.js" />
            {/* <Script src="https://d1n2g0pp9xoy51.cloudfront.net/themes/ilam/flow/ekhoolPlayer.js?v=5" />
            <Script src="https://d1n2g0pp9xoy51.cloudfront.net/themes/ilam/flow/hlsjs.light.js?v=5" /> */}
            <Script src='https://d1n2g0pp9xoy51.cloudfront.net/themes/ilam/flow/plyr.js' />
            <Script src="https://d2z8k3nky90g7o.cloudfront.net/videoplayer/clapper.js" />
            <Script src="https://d2z8k3nky90g7o.cloudfront.net/videoplayer/hlsjs-playback.js" />
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PDGSZ924"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

            <link rel="stylesheet" href="https://d2z8k3nky90g7o.cloudfront.net/materials_v2/admin/css/animate.min.css" />
            <link rel="stylesheet" href={'https://d1n2g0pp9xoy51.cloudfront.net/themes/ilam/flow/skin.css'} />
            <link rel="stylesheet" href="https://cdn.plyr.io/3.7.2/plyr.css" />
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Round" rel="stylesheet" />
            <link href="/dist/output.css" rel="stylesheet"/>

            <ThemeProvider >
                <ThemeColorPresets>
                    <NotistackProvider>
                        <ProgressLoading/>
                        <FullscreenModel open={false} />
                        <ShareModal/>
                        <AuthProvider>
                            <SiteLayout >
                                <Component {...pageProps} appData={appData} />
                            </SiteLayout>
                        </AuthProvider>
                    </NotistackProvider>
                </ThemeColorPresets>
            </ThemeProvider>
            </main>
        </>
        
    );
};

// CustomApp.getInitialProps = async ({ req, query }) => {

//     const pageProps = {};
    
//     try {

//         const url = HTTP_REST_URLS.APPLICATION_DETAILS;
//         const result = await fetch(url);
//         const jsonResponse = await result.json();

//         if(result.status !== 200)
//         {
//             throw new Error(ERRORS.APPLICATION_DATA_ERROR.code);
//         }

//         if(!jsonResponse)
//         {
//             throw new Error(ERRORS.APPLICATION_DATA_ERROR.code);
//         }

//         if(!jsonResponse?.data)
//         {
//             throw new Error(ERRORS.APPLICATION_DATA_ERROR.code);
//         }

//         pageProps['appData'] = jsonResponse?.data;
        
//     } 
//     catch (error) 
//     {
//         const { message } = error;

//         pageProps['error'] = true;

//         switch (message) 
//         {
//             case ERRORS.APPLICATION_DATA_ERROR.code:
//                 pageProps['errorCode'] = message;
//                 break;
        
//             default:
//                 pageProps['errorCode'] = ERRORS.APPLICATION_DATA_ERROR.code;
//                 break;
//         }

//     }
    
//     return {
//         initialProps: pageProps
//     }
// };

export default wrapper.withRedux(CustomApp);