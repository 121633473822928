import { editorConfig } from "../config/editorConfig";

const { IMAGES, DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, BORDER, CONTENT, COLOR, COMPONENTS, TABS, YOUTUBE, EMBEDDED, PROGRAMS, ARTICLES } = editorConfig;

const allComponents = [
    {
        id: 1,
        name: 'Header',
        children: [
            {
                "key": "header3",
                "name": "Header",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png",
                "editorConfig": [
                  "dimensions",
                  "spacing",
                  "borderRadius",
                  "shadow",
                  "color",
                  "border",
                  "components"
                ],
                "screens": [
                  "login",
                  "register",
                  "home"
                ],
                "children": [
                  {
                    "props": {
                      "style": {
                        "color": "#d642b4",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": -2,
                      "name": "Home",
                      "path": "/",
                      "parentId": 1
                    },
                    "children": "Home",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 8.677769614650007,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer", 
                    "variant": "horizontal"
                  },
                  { 
                    "props": {
                      "style": {
                        "color": "#d642b4",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": -2,
                      "name": "After login",
                      "path": "/all-courses",
                      "parentId": 1
                    },
                    "children": "Courses",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 4,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#d642b4",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": "12",
                      "name": "contact us",
                      "path": "/p/contact-us",
                      "parentId": 1
                    },
                    "children": "Contact",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 9,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#d642b4",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": "13",
                      "name": "about us",
                      "path": "/p/about-us",
                      "parentId": 1
                    },
                    "children": "About",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 6,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {},
                    "url": {
                      "id": 0,
                      "name": "Url",
                      "path": "/cart"
                    },
                    "children": "cart",
                    "type": "icon",
                    "name": "Icon"
                  },
                  {
                    "id": 5,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "variant": "contained",
                      "visibilityporps": {
                        "visibleTo": {
                          "name": "Logged out users",
                          "value": "logged_out"
                        }
                      },
                      "style": {
                        "color": "#ffffff"
                      },
                      "sx": {
                        "backgroundColor": "#d642b4",
                        "boxShadow": "none"
                      }
                    },
                    "url": "/register",
                    "children": "Register",
                    "type": "button",
                    "name": "Button",
                    "label": "Register"
                  },
                  {
                    "props": {
                      "variant": "contained",
                      "visibilityporps": {
                        "visibleTo": {
                          "name": "Logged out users",
                          "value": "logged_out"
                        }
                      },
                      "style": {
                        "color": "#ffffff"
                      },
                      "sx": {
                        "backgroundColor": "#d642b4",
                        "boxShadow": "none"
                      }
                    },
                    "url": "/login",
                    "children": "Login",
                    "type": "button",
                    "name": "Button",
                    "label": "Login"
                  }
                ],
                "style": {
                  "backgroundColor": "#ffffff00",
                  "boxShadow": "0px 0px 0px 0px #cac5d8",
                  "paddingTop": 0,
                  "paddingBottom": 0,
                  "paddingLeft": 10,
                  "paddingRight": 10
                },
                "id": 8
              },
              {
                "key": "header4",
                "name": "Header",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png",
                "editorConfig": [
                  "dimensions",
                  "spacing",
                  "borderRadius",
                  "shadow",
                  "color",
                  "border",
                  "components"
                ],
                "screens": [
                  "login",
                  "register",
                  "home"
                ],
                "children": [
                  {
                    "props": {
                      "style": {
                        "color": "#212B36",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": -2,
                      "name": "Home",
                      "path": "/",
                      "parentId": 1
                    },
                    "children": "Home",
                    "type": "typography",
                    "name": "Text",
                    "showEditor": false
                  },
                  {
                    "id": 8.677769614650007,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#212B36",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": -2,
                      "name": "After login",
                      "path": "/all-courses",
                      "parentId": 1
                    },
                    "children": "Courses",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 4,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#212B36",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": "12",
                      "name": "contact us",
                      "path": "/p/contact-us",
                      "parentId": 1
                    },
                    "children": "Contact",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 9,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#212B36",
                        "fontWeight": 500
                      }
                    },
                    "url": {
                      "id": "13",
                      "name": "about us",
                      "path": "/p/about-us",
                      "parentId": 1
                    },
                    "children": "About",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "id": 6,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {},
                    "url": {
                      "id": 0,
                      "name": "Url",
                      "path": "/cart"
                    },
                    "children": "cart",
                    "type": "icon",
                    "name": "Icon"
                  },
                  {
                    "id": 5,
                    "props": {},
                    "type": "spacer",
                    "name": "Spacer",
                    "variant": "horizontal"
                  },
                  {
                    "props": {
                      "variant": "contained",
                      "visibilityporps": {
                        "visibleTo": {
                          "name": "Logged out users",
                          "value": "logged_out"
                        }
                      }
                    },
                    "url": "/register",
                    "children": "Register",
                    "type": "button",
                    "name": "Button",
                    "label": "Register"
                  },
                  {
                    "props": {
                      "variant": "contained",
                      "visibilityporps": {
                        "visibleTo": {
                          "name": "Logged out users",
                          "value": "logged_out"
                        }
                      }
                    },
                    "url": "/login",
                    "children": "Login",
                    "type": "button",
                    "name": "Button",
                    "label": "Login"
                  }
                ],
                "style": {
                  "backgroundColor": "#ffffff00",
                  "boxShadow": "0px 0px 0px 0px #cac5d8",
                  "paddingTop": 0,
                  "paddingBottom": 0,
                  "paddingLeft": 10,
                  "paddingRight": 10
                },
                "id": 1
              },
            {
                "key": "header11",
                "name": "Header",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "children": [
                    {
                        "props": {
                            
                            "color": "info",
                            "variant": "text"
                        },
                        "url": {
                            "id": -1,
                            "name": "Home",
                            "path": "/"
                        },
                        "children": "Home",
                        "type": "button",
                        "name": "Button",
                        "label": "Home",
                        "id": 1
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "text"
                        },
                        "url": null,
                        "children": "About",
                        "type": "button",
                        "name": "Button",
                        "label": "About",
                        "id": 2
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "text"
                        },
                        "url": null,
                        "children": "Blog",
                        "type": "button",
                        "name": "Button",
                        "label": "Blog",
                        "id": 3
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "text"
                        },
                        "url": {
                            "id": "2",
                            "name": "contact us",
                            "path": "/p/contact-us"
                        },
                        "children": "Contact",
                        "type": "button",
                        "name": "Button",
                        "label": "Contact",
                        "id": 4
                    },
                    {
                        "props": {
                            "variant": "outlined",
                            "color": "info",
                            "sx": {
                                "borderRadius": 0
                            }
                        },
                        "url": "/login",
                        "children": "Login",
                        "type": "button",
                        "name": "Button",
                        "label": "Login"
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained",
                            "sx": {
                                "borderRadius": 0
                            }
                        },
                        "url": "/register",
                        "children": "Sign up",
                        "type": "button",
                        "name": "Button",
                        "label": "Sign up"
                    }
                ],
                "style": {
                    "backgroundColor": "#ffffff",
                    "boxShadow": "0px 0px 0px 0px #cac5d8",
                    "paddingTop": 0,
                    "paddingBottom": 0,
                    "paddingLeft": '70px',
                    "paddingRight": '70px'
                }
       
        },

        {
            "key": "header13",
            "name": "Header",
            "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header.png",
            "editorConfig": [
              "dimensions",
              "spacing",
              "borderRadius",
              "shadow",
              "color",
              "border",
              "components"
            ],
            "screens": [
              "login",
              "register",
              "home"
            ],
            "id": 8,
            "children": [
              {
                "props": {
                  "style": {
                    "color": "#000000"
                  }
                },
                "url": "",
                "children": "cart",
                "type": "icon",
                "name": "Icon"
              },
              {
                "props": {
                  "style": {
                    "color": "#000000"
                  }
                },
                "url": "",
                "children": "cellphone",
                "type": "icon",
                "name": "Icon"
              },
              {
                "props": {
                  "style": {
                    "color": "#00AB55",
                    "fontWeight": 500
                  }
                },
                "url": "",
                "children": "Download App",
                "type": "typography",
                "name": "Text"
              },
              {
                "props": {
                  "style": {
                    "color": "#ffffff"
                  },
                  "variant": "contained",
                  "color": "primary",
                  "sx": {
                    "backgroundColor": "#00AB55",
                    "boxShadow": "none"
                  }
                },
                "url": "/login",
                "children": "Login",
                "type": "button",
                "name": "Button",
                "label": "Login"
              }
            ],
            "properties": {
              "devices": [
                "pc",
                "tab",
                "mobile"
              ],
              "visibleTo": null
            },
            "style": {
              "backgroundColor": "#ffffff00",
              "boxShadow": "0px 0px 0px 0px #000000",
              "paddingLeft": 120,
              "paddingRight": 120
            }
          },
           
            {
                "key": "header12",
                "name": "Header",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/header4.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "children": [
                    {
                        "props": {
                            "variant": "contained",
                            "color": "info",
                            "sx": {
                                "borderRadius": 1
                            },
                            "style": {
                                "color": "#ffffff"
                            },
                            "size": "medium",
                            "visibilityporps": {
                                "visibleTo": {
                                    "name": "Logged out users",
                                    "value": "logged_out"
                                }
                            }
                        },
                        "url": "/login",
                        "children": "Login",
                        "type": "button",
                        "name": "Button",
                        "label": "Login"
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained",
                            "sx": {
                                "borderRadius": 1
                            },
                            "style": {
                                "color": "#ffffff"
                            },
                            "size": "medium",
                            "visibilityporps": {
                                "visibleTo": {
                                    "name": "Logged out users",
                                    "value": "logged_out"
                                }
                            }
                        },
                        "url": "/register",
                        "children": "Sign up",
                        "type": "button",
                        "name": "Button",
                        "label": "Sign up"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 400
                            }
                        },
                        "url": "/register",
                        "children": "Home",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 400,
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "About",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 400
                            }
                        },
                        "url": "",
                        "children": "Blog",
                        "type": "typography",
                        "name": "Text"
                    }
                ],
                "style": {
                    "backgroundColor": "#ffffff",
                    "boxShadow": "0px 0px 0px 0px #cac5d8",
                    "paddingTop": 0,
                    "paddingBottom": 0,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "id": 6,
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ],
                    "visibleTo": null
                }
            },
        ]
    },

    {
        id: 2,
        name: 'Banner',
        children: [
            // {
            //     "name": "Banner",
            //     "key": "banner1",
            //     "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
            //     editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
            //     screens: ['login', 'register', 'home'],
            //     "config": {
            //         "maxImages": 1
            //     },
            //     "id": 2,
            //     "children": [
            //         {
            //             "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_0d9f5f2cc8e8e7ede3d67ad1103526ba.webp",
            //             "type": "image",
            //             "id": 0
            //         }
            //     ],
            //     "style": {
            //         "paddingLeft": 0,
            //         "paddingRight": 0
            //     }
            // },
            {
                "name": "Banner",
                "key": "banner2",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "id": 2,
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_0d9f5f2cc8e8e7ede3d67ad1103526ba.webp",
                        "type": "image",
                        "id": 0
                    }
                ],
                "style": {
                    "paddingLeft": 0,
                    "paddingRight": 0
                }
            },

            {
                "name": "Carousel",
                "key": "carousel1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "id": 3,
            },
            {
                "name": "Banner",
                "key": "banner4",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "id": 2,
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_0d9f5f2cc8e8e7ede3d67ad1103526ba.webp",
                        "type": "image",
                        "id": 0
                    }
                ],
                "style": {
                    "paddingLeft": 0,
                    "paddingRight": 0
                }
            },
            {
                "name": "Banner",
                "key": "banner5",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "id": 2,
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_0d9f5f2cc8e8e7ede3d67ad1103526ba.webp",
                        "type": "image",
                        "id": 0
                    }
                ],
                "style": {
                    "paddingLeft": 0,
                    "paddingRight": 0
                }
            },
            {
                "name": "Banner",
                "key": "banner6",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "id": 2,
                "children": [
                    {
                        "src": "https://d2b7aqiel0gukx.cloudfront.net/uploads/530/data/site_builder/app_banner/str.png",
                        "type": "image",
                        "id": 0
                    }
                ],
                "style": {
                    "paddingLeft": 0,
                    "paddingRight": 0
                }
            },
            {
                "name": "Banner",
                "key": "banner7",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner7.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "images",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "id": 2,
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_ad4079578bb5425a10acbc91982c7111.png",
                        "type": "image",
                        "id": 0,
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 48,
                                "fontWeight": 800
                            }
                        },
                        "url": "",
                        "children": "Wanna Know More About TuT's ?.",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    }
                ],
                "style": {
                    "paddingLeft": 120,
                    "paddingRight": 120,
                    "backgroundColor": "#f2f0f8",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70
                }
            },
            {
                
                "name": "Banner",
                "key": "banner8",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "images",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "id": 3,
                "children": [
                    {
                        "id": 10,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_37a5fb098040fe1806f57f5d7714ec09.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {}
                    },
                    {
                        "id": 6,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_37a5fb098040fe1806f57f5d7714ec09.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {}
                    },
                    {
                        "id": 3,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_37a5fb098040fe1806f57f5d7714ec09.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {}
                    },
                    {
                        "id": 9,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_37a5fb098040fe1806f57f5d7714ec09.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {}
                    },
                    {
                        "id": 4,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_37a5fb098040fe1806f57f5d7714ec09.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {}
                    },
                    {
                        "id": 5,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_37a5fb098040fe1806f57f5d7714ec09.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {}
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 400,
                                "fontSize": 16
                            }
                        },
                        "url": "",
                        "children": "TNPSC",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 400,
                                "fontSize": 16
                            }
                        },
                        "url": "",
                        "children": "TNPSC",
                        "type": "typography",
                        "name": "Text",
                        "id": 7
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 400,
                                "fontSize": 16
                            }
                        },
                        "url": "",
                        "children": "TNPSC",
                        "type": "typography",
                        "name": "Text",
                        "id": 1
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 400,
                                "fontSize": 16
                            }
                        },
                        "url": "",
                        "children": "TN",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": true
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 400,
                                "fontSize": 16
                            }
                        },
                        "url": "",
                        "children": "TNPSC",
                        "type": "typography",
                        "name": "Text",
                        "id": 9
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 400,
                                "fontSize": 16
                            }
                        },
                        "url": "",
                        "children": "TNPSC",
                        "type": "typography",
                        "name": "Text",
                        "id": 3
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 600,
                                "fontSize": 24
                            }
                        },
                        "url": "",
                        "children": "2309",
                        "type": "number",
                        "name": "Text",
                        "id": 8
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 600,
                                "fontSize": 24
                            }
                        },
                        "url": "",
                        "children": "2309",
                        "type": "number",
                        "name": "Text",
                        "id": 3
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 600,
                                "fontSize": 24
                            }
                        },
                        "url": "",
                        "children": "2309",
                        "type": "number",
                        "name": "Text",
                        "id": 3
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 600,
                                "fontSize": 24
                            }
                        },
                        "url": "",
                        "children": "2309",
                        "type": "number",
                        "name": "Text",
                        "id": 1
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 600,
                                "fontSize": 24
                            }
                        },
                        "url": "",
                        "children": "2309",
                        "type": "number",
                        "name": "Text",
                        "id": 7
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff",
                                "fontWeight": 600,
                                "fontSize": 24
                            }
                        },
                        "url": "",
                        "children": "2309",
                        "type": "number",
                        "name": "Text",
                        "id": 5
                    }
                ],
                "style": {
                    "backgroundColor": "#A917FE",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 0,
                    "paddingRight": 0,
                    "paddingTop": 70,
                    "paddingBottom": 70
                },
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ],
                    "visibleTo": null
                },
                "props": {
                    "className": "animate__animated none animate__repeat-1"
                }
            },
        ]
    },
    // {
    //     id: 2,
    //     name: 'Content',
    //     children: [
    //         {
    //             "name": "Content",
    //             "key": "content3",
    //             "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
    //             editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
    //             screens: ['login', 'register', 'home'],
    //             "config": {
    //                 "maxImages": 1
    //             },
    //             "id": 2,
    //             "children": [
    //                 {
    //                     "type": "html",
    //                     "name": "Content",
    //                     "id": 0,
    //                     "content": `<section class="text-gray-600 body-font">
    //                     <div class="container px-5 py-24 mx-auto">
    //                       <div class="flex flex-col text-center w-full mb-20" data-read-aloud-multi-block="true">
    //                         <h2 class="text-xs text-green-500 tracking-widest font-medium title-font mb-1">ROOF PARTY POLAROID</h2>
    //                         <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Master Cleanse Reliac Heirloom</h1>
    //                         <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>
    //                       </div>
    //                       <div class="flex flex-wrap">
    //                         <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60" data-read-aloud-multi-block="true">
    //                           <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Shooting Stars</h2>
    //                           <p class="leading-relaxed text-base mb-4">Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>
    //                           <a class="text-green-500 inline-flex items-center">Learn More
    //                             <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
    //                               <path d="M5 12h14M12 5l7 7-7 7"></path>
    //                             </svg>
    //                           </a>
    //                         </div>
    //                         <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60" data-read-aloud-multi-block="true">
    //                           <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">The Catalyzer</h2>
    //                           <p class="leading-relaxed text-base mb-4">Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>
    //                           <a class="text-green-500 inline-flex items-center">Learn More
    //                             <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
    //                               <path d="M5 12h14M12 5l7 7-7 7"></path>
    //                             </svg>
    //                           </a>
    //                         </div>
    //                         <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60" data-read-aloud-multi-block="true">
    //                           <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Neptune</h2>
    //                           <p class="leading-relaxed text-base mb-4">Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>
    //                           <a class="text-green-500 inline-flex items-center">Learn More
    //                             <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
    //                               <path d="M5 12h14M12 5l7 7-7 7"></path>
    //                             </svg>
    //                           </a>
    //                         </div>
    //                         <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60" data-read-aloud-multi-block="true">
    //                           <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Melanchole</h2>
    //                           <p class="leading-relaxed text-base mb-4">Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>
    //                           <a class="text-green-500 inline-flex items-center">Learn More
    //                             <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
    //                               <path d="M5 12h14M12 5l7 7-7 7"></path>
    //                             </svg>
    //                           </a>
    //                         </div>
    //                       </div>
    //                       <button class="flex mx-auto mt-16 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-green-600 rounded text-lg">Button</button>
    //                     </div>
    //                   </section>`
    //                 }
    //             ],
    //             "style": {
    //                 "paddingLeft": 0,
    //                 "paddingRight": 0
    //             }
    //         },
    //     ]
    // },
    {
        id: 2,
        name: 'Program Listing',
        children: [
            // {
            //     "name": "Programs with Category filter",
            //     "key": "programs2",
            //     "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/banner1.png",
            //     editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
            //     screens: ['login', 'register', 'home'],
            //     "id": 2,
            //     "children": [
            //         {
            //             "props": {},
            //             "url": "",
            //             "children": "As quoted by the legendary English artist, Edmund De Waal, we believe that \"With languages, you are at home, anywhere\".",
            //             "type": "typography",
            //             "name": "Text"
            //         },
            //     ],
            //     "style": {
            //         "paddingLeft": 0,
            //         "paddingRight": 0
            //     }
            // },
            {
                "key": "programGridCard",
                "name": "Program Listing",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 36
                            },
                        },
                        "url": "",
                        "children": "Course Library",
                        "type": "typography",
                        "name": "Text",
                        "id": 1
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Checkout your favorite course",
                        "type": "typography",
                        "name": "Text",
                        "id": 2
                    },
                    {
                        "props": {
    
                        },
                        "type": "customProgramListing",
                        "showEditor": false,
                        "name": 'Programs'
                    }
                ]
            },
            {
                "name": "Category Programs",
                "key": "categoryPrograms1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "id": 6,
                "children": [
                    {
                        "props": {
                            "style": {
                                "fontSize": 40,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Certified online courses for Professionals",
                        "type": "typography",
                        "name": "Text"
                    }
                ],
                "style": {
                    "paddingLeft": 120,
                    "paddingRight": 120,
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "justifyContent": "auto",
                    "alignItems": "center"
                }
            },
            {
                "name": "Category Programs3",
                "key": "categoryPrograms3",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/program_listing.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
               
               
            },


        ]
    },
    {
        id: 2,
        name: 'Category Listing',
        children: [


            {
                "key": "programs4",
                "name": "category1",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
            {
                "key": "programs3",
                "name": "category2",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
            {
                "key": "category3",
                "name": "category3",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
            {
                "key": "category4",
                "name": "category4",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
            {
                "key": "category5",
                "name": "category5",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
            {
                "key": "category6",
                "name": "category6",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_programs_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
            {
                "key": "category7",
                "name": "Category7",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/category_7.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    
                ]
            },
        ]
    },
    {
        id: 2,
        name: 'Articles',
        children: [
            {
                "name": "Article Listing",
                "key": "articles1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/articles_1.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components",
                    "articles"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "id": 7,
                "children": [
                    {
                        "props": {
                            "style": {
                                "fontSize": 15,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "The blog",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 36,
                                "fontWeight": 600,
                                "textAlign": "center"
                            }
                        },
                        "url": "",
                        "children": "Writings from our Team",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {

                        },
                        "type": "articles",
                        "showEditor": false,
                        "name": 'Articles'
                    }
                ],
                "articles": [],
                "style": {
                    "paddingLeft": 120,
                    "paddingRight": 120,
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "justifyContent": "auto",
                    "alignItems": "center"
                },
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ],
                    "visibleTo": null
                }
            },


           
            {
                
                    "name": "Article Listing",
                    "key": "articles2",
                    "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/articles2.png",
                    "editorConfig": [
                        "dimensions",
                        "spacing",
                        "borderRadius",
                        "shadow",
                        "color",
                        "border",
                        "components",
                        "articles"
                    ],
                    "screens": [
                        "login",
                        "register",
                        "home"
                    ],
                    "id": 4,
                    "children": [
                        {
                            "props": {
                                "style": {
                                    "color": "#FF4842",
                                    "fontWeight": 600,
                                    "fontSize": 24
                                }
                            },
                            "url": "",
                            "children": "Lorem ipsum ",
                            "type": "typography",
                            "name": "Text"
                        },
                        {
                            "props": {
                                "style": {
                                    "color": "#212B36",
                                    "fontSize": 32,
                                    "fontWeight": 600
                                }
                            },
                            "url": "",
                            "children": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
                            "type": "typography",
                            "name": "Text"
                        },
                        {
                            "props": {
                                "style": {
                                    "color": "#212B36",
                                    "fontWeight": 500
                                }
                            },
                            "url": "",
                            "children": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
                            "type": "typography",
                            "name": "Text"
                        },
                        {
                            "id": 5.90279460094738,
                            "src": "https://images.unsplash.com/photo-1473221326025-9183b464bb7e?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzNjI4MDh8MHwxfHNlYXJjaHwzfHxsYW5kc2NhcGV8ZW58MHwwfHx3aGl0ZXwxNjg1NDUzODU3fDA&ixlib=rb-4.0.3&q=85",
                            "type": "image",
                            "props": {},
                            "children": [],
                            "url": "",
                            "style": {},
                            "showEditor": false
                        }
                    ],
                    "properties": {
                        "devices": [
                            "pc",
                            "tab",
                            "mobile"
                        ],
                        "visibleTo": null
                    },
                    "style": {
                        "backgroundColor": "#ffffff00",
                        "boxShadow": "0px 0px 0px 0px #000000",
                        "paddingLeft": 120,
                        "paddingRight": 120,
                        "paddingTop": 70,
                        "paddingBottom": 70
                    }
                }
            
            
               
         ]
    },
    {
        id: 2,
        name: 'Achievers',
        children: [
            {
                "name": "Achievers Listing",
                "key": "achievers1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/achivers_1.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "id": 4,
                "children": [
                    {
                        "props": {
                            "style": {
                                "fontSize": 15,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "OUR SUPER ACHIEVERS",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 48,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Subscribe now | Join our super achievers club",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "variant": "vertical",
                            "sx": {
                                "height": 30
                            }
                        },
                        "variant": "vertical",
                        "type": "spacer",
                        "name": "Spacer"
                    },
                    {
                        "props": {
                            "variant": "contained"
                        },
                        "url": "",
                        "children": "Start Learning",
                        "type": "button",
                        "name": "Button",
                        "label": "Start Learning"
                    }
                ],
                "style": {
                    "paddingLeft": 60,
                    "paddingRight": 60,
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "alignItems": "center",
                    "justifyContent": "auto",
                },
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ],
                    "visibleTo": null
                }
            }
        ]
    },
    {
        id: 2,
        name: 'Ticker',
        children: [
            {
                "name": "Ticker",
                "key": "ticker1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/ticker_1.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "id": 5,
                "children": [
                    {
                        "props": {},
                        "url": "",
                        "children": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "sx": {
                                "width": 20
                            }
                        },
                        "variant": "horizontal",
                        "type": "spacer",
                        "name": "Spacer"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#00AB55"
                            }
                        },
                        "url": {
                            "id": -3,
                            "name": "Search",
                            "path": "/search"
                        },
                        "children": "Click here",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "id": 3.2012827714163783,
                        "props": {
                            "sx": {
                                "width": 20
                            }
                        },
                        "type": "spacer",
                        "name": "Spacer",
                        "variant": "horizontal"
                    },
                    {
                        "props": {},
                        "url": "",
                        "children": "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "sx": {
                                "width": 20
                            }
                        },
                        "variant": "horizontal",
                        "type": "spacer",
                        "name": "Spacer"
                    }
                ],
                "style": {
                    "paddingLeft": 0,
                    "paddingRight": 0
                }
            }
        ]
    },
    {
        id: 3,
        name: 'Hero',
        children: [
            {
                "key": "hero5",
                "name": "Hero",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero5.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "backgroundImage": "url(https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_732eb04e13b9de3fc1193242a2e665a0.jpg)",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "backgroundSize": "cover",
                    "backgroundRepeat": "no-repeat",
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_b86433850b59a6b3a2f5a22c427eefe6.png",
                        "type": "image",
                        "id": 1,
                        "style": {
                            "height": 362,
                            "width": 362
                        }
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 48
                            }
                        },
                        "url": "",
                        "children": "Investing in knowledge and your future",
                        "type": "typography",
                        "id": 2
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Top instructore from millions of students on us. We provide the tools and skills to study what you love.",
                        "type": "typography",
                        "id": 3
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained"
                        },
                        "url": null,
                        "children": "Lets go",
                        "type": "button",
                        "name": "Button",
                        "label": "Lets go",
                        "id": 4
                    }
                ]
            },
            
            {
                "key": "hero6",
                "name": "Hero2",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero6.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "children": [
                    {
                        "props": {
                            "style": {
                                "fontSize": 48,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "One platform many courses for you",
                        "type": "typography",
                        "id": 4
                    },
                    {
                        "props": {
                        },
                        "url": "",
                        "children": "Increasingly many people use digital media for learning and for continuing their education. E-learning content formats that are individually modified to meet each learner’s needs are fundamental for successful learning.",
                        "type": "typography",
                        "id": 2
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained"
                        },
                        "url": null,
                        "children": "Search Courses",
                        "type": "button",
                        "name": "Button",
                        "label": "Search Courses",
                        "id": 3
                    },
                    {
                        "src": "https://static.wixstatic.com/media/2be1ce_864567900845418ebfd61e297637464d~mv2.gif/v1/fit/w_320,h_206,al_c,q_80,enc_auto/file.gif",
                        "type": "image",
                        "id": 1,
                        "style": {
                            "height": 373,
                            "width": 374
                        }
                    }
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                }
            },
            {
                "key": "hero8",
                "name": "Hero3",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero6.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "children": [
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                }
            },
            {
                "key": "hero9",
                "name": "Hero",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero6.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "children": [
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                }
            },
            {
                "key": "hero10",
                "name": "Hero10",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero5.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "backgroundImage": "url(https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_732eb04e13b9de3fc1193242a2e665a0.jpg)",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "backgroundSize": "cover",
                    "backgroundRepeat": "no-repeat",
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_b86433850b59a6b3a2f5a22c427eefe6.png",
                        "type": "image",
                        "id": 1,
                        "style": {
                            "height": 362,
                            "width": 362
                        }
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 48
                            }
                        },
                        "url": "",
                        "children": "Investing in knowledge and your consistency leads to your future",
                        "type": "typography",
                        "id": 2
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Top instructore from millions of students on us. We provide the tools and skills to study what you love.",
                        "type": "typography",
                        "id": 3
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained"
                        },
                        "url": null,
                        "children": "This is a Button",
                        "type": "button",
                        "name": "Button",
                        "label": "Lets go",
                        "id": 4
                    },                    
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Above the Button gau.",
                        "type": "typography",
                        "id": 3
                    }
                ]
            },
            {
                "key": "hero11",
                "name": "Hero11",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/hero5.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "backgroundImage": "url(https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_732eb04e13b9de3fc1193242a2e665a0.jpg)",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "backgroundSize": "cover",
                    "backgroundRepeat": "no-repeat",
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_b86433850b59a6b3a2f5a22c427eefe6.png",
                        "type": "image",
                        "id": 1,
                        "style": {
                            "height": 362,
                            "width": 362
                        }
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 48
                            }
                        },
                        "url": "",
                        "children": "Investing in knowledge and your consistency leads to your future",
                        "type": "typography",
                        "id": 2
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Top instructore from millions of students on us. We provide the tools and skills to study what you love.",
                        "type": "typography",
                        "id": 3
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained"
                        },
                        "url": null,
                        "children": "This is a Button",
                        "type": "button",
                        "name": "Button",
                        "label": "Lets go",
                        "id": 4
                    },                    
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Above the Button  gau.",
                        "type": "typography",
                        "id": 3
                    }
                ]
            },
        ]
    },
    {
        id: 12,
        name: 'Contact',
        children: [
            {
                "key": "contact2",
                "name": "Contact",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/testimonial2.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 60,
                    "paddingRight": 60
                },
                config: {
                    totalOrder: 2,
                    multipleRows: true
                },
                "children": [
                    
                ]
            },
        ]
    },
    {
        id: 10,
        name: 'Testimonial',
        children: [
            {
                "key": "testimonial2",
                "name": "Testimonial",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/testimonial2.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 60,
                    "paddingRight": 60
                },
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 36
                            },
                        },
                        "url": "",
                        "children": "Testimonials",
                        "type": "typography",
                        "name": "Text",
                        "id": 1
                    },
                    {
                        "props": {
                        },
                        "url": "",
                        "children": "What say about us",
                        "type": "typography",
                        "name": "Text",
                        "id": 2
                    },
                    {
                        "props": {

                        },
                        "type": "testimonials1",
                        "showEditor": false,
                        "name": 'Testimonials'
                    }
                ]
            },
        ]
    },
    {
        id: 14,
        name: 'About',
        children: [
            {
                "key": "about1",
                "name": "About",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/about1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#f2f0f8",
                    "boxShadow": "0px 0px 0px 0px #e9d6d6",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 800,
                                "fontSize": 48
                            },
                        },
                        "url": "",
                        "children": "Learn new skills from top Educators.",
                        "type": "typography",
                        "id": 1
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "The digital learning scenarios range from computer-based or Web-based training sessions to interactive learning with simulations or strategic games. A variety of tools to create e-learning content formats can even facilitate complex e-learning training sessions.",
                        "type": "typography",
                        "id": 2
                    },
                    {
                        "props": {
                            "style": {
                                "fontWeight": 600,
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Computer-Based Training (CBT)/Web-Based Training (WBT)",
                        "type": "typography",
                        "id": 3
                    },
                    {
                        "props": {
                            "style": {
                                "fontWeight": 600,
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": " Game Based Learning/Serious Games",
                        "type": "typography",
                        "id": 4
                    },
                    {
                        "props": {
                            "style": {
                                "fontWeight": 600,
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "Augmented Reality/Mixed Reality/Virtual Reality",
                        "type": "typography",
                        "id": 5
                    },
                    {
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_78c168c75c5fd68dfeef09a2d9dd009a.jpg",
                        "type": "image",
                        "id": 1,
                        "style": {
                            "height": 370,
                            "width": 465
                        }
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 20
                            },
                        },
                        "url": "",
                        "children": "Videos/Learning Videos/Mobile Content",
                        "type": "typography",
                        "id": 8
                    },
                    {
                        "props": {
                            "variant": "contained",
                            "color": "info"
                        },
                        "url": null,
                        "children": "Explore courses",
                        "type": "button",
                        "name": "Button",
                        "label": "Explore courses",
                        "id": 8
                    }
                ],
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ]
                }
            },
                      
        ]
    },
    {
        id: 15,
        name: 'Youtube',
        children: [
            {
                "id": 5,
                "name": "Youtube",
                "key": "youtube1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/youtube1.png",
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, YOUTUBE],
                    screens: ['login', 'register', 'home'],   
    
                "style": {
                    "backgroundColor": "#8CC946",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 90,
                    "paddingBottom": 90
                }
            }
                      
        ]
    },
    {
        id: 16,
        name: 'Layout',
        children: [
            // {
            //     "key": "layoutGrid",
            //     preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/cardLayout.png',
            //     editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
            //     screens: ['login', 'register', 'home'],                
            //     "children": [
            //         {
            //             "props": {
            //                 "style": {
            //                     "color": "#000000"
            //                 }
            //             },
            //             "type": "content",
            //             "name": "Content",
            //             "content": {
            //                 "title": "Get Certificate",
            //                 "content": "A design is a plan or specification for the construction of an object or system or for the implementation of an activity or process or the result"
            //             },
            //             "id": 3
            //         },
            //         {
            //             "props": {
            //                 "style": {
            //                     "color": "#000000"
            //                 }
            //             },
            //             "type": "content",
            //             "name": "Content",
            //             "content": {
            //                 "title": "Get Membership",
            //                 "content": "A design is a plan or specification for the construction of an object or system or for the implementation of an activity or process or the result"
            //             },
            //             "id": 3
            //         },
            //         {
            //             "props": {
            //                 "style": {
            //                     "color": "#000000"
            //                 }
            //             },
            //             "type": "content",
            //             "name": "Content",
            //             "content": {
            //                 "title": "Become a Tutor",
            //                 "content": "Our customers are our foremost priority and we don't leave any efforts in making their lives awesome. Their love and support has made us what we are. We look forward to seeing you in the family and growing together."
            //             },
            //             "id": 3
            //         }
            //     ],
            //     "style": {
            //         "backgroundColor": "#ffffff",
            //         "boxShadow": "0px 0px 0px 0px #000000",
            //         "paddingLeft": 120,
            //         "paddingRight": 120,
            //         "paddingTop": 0,
            //         "paddingBottom": 0
            //     }
            // },
            {
                "key": "columnLayout",
                "name": "Layout",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/columnLayout.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],                
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 48
                            },
                        },
                        "url": "",
                        "children": "We build experience",
                        "type": "typography",
                        "id": 1
                    },
                    {
                        "props": {
                            "animation": true,
                            "animationProps": {
                                "type": "Typing",
                                "duration": 3000
                            },
                            "style": {
                                "color": "#d12f75",
                                "fontWeight": 600,
                                "fontSize": 32
                            }
                        },
                        "url": "",
                        "children": [
                            "Learning.",
                            "Teaching.",
                            "Business."
                        ],
                        "type": "typography",
                        "id": 4
                    },
                    {
                        "props": {
                        },
                        "url": "",
                        "children": "Leading companies use the same courses to help employees keep their skills fresh.",
                        "type": "typography",
                        "id": 3
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "contained"
                        },
                        "url": null,
                        "children": "Start now",
                        "type": "button",
                        "name": "Button",
                        "label": "Start now",
                        "id": 2
                    }
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 120,
                    "paddingRight": 120
                }
            },
            {
                "id": 1,
                "name": "Layout",
                "key": "cardLayout",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/layoutGrid.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 0,
                    "paddingRight": 0,
                    "alignItems": "center"
                },
                "children": [
                    {
                        "props": {},
                        "type": "content2",
                        "name": "Content",
                        "content": {
                            "title": "Content title",
                            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                            "src": null,
                            "icon": "menu"
                        },
                        "id": 1,
                        "showEditor": false
                    },
                    {
                        "id": 1,
                        "props": {},
                        "type": "content2",
                        "name": "Content",
                        "content": {
                            "title": "Content title",
                            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                            "src": null,
                            "icon": "menu"
                        },
                        "showEditor": false
                    },
                    {
                        "id": 1,
                        "props": {},
                        "type": "content2",
                        "name": "Content",
                        "content": {
                            "title": "Content title",
                            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                            "src": null,
                            "icon": "menu"
                        }
                    },
                    {
                        "id": 1,
                        "props": {},
                        "type": "content2",
                        "name": "Content",
                        "content": {
                            "title": "Content title",
                            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                            "src": null,
                            "icon": "menu"
                        },
                        "showEditor": false
                    },
                    {
                        "id": 1,
                        "props": {},
                        "type": "content2",
                        "name": "Content",
                        "content": {
                            "title": "Content title",
                            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                            "src": null,
                            "icon": "menu"
                        }
                    },
                    {
                        "id": 1,
                        "props": {},
                        "type": "content2",
                        "name": "Content",
                        "content": {
                            "title": "Content title",
                            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                            "src": null,
                            "icon": "menu"
                        }
                    },
                    {
                        "props": {
                            "style": {
                                "fontSize": 40,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Title for this section",
                        "type": "typography",
                        "name": "Text"
                    }
                ]
            },
            {
                "key": "cardRowLayout",
                "name": "Layout",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/cardRowLayout.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'], 
                "style": {
                    "backgroundColor": "#f2f0f8",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 36,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Are you ready to start your course now !",
                        "type": "typography",
                        "id": 1
                    },
                    {
                        "props": {
                            "variant": "contained",
                            "color": "info"
                        },
                        "url": "/register",
                        "children": "Lets get start",
                        "type": "button",
                        "name": "Button",
                        "label": "Lets Get Start",
                        "id": 3
                    },
                    {
                        "props": {
                            "color": "info",
                            "variant": "outlined"
                        },
                        "url": {
                            "id": "2",
                            "name": "contact us",
                            "path": "/p/contact-us"
                        },
                        "children": "Contact us",
                        "type": "button",
                        "name": "Button",
                        "label": "Contact us",
                        "id": 3
                    }
                ]
            },
            {
                "key": "layout1",
                "name": "Layout",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/columnLayout.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],                
                "children": [
                    
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 120,
                    "paddingRight": 120
                }
            },
            {
                "key": "layout2",
                "name": "Layout",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/Layout2.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 24,
                                "fontWeight": 800,
                                
                            }
                        },
                        "url": "",
                        "children": "When You Learn with Us, You Stand Out On",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#0e64c2",
                                "fontSize": 20,
                                "fontWeight": 800
                            }
                        },
                        "url": "",
                        "children": "SKILLS that you learned at university",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#FFC107",
                                "fontSize": 20,
                                "fontWeight": 800
                            }
                        },
                        "url": "",
                        "children": "KNOWLEDGE earned to see all possibilities",
                        "type": "typography",
                        "name": "Text",
                        "id": 3
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 800
                            }
                        },
                        "url": "",
                        "children": "COMMUNITY help you to explore all",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#FFC107",
                                "fontSize": 20,
                                "fontWeight": 800
                            }
                        },
                        "url": "",
                        "children": "KNOWLEDGE earned to see all possibilities",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "id": 10,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_aaf455b2f45fe6b942ddcd062d0ea373.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {},
                        "showEditor": false
                    },
                    {
                        "id": 3,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_92d8a77a5bcc3ee89ea08ccab030a784.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {},
                        "showEditor": false
                    },
                    {
                        "id": 8,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_89844c13472d7b8680d508598137055f.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {
                            "height": 208,
                            "width": 333
                        },
                        "showEditor": false
                    },
                    {
                        "id": 7,
                        "src": "https://d2z8k3nky90g7o.cloudfront.net/uploads/237/data/page_builder/page_builder_aaf455b2f45fe6b942ddcd062d0ea373.png",
                        "type": "image",
                        "props": {},
                        "children": [],
                        "url": "",
                        "style": {},
                        "showEditor": false
                    }
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "id": 4
            },
            {
                "key": "layout3",
                "name": "Layout",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/Layout3.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 24,
                                "fontWeight": 800
                            }
                        },
                        "url": "",
                        "children": "When You Learn with Us, You Stand Out",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#FF4842"
                            },
                            "fontSize": "medium",
                            "style1": {
                                "fontSize": 24,
                                "fontWeight": 800
                            },
                            "style2": {
                                "color": "#ffffff"
                            },
                            "borderRadius": "Low",
                            "style3": {
                                "color": "#ca23232d"
                            },
                            "style4": {
                                "color": "#ee12e8"
                            },
                            "path": "/login"
                        },
                        "type": "feature1",
                        "name": "Content",
                        "content": {
                            "content": "Intuitive Learning",
                            "icon": "account"
                        }
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            },
                            "fontSize": "medium",
                            "style1": {
                                "fontSize": 24,
                                "fontWeight": 800
                            },
                            "style2": {
                                "color": "#ffffff"
                            },
                            "borderRadius": "Low",
                            "style3": {
                                "color": "#ca23232d"
                            },
                            "style4": {
                                "color": "#ee12e8"
                            }
                        },
                        "type": "feature1",
                        "name": "Content",
                        "content": {
                            "content": "Boost your memory.",
                            "icon": "account"
                        },
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            },
                            "fontSize": "medium",
                            "style1": {
                                "fontSize": 24,
                                "fontWeight": 800
                            },
                            "style2": {
                                "color": "#ffffff"
                            },
                            "borderRadius": "Low",
                            "style3": {
                                "color": "#ca23232d"
                            },
                            "style4": {
                                "color": "#ee12e8"
                            }
                        },
                        "type": "feature1",
                        "name": "Content",
                        "content": {
                            "content": "Swat Analysis",
                            "icon": "account"
                        },
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            },
                            "fontSize": "medium",
                            "style1": {
                                "fontSize": 24,
                                "fontWeight": 800
                            },
                            "style2": {
                                "color": "#ffffff"
                            },
                            "borderRadius": "Low",
                            "style3": {
                                "color": "#ca23232d"
                            },
                            "style4": {
                                "color": "#ee12e8"
                            }
                        },
                        "type": "feature1",
                        "name": "Content",
                        "content": {
                            "content": "Learn through fun activity",
                            "icon": "account"
                        },
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            },
                            "fontSize": "medium",
                            "style1": {
                                "fontSize": 24,
                                "fontWeight": 800
                            },
                            "style2": {
                                "color": "#ffffff"
                            },
                            "borderRadius": "Low",
                            "style3": {
                                "color": "#ca23232d"
                            },
                            "style4": {
                                "color": "#ee12e8"
                            }
                        },
                        "type": "feature1",
                        "name": "Content",
                        "content": {
                            "content": "Skill induced learning method",
                            "icon": "account"
                        },
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            },
                            "fontSize": "medium",
                            "style1": {
                                "fontSize": 24,
                                "fontWeight": 800
                            },
                            "style2": {
                                "color": "#ffffff"
                            },
                            "borderRadius": "Low",
                            "style3": {
                                "color": "#ca23232d"
                            },
                            "style4": {
                                "color": "#ee12e8"
                            }
                        },
                        "type": "feature1",
                        "name": "Content",
                        "content": {
                            "content": "Never forget again",
                            "icon": "account"
                        },
                        "showEditor": false
                    }
                ],
                "style": {
                    "backgroundColor": "#d8bbbb",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 240,
                    "paddingRight": 240
                },
                "id": 9
            },
            {
                "id": 5,
                "name": "Layout footer",
                "key": "LayoutFooter",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer_layout.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components",
                    "content"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 0,
                    "paddingBottom": 0,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Australia",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Brazil",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Canada",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "China",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "France",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Germany",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Italy",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Japan",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Mexico",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Netherlands",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "Spain",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "United Kingdom",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 16,
                                "fontWeight": 500
                            }
                        },
                        "url": "",
                        "children": "United States",
                        "type": "typography",
                        "name": "Text"
                    }
                ]
            },
            {
                "id": 6,
                "name": "Layout footer",
                "key": "layoutFooter1",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/layoutFooter1.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components",
                    "content"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "style": {
                    "backgroundColor": "#f2f0f8",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 0,
                    "paddingBottom": 0,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "/login",
                        "children": "About us",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Contact us",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "More info",
                        "type": "typography",
                        "name": "Text"
                    }
                ]
            },
            {
                "id": 7,
                "name": "Layout footer",
                "key": "layoutFooter2",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/layoutFooter2.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components",
                    "content"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "style": {
                    "backgroundColor": "#ffffff",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 0,
                    "paddingBottom": 0,
                    "paddingLeft": 120,
                    "paddingRight": 120
                },
                "children": []
            }
 
        ]
    },

    {   id: 5,
        name: 'Info Header',
        children: [  
            {
                "key": "infoheader1",
                "name": "Info",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/columnLayout.png",
                "editorConfig": [
                  "dimensions",
                  "spacing",
                  "borderRadius",
                  "shadow",
                  "color",
                  "border",
                  "components"
                ],
                "screens": [
                  "login",
                  "register",
                  "home"
                ],
                "children": [
                  {
                    "props": {
                      "style": {
                        "color": "#ffffff"
                      },
                      "fontSize": "small"
                    },
                    "url": "",
                    "children": "facebook",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#ffffff"
                      },
                      "fontSize": "small"
                    },
                    "url": "",
                    "children": "youtube",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#ffffff"
                      },
                      "fontSize": "small"
                    },
                    "url": "",
                    "children": "instagram",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#ffffff"
                      },
                      "fontSize": "small"
                    },
                    "url": "",
                    "children": "cart",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#ffffff"
                      },
                      "fontSize": "small"
                    },
                    "url": "",
                    "children": "book",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#ffffff",
                        "fontSize": 20,
                        "fontWeight": 800
                      }
                    },
                    "url": "",
                    "children": "xxxx.org@gmail.com",
                    "type": "typography",
                    "name": "Text"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#fff8f8"
                      },
                      "fontSize": "medium"
                    },
                    "url": "",
                    "children": "email",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1",
                    "showEditor": true
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#fff8f8"
                      },
                      "fontSize": "medium"
                    },
                    "url": "",
                    "children": "cellphone-basic",
                    "type": "icon",
                    "name": "Icon",
                    "key": "infoheader1"
                  },
                  {
                    "props": {
                      "style": {
                        "color": "#dfe8f1",
                        "fontSize": 20,
                        "fontWeight": 800
                      }
                    },
                    "url": "",
                    "children": "9334XXXXXX",
                    "type": "typography",
                    "name": "Text"
                  }
                ],
                "id": 3,
                "style": {
                  "backgroundColor": "#A917FE",
                  "boxShadow": "0px 0px 0px 0px #000000",
                  "paddingLeft": 60,
                  "paddingRight": 60
                },
                "properties": {
                  "devices": [
                    "pc",
                    "tab",
                    "mobile"
                  ],
                  "visibleTo": null
                },
                "props": {
                  "className": "animate__animated none animate__repeat-1"
                }
              }
            
            
        
        
    
    
    ]
    },
    {
        id: 5,
        name: 'Footer',
        children: [
            {
                "id": 1,
                "name": "Footer",
                "key": "footer3",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "content": {
                    "content": ""
                },
                "style": {
                    "backgroundColor": "#ffffff00",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingTop": 70,
                    "paddingBottom": 70
                },
                "children": [
                    {
                        "props": {},
                        "url": "",
                        "children": "As quoted by the legendary English artist, Edmund De Waal, we believe that \"With languages, you are at home, anywhere\".",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": "",
                        "children": "Cart",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": "",
                        "children": "All courses",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": "",
                        "children": "Tutor",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": -3,
                            "name": "Search",
                            "path": "/search"
                        },
                        "children": "Search",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": "1",
                            "name": "about-us",
                            "path": "/p/about-us"
                        },
                        "children": "About us",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": 0,
                            "name": "Url",
                            "path": "https://facebook.com/"
                        },
                        "children": "Facebook",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": 0,
                            "name": "Url",
                            "path": "https://instagram.com/"
                        },
                        "children": "Instagram",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": 0,
                            "name": "Url",
                            "path": "https://youtube.com/"
                        },
                        "children": "Youtube",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": 0,
                            "name": "Url",
                            "path": "https://linkedin.com/"
                        },
                        "children": "Linked-in",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {},
                        "url": {
                            "id": 0,
                            "name": "Url",
                            "path": "https://twitter.com/"
                        },
                        "children": "Twitter",
                        "type": "typography",
                        "name": "Text"
                    }
                ],
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ],
                    "visibleTo": null
                }
            },
            {
                "name": "Footer",
                "key": "footer4",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#000000",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 60,
                    "paddingRight": 60,
                    "paddingTop": 0,
                    "paddingBottom": 0
                }
            },
            {
                "name": "Footer",
                "key": "footer13",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    // "backgroundColor": "#000000",
                    // "boxShadow": "0px 0px 0px 0px #000000",
                    // "paddingLeft": 60,
                    // "paddingRight": 60,
                    // "paddingTop": 0,
                    // "paddingBottom": 0

                    // "backgroundColor": "#000000",
                    // "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 0,
                    "paddingRight": 0,
                    "paddingTop": 0,
                    "paddingBottom": 0
                }
            }, 
            {
                "name": "Footer",
                "key": "footer14",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer4.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#000000",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 60,
                    "paddingRight": 60,
                    "paddingTop": 0,
                    "paddingBottom": 0
                }
            },
            {
                "name": "Footer",
                "key": "footer15",
                "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer15.png",
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "style": {
                    "backgroundColor": "#f2f0f8",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 120,
                    "paddingRight": 120,
                    "paddingTop": 0,
                    "paddingBottom": 0
                },
                "id": 8,
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontWeight": 600,
                                "fontSize": 20
                            }
                        },
                        "url": "",
                        "children": "DIGITAL MARKETING SCHOOL",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Pro Digital Marketing Management Program",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Master Digital Marketing Program",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Advanced Digital Marketing Program",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "SEO Certification Course",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Advanced Certification in Digital Marketing & Data Analytics",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Fastrack Digital Marketing Program",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Tycoon Digital Marketing Program",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "CLOUD COMPUTING SCHOOL",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Master Salesforce Developer",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "ENGINEERING SCHOOL",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Full Stack Software Engineering Course",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "CYBER SECURITY SCHOOL",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Cyber Security Course",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "EDUVOGUE",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "About Us",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Contact us",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Blog",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Reviews",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Terms & Conditions",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Privacy Policy",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "WORK WITH US",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Careers",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Become Affiliate",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Franchise",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "CORPORATE",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Corporate Training",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5",
                                "fontSize": 16,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "Hire from Eduvogue",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#212B36",
                                "fontSize": 20,
                                "fontWeight": 600
                            }
                        },
                        "url": "",
                        "children": "FOLLOW US",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon",
                        "id": 5,
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "menu",
                        "type": "icon",
                        "name": "Icon",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#cc2fa5"
                            },
                            "fontSize": "small"
                        },
                        "url": "",
                        "children": "arrow-right-bold-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ff006e"
                            },
                            "fontSize": "large"
                        },
                        "url": "",
                        "children": "instagram",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#FF4842"
                            },
                            "fontSize": "large"
                        },
                        "url": "",
                        "children": "youtube",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#1890FF"
                            },
                            "fontSize": "large"
                        },
                        "url": "",
                        "children": "linkedin",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#1890FF"
                            },
                            "fontSize": "large"
                        },
                        "url": "",
                        "children": "facebook",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#1890FF"
                            },
                            "fontSize": "large"
                        },
                        "url": "",
                        "children": "twitter",
                        "type": "icon",
                        "name": "Icon"
                    }
                ]
            },
            {
                "name": "Footer",
                "key": "footer16",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer.png',
                "editorConfig": [
                    "dimensions",
                    "spacing",
                    "borderRadius",
                    "shadow",
                    "color",
                    "border",
                    "components"
                ],
                "screens": [
                    "login",
                    "register",
                    "home"
                ],
                "style": {
                    "backgroundColor": "#540383",
                    "boxShadow": "0px 0px 0px 0px #000000",
                    "paddingLeft": 60,
                    "paddingRight": 60,
                    "paddingTop": 70,
                    "paddingBottom": 70
                },
                "id": 5,
                "children": [
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            },
                            "form_id": "3",
                            "buttonLabel": "Send"
                        },
                        "children": [],
                        "type": "form",
                        "name": "Form"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#fffefe"
                            }
                        },
                        "url": "",
                        "children": "cellphone",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#fffbfb"
                            }
                        },
                        "url": "",
                        "children": "email",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "cellphone-basic",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "resbee.org@gmail.com",
                        "type": "typography",
                        "name": "Text",
                        "showEditor": false
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "map-marker-circle",
                        "type": "icon",
                        "name": "Icon"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "+91 1234567890",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "+91 904444545454",
                        "type": "typography",
                        "name": "Text"
                    },
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "707D, KK NAGAR ETTAYAPAM ROAD XTN THOOTHUKUDI 62675608",
                        "type": "typography",
                        "name": "Text"
                    },
                    
                    {
                        "props": {
                            "style": {
                                "color": "#ffffff"
                            }
                        },
                        "url": "",
                        "children": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et mi tristique urna eleifend viverra. Etiam aliquet mauris quis semper semper. Donec commodo pretium dui, ut commodo sapien mattis laoreet. Nunc rhoncus massa in metus interdum sollicitudin. Pellentesque lacinia vehicula purus id dignissim. Nulla tempus lacinia orci a malesuada. Sed id porta lectus, vel vestibulum nulla.",
                        "type": "number",
                        "name": "Text"
                    }
                ],
                "properties": {
                    "devices": [
                        "pc",
                        "tab",
                        "mobile"
                    ],
                    "visibleTo": null
                }
            },
        ]
    },

    {
        id: 10,
        name: 'Footer Info',
        children: [
            {
                "name": "Footer Info",
                "key": "footerInfo1",
                preview_image: 'https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/footer_info_1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
                "style": {
                    "backgroundColor": "#ffffff",                    
                    "paddingLeft": 60,
                    "paddingRight": 60,
                    "paddingTop": 0,
                    "paddingBottom": 0
                }
            },
    
        ]
    },
    // {
    //     id: 6,
    //     name: 'Statistics',
    //     children: [
    //         {
    //             "key": "statistics1",
    //             "name": "Statistics",
    //             "style": {
    //                 "backgroundColor": "#ffffff00",
    //                 "boxShadow": "0px 0px 0px 0px #000000"
    //             },
    //             "preview_image": "https://d2z8k3nky90g7o.cloudfront.net/uploads/default/siteBuilder/cardRowLayout.png",
    //             "editorConfig": [ DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
    //             "screens": ['login', 'register', 'home'],
    //             "children": [
    //                 {
    //                     "props": {},
    //                     "url": "",
    //                     "children": "10K+",
    //                     "type": "typography",
    //                     "name": "Text",
    //                     "icon": "menu"
    //                 },
    //                 {
    //                     "props": {},
    //                     "url": "",
    //                     "children": "500K",
    //                     "type": "typography",
    //                     "name": "Text",
    //                 },
    //                 {
    //                     "props": {},
    //                     "url": "",
    //                     "children": "300K+",
    //                     "type": "typography",
    //                     "name": "Text",
    //                 },
    //                 {
    //                     "props": {},
    //                     "url": "",
    //                     "children": "TOTAL COURSES",
    //                     "type": "typography",
    //                     "name": "Text"
    //                 },
    //                 {
    //                     "props": {},
    //                     "url": "",
    //                     "children": "EXPERT MENTORS",
    //                     "type": "typography",
    //                     "name": "Text"
    //                 },
    //                 {
    //                     "props": {},
    //                     "url": "",
    //                     "children": "STUDENTS GLOBALLY",
    //                     "type": "typography",
    //                     "name": "Text"
    //                 },
                
    //             ]
    //             }]}

];

export {
    allComponents
}