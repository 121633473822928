export const getStyles = (style) => {

    let resolution    = typeof window == 'object' ? window.innerWidth : 1300;
    let isMobile      = resolution >= 320 && resolution <= 576;
    let isTablet      = resolution >= 577 && resolution <= 1024;

    if(typeof style === 'object')
    {
        let obj = {}
        obj = {...style}
        obj['paddingLeft'] = isMobile || isTablet ? 0 : style?.paddingLeft
        obj['paddingRight'] = isMobile || isTablet ? 0 : style?.paddingRight

        if(isMobile)
        {
            obj['fontSize'] = style?.fontSize / 2
        }

        return obj
    }

    return style

}