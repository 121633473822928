import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Badge, useTheme, MenuItem, Switch, Menu, Typography, Drawer, CardContent } from '@mui/material';

import cssStyles from "../../utils/cssStyles";
import {devices, HEADER, NAVBAR} from "../../config/config";
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
import {IconButtonAnimate} from "../animate";
import Iconify from "../Iconify";
import {ArrowBack, ArrowBackRounded, MoreVertRounded, ShoppingCart} from "@mui/icons-material";
import AccountPopover from "../homeHeader/AccountPopover";
import NotificationsPopover from "../homeHeader/NotificationsPopover";
import {useRouter} from "next/router";
import { useDispatch, useSelector } from 'react-redux';
import CartPopover from '../homeHeader/CartPopover';
import AppLogo from '../logo/Logo';
import ThemeSwicth from '../homeHeader/ThemeSwitch';
import { useState } from 'react';
import { useSetTheme } from '../../hooks/useSetTheme';
import { reduxHelper } from '../../config/reduxHelper';
import AccountData from '../account/AccountData';
import NotificationSidePopover from '../homeHeader/NotificationSidePopup';
import { getString } from '../../functions/getString'

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: 65,
        width: `100%`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}));

const HomeHeader = ({ onOpenSidebar, isCollapse = false, verticalLayout = false, isLight, ...props }) => {

    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
    const isDesktop = useResponsive('up', 'lg');
    const {back, push} = useRouter();

    const { reducer } = useSelector((state) => state);
    const { cartItems, device } = reducer;

    const isMobile = device === devices.MOBILE;
    const isTablet = device === devices.TABLET;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const { darkTheme } = reducer;
    
    const dispatch = useDispatch();

    const updateTheme = async () => {
        dispatch({
            type: reduxHelper.UPDATE_THEME,
            payload: !darkTheme
        })
        useSetTheme(!darkTheme)
    }
  
    const theme = useTheme();

    return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout} sx={{ zIndex: 99999 }} {...props} >
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 3 },
                    borderBottom: isLight ? 0 : 1,
                    borderColor: 'divider'
                }}
            >
                {/*{isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}*/}

                <Stack direction={'row'} alignItems={'center'} gap={2} >
                {
                    !isMobile && !isTablet &&
                    <IconButtonAnimate onClick={() => {back() }} sx={{ color: isLight ? '#fff' : 'text.primary' }}>
                        <ArrowBackRounded/>
                    </IconButtonAnimate>
                }
                    <AppLogo/>
                </Stack>

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.2, sm: 1.5 }} gap={1} >

                    {/* <CartPopover
                        sx={{
                            color: '#fff',
                            display:{xs:'none'}
                        }}
                    />
                    <NotificationsPopover 
                        sx={{
                            color: '#fff',
                            display:{xs:'none'}
                        }}
                    /> */}

                    {
                        !isMobile && !isTablet &&
                        <>
                            <ThemeSwicth/>
                            {/* <NotificationsPopover/> */}
                            {/* <NotificationSidePopover/> */}
                            <AccountPopover />
                        </>
                    }

                    {
                    isMobile || isTablet ?
                    <>
                        <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            color={'inherit'}
                            size={'small'}
                            sx={{ color: isLight ? '#fff' : 'text.primary' }}
                        >
                            <MoreVertRounded fontSize={'small'} />
                        </IconButton>
                        <Drawer
                            
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                                boxShadow: theme.shadows[19]
                            }}
                        >
                            <Box width={isMobile ? '80vw' : '40vw'}  > 

                                <Box p={2}>
                                    <AppLogo/>
                                </Box>
                              
                                <MenuItem  onClick={() => { 
                                    push('/dashboard') 
                                    handleClose()
                                }} >
                                    <Typography variant={'body2'} >{getString('Dashboard')}</Typography>
                                </MenuItem>
                                <MenuItem  onClick={() => { 
                                    push('/notifications') 
                                    handleClose()
                                }} >
                                    <Typography variant={'body2'} >{getString('Notification')}</Typography>
                                </MenuItem>
                                <MenuItem sx={{ gap: 2 }} >
                                    <Typography variant={'body2'} >{getString('Dark mode')}</Typography>
                                    <Switch size={'small'} defaultChecked={darkTheme} onChange={() => { updateTheme() }} />
                                </MenuItem>
                                <MenuItem>
                                    <Stack width={'100%'} bottom={0} height={210} bgcolor={'background.default'} >
                                        <AccountData/>
                                    </Stack>
                                </MenuItem>

                            </Box>
                        </Drawer>
                    </>
                    :
                    <></>
                }
                </Stack>

            </Toolbar>
        </RootStyle>
    );
}

export default HomeHeader;