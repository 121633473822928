import {HTTP_REST_URLS} from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export const useNotifications = (headers) => new Promise((res, rej) => {

    const url = HTTP_REST_URLS.GET_NOTIFICATIONS;

    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((res) => (res.json()))
        .then(async(response) => {
            const resultValue = await useApiMiddleware(response)   

            if(!resultValue.error)
            {
                const announcementData = resultValue?.announcement;
                if(Array.isArray(announcementData))
                {

                    let array = []

                    announcementData.map((item, index) => {

                        item.noticeboard_data?.map((i) => {

                            i.pb_title = item.basic?.title
                            i.pb_image = item?.basic?.pb_image
                            i.id = parseInt(i.id)

                            array.push(i)

                        })

                    })

                    const result = array.sort(function(a, b) {
                        return a.id + b.id  ||  a.name.localeCompare(b.name);
                    });

                    res(result)

                }
            }

        })

})
