//MUI COMPONENTS
import { Backdrop, Button, Card, CardContent, CardHeader, IconButton, Stack, Typography } from "@mui/material"
import { useRouter } from "next/router";

//PACKAGES
import { useSnackbar } from "notistack";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

//CONFIG
import { icons } from "../../config/icons";
import { reduxHelper } from "../../config/reduxHelper";
import { getString } from "../../functions/getString";
import useDevice from "../../hooks/useDevice";

//COMPONENTS
import Iconify from "../Iconify";

//PROPS TO BE SET ON SHARE ICONS
const iconProps = {
    fontSize: '22px'
}

//FUNCTION TO GET SOCIAL SHARE DATA
const getShareItems = (shareModal) => [
    {
        name: 'Facebook',
        icon: <Iconify icon={icons.FACEBOOK} {...iconProps} />,
        color: '#3b5998',
        url: `https://www.facebook.com/sharer/sharer.php?u=${shareModal?.url}`
    },
    {
        name: 'Twitter',
        icon: <Iconify icon={icons.TWITTER} {...iconProps} />,
        color: '#1da1f2',
        url: `http://twitter.com/share?text=${shareModal?.title ? shareModal?.title : ''}&url=${shareModal?.url}`
    },
    {
        name: 'Whatsapp',
        icon: <Iconify icon={icons.WHATSAPP} {...iconProps} />,
        color: '#128c7e',
        url: `https:///wa.me/send?text=${shareModal?.url}`
    },
    {
        name: 'LinkedIn',
        icon: <Iconify icon={icons.LINKED_ID} {...iconProps} />,
        color: '#0077b5',
        url: `https://www.linkedin.com/sharing/share-offsite/?url=${shareModal?.url}`
    },
    {
        name: 'Email',
        icon: <Iconify icon={icons.MAIL} {...iconProps} />,
        color: '#ea4335',
        url:  `https://mail.google.com/mail/?view=cm&fs=1&to=.com&su=Your%20Subject&body=${shareModal?.url}`
        // url: `mailto:?body=${shareModal?.url}`
    },
    {
        name: 'Telegram',
        icon: <Iconify icon={icons.TELEGRAM} {...iconProps} />,
        color: '#24A1DE',
        url: `https://t.me/share/url?url=${shareModal?.url}`
    }
]

//COMPONENT
function ShareModal()
{
    //HOOKS
    const { enqueueSnackbar }   = useSnackbar();
    const { reducer }           = useSelector((state) => state);
    const dispatch              = useDispatch();
    const {isMobile}            = useDevice()

    //VARIABLS
    const { shareModal, appData } = reducer;
    var shareItems            = getShareItems(shareModal);

    //FUNCTIONS

    const { query, pathname }         = useRouter();
    const { slug }          = query || {};

    const splitedPath =  pathname.split('/');
    var shareSocialMediaItems = "";

    if(splitedPath[1] == "course")
    {
        if( appData?.course_social_media_share)
        {
            shareSocialMediaItems = appData?.course_social_media_share
        }
    }else{
        if( appData?.bundle_social_media_share)
        {
            shareSocialMediaItems = appData?.bundle_social_media_share
        }
    }
   

    if (shareSocialMediaItems.is_facebook == "0") {
        shareItems = shareItems.filter(item => item.name !== 'Facebook');
    }
    if (shareSocialMediaItems.is_twitter == "0") {
        shareItems = shareItems.filter(item => item.name !== 'Twitter');
    }
    if (shareSocialMediaItems.is_whatsapp == "0") {
        shareItems = shareItems.filter(item => item.name !== 'Whatsapp');
    }
    if (shareSocialMediaItems.is_linkedin == "0") {
        shareItems = shareItems.filter(item => item.name !== 'LinkedIn');
    }
    if (shareSocialMediaItems.is_gmail == "0") {
        shareItems = shareItems.filter(item => item.name !== 'Email');
    }
    if (shareSocialMediaItems.is_telegram == "0") {
        shareItems = shareItems.filter(item => item.name !== 'Telegram');
    }
    

    const closeModal = () => {
        dispatch({
            type: reduxHelper.UPDATE_SHARE_MODAL,
            payload: null
        })
    }
    useEffect(()=>{
        if (typeof translateScript === "function") 
        {
            translateScript();
        }
    },[])
    
    return (
        <Backdrop open={shareModal != null} sx={{ zIndex: 9999 }} >

            <Card>
                <CardHeader
                    title={'Share'}
                    action={
                        <IconButton
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            <Iconify
                                icon={icons.CLOSE}
                            />
                        </IconButton>
                    }
                />

                <CardContent>

                    <Stack mb={2.5} direction={'row'} alignItems={'center'} gap={3} >
                        {
                            shareItems?.map((item, index) => {

                                const { name, icon, color } = item;

                                return (
                                    <Stack
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        gap={0.5}
                                        key={index}
                                    >
                                        <Stack  
                                            height={isMobile ? 32 : 40}
                                            width={isMobile ? 32 : 40}
                                            bgcolor={color}
                                            borderRadius={25}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            sx={{ cursor: 'pointer', color: '#fff' }}
                                            onClick={() => { window.open(item?.url) }}
                                        >
                                            {icon}
                                        </Stack>
                                        <Typography variant={'caption'} >{name}</Typography>
                                    </Stack>
                                )
                            })
                        }
                    </Stack>
                    
                    <Stack
                        height={50}
                        width={'100%'}
                        bgcolor={'divider'}
                        borderRadius={1}
                        alignItems={'center'}
                        px={2}
                        justifyContent={'space-between'}
                        direction={'row'}
                        pr={1}
                    >

                        <Typography 
                            variant={'body2'} 
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                fontWeight: 400,
                                width: '74%',
                                '&:focus-visible': {
                                    bgcolor: 'yellow'
                                }
                            }}
                        >{shareModal?.url}</Typography>

                        <Button
                            variant={'contained'}
                            size={'small'}
                            sx={{
                                borderRadius: 150,
                                fontWeight: 500
                            }}
                            onClick={() => {

                                if(typeof window === 'object')
                                {
                                    navigator.clipboard.writeText(shareModal?.url)
                                    closeModal()
                                    enqueueSnackbar('Copied', {
                                        variant: 'success'
                                    })
                                }

                            }}
                        >{getString('Copy')}</Button>

                    </Stack>
                </CardContent>
                
            </Card>
            
        </Backdrop>
    )
};

export default ShareModal;