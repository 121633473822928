import { useEffect } from "react";
import { useState } from "react";
import { HTTP_REST_URLS } from "../config/urls";

const useApplicationDetails = () => {

    if(typeof window !== 'object')
    {
        return ({
            appData: null
        })
    }

    const [appData, setAppData] = useState(null);

    const getApplicationData = () => {
        
        const url = HTTP_REST_URLS.APPLICATION_DETAILS;

        fetch(url)
            .then((res) => (res.json()))
            .then((response) => {
                
                
                setAppData(response?.data)
            })
            .catch((error) => {
                setAppData(null)
            })

    }

    useEffect(() => {
        getApplicationData()
    }, [])

    return ({
        appData: appData
    });

};

export default useApplicationDetails;