import { IconButton, Stack, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, RadioButtonCheckedRounded, RadioButtonUncheckedRounded } from "@mui/icons-material";

const CustomFormCheckboxFields = ({ item, index }) => {

    const [inputValue, setInputValue] = useState([])

    useEffect(() => {
        item['value'] = inputValue
    }, [inputValue?.length])

    return (
        <Stack>
            <Typography variant="body1" >{item?.placeHolder}</Typography>
            <Stack mt={1.5} gap={0.5} >
                {
                    item?.options?.map((i, ind) => {
                        
                        const isSelected = inputValue?.includes(i)

                        return (
                            <Stack direction={'row'} alignItems={'center'} >
                                <IconButton
                                    onClick={() => { 
                                        if(isSelected)
                                        {
                                            const filteredRes = inputValue?.filter((k) => (k != i))
                                            setInputValue(filteredRes)
                                        }
                                        else
                                        {
                                            setInputValue((oldVal) => [...oldVal, i])
                                        }
                                    }} 
                                    size={'small'}
                                >
                                    {
                                        isSelected ? <CheckBoxRounded color={'primary'} fontSize={'medium'} /> : <CheckBoxOutlineBlankRounded fontSize="medium" color={'inherit'} />
                                    }
                                </IconButton>
                                <Typography variant="body2" >{i?.value}</Typography>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </Stack>
    )
};

export default CustomFormCheckboxFields;