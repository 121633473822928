import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// hooks
//
import componentsOverride from '../../theme/overrides';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

ThemeColorPresets.propTypes = {
    children: PropTypes.node,
};

export default function ThemeColorPresets({ children }) {

    const defaultTheme = useTheme();

    const { reducer } = useSelector((state) => state);
    const { selectedThemeColors } = reducer;

    const PRIMARY = selectedThemeColors

    const themeOptions = useMemo(
        () => ({
            ...defaultTheme,
            palette: {
                ...defaultTheme.palette,
                primary: PRIMARY,
            },
            customShadows: {
                ...defaultTheme.customShadows,
                primary: `0 8px 16px 0 ${alpha(PRIMARY.main, 0.24)}`,
            },
        }),
        [PRIMARY, defaultTheme]
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
