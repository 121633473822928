// import { useSnackbar } from 'notistack';
// import { useState } from 'react';
// import NextLink from 'next/link';
// import { useRouter } from 'next/router';
// import { alpha } from '@mui/material/styles';
// import {Box, Divider, Typography, Stack, MenuItem, Avatar, ListItemText, ListItemIcon, Switch, Backdrop, Card, CardHeader, IconButton, CardContent, Button, useTheme} from '@mui/material';
// import {IconButtonAnimate} from "../animate";
// import MenuPopover from "../extras/MenuPopover";
// import {useDispatch, useSelector} from "react-redux";
// import {reduxHelper} from "../../config/reduxHelper";
// import {useSetTheme} from "../../hooks/useSetTheme";
// import { useLogout } from '../../hooks/useLogout';
// import { CloseRounded } from '@mui/icons-material';
// import AccountData from '../account/AccountData';


// const MENU_OPTIONS = [
//   {
//     label: 'Dashboard',
//     linkTo: '/dashboard',
//   },
//   // {
//   //   label: 'My Learning',
//   //   linkTo: '/my-courses',
//   // },
//   {
//     label: 'Profile',
//     linkTo: '/profile',
//   },
// ];



// const AccountPopover = () => {

//   const [open, setOpen] = useState(null);

//   const { reducer } = useSelector((state) => state);
//   const { darkTheme, user, showLogoutPopup } = reducer;
//   const userProfile = user?.us_image

//   const dispatch = useDispatch();
//   const router = useRouter()

//   const updateTheme = async () => {
//       dispatch({
//           type: reduxHelper.UPDATE_THEME,
//           payload: !darkTheme
//       })
//       useSetTheme(!darkTheme)
//   }

//   const handleOpen = (event) => {
//     setOpen(event.currentTarget);
//   };

//   const handleClose = () => {
//     setOpen(null);
//   };
  
//   const userName = user?.us_name;
//   const userEmail = user?.us_email;

//   const { route } = router;

//   const openLogoutPopup = () => {
//     dispatch({
//       type: reduxHelper.UPDATE_LOGOUT_POPUP,
//       payload: true
//     })
//   }

//   const theme = useTheme();

//   const PRIMARY_TEXT = theme.palette.text.primary

//   if(!user)
//   {
//     return <></>
//   }

//   return (
//     <>
//       {/* <IconButton
//         onClick={handleOpen}
//       >
//         <Avatar
//             src={userProfile}
//             sx={{width: 30, height: 30 }}
//         />
//       </IconButton> */}

//       <Stack
//         direction={'row'}
//         alignItems={'center'}
//         gap={1}
//         border={1}
//         borderColor={'divider'}
//         px={1.5}
//         py={0.8}
//         borderRadius={1}
//         onClick={handleOpen}
//         sx={{ cursor: 'pointer' }}
//       >
//         <Avatar
//             src={userProfile}
//             sx={{width: 26, height: 26 }}
//         />
//         <Typography 
//           color={PRIMARY_TEXT} 
//           sx={{
//               display: '-webkit-box',
//               overflow: 'hidden',
//               WebkitBoxOrient: 'vertical',
//               WebkitLineClamp: 1,
//               fontWeight: 400,
//               maxWidth: '100px',
//               color: 'text.primary',
//           }}
//         >{userName}</Typography>
//       </Stack>

//       <MenuPopover
//         open={Boolean(open)}
//         anchorEl={open}
//         onClose={handleClose}
//         sx={{
//           mt: 1.5,
//           ml: 0.75,
//           '& .MuiMenuItem-root': {
//             typography: 'body2',
//             borderRadius: 0.75,
//           },
//           width: 300,
//           height: 95
//         }}
//       >

//         <AccountData query={'&hideProduct=yes'} />
        
//       </MenuPopover>
//     </>
//   );
// }

// export default AccountPopover;


import { Stack } from "@mui/material"
import Script from "next/script";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../hooks/useUser";
import LanguagePopover from "./LanguagePopover"

const AccountPopover = ({ isHome = true }) => {

  // return (<>asdf</>)

  const { user } = useUser()

  const { product_menu_url } = user || {}
  const { assets_url } = user || {}



  const widget_url = `${product_menu_url}&userDivId=account-data-menu&productAsWidget=yes&productDivId=product-div-id&active=lms`

  const ethiopian_url = `${assets_url}admin/js/ethiopian_calendar_script.js`


  useEffect(() => {
    if (user) {
        // Ethiopian Calendar Section
        const existingEthiopianScript = document.getElementById('ethiopian-calendar-script');
        if (existingEthiopianScript) existingEthiopianScript.remove();
        
        const ethiopianCalendarScript = document.createElement('script');
        ethiopianCalendarScript.type = 'text/javascript';
        ethiopianCalendarScript.src = ethiopian_url;
        ethiopianCalendarScript.id = 'ethiopian-calendar-script';
        document.body.appendChild(ethiopianCalendarScript);

        // Account URL Script Section
        const existingAccountScript = document.getElementById('account-url-script');
        if (existingAccountScript) existingAccountScript.remove();
        
        const accountScript = document.createElement('script');
        accountScript.type = 'text/javascript';
        accountScript.src = widget_url;
        accountScript.id = 'account-url-script';

        // Ensure functions execute after script loads
        accountScript.onload = () => {
            start();
            getSysnotify();
            if (typeof translateScript === "function") 
            {
              translateScript();
            }
            
        };

        document.body.appendChild(accountScript);
    }
}, [user]);


  // if(!user)
  // {
  //   return <>
  //     <LanguagePopover/>
  //   </>
  // }

  return (
    <Stack direction={'row'} alignItems={'center'} gap={2} >

      {/* <LanguagePopover/> */}

        <Stack id={'account-data-menu'} >
          
        </Stack>

        <Stack 
          id={'product-div-id'} 
        >
        
      </Stack>

    </Stack>
  )
};

export default AccountPopover;