import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import Logo from '../components/logo/Logo';

const CustomLogo = () => {
    return (
        <Box>
            <Logo/>
        </Box>
    )
};

export default CustomLogo