import { useEffect } from "react";
import { useState } from "react";
import {HTTP_REST_URLS} from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export const useFeatureAccess = (feature) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.FEATURE_ACCESS ;

    fetch(url, {
        method: 'GET'
    })
        .then((res) => (res.json()))
        .then(async(response) => {

            const resultValue = await useApiMiddleware(response)
            
            if(!resultValue.error)
            {
                const { data } =  resultValue || {};
                const { value } = data || {};

                const result = value[feature]?.value == 1;

                resolve(result)
                
            }
            else
            {
                resolve(false)
            }

        })
        .catch((error) => {
            resolve(false)
        })

})