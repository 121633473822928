import { ContentCopyRounded, ContentPasteRounded, DeleteRounded, EditRounded, ImageRounded } from "@mui/icons-material";
import { alpha, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "../components/themebuilder/ImageGallery";
import { reduxHelper } from "../config/reduxHelper";
import { customComponentTypes } from "./types";
import { Resizable } from 're-resizable'
import { useSnackbar } from 'notistack'

const positions = {
    
    TOP_LEFT: 'TOP_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT'

}

const ImageBorder = ({ position }) => {

    const theme = useTheme();

    const BORDER_COLOR = theme.palette.info.main;

    return (
        <Stack
            sx={{
                pointerEvents: 'all',
                position: 'absolute',
                border: `2px solid ${BORDER_COLOR}`,
                height: 10,
                width: 10,
                backgroundColor: '#fff',
                top: position === positions.TOP_LEFT || position === positions.TOP_RIGHT ? 0 : undefined,
                bottom: position === positions.BOTTOM_LEFT || position === positions.BOTTOM_RIGHT ? 0 : undefined,
                left: position === positions.BOTTOM_LEFT || position === positions.TOP_LEFT ? 0 : undefined,
                right: position === positions.BOTTOM_RIGHT || position === positions.TOP_RIGHT ? 0 : undefined,
                zIndex: 99,
            }}
        >

        </Stack>
    )
}

const CustomComponentWrapper = ({ children, item, index, id, parentItem }) => {

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder, themebuilderSelectedItem, pageJson, copiedSiteBuilderChild, siteBuilderSelectedChild, siteBuilderHoveredChild } = reducer;

    const themeChildren = themebuilderSelectedItem?.children || [];
    const imageData = themeChildren?.filter((i) => (i.type == customComponentTypes.image)) || {};
    const dataWithoutImage = themeChildren?.filter((i) => (i.type !== customComponentTypes.image))

    const theme = useTheme(); 
    const dispatch = useDispatch();

    const updateComponent = () => {
        dispatch({
            type: reduxHelper.UPDATE_COMPONENT
        })
    }

    const outlineColor = theme.palette.info.main;
    const PRIMARY_BACKGROUND = theme.palette.background.default;
    
    const { type } = item || {};
    
    const isImage = type == customComponentTypes?.image;

    const [isSelected, setSelected] = useState(false);
    const [isHovered, setHovered] = useState(false);

    useEffect(() => {

        if(siteBuilderHoveredChild)
        {
            if(siteBuilderHoveredChild == item)
            {
                setHovered(true)
            }
            else
            {
                setHovered(false)
            }
        }
        else
        {
            setHovered(false)
        }

    }, [siteBuilderHoveredChild])

    const { enqueueSnackbar } = useSnackbar()

    if(!isSiteBuilder)
    {
        return children
    }

    // if(parentItem != themebuilderSelectedItem)
    // {
    //     return children
    // }

    const onCopyChild = () => {
        
        dispatch({
            type: reduxHelper.UPDATE_COPIED_SITE_BUILDER_CHILD,
            payload: item
        })
        enqueueSnackbar("Content coppied", {
            variant: 'info'
        })

    }

    const onPasteChild = () => {

        if(Array.isArray(themeChildren))
        {
            themeChildren?.push(copiedSiteBuilderChild)
            updateComponent()
        }

    }

    const onDeleteChild = () => {

        const filterResult = themeChildren?.filter((i) => (i !== item))
        themebuilderSelectedItem['children'] = filterResult;
        updateComponent()

    }

    return (
        <Stack 
            position={'relative'}
        >
            
            {
                isHovered &&
                <Stack
                    bgcolor={'info.main'}
                    px={0.8}
                    width={'fit-content'}
                    py={0.5}
                    position={'absolute'}
                    top={isImage ? -20 : -23}
                    left={isImage ? 3 : 0}
                    zIndex={9}
                >
                    <Typography fontSize={10} color={'white'} fontFamily={'Poppins'} textTransform={'capitalize'} >{type}</Typography>
                </Stack>
            }

            {
                isSelected &&
                <Stack
                    bgcolor={'info.main'}
                    px={1}
                    width={'fit-content'}
                    py={0.8}
                    position={'absolute'}
                    top={isImage ? -31 : -29}
                    right={isImage ? 15 : 0}
                    color={'#fff'}
                    direction={'row'}
                    alignItems={'center'}
                    gap={1.5}
                >

                    {
                        !isImage &&
                        <Tooltip title={'Edit'} placement="top" >
                            <EditRounded 
                                sx={{ 
                                    height: 18, 
                                    width: 18, 
                                    cursor: 'pointer' 
                                }} 
                                onClick={() => { 
                                    item['showEditor'] = true
                                    dispatch({
                                        type: reduxHelper.UPDATE_SITE_BUILDER_SELECTED_CHILD,
                                        payload: item
                                    })
                                    dispatch({
                                        type: reduxHelper.UPDATE_BLOCK_PROPERTIES,
                                        payload: true
                                    })
                                }}
                            />
                        </Tooltip>
                    }

                    {/* <Tooltip title={'Copy'} placement="top" >
                        <ContentCopyRounded sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={() => { onCopyChild() }} />
                    </Tooltip> */}
                    
                    <Tooltip title={'Delete'} placement="top" >
                        <DeleteRounded sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={() => { onDeleteChild() }} />
                    </Tooltip>

                </Stack>
            }

            {
                isImage && isSelected &&
                <Stack
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '100%',
                        width: '100%',
                        zIndex: 99
                    }}
                >
                    <ImageBorder position={positions.TOP_LEFT} />
                    <ImageBorder position={positions.TOP_RIGHT} />
                    <ImageBorder position={positions.BOTTOM_LEFT} />
                    <ImageBorder position={positions.BOTTOM_RIGHT} />
                </Stack>
            }

            <Stack
                sx={{
                    ...(isSiteBuilder && {
                        '&:hover': {
                            outline: `1px solid ${outlineColor}`,
                        },
                        // ...isSelected && {
                        //     outline: `2px solid ${outlineColor}`,
                        // }
                        '&:focus-visible': {
                            outline: `2px solid ${outlineColor}`,
                        }
                    }),
                    ...(isHovered && {
                        outline: `1px solid ${outlineColor}`,
                    }),
                    outlineOffset: isImage ? 0 : isSelected ? -2 : -1,
                    caretColor: isImage ? 'transparent' : undefined
                }}
                contentEditable={isSiteBuilder}
                onBlur={(e) => {
                    
                    setTimeout(() => {
                        setSelected(false)
                    }, 250)

                    if(isSiteBuilder)
                    {
                        
                        switch(type)
                        {
                            case customComponentTypes.typography:
                                
                                siteBuilderSelectedChild['children'] = e.target.innerText;
                                break;

                            case customComponentTypes.button:

                                dataWithoutImage[index]['label'] = e.target.innerText;
                                break;

                            default :
                                break;
                        }

                    }

                }}
                
                onFocus={() => {
                    setSelected(true)
                    if(siteBuilderSelectedChild != item)
                    {
                        item['showEditor'] = false
                        dispatch({
                            type: reduxHelper.UPDATE_SITE_BUILDER_SELECTED_CHILD,
                            payload: item
                        })
                        updateComponent()
                    }
                }}
                onMouseEnter={() => {
                    setHovered(true)
                }}
                onMouseLeave={() => {
                    setHovered(false)
                }}
            >

                {
                    isHovered && isImage &&
                    <Stack
                        height={'100%'}
                        width={'100%'}
                        position={'absolute'}
                        zIndex={99}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgcolor={alpha(pageJson?.pageStyle?.backgroundColor || PRIMARY_BACKGROUND, 0.5)}
                        sx={{ cursor: 'pointer' }}
                    >

                        <ImageGallery
                            onAddImage={(image) => {
                                imageData[index]['src'] = image;
                                updateComponent()
                            }}
                        >
                            <ImageRounded sx={{ color: 'background.default', height: 60, width: 60 }} />
                        </ImageGallery>

                    </Stack>
                }

                <Stack
                    defaultSize={{
                        width: siteBuilderSelectedChild?.style?.width || 320,
                        height: siteBuilderSelectedChild?.style?.height || 200
                    }}
                    component={isImage ? Resizable : undefined}
                    sx={{
                        zIndex: 9,
                        position: 'relative'
                    }}
                    onResizeStop={(e, direction, ref, d) => {


                        let obj = {};
                        obj  = {...siteBuilderSelectedChild?.style}
                        obj['height'] = obj?.height ? (parseInt(obj?.height) + d.height) : (200 + d.height)
                        obj['width'] = obj?.width ? (parseInt(obj?.width) + d.width) : (320 + d.width)

                        siteBuilderSelectedChild['style'] = obj;

                        updateComponent()

                    }}
                    lockAspectRatio={true}
                >
                    {children}
                </Stack>
            </Stack>
        </Stack>
    )
};

export default CustomComponentWrapper

// import { ContentCopyRounded, ContentPasteRounded, DeleteRounded, EditRounded, ImageRounded } from "@mui/icons-material";
// import { alpha, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material"
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import ImageGallery from "../components/themebuilder/ImageGallery";
// import { reduxHelper } from "../config/reduxHelper";
// import { customComponentTypes } from "./types";
// import { Resizable } from 're-resizable'
// import { useSnackbar } from 'notistack'

// const positions = {
    
//     TOP_LEFT: 'TOP_LEFT',
//     TOP_RIGHT: 'TOP_RIGHT',
//     BOTTOM_LEFT: 'BOTTOM_LEFT',
//     BOTTOM_RIGHT: 'BOTTOM_RIGHT'

// }

// const ImageBorder = ({ position }) => {

//     const theme = useTheme();

//     const BORDER_COLOR = theme.palette.info.main;

//     return (
//         <Stack
//             sx={{
//                 pointerEvents: 'all',
//                 position: 'absolute',
//                 border: `2px solid ${BORDER_COLOR}`,
//                 height: 10,
//                 width: 10,
//                 backgroundColor: '#fff',
//                 top: position === positions.TOP_LEFT || position === positions.TOP_RIGHT ? 0 : undefined,
//                 bottom: position === positions.BOTTOM_LEFT || position === positions.BOTTOM_RIGHT ? 0 : undefined,
//                 left: position === positions.BOTTOM_LEFT || position === positions.TOP_LEFT ? 0 : undefined,
//                 right: position === positions.BOTTOM_RIGHT || position === positions.TOP_RIGHT ? 0 : undefined,
//                 zIndex: 99,
//             }}
//         >

//         </Stack>
//     )
// }

// const CustomComponentWrapper = ({ children, item, index, id, parentItem }) => {

//     const { reducer } = useSelector((state) => state);
//     const { isSiteBuilder, themebuilderSelectedItem, pageJson, copiedSiteBuilderChild, siteBuilderSelectedChild, siteBuilderHoveredChild } = reducer;

//     const themeChildren = themebuilderSelectedItem?.children || [];
//     const imageData = themeChildren?.filter((i) => (i.type == customComponentTypes.image)) || {};
//     const dataWithoutImage = themeChildren?.filter((i) => (i.type !== customComponentTypes.image))

//     const theme = useTheme(); 
//     const dispatch = useDispatch();

//     const updateComponent = () => {
//         dispatch({
//             type: reduxHelper.UPDATE_COMPONENT
//         })
//     }

//     const outlineColor = theme.palette.info.main;
//     const PRIMARY_BACKGROUND = theme.palette.background.default;
    
//     const { type } = item || {};
    
//     const isImage = type == customComponentTypes?.image;

//     const [isSelected, setSelected] = useState(false);
//     const [isHovered, setHovered] = useState(false);

//     useEffect(() => {

//         if(siteBuilderHoveredChild)
//         {
//             if(siteBuilderHoveredChild == item)
//             {
//                 setHovered(true)
//             }
//             else
//             {
//                 setHovered(false)
//             }
//         }
//         else
//         {
//             setHovered(false)
//         }

//     }, [siteBuilderHoveredChild])

//     const { enqueueSnackbar } = useSnackbar()

//     if(!isSiteBuilder)
//     {
//         return children
//     }

//     // if(parentItem != themebuilderSelectedItem)
//     // {
//     //     return children
//     // }

//     const onCopyChild = () => {
        
//         dispatch({
//             type: reduxHelper.UPDATE_COPIED_SITE_BUILDER_CHILD,
//             payload: item
//         })
//         enqueueSnackbar("Content coppied", {
//             variant: 'info'
//         })

//     }

//     const onPasteChild = () => {

//         if(Array.isArray(themeChildren))
//         {
//             themeChildren?.push(copiedSiteBuilderChild)
//             updateComponent()
//         }

//     }

//     const onDeleteChild = () => {

//         const filterResult = themeChildren?.filter((i) => (i !== item))
//         themebuilderSelectedItem['children'] = filterResult;
//         updateComponent()

//     }

//     return (
//         <Stack 
//             position={'relative'}
//         >
            
//             {
//                 isHovered &&
//                 <Stack
//                     bgcolor={'info.main'}
//                     px={0.8}
//                     width={'fit-content'}
//                     py={0.5}
//                     position={'absolute'}
//                     top={isImage ? -20 : -23}
//                     left={isImage ? 3 : 0}
//                 >
//                     <Typography fontSize={10} color={'white'} fontFamily={'Poppins'} textTransform={'capitalize'} >{type}</Typography>
//                 </Stack>
//             }

//             {
//                 isSelected &&
//                 <Stack
//                     bgcolor={'info.main'}
//                     px={1}
//                     width={'fit-content'}
//                     py={0.8}
//                     position={'absolute'}
//                     top={isImage ? -31 : -29}
//                     right={isImage ? 15 : 0}
//                     color={'#fff'}
//                     direction={'row'}
//                     alignItems={'center'}
//                     gap={1.5}
//                 >

//                     {
//                         !isImage &&
//                         <Tooltip title={'Edit'} placement="top" >
//                             <EditRounded 
//                                 sx={{ 
//                                     height: 18, 
//                                     width: 18, 
//                                     cursor: 'pointer' 
//                                 }} 
//                                 onClick={() => { 
//                                     item['showEditor'] = true
//                                     dispatch({
//                                         type: reduxHelper.UPDATE_SITE_BUILDER_SELECTED_CHILD,
//                                         payload: item
//                                     })
//                                     dispatch({
//                                         type: reduxHelper.UPDATE_BLOCK_PROPERTIES,
//                                         payload: true
//                                     })
//                                 }}
//                             />
//                         </Tooltip>
//                     }

//                     <Tooltip title={'Copy'} placement="top" >
//                         <ContentCopyRounded sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={() => { onCopyChild() }} />
//                     </Tooltip>
                    
//                     <Tooltip title={'Delete'} placement="top" >
//                         <DeleteRounded sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={() => { onDeleteChild() }} />
//                     </Tooltip>

//                 </Stack>
//             }

//             {
//                 isImage && isSelected &&
//                 <Stack
//                     sx={{
//                         position: 'absolute',
//                         left: 0,
//                         top: 0,
//                         height: '100%',
//                         width: '100%',
//                         zIndex: 99
//                     }}
//                 >
//                     <ImageBorder position={positions.TOP_LEFT} />
//                     <ImageBorder position={positions.TOP_RIGHT} />
//                     <ImageBorder position={positions.BOTTOM_LEFT} />
//                     <ImageBorder position={positions.BOTTOM_RIGHT} />
//                 </Stack>
//             }

//             <Stack
//                 sx={{
//                     ...(isSiteBuilder && {
//                         '&:hover': {
//                             outline: `1px solid ${outlineColor}`,
//                         },
//                         // ...isSelected && {
//                         //     outline: `2px solid ${outlineColor}`,
//                         // }
//                         '&:focus-visible': {
//                             outline: `2px solid ${outlineColor}`,
//                         }
//                     }),
//                     ...(isHovered && {
//                         outline: `1px solid ${outlineColor}`,
//                     }),
//                     outlineOffset: isImage ? 0 : isSelected ? -2 : -1,
//                     caretColor: isImage ? 'transparent' : undefined
//                 }}
//                 contentEditable={isSiteBuilder}
//                 onBlur={(e) => {
                    
//                     setTimeout(() => {
//                         setSelected(false)
//                     }, 250)

//                     if(isSiteBuilder)
//                     {
                        
//                         switch(type)
//                         {
//                             case customComponentTypes.typography:
                                
//                                 siteBuilderSelectedChild['children'] = e.target.innerText;
//                                 break;

//                             case customComponentTypes.button:

//                                 dataWithoutImage[index]['label'] = e.target.innerText;
//                                 break;

//                             default :
//                                 break;
//                         }

//                     }

//                 }}
                
//                 onFocus={() => {
//                     setSelected(true)
//                     if(siteBuilderSelectedChild != item)
//                     {
//                         item['showEditor'] = false
//                         dispatch({
//                             type: reduxHelper.UPDATE_SITE_BUILDER_SELECTED_CHILD,
//                             payload: item
//                         })
//                         updateComponent()
//                     }
//                 }}
//                 onMouseEnter={() => {
//                     setHovered(true)
//                 }}
//                 onMouseLeave={() => {
//                     setHovered(false)
//                 }}
//             >

//                 {
//                     isHovered && isImage &&
//                     <Stack
//                         height={'100%'}
//                         width={'100%'}
//                         position={'absolute'}
//                         zIndex={99}
//                         alignItems={'center'}
//                         justifyContent={'center'}
//                         bgcolor={alpha(pageJson?.pageStyle?.backgroundColor || PRIMARY_BACKGROUND, 0.5)}
//                         sx={{ cursor: 'pointer' }}
//                     >

//                         <ImageGallery
//                             onAddImage={(image) => {
//                                 imageData[index]['src'] = image;
//                                 updateComponent()
//                             }}
//                         >
//                             <ImageRounded sx={{ color: 'background.default', height: 60, width: 60 }} />
//                         </ImageGallery>

//                     </Stack>
//                 }

//                 <Stack
//                     defaultSize={{
//                         width: item?.style?.width || 'auto',
//                         height: item?.style?.height || 'auto'
//                     }}
//                     // component={isImage ? Resizable : undefined}
//                     component={siteBuilderSelectedChild == item ? Resizable : 'div'}
//                     sx={{
//                         zIndex: 9,
//                         position: 'relative'
//                     }}
//                     onResizeStop={(e, direction, ref, d) => {

//                         let obj = {};
//                         obj = {...item?.props?.style}
//                         obj['height'] = obj?.height ? (parseInt(obj?.height) + d.height) : d.height > 0 ? d.height : '100%'
//                         obj['width'] = obj?.width ? (parseInt(obj?.width) + d.width) : d.width > 0 ? d.width : '100%'

//                         item['props']['style'] = obj;

//                         updateComponent()

//                     }}
//                     lockAspectRatio={isImage}
//                     boundsByDirection={true}
//                 >
//                     {children}
//                 </Stack>
//             </Stack>
//         </Stack>
//     )
// };

// export default CustomComponentWrapper