import { ExpandMoreRounded } from "@mui/icons-material"
import { Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material"

const CustomAccordion = ({ item, ...props }) => {
    return (
        <Box>
            {
                item?.children?.map((i, index) => {
                    return (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreRounded />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div dangerouslySetInnerHTML={{ __html: i?.title }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <div dangerouslySetInnerHTML={{ __html: i?.content }} />
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Box>
    )
};

export default CustomAccordion;