import { useSelector } from "react-redux";

const AccountData = ({ query = '' }) => {

    const { reducer } = useSelector((state) => state);
    const { user } = reducer

    if(user)
    {
        return <iframe src={user?.widget_url + query} height={'100%'} width={'100%'} style={{ border: 'none' }} />;
    }

    return <></>
    
};

export default AccountData;