
// import {AppBar, Box, Toolbar, Typography,Menu ,Container,Button, MenuItem, useTheme, Input, Card, Avatar, Grid, Stack, Drawer, IconButton, Link,Divider} from '@mui/material';
// import CustomComponent from '../../../customComponents/CustomComponent';
// import { useSelector } from 'react-redux';
// import { useState } from 'react';
// import MenuIcon from '@mui/icons-material/Menu';
// import Image from 'next/image';
// import EkhoolImage from '../../ekhoolImage/ekhoolImage';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import LanguageIcon from '@mui/icons-material/Language';
// import CloseIcon from '@mui/icons-material/Close';
// import { useRouter } from 'next/router';
// import LanguagePopover from '../../homeHeader/LanguagePopover';
// import { getString } from '../../../functions/getString';
// import AccountPopover from '../../homeHeader/AccountPopover';
// import useDevice from '../../../hooks/useDevice';
// import { getStyles } from '../../../functions/getStyles'
// import ImportContactsIcon from '@mui/icons-material/ImportContacts';
// import { Router } from '@mui/icons-material';

// const key = 'header11'

// const Header11 = () => {

//   const { reducer } = useSelector((state) => state);
//   const { layoutJson, appData, user, isSiteBuilder } = reducer;

//   const themeJson = layoutJson?.components?.filter((i) => (i.key === key));
//   const themeItem = themeJson?.length > 0 && themeJson[0];
//  const style = getStyles(themeItem && themeItem?.style ? themeItem.style : {})

//   const [open, setOpen] = useState(false);
//   const [anchorElNav ,setAnchorElNav] = useState(null);

//   const { replace, push } = useRouter()

//   const {isDesktop, isMobile, isTablet} = useDevice()

// //   const handleOpenNavMenu = (event) => {
// //     setAnchorElNav(event.currentTarget);
// //   };   

//   const links = [
//     { text: getString('Home'), url: '/p/landing' },
//     // { text: getString('My Account'), url: '/about' },
//     { text: getString('Course Catalog'), url: '/all-courses' },
//     { text: getString('Site Map'), url: '/p/site-map' },    
//   ];

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   return (

//     <>

//     {/* {
//       !isSiteBuilder &&
//       <Stack height={78} />
//     } */}

//       <Stack >

//       {/* <Container maxWidth="xxl"sx={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;",padding:'0px !important'}} > */}
//       <Stack position={isSiteBuilder ? 'unset' : 'fixed'} top={0} width={'100vw'} zIndex={9} bgcolor={'#fff'} borderBottom={isMobile || isTablet ? 0:2} color={'grey.500'} >
//         <Toolbar disableGutters sx={{display:"block",minHeight:'auto !important'}}>
//           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },alignItems:'center', }} >
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit">
//                 <MenuIcon />
//             </IconButton>

//             <Drawer
//                 id="menu-appbar"
//                 anchorEl={anchorElNav}
//                 anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//                 }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: 'block', md: 'none' },
//               }} >    
//                 <Box sx={{width:"70vw",overflowX:"hidden"}}> 
//                     <Box sx={{display:'flex',boxShadow:" rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",}} height={"7vh"} >      
//                         <Box   position="relative" height={"50px"} width={140} m={1}p={1}pb={2} sx={{alignItems:'center'}}>
//                           <Link href="/" >
//                             <EkhoolImage  src={appData?.logo} layout="fill" objectFit="contain" />
//                           </Link>
//                         </Box>  
//                     </Box>    
//                   <Box p={3} gap={2}>

//                     {
//                         !user &&
//                         <>

//                           <Box display="flex" flexDirection={"column"} alignItems="left" gap={"15px"} >
//                                 <>
//                                   <Typography onClick={() => { replace('/login')}} sx={{ borderRadius: 0 ,color:'#A100FF',cursor:'pointer'}}>{getString('Log in')}</Typography>
//                                   {/* <Typography onClick={() => { replace('/login')}}  sx={{color:'#A100FF',cursor:'pointer'}}>{getString('Sign up')}</Typography> */}
//                                 </>

//                             {/* <ShoppingCartOutlinedIcon/> */}

//                           </Box>

//                           <Divider sx={{mt:2}}/>

//                         </>
//                     }

//                   <Box display={'flex'} flexDirection={"column"} gap={2} alignItems={'left'} pt={1}>
//                     {
//                       links.map((link, index) => (
//                         <Box key={link.url}>
//                           <Link href={link.url} sx={{color:'black'}}>{link.text}</Link>
//                         </Box>
//                       ))
//                     }        
//                 </Box>
//                 <Box >
//                   <Stack alignItems={'center'} direction={'row'} gap={1} height={50} width={90} sx={{border:'1px solid black', px:1,  cursor:'pointer'}} onClick={() => {push('/dashboard')}} mt={1}>
//                     <ImportContactsIcon />
//                     <Typography variant='subtitle1' sx={{fontWeight:500}}>Learn</Typography>
//                   </Stack>

//                     <Button variant="outlined"sx={{border:"1px solid black",color:'black',borderRadius:1,top:"20px",padding:'1px !important'}}>
//                         <AccountPopover/>
//                   </Button>


//                 </Box>

//                 {
//                   user &&
//                   <Button variant="outlined"sx={{border:"1px solid black",color:'black',borderRadius:1,top:"20px",padding:'1px !important', mt: 2}} href={user?.logout_url} >Logout</Button>
//                 }

//                   </Box>


//                 </Box>   



//             </Drawer>

//             <Box 
//               sx={{               
//                   display: { xs: 'block', md: 'none' },
//                 }}
//                 height={40} width={150}
//                 position={'relative'}
//               >    
//                 {
//                   appData &&
//                   <EkhoolImage src={appData.logo} layout={'fill'} objectFit={'contain'}/>
//                 }              
//             </Box> 
//           </Box>

//           <Stack 
//           style={{
//             ...style,
//             // gap: undefined,                
//             paddingLeft: isMobile || isTablet ? '15px' : "60px",
//             paddingRight: isMobile || isTablet ? '15px' : "60px"
//             // paddingTop: isMobile || isTablet ? '15px' : style?.paddingTop,
//             // paddingBottom: isMobile || isTablet ? '15px' : style?.paddingBottom,
//           }}
//           {...themeItem?.props}   
//           >

//             <Stack py={2}  direction={{xl:'row', md:'row'}} spacing={{xl:5, md:3}} display={{xs: 'none', md: "flex"}} alignItems={'center'} justifyContent={"space-between"}>

//               <Box 
//                   height={40} width={isDesktop ? 150 :'none'}
//                   position={'relative'}
//               >    

//                 {
//                   appData &&
//                   <EkhoolImage src={appData.logo} layout={'fill'} objectFit={'contain'}/>
//                 }

//               </Box>  

//               <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
//                 {
//                   links.map((link, index) => (
//                     <Box key={link.url}>
//                       <Link href={link.url} sx={{borderRight: index !== links.length - 1 ? "1px solid black" : "none", paddingRight:2,color:'black', textDecoration: 'none !important',  '&:hover': {color: '#A100FF',}}}>{link.text}</Link>

//                     </Box>
//                   ))
//                 }     
//               </Box>


//             <Box display="flex" alignItems="flex-end" justifyContent="flex-end" gap={"12px"} >
//                 {/* <Button sx={{color:'black', '&:hover': {backgroundColor: 'white',color: '#A100FF' ,}}} onClick={() => { push('/cart') }} > <ShoppingCartOutlinedIcon/></Button> */}

//                   {
//                     !user &&
//                     <>
//                       <Button variant="outlined" onClick={() => { replace('/login') }} sx={{ borderRadius: 0 ,color:'black',padding:"4px 20px !important",border:"1px solid black", py: '8px !important', '&:hover': {backgroundColor: '#fff',border:'1px solid black'}}}>{getString("Login")}</Button>
//                       {/* <Button  color="secondary" onClick={() => { replace('/login') }} sx={{backgroundColor:'#A100FF',color:'white',padding:"5px 20px !important",borderRadius:0, py: '9px !important','&:hover': { backgroundColor: '#A100FF'}}}>Sign up</Button> */}
//                     </>
//                   }

//                   {/* <Stack alignItems={'center'} direction={'row'} gap={1} height={40} sx={{border:'1px solid black', px:1,  cursor:'pointer'}} onClick={() => {push('/dashboard')}}>
//                     <ImportContactsIcon fontSize='small' sx={{color:'black'}}/>
//                     <Typography variant='body2' sx={{color:'black', fontWeight:500}}>Learn</Typography>
//                   </Stack> */}


//                   <AccountPopover/>

//       {/* 
//                   <Box display={'flex'} gap={2}>

//                     <AccountPopover/>

//                 </Box> */}
//             </Box>

//           </Stack>

//           </Stack>

//         </Toolbar>

//       </Stack>

//     </Stack>


//     </>

//   );
// };
// export default Header11;


import { AppBar, Box, Toolbar, Typography, Menu, Container, Button, MenuItem, useTheme, Input, Card, Avatar, Grid, Stack, Drawer, IconButton, Link, Divider } from '@mui/material';
import CustomComponent from '../../../customComponents/CustomComponent';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Image from 'next/image';
import EkhoolImage from '../../ekhoolImage/ekhoolImage';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import LanguagePopover from '../../homeHeader/LanguagePopover';
import { getString } from '../../../functions/getString';
import AccountPopover from '../../homeHeader/AccountPopover';
import useDevice from '../../../hooks/useDevice';
import { getStyles } from '../../../functions/getStyles';
import HomeAccountPopover from '../../homeHeader/HomeAccountPopover';
import TagCategory from '../../tag/tagcategory';
const key = 'header11'

const Header11 = () => {

  const { reducer } = useSelector((state) => state);
  const { layoutJson, appData, user, isSiteBuilder } = reducer;

  const themeJson = layoutJson?.components?.filter((i) => (i.key === key));
  const themeItem = themeJson?.length > 0 && themeJson[0];
  const style = getStyles(themeItem && themeItem?.style ? themeItem.style : {})

  const [open, setOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const { replace, push, pathname, query } = useRouter()
  const { isDesktop, isMobile, isTablet } = useDevice()

  const theme  = useTheme();

  //   const handleOpenNavMenu = (event) => {
  //     setAnchorElNav(event.currentTarget);
  //   };   

  const links = [
    { text: getString('Home'), url: '/' },
    // { text: getString('My Account'), url: '/about' },
    { text: getString('Course Catalog'), url: '/all-courses' },
    { text: getString('Site Map'), url: '/p/site-map' },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (

    <>

      {
        !isSiteBuilder && 
        <Stack height={{md:70, sm:60, xs:40}} />
      }

      <Stack >

        {/* <Container maxWidth="xxl"sx={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;",padding:'0px !important'}} > */}
        <Stack position={isSiteBuilder ? 'unset' : 'fixed'} top={0} width={'100vw'} zIndex={9} bgcolor={'#fff'} borderBottom={2} color={'grey.500'} >
          <Toolbar disableGutters sx={{ display: "block", minHeight: 'auto !important' }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center', }} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit">
                <MenuIcon />
              </IconButton>

              <Drawer
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }} >
                <Box sx={{ width: "70vw", overflowX: "hidden" }}>
                  <Box sx={{ display: 'flex', boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;", }} height={"7vh"} >
                    <Box position="relative" height={"50px"} width={140} m={1} p={1} pb={2} sx={{ alignItems: 'center' }}>
                      <Link href="/" >
                       {!isSiteBuilder && <EkhoolImage src={appData?.logo} layout="fill" objectFit="contain" />}
                      </Link>
                    </Box>
                  </Box>
                  <Box p={3} gap={2}>

                    {
                      !user &&
                      <>

                        <Box display="flex" flexDirection={"column"} alignItems="left" gap={"15px"} >
                          <>
                            <Typography onClick={() => { replace('/login') }} sx={{ borderRadius: 0, color: 'black', cursor: 'pointer' }}>{getString('Log in')}</Typography>
                            {/* <Typography onClick={() => { replace('/login')}}  sx={{color:'#A100FF',cursor:'pointer'}}>{getString('Sign up')}</Typography> */}
                          </>

                          {/* <ShoppingCartOutlinedIcon/> */}

                        </Box>

                        <Divider sx={{ mt: 2 }} />

                      </>
                    }

                    <Box display={'flex'} flexDirection={"column"} gap={2} alignItems={'left'} pt={1}>
                      {
                        links.map((link, index) => (
                          <Box key={link.url}>
                            <Link href={link.url} sx={{ color: 'black' }}>{link.text}</Link>
                          </Box>
                        ))
                      }
                    </Box>
                    <Box >
                      <Button variant="outlined" sx={{ border: "1px solid black", color: 'black', borderRadius: 0, top: "20px" }}><LanguageIcon />English</Button>
                    </Box>
                  </Box>
                </Box>
              </Drawer>

              <Box
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                height={40} width={150}
                position={'relative'}
              >
                {
                  appData &&!isSiteBuilder &&
                  <EkhoolImage src={appData.logo} layout={'fill'} objectFit={'contain'} />
                }
              </Box>
            </Box>

            <Stack
              style={{
                ...style,
                // gap: undefined,                
                paddingLeft: isMobile || isTablet ? '15px' : "60px",
                paddingRight: isMobile || isTablet ? '15px' : "60px",
              //   paddingTop: isMobile || isTablet ? '15px' : style?.paddingTop,
              //   paddingBottom: isMobile || isTablet ? '15px' : style?.paddingBottom,
              }}
              {...themeItem?.props}
            >

              <Stack py={2} direction={{ xl: 'row', md: 'row' }} spacing={{ xl: 5, md: 3 }} display={{ xs: 'none', md: "flex" }} alignItems={'center'} justifyContent={"space-between"}>

              <Box display={'flex'} gap={5} alignItems={'center'} justifyContent={'center'}>
                  <Box
                    height={40} width={isDesktop ? 150 : 'none'}
                    position={'relative'}
                  >
                    {
                      appData && ! isSiteBuilder &&
                      <EkhoolImage src={appData.logo} layout={'fill'} objectFit={'contain'} />
                    }
                  </Box>
                  <TagCategory/>
                </Box>



                <Box display={'flex'} flexDirection={'row'} gap={1.5} alignItems={'center'}
                sx={{
                  
                    '.active-path' : {
                      color: theme.palette.primary.main ,
                      // color:'white !important',    
                      fontWeight:600,
                      fontSize:'18px'
                    },
                }}
                   
                >
                  {/* {links.map((link, index) => (
                    <Box key={link.url}>
                      <Link href={link.url} sx={{borderRight: index !== links.length - 1 ? "1px solid black" : "none", paddingRight:2,color:'black', textDecoration: 'none !important',  '&:hover': {color: '#A100FF',}}}>{link.text}</Link>
                    </Box>
                  ))}      */}

                  <Link href='/p/landing' underline='none'> <Typography className={query?.slug == 'landing' ?  'active-path' : ''} color={'black'} sx={{ '&:hover': { color: theme.palette.primary.light, cursor: 'pointer' }, py: 0.5, px: 1, borderRadius: 1 }}>Home</Typography> </Link>

                  <Divider flexItem orientation='vertical' color={'black'} />

                  <Link href='/all-courses' underline='none'> <Typography className={pathname == '/all-courses' ? 'active-path' : ''} color={'black'} sx={{ '&:hover': { color: theme.palette.primary.light, cursor: 'pointer' }, py: 0.5, px: 1, borderRadius: 1 }}>Course Catalog</Typography></Link>

                  <Divider flexItem orientation='vertical' color={'black'} />

                  <Link href='/p/site-map' underline='none'><Typography className={query?.slug == 'site-map' ? 'active-path' : ''} color={'black'} sx={{ '&:hover': { color: theme.palette.primary.light, cursor: 'pointer' }, py: 0.5, px: 1, borderRadius: 1 }}>Site Map</Typography></Link>

                </Box>


                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={"10px"}>
                  {/* <Button sx={{color:'black', '&:hover': {backgroundColor: 'white',color: '#A100FF' ,}}} onClick={() => { push('/cart') }} > <ShoppingCartOutlinedIcon/></Button> */}

                  {
                    !user &&
                    <>
                      <Button variant="outlined" onClick={() => { replace('/login') }} sx={{ borderRadius: 0, color: 'black', padding: "4px 20px !important", border: "1px solid black", py: '8px !important', '&:hover': { backgroundColor: '#fff', border: '1px solid black' } }}>{getString('Log in')}</Button>
                      {/* <Button  color="secondary" onClick={() => { replace('/login') }} sx={{backgroundColor:'#A100FF',color:'white',padding:"5px 20px !important",borderRadius:0, py: '9px !important','&:hover': { backgroundColor: '#A100FF'}}}>Sign up</Button> */}
                    </>
                  }

                  {/* <AccountPopover /> */}
                  <HomeAccountPopover/>

                  {/* 
            <Box display={'flex'} gap={2}>

               <AccountPopover/>

          </Box> */}
                </Box>

              </Stack>

            </Stack>

          </Toolbar>

        </Stack>

      </Stack>


    </>

  );
};
export default Header11;







