import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, MenuItem } from "@mui/material"
import { useRouter } from "next/router";
import { useState } from "react";
import { useSelector } from "react-redux";
import MenuPopover from "../components/extras/MenuPopover";
import CustomPopover from "./CustomPopover";
import { useIsLightColor } from '../hooks/useIsLightColor'

const CustomButton = ({ item, children = "Button", ...props}) => {
    
    const { push } = useRouter();
    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder } = reducer;

    if(Array.isArray(children))
    {
        return <CustomPopover item={item} />
    }

    const { backgroundColor } = props?.sx || false;
    const isLightColor = backgroundColor ? useIsLightColor(backgroundColor) : false;

    return (
        <Button 
            disableElevation={true}
            {...props} 
            sx={{ 
                ...props.sx, 
                width: props?.fullWidth ? undefined : 'fit-content',
                boxShadow: 'none !important',
                '&:hover': {
                    backgroundColor: props?.sx?.backgroundColor || undefined
                },
                color: isLightColor ? '#000' : !backgroundColor ? undefined : '#fff'
            }} 
            onClick={() => { 
                if(!isSiteBuilder)
                {
                    if(typeof item?.url == 'string')
                    {
                        push(item?.url)
                    }
                    else if(typeof item?.url === 'object')
                    {
                        push(item?.url?.path)
                    }
                }
            }} 
        >{item?.label}</Button>
    )

};

export default CustomButton;