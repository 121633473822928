import {HTTP_REST_URLS} from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export const useTestimonialsData = (headers, id) => new Promise((res, rej) => {

    const url = HTTP_REST_URLS.GET_TESTIMONIALS

    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((res) => (res.json()))
        .then(async(response) => {
            const resultValue = await useApiMiddleware(response)    
                                
            if(!resultValue.error)
            {
                if(resultValue?.testimonials?.length > 0)
                {

                    resultValue?.testimonials?.map((item) => {

                        if(item?.t_image == 'default.jpg')
                        {
                            item ['t_image'] = resultValue?.default_img_path + 'default.jpg' 
                        }
                        else{
                            item['t_image'] = resultValue?.testimonial_img_path + item?.t_image
                        }
                                                
                    })
                    res(resultValue?.testimonials)
                }
            else
            {
                res([])
            }
        }
        else
        {
            res([])
        }
               

    })

})
