export const USER_ACTIVITY_KEY          = 'dXNlckFjdGl2aXR5'
export const TOKEN_KEY                  = '49d1a65cf6e311ec'
export const USER_INFO_KEY              = 'GrWYCs9U3z'
export const BUNDLE_CART_KEY            = 'YnVuZGxlQ2FydA'
export const CART_KEY                   = 'cHJvZ3JhbUNhcnQ'
export const BUNDLE_KEY                 = 'YnVuZGxlY2FydA'
export const USER_WEEKLY_ACTIVITY       = 'dXNlcldlZWtseUFjdGl2aXR5'
export const CALL_BACK_URL_KEY          = 'Y2FsbGJhY2tVcmw'
export const ENCRYPTED_AUTH_DATA_KEY    = 'ZW5jcnlwdGVkQXV0aERhdGFLZXk'
export const ADMIN_TOKEN_KEY            = 'adminUser'
export const ANNOUNCEMENT_KEY           = 'YW5ub3VuY2VtZW50YmFy'
export const SITE_JSON_KEY              = 'c2l0ZUJ1aWxkZXJKc29u'
export const REFERER_KEY                = 'cmVmZXJlcg'
export const PERCENTAGE_CACHE           = 'cGVyY2VudGFnZV9jYWNoZQ'
export const PROGRAM_LAST_MATERIAL      = 'nGi02LjMen7g3etgjB='
export const OLD_PERCENTAGE_DATE_KEY    = 'b2xkX3BlcmNlbnRhZ2VfZGF0ZQ'
export const SCROLL_POSITION_KEY        = 'U0NST0xMX1BPU0lUSU9OX0tFWQ'
export const BECOME_TUTOR               = 'YmVjb21lIGEgdHV0b3I='
export const BILLING_DATA               = 'YmlsbGluZ2FkZHJlc3M='
export const LANGUAGE_KEY               = 'bGFuZ3VhZ2Vfa2V5'
export const LANGUAGE_NAME_KEY          = 'bGFuZ3VhZ2UtbmFtZQ'
export const SJSON_KEY                  = 'c2l0ZUJvfriohJKc29u'