import { CloseRounded } from "@mui/icons-material";
import { Alert, Box, Button, Collapse, IconButton, Link, Stack, Typography, useTheme } from "@mui/material"
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ANNOUNCEMENT_KEY } from "../../config/key";
import { reduxHelper } from "../../config/reduxHelper";
import { getString } from "../../functions/getString";

const AnnouncementBar = () => {

    const { reducer } = useSelector((state) => state);
    const { showAnnouncementBar, user, appData } = reducer;

    const announcementBarCookie = Cookies.get(ANNOUNCEMENT_KEY)

    const dispatch = useDispatch();
    const theme = useTheme();

    const { push, pathname } = useRouter();

    const PRIMARY_MAIN = theme.palette.primary.main;

    const openBar = () => {
        dispatch({
            type: reduxHelper.UPDATE_ANNOUNCEMENT_BAR,
            payload: true
        })
    }

    const closeBar = () => {
        dispatch({
            type: reduxHelper.UPDATE_ANNOUNCEMENT_BAR,
            payload: !showAnnouncementBar
        })
    }

    const alertMessage = user?.banner_message;
    const trainerRequested = user?.trainer_request == 1;
    const trainerRejected = user?.trainer_request == 3;
    const trainerApproved = user?.trainer_request == 7;
    const trainerAccepted = user?.trainer_request == 2;
    const trainerPaymentUrl = user?.payment_url;
    const paymentFee = appData?.trainerFee;
    const priceSymbol = appData?.amount_details?.symbol;

    useEffect(() => {
        if(user && user?.trainer_request != 0)
        {
            openBar()
        }
    }, [user]);

    if(pathname == '/tutor')
    {
        return <></>
    }

    return (
        <Collapse 
            in={!announcementBarCookie && showAnnouncementBar}
        >
            {/* <Stack
                width={'100%'}
                bgcolor={PRIMARY_MAIN}
                alignItems={'center'}
                justifyContent={'center'}
                p={2}
                position={'relative'}
            >

                <Typography>Please wait for your approval</Typography>

                <Box
                    position={'absolute'}
                    right={10}
                >
                    <IconButton
                        onClick={() => {
                            closeBar()
                        }}
                    >
                        <CloseRounded/>
                    </IconButton>
                </Box>

            </Stack> */}

            <Alert
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 0,
                    px: 4,
                    py: 2,
                    zIndex: 999999999999999,
                    // position: 'fixed',
                    width: '100%'
                }}
                severity={trainerRequested ? 'info' : trainerRejected ? 'error' : trainerAccepted ? 'success' : trainerApproved ? 'info' :'warning'}
                onClose={() => {
                    closeBar()
                    Cookies.set(ANNOUNCEMENT_KEY, 'ok')
                }}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={2}
                >
                    {alertMessage}
                    {
                        trainerApproved &&
                        <Button 
                            variant={'contained'} 
                            color={trainerRequested ? 'info' : trainerRejected ? 'error' : trainerApproved ? 'info' : 'warning' } 
                            disableElevation 
                            sx={{ 
                                boxShadow: 'none',
                                fontFamily: 'Roboto'
                            }}  
                            size={'small'}
                            onClick={() => {
                                if(typeof window === 'object')
                                {
                                    window?.open(trainerPaymentUrl)
                                }
                            }}
                        > {getString('Pay')}  {priceSymbol}{paymentFee}</Button>
                    }

                    {
                        trainerRejected &&
                        <Link href="/contact-us" uderline='none'>
                            <Button 
                                variant={'contained'} 
                                color={trainerRequested ? 'info' : trainerRejected ? 'error' : trainerApproved ? 'success' : 'warning' } 
                                disableElevation 
                                sx={{ 
                                    boxShadow: 'none' 
                                }}  
                                size={'small'}
                                onClick={() => {
                                    push('/contact-us')
                                }}
                            >
                                {getString('Contact us')}                                
                            </Button>
                        </Link>
                    }

                </Stack>
            </Alert>

        </Collapse>
    )
};

export default AnnouncementBar;