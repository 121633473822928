import { PlayCircleRounded, ShoppingCartCheckoutRounded, ShoppingCartRounded } from "@mui/icons-material";
import { alpha, Card, CardContent, Link, LinearProgress, Skeleton, Stack, Typography, styled, Tooltip, Fab, Box, useTheme, useMediaQuery,  } from "@mui/material";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxHelper } from "../../config/reduxHelper";
import { useAddToCart } from "../../hooks/useAddToCart";
import { useCheckCart } from "../../hooks/useCheckCart";
import { useProgramPricing } from "../../hooks/useProgramPricing";
import EkhoolImage from "../ekhoolImage/ekhoolImage";
import Label from '../extras/Label'
import { getString } from '../../functions/getString';
import Iconify from "../Iconify";
import { icons } from "../../config/icons"

const LineStyle = styled(Stack)(({ theme }) => ({

    height: 3,
    width: '100%',
    // backgroundColor: theme.palette.divider,
    backgroundColor: '#808080',
    borderRadius: 10

}))


function BundleCard({ item, index})
{ 

    const title                         = item.bb_title;
    const enrolled                      = item.enrolled;
    const selfEnrolled                  = item.bb_has_self_enroll;
    const selfEnrolledDate              = item.bb_self_enroll_date;
    const thumbnail                     = item.bb_image;
    const percentage                    = item.percentage;
    const totalLearners                 = item?.enrolled_learners;
    const shortDescription              = item?.bb_short_description;
    const description                   = item?.bb_description;
    const totalRating                   = item?.ratting === "" ? 0 : item?.ratting;
    const createdDate                   = item?.created_date;
    const totalLessons                  = item?.lessons?.length;
    const unlimitedBundle               = item.unlimited_validity;
    const subscriptionEndData           = item?.subscription_end_date;
    const userSuspended                 = item?.learner_suspended;   
    const bundleSubscriptionExpired     = item?.expired;
    const addonsCount                   = item?.bb_products != undefined ? item?.bb_products : null;
    const bundleExpired                 = item?.validity_expired;
    const programsLength                = item?.programs_count
    const currentDate                   = new Date().toISOString().split('T')[0];


   
    const [priceData, setPriceData]         = useState(null);
    const [itemOnCart, setItemOnCart]       = useState(false)
    const [isMonthlyPay, setIsMonthlyPay]   = useState();

    const bundleDuration                    = item?.bb_preview_time
    const totalEnrolledLearners             = item?.bb_total_enrolled_learners || item?.enrolled_learners
    const certifiedBundle                   = item?.pb_has_certificate

    const { reducer } = useSelector((state) => state); 
    const { appData, cartItems } = reducer;
    const priceSymbol                     = appData?.amount_details?.symbol;

    const monthPayment                    = item && item.bb_month_payment ? item.bb_month_payment : null
    const parsedMonthPayment              = monthPayment && JSON.parse(monthPayment);
    const filteredData                    = parsedMonthPayment && parsedMonthPayment.filter((i) => ( i.show == '1' ));
    const priceToShow                     = filteredData && filteredData.length > 0 && filteredData[0].price;

    const strickoutDetail               = item?.bb_discount_value;
    const CoursePrice                   = item?.bb_price;
    const percentageValue               = parseInt(strickoutDetail) - parseInt(CoursePrice)
    const percentageAmount              = (parseInt(percentageValue ) / parseInt(strickoutDetail)) * 100;
    const percentageRound               = Math.round(percentageAmount);

    const enrolledPath          = `/bundle/${item?.bb_slug}`
    const notEnrolledPath       = `/bundle/${item?.bb_slug}`
    const pathWithLastPlayed    = `/bundle/${item?.bb_slug}/learn/${item?.ps_last_played_material}`
    const hasLastPlayed         = item?.ps_last_played_material!== undefined && item?.ps_last_played_material != 0

    const showPricing = appData?.enable_learner_purchase
   

    // let navigatePath = enrolled && hasLastPlayed ? pathWithLastPlayed : enrolled ? enrolledPath : notEnrolledPath

    let navigatePath = enrolled ? enrolledPath : notEnrolledPath

    const getPriceData = async () => {

        let bundleObject = {
            bundle_detail: item
        }
        const result = useProgramPricing(bundleObject) 
        setPriceData(result);
    }

    const dispatch = useDispatch()

    const getBundlePricingForMonth = () =>{
        if(parsedMonthPayment != null){
            parsedMonthPayment.map((i) => 
            {
                if(i.show == true){
                    setIsMonthlyPay(i)
                }
            }
            )
        }  
    } 

    const monthlypayments       = isMonthlyPay?.price / isMonthlyPay?.months ? isMonthlyPay?.price / isMonthlyPay?.months : 0;
    const monthlypaymentDivde   = monthlypayments.toFixed(2) 

    const checkItemOnCart = async () => {
        const result = await useCheckCart(`b-${item?.id ?? item?.bundle_id}`)
        setItemOnCart(result)  
    }

    useEffect(() => {
        getPriceData()
    }, [item]);

    useEffect(() =>{
        checkItemOnCart()
    },[])
         
    useEffect(() => {
        getBundlePricingForMonth()
    }, [])

    const { enqueueSnackbar } = useSnackbar();

    const addToCart = async () => {
        if(itemOnCart)
        {
            push('/cart')
        }
        else
        {
            const result = await useAddToCart(`b-${item?.id ?? item?.bundle_id}`)
            enqueueSnackbar(getString('Added to cart'), {
                variant: 'success'
            })
            setItemOnCart(true)
            dispatch({
                type: reduxHelper.UPDATE_CART_ITEMS,
                payload: [...cartItems, `b-${item?.id ?? item?.bundle_id}`]
            })
        }
    }  

    const { push } = useRouter()
    const theme = useTheme();

    const isIOS = useMediaQuery(theme.breakpoints.only('xs')); // Define the breakpoint for iOS  
    const cardHeight = isIOS ? '150px' : '100%'; 

    return (
        // <Link 
        //     href={navigatePath} 
        //     sx={{
        //         color: 'text.primary',
        //         textDecorationLine: 'none',
        //         '&:hover': {
        //             textDecorationLine: 'none'
        //         }
        //     }}
        // >
            <Stack 
                mb={2} 
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    height: 'auto'
                }}
            >

                <Stack gap={0.5} mb={0.5} alignItems={'center'} >
                    <LineStyle sx={{ width: '80%' }} />
                    <LineStyle sx={{ width: '90%' }} />
                </Stack>

                <Stack
                    minHeight={{
                        xs: 110,
                        sm: 152
                    }}
                    bgcolor={'background.neutral'}
                    borderRadius={1}
                    position={'relative'}
                    overflow={'hidden'}
                    mb={2}
                    sx={{
                        top:0,
                         '.program-card-overlay': {
                            position: 'absolute',
                            top: '100%',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            width: '100%',
                            opacity: 0,
                            transition: '.5s ease',
                            backgroundColor: alpha('#000000', 0.8),
                        },
                        '&:hover': {
                            '.program-card-overlay':{
                                opacity: 1,
                                top:0
                            }
                        },
                        '.programcard-fab-icon': {
                            display: 'flex'
                        }
                    
                }}
                >

                    {
                        enrolled && bundleSubscriptionExpired && 
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.06)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            sx={{ 
                                cursor:'pointer'
                                // display: 'none'
                            }}
                        >
                             <Box border={'1px solid'} color={'white'} p={1} gap={2}>

                                {/* {                                    
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <Iconify icon={icons.DURATION} sx={{height:20, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}> {getString ('Learning duration :')} {bundleDuration}</Typography>
                                    </Box>
                                }      */}
                                {
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.COURSE} sx={{height:20, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of Courses:')} {programsLength}</Typography>
                                    </Box>
                                }
                                                            
                                {/* {
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.GROUP_PERSON_FILLED} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>Total enrollment : {totalEnrolledLearners}</Typography>
                                    </Box>
                                } */}
                                {
                                    certifiedBundle == 1 &&
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.CERTIFICATE_OUTLINE} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString("Certificate available :")}</Typography>
                                    </Box>
                                }
                                </Box>
                        </Stack>
                    }

                    {
                        !enrolled &&
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.06)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            sx={{ 
                                cursor:'pointer'
                                // display: 'none'
                            }}
                            onClick={() => {
                                push(navigatePath)}}
                        >
                             <Box border={'1px solid'} color={'white'} p={1} gap={2}>

                                {/* {
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <Iconify icon={icons.DURATION} sx={{height:20, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString("Learning duration  ")}:{bundleDuration}</Typography>
                                    </Box>
                                }      */}
                                {
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.COURSE} sx={{height:20, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}> {getString("Number of Courses  ")}:{programsLength}</Typography>
                                    </Box>
                                }
                                                            
                                {/* {                                    
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.GROUP_PERSON_FILLED} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>Total enrollment : {totalEnrolledLearners}</Typography>
                                    </Box>
                                } */}
                                {
                                    certifiedBundle == 1 &&
                                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                                        <Iconify icon={icons.CERTIFICATE_OUTLINE} sx={{height:25, width:25}}/>
                                        <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString("Certificate available :")}</Typography>
                                    </Box>
                                }
                                </Box>
                        </Stack>
                    }

                    { 
                        enrolled && !bundleSubscriptionExpired && 
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.5)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay "                           
                            sx={{ 
                                overflow: 'hidden',   
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                push(navigatePath)
                            }}
                        >

                            {/* <PlayCircleRounded
                                sx={{
                                    height: 50,
                                    width: 50,
                                    color: '#fff'
                                }}
                            /> */}

                            <Box border={'1px solid'} color={'white'} p={1} gap={2}>

                            {/* {
                                <Box display={'flex'} gap={1} alignItems={'center'}>
                                    <Iconify icon={icons.DURATION} sx={{height:20, width:25}}/>
                                    <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString("Learning duration :")} {bundleDuration}</Typography>
                                </Box>
                            }      */}
                            {
                                 <Box display={'flex'} gap={1}  alignItems={'center'}>
                                    <Iconify icon={icons.COURSE} sx={{height:20, width:25}}/>
                                    <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString('Number of Courses :')} {programsLength}</Typography>
                                </Box>
                            }
                                                        
                            {/* {
                                <Box display={'flex'} gap={1}  alignItems={'center'}>
                                    <Iconify icon={icons.GROUP_PERSON_FILLED} sx={{height:25, width:25}}/>
                                    <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>Total enrollment : {totalEnrolledLearners}</Typography>
                                </Box>
                            } */}
                            {
                                certifiedBundle == 1 &&
                                <Box display={'flex'} gap={1}  alignItems={'center'}>
                                    <Iconify icon={icons.CERTIFICATE_OUTLINE} sx={{height:25, width:25}}/>
                                    <Typography variant="subtitle1" sx={{ color: '#fff',}} fontWeight={500}>{getString("Certificate available :")}</Typography>
                                </Box>
                            }
                            </Box>

                        </Stack>                       
                        
                    }
                    
                    
                    {
                        ((enrolled && bundleSubscriptionExpired ) || (enrolled && bundleSubscriptionExpired && bundleExpired)) &&
                        
                        <Stack
                            height={'100%'}
                            width={'100%'}
                            position={'absolute'}
                            bgcolor={alpha('#000000', 0.5)} 
                            zIndex={999}
                            alignItems={'center'}
                            justifyContent={'center'}
                            className="program-card-overlay"
                            sx={{ 
                                cursor:'not-allowed'
                            }}
                            
                        >
                            <Typography color={'background.paper'} variant={'subtitle1'}> {getString('Expired Bundle')} </Typography>
                        </Stack>   
                        
                    }
                    <Box sx={{cursor:'pointer'}}>
                        <EkhoolImage src={item.bb_image}  onClick={() => { push(navigatePath) }}/>
                    </Box>
                    {
                        !isNaN(percentageRound) && !enrolled && priceData?.hasStrike && showPricing &&
                        <Stack
                            position={'absolute'}
                            right={10}
                            top={15}
                            zIndex={999}
                        >
                            {/* <Typography variant="subtitle" fontFamily={'Roboto'} color={'#FF8000'}>You save {percentageRound}%</Typography> */}
                            <Label sx={{ bgcolor: 'warning', color: '#000', borderRadius:0}} > {getString('You save ')} {percentageRound}%</Label>
                        </Stack>
                    }
                    {
                        !enrolled && (priceData?.originalPrice > 0 || priceToShow || addonsCount != null)  && showPricing && (selfEnrolled == '0' || ( selfEnrolled == '1' &&   selfEnrolledDate <= currentDate )) &&
                        <Tooltip
                            title={itemOnCart ? getString('Go to cart') : getString('Add to cart')}
                        >
                            <Fab
                                size={'small'}
                                sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 10,
                                    zIndex: 999,
                                    display: 'none'
                                }}
                                className={'programcard-fab-icon'}
                                onClick={() => {
                                    addToCart()
                                }}
                            >
                                {
                                    itemOnCart ? <ShoppingCartCheckoutRounded fontSize="small" /> : <ShoppingCartRounded fontSize="small" />
                                }
                            </Fab>
                        </Tooltip>
                    }

                    {
                        enrolled && bundleSubscriptionExpired && !bundleExpired && (priceData?.originalPrice > 0 || priceToShow || addonsCount != null) && showPricing && (selfEnrolled == '0' || ( selfEnrolled == '1' &&   selfEnrolledDate <= currentDate )) &&
                        <Tooltip
                            title={itemOnCart ? getString( 'Go to cart') : getString('Add to cart') }
                        >
                            <Fab
                                size={'small'}
                                sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 10,
                                    zIndex: 999,
                                    display: 'none'
                                }}
                                className={'programcard-fab-icon'}
                                onClick={() => {
                                    addToCart()
                                }}
                            >
                                {
                                    itemOnCart ? <ShoppingCartCheckoutRounded fontSize="small" /> : <ShoppingCartRounded fontSize="small" />
                                }
                            </Fab>
                        </Tooltip>
                    }
                   
                </Stack>
                <Stack
                    onClick={() => !bundleSubscriptionExpired ?  push(navigatePath) : (bundleSubscriptionExpired && !bundleExpired ? push(notEnrolledPath) : (bundleSubscriptionExpired && bundleExpired) && '')  }  sx={{ cursor:  enrolled && bundleSubscriptionExpired && bundleExpired  ? 'not-allowed' :  'pointer' }}
                >
                <Tooltip placement="top-start" arrow title={title} sx={{mt:0}}>
                    <Typography variant="subtitle1" sx={{ minHeight: 50,  overflow: 'hidden', WebkitBoxOrient: 'vertical', display: '-webkit-box', WebkitLineClamp: 2}} >{title}</Typography>
                </Tooltip>
                <Typography variant="caption" color={'text.secondary'} >{programsLength} {programsLength > 1 ? getString('Courses') : getString('Course')}</Typography>

                {
                    enrolled && !bundleSubscriptionExpired &&
                    <Stack mt={1.5} gap={0.75} >
                        <LinearProgress value={percentage} variant={'determinate'} sx={{ height: 2 }} color={'inherit'} />
                        <Box display={'flex'} flexDirection={{sm:'row', xs:'column'}} justifyContent={'space-between'}>
                            <Typography variant="caption" color={'text.primary'} >{percentage > 0 ? `${percentage}% ${getString('completed')}` : getString('Start learning')}</Typography>
                            {
                                !userSuspended 
                                    ?  
                                    // <Typography color={unlimitedBundle && !bundleSubscriptionExpired ? 'green' : 'error'} variant={'caption'} fontWeight={500}>{unlimitedBundle && !bundleSubscriptionExpired ? 'Full Lifetime Access' : (!bundleSubscriptionExpired ? `Expire on ${subscriptionEndData}` : 'Expired')}</Typography>
                                    <Typography color={unlimitedBundle ? 'green' : 'green'} variant={'caption'} fontWeight={500}>{unlimitedBundle ? getString('Full Lifetime Access') : `${getString('Expire on')} ${subscriptionEndData}` }</Typography>
                                    :
                                    <Typography color={'error'} variant={'caption'} fontWeight={500}>{getString('Suspended')}</Typography>
                            }
                        </Box>
                    </Stack>
                }

                {
                   (!enrolled || enrolled && bundleSubscriptionExpired )&& showPricing &&
                    <Stack mt={1} >
                        
                        {
                            !priceData && 
                            <Skeleton
                                height={40}
                                width={65}
                                sx={{ borderRadius: 0.5 }}
                            />
                        }

                        {
                            priceData && !priceToShow &&
                            <Stack direction={'row'} alignItems={'center'} gap={1.5} >
                                {
                                    priceData?.originalPrice > 0 ?
                                    <Typography variant="subtitle2" >{priceSymbol} {priceData?.originalPrice}</Typography>
                                    :
                                    !appData?.isEthiopia ? <Typography variant="subtitle2" >{getString('Free')}</Typography> : ""
                                }
                                {
                                    priceData?.hasStrike && 
                                    <Typography variant="body2" sx={{ textDecorationLine: 'line-through' }} >{priceSymbol} {priceData?.strikeOutPrice}</Typography>
                                }
                            </Stack>
                            
                        }
                        {
                            ( selfEnrolled == '1' &&   selfEnrolledDate > currentDate ) &&     <Typography color={ 'red'} variant={'caption'} fontWeight={500}> Available from  {selfEnrolledDate} </Typography>

                        }
                    
                        {
                            priceToShow &&
                                <Stack direction={'row'}>
                                    <Typography  variant="subtitle2">{priceSymbol} {monthlypaymentDivde}/</Typography>
                                    <Typography  variant="subtitle2">{isMonthlyPay?.months} {getString('Months')} </Typography>                        
                                </Stack>
                                
                        }

                    </Stack>
                }
                
                    
               </Stack>

            </Stack>
        // </Link>

    )
};

export default BundleCard;
