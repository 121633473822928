import { Button, IconButton, Menu, MenuItem, Radio, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { customComponentTypes } from "./types";
import { useSubmitForm } from '../hooks/useSubmitForm';
import { useFormData } from '../hooks/useFormData';
import { useJsonData } from '../hooks/useJsonData';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { inputTypes } from '../components/formBuilder/inputTypes'
import CustomFormTextField from './formInputs/customFormTextField'
import CustomFormParagraphField from './formInputs/customFormParagraphField'
import CustomFormRadioFields from './formInputs/customFormRadioFields'
import CustomFormCheckboxFields from './formInputs/customFormCheckboxFields'
import CustomFormMenuFields from './formInputs/customFormMenuFields'

const CustomForm = ({ item, ...props }) => {

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder } = reducer;

    const [inputData, setInputData] = useState(item?.children);
    const [value, setValue] = useState('');
    const [formData, setFormData] = useState(null)

    const formId = item?.props?.form_id

    const updateValue = () => {
        setValue(Math.random())
    }

    const { enqueueSnackbar } = useSnackbar();

    const submitForm = async () => {
        let formDataObject = {};
        formDataObject['template_id'] = formId;
        formDataObject['form_data'] = JSON.stringify(formData);
        const result = await useSubmitForm(formDataObject)
        if(result)
        {
            enqueueSnackbar('Form submitted successfully', {
                variant: 'success'
            })
        }
        else
        {
            enqueueSnackbar('Something went wrong', {
                variant: 'error'
            })
        }
    }

    const getFormData = async () => {

        const result = await useFormData(formId)
        if(result.error == false)
        {
            const jsonResponse = await useJsonData(result?.formPath)
            if(jsonResponse?.error == false)
            {
                setFormData(jsonResponse?.data)
            }
        }

    }

    useEffect(() => {
        
        if(formId)
        {
            getFormData()
        }

    }, [formId])

    if(!formId)
    {
        return <></>;
    }

    if(!Array.isArray(formData?.inputs))
    {
        return <></>;
    }

    return (
        <Stack>

            <Stack
                gap={3}
                minWidth={300}
            >

                {
                    formData?.inputs?.map((i, index) => {
                        
                        switch(i?.variant)
                        {
                            case inputTypes.textField:
                                return <CustomFormTextField item={i} index={index} />;

                            case inputTypes.inputArea:
                                return <CustomFormParagraphField item={i} index={index} />;

                            case inputTypes.radioButton:
                                return <CustomFormRadioFields item={i} index={index} />;

                            case inputTypes.checkbox:
                                return <CustomFormCheckboxFields item={i} index={index} />;

                            case inputTypes.dropDown:
                                return <CustomFormMenuFields item={i} index={index} />

                            default:
                                return <></>
                        }

                    })
                }

                <Button 
                    variant="outlined" 
                    color="info" 
                    onClick={() => {
                        submitForm()
                    }}
                >{props?.buttonLabel || 'Submit'}</Button>

            </Stack>

        </Stack>
    )
};

export default CustomForm;