import { customComponentTypes } from "../customComponents/types";
import {colors} from "./colors";
import { editorConfig } from "./editorConfig";

export const loginPageJson = [
    {
        id: 1,
        title: 'LoginForm',
        key: 'loginform1',
        path: "components/login/loginComponent1",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
        editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
        style: {},
        inputs: [
            {
                name: 'email',
                type: 'email',
                mandatory: true,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'email',
                label: 'Email address'
            },
            {
                name: 'password',
                type: 'password',
                mandatory: true,
                maxChar: 25,
                minChar: 5,
                secureEntry: true,
                key: 'password',
                label: 'Password'
            },
            {
                name: 'Phone number',
                type: 'phoneNumber',
                mandatory: false,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'phoneNumber',
                label: 'phoneNumber'
            }
        ]
    },
]

export const registerPageJson = [
    {
        id: 1,
        title: 'Register Component',
        key: 'registerComponent2',
        path: "components/register/registerComponent2",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
        editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
        style: {},
        inputs: [
            {
                name: 'email',
                type: 'email',
                mandatory: true,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'email',
                label: 'Email address'
            },
            {
                name: 'password',
                type: 'password',
                mandatory: true,
                maxChar: 25,
                minChar: 5,
                secureEntry: true,
                key: 'password',
                label: 'Password'
            },
            {
                name: 'address',
                type: 'address',
                mandatory: false,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'address',
                label: 'Address'
            },
            {
                name: 'city',
                type: 'city',
                mandatory: true,
                maxChar: 25,
                minChar: 5,
                secureEntry: true,
                key: 'city',
                label: 'city'
            },
            {
                name: 'area',
                type: 'area',
                mandatory: false,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'area',
                label: 'area'
            },
            {
                name: 'Phone number',
                type: 'phoneNumber',
                mandatory: false,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'phoneNumber',
                label: 'phoneNumber'
            },
            {
                name: 'User name',
                type: 'username',
                mandatory: false,
                maxChar: 25,
                minChar: 5,
                secureEntry: false,
                key: 'username',
                label: 'username'
            }
        ]
    },
]

export const homepageJson = [
    {
        id: 0,
        title: 'Header bar',
        key: 'headerbar1',
        path: "components/headerbar/headerbar1",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_bar_1.png',
        parentKey: 'headerbar',
        editorConfig: ['background'],
        style: {
            backgroundColor: '#7A3DF4'
        },
        icons: [
            {
                title: 'facebook',
                icon: 'facebook',
                link: 'https://demo.ekhool.com',
                key: 'header_bar_facebook_icon',
                parentKey: 'headerbar',
                editorConfig: ['typography'],
                style: {
                    typography: {
                        color: '#ffffff',
                        fontSize: {
                            value: 25,
                            ext: 'px'
                        }
                    },
                }
            },
            {
                title: 'instagram',
                icon: 'instagram',
                link: 'https://accenture.e-khool.com',
                key: 'header_bar_instagram_icon',
                parentKey: 'headerbar',
                editorConfig: ['typography'],
                style: {
                    typography: {
                        color: '#ffffff',
                        fontSize: {
                            value: 25,
                            ext: 'px'
                        }
                    },
                }
            }
        ]
    },
    {
        id: 7,
        title: 'Header',
        key: 'header1',
        path: "components/header/header",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
        editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
    },
    {
        id: 0,
        name: 'Banner',
        key: 'banner1',
        path: "components/root/banners/banner1",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_1.png',
        parentKey: 'banner',
        visible: true,
        editorConfig: [ 'banner', 'dimensions', 'margin', 'borderRadius'],
        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/101/data/images/banner/7fe234bfb86436114217b73ce9ffe376.jpg',
        style: {
            dimensions: {
                height: {
                    value: 32,
                    ext: 'vw'
                },
                width: {
                    value: 98,
                    ext: '%'
                },
                maxHeight: {
                    value: 32,
                    ext: 'vw'
                },
                maxWidth: {
                    value: 100,
                    ext: 'vw'
                }
            },
            typography: {
                fontFamily: 'Poppins',
                fontSize: {
                    value: 22,
                    ext: 'px'
                },
                letterSpacing: {
                    value: 2,
                    ext: 'px'
                },
                textAlign: 'left',
                textDecoration: 'none'
            },
            padding: {
                right: {
                    value: 0,
                    ext: 'px'
                },
                left: {
                    value: 0,
                    ext: 'px'
                },
                top: {
                    value: 0,
                    ext: 'px'
                },
                bottom: {
                    value: 0,
                    ext: 'px'
                }
            },
            margin: {
                right: {
                    value: 10,
                    ext: 'px'
                },
                left: {
                    value: 10,
                    ext: 'px'
                },
                top: {
                    value: 25,
                    ext: 'px'
                },
                bottom: {
                    value: 25,
                    ext: 'px'
                }
            },
            borderRadius: {
                topRight: {
                    value: 10,
                    ext: 'px'
                },
                topLeft: {
                    value: 10,
                    ext: 'px'
                },
                bottomRight: {
                    value: 10,
                    ext: 'px'
                },
                bottomLeft: {
                    value: 10,
                    ext: 'px'
                }
            }
        },
    },
    {
        id: 2,
        name: 'Programs',
        key: 'programs',
        path: "components/index/programs",
        visible: true,
        hasTab: true,
        editorConfig: [ 'margin', 'padding', 'typography' ],
        tab: {
            tabs: ['All', 'Programs', 'Bundles'],
            visible: true,
            key: 'programTab',
            editorConfig: ['dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],
            programSlug: 'Programs',
            bundleSlug: 'Bundles',
            style : {
                dimensions: {
                    height: {
                        value: 100,
                        ext: '%'
                    },
                    width: {
                        value: 160,
                        ext: 'px'
                    },
                    maxHeight: {
                        value: 32,
                        ext: 'vw'
                    },
                    maxWidth: {
                        value: 100,
                        ext: 'vw'
                    }
                },
                typography: {
                    fontFamily: 'Poppins',
                    fontSize: {
                        value: 22,
                        ext: 'px'
                    },
                    letterSpacing: {
                        value: 2,
                        ext: 'px'
                    },
                    textAlign: 'left',
                    textDecoration: 'none'
                },
                padding: {
                    right: {
                        value: 0,
                        ext: 'px'
                    },
                    left: {
                        value: 0,
                        ext: 'px'
                    },
                    top: {
                        value: 0,
                        ext: 'px'
                    },
                    bottom: {
                        value: 0,
                        ext: 'px'
                    }
                },
                margin: {
                    right: {
                        value: 5,
                        ext: 'px'
                    },
                    left: {
                        value: 5,
                        ext: 'px'
                    },
                    top: {
                        value: 0,
                        ext: 'px'
                    },
                    bottom: {
                        value: 0,
                        ext: 'px'
                    }
                },
                borderRadius: {
                    topRight: {
                        value: 100,
                        ext: 'px'
                    },
                    topLeft: {
                        value: 100,
                        ext: 'px'
                    },
                    bottomRight: {
                        value: 100,
                        ext: 'px'
                    },
                    bottomLeft: {
                        value: 100,
                        ext: 'px'
                    }
                },
                backgroundColor: colors.appPrimaryColor
            }
        },
        childrenConfig: [
            {
                id: 1,
                path: 'programcard/ProgramCard',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/101/data/images/banner/7fe234bfb86436114217b73ce9ffe376.jpg',
                key: 'program_card_1',
                name: 'Program card 1',
                unique_key: 'program_card',
                parentKey: 'programs'
            },
            {
                id: 2,
                path: 'programcard/ProgramCard',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/101/data/images/banner/7fe234bfb86436114217b73ce9ffe376.jpg',
                key: 'program_card_1',
                name: 'Program card 1',
                unique_key: 'bundle_card',
                parentKey: 'programs'
            }
        ],
        components : [
            {
                id: 1,
                path: 'programcard/ProgramCard',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/101/data/images/banner/7fe234bfb86436114217b73ce9ffe376.jpg',
                key: 'program_card_1',
                name: 'Program card 1',
                unique_key: 'program_card',
                parentKey: 'programs'
            },
            {
                id: 2,
                path: 'programcard/ProgramCard2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/70/data/images/program/28.webp?v=57',
                key: 'program_card_2',
                name: 'Program card 2',
                unique_key: 'program_card',
                parentKey: 'programs'
            }
        ],
        parentKey: 'programs',
        style : {
            typography: {
                fontFamily: 'Poppins',
                fontSize: {
                    value: 22,
                    ext: 'px'
                },
                letterSpacing: {
                    value: 2,
                    ext: 'px'
                },
                textAlign: 'left',
                textDecoration: 'none'
            },
            padding: {
                right: {
                    value: 0,
                    ext: 'px'
                },
                left: {
                    value: 0,
                    ext: 'px'
                },
                top: {
                    value: 0,
                    ext: 'px'
                },
                bottom: {
                    value: 0,
                    ext: 'px'
                }
            },
            margin: {
                right: {
                    value: 0,
                    ext: 'px'
                },
                left: {
                    value: 0,
                    ext: 'px'
                },
                top: {
                    value: 0,
                    ext: 'px'
                },
                bottom: {
                    value: 0,
                    ext: 'px'
                }
            }
        }
    },
    {
        id: 3,
        name: 'Top categories',
        key: 'top_categories1',
        path: 'components/root/topcategories/topcategories1',
        editorConfig: ['margin', 'padding', 'typography'],
        title: 'Top categories',
        parentKey: 'top_categories',
        style : {
            typography: {
                fontFamily: 'Poppins',
                fontSize: {
                    value: 22,
                    ext: 'px'
                },
                letterSpacing: {
                    value: 2,
                    ext: 'px'
                },
                textAlign: 'left',
                textDecoration: 'none',
                color: '#000000'
            },
            padding: {
                right: {
                    value: 0,
                    ext: 'px'
                },
                left: {
                    value: 0,
                    ext: 'px'
                },
                top: {
                    value: 0,
                    ext: 'px'
                },
                bottom: {
                    value: 0,
                    ext: 'px'
                }
            },
            margin: {
                right: {
                    value: 0,
                    ext: 'px'
                },
                left: {
                    value: 2,
                    ext: 'px'
                },
                top: {
                    value: 4,
                    ext: 'px'
                },
                bottom: {
                    value: 0,
                    ext: 'px'
                }
            },
            backgroundColor: colors.appPrimaryColor
        },
        child: {
            editorConfig: ['banner', 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius'],
            key: 'top_categories_child',
            style: {
                typography: {
                    fontFamily: 'Poppins',
                    fontSize: {
                        value: 22,
                        ext: 'px'
                    },
                    letterSpacing: {
                        value: 2,
                        ext: 'px'
                    },
                    textAlign: 'left',
                    textDecoration: 'none',
                    color: '#000000'
                },
                padding: {
                    right: {
                        value: 10,
                        ext: 'px'
                    },
                    left: {
                        value: 10,
                        ext: 'px'
                    },
                    top: {
                        value: 10,
                        ext: 'px'
                    },
                    bottom: {
                        value: 10,
                        ext: 'px'
                    }
                },
                margin: {
                    right: {
                        value: 10,
                        ext: 'px'
                    },
                    left: {
                        value: 10,
                        ext: 'px'
                    },
                    top: {
                        value: 10,
                        ext: 'px'
                    },
                    bottom: {
                        value: 10,
                        ext: 'px'
                    }
                },
                borderRadius: {
                    topRight: {
                        value: 2,
                        ext: 'px'
                    },
                    topLeft: {
                        value: 2,
                        ext: 'px'
                    },
                    bottomRight: {
                        value: 2,
                        ext: 'px'
                    },
                    bottomLeft: {
                        value: 2,
                        ext: 'px'
                    }
                },
                backgroundColor: '#ffffff'
            }
        }
    },
    {
        id: 4,
        name: 'CurrentAffairsHomeCard',
        key: 'current_affairs',
        path: "components/currentaffairs/currentaffairs",
        editorConfig: ['typography'],
        title: 'Current affairs',
        parentKey: 'current_affairs',
        style: {
            typography: {
                fontFamily: 'Poppins',
                fontSize: {
                    value: 22,
                    ext: 'px'
                },
                letterSpacing: {
                    value: 2,
                    ext: 'px'
                },
                textAlign: 'left',
                textDecoration: 'none',
                color: '#000000'
            },
        },
        children: [
            {
                title: 'Current affairs',
                editorConfig: ['typography'],
                style: {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000',
                        fontWeight: 700,
                        variant: 'h5'
                    },
                },
            },
            {
                title: 'Get an instant update of GK and insights of daily current affairs',
                editorConfig: ['typography'],
                style: {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000',
                        fontWeight: 600,
                        variant: 'body2'
                    },
                },
            },
            {
                title: 'Glimse of recent current events by the compilation of top faculties of Suresh Paadasaalai. Its completely free!!',
                editorConfig: ['typography'],
                style: {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000',
                        variant: 'caption'
                    },
                },
            },
        ],
        button: {
            name: 'Current affairs see all button',
            key: 'current_affairs_see_all_button',
            editorConfig: [ 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],
            title: 'See all',
            style: {
                typography: {
                    fontFamily: 'Poppins',
                    fontSize: {
                        value: 22,
                        ext: 'px'
                    },
                    letterSpacing: {
                        value: 2,
                        ext: 'px'
                    },
                    textAlign: 'left',
                    textDecoration: 'none',
                    color: '#000000'
                },
                padding: {
                    right: {
                        value: 12,
                        ext: 'px'
                    },
                    left: {
                        value: 12,
                        ext: 'px'
                    },
                    top: {
                        value: 10,
                        ext: 'px'
                    },
                    bottom: {
                        value: 10,
                        ext: 'px'
                    }
                },
                margin: {
                    right: {
                        value: 0,
                        ext: 'px'
                    },
                    left: {
                        value: 0,
                        ext: 'px'
                    },
                    top: {
                        value: 0,
                        ext: 'px'
                    },
                    bottom: {
                        value: 0,
                        ext: 'px'
                    }
                },
                borderRadius: {
                    topRight: {
                        value: 50,
                        ext: 'px'
                    },
                    topLeft: {
                        value: 50,
                        ext: 'px'
                    },
                    bottomRight: {
                        value: 50,
                        ext: 'px'
                    },
                    bottomLeft: {
                        value: 50,
                        ext: 'px'
                    }
                },
                backgroundColor: '#ffffff'
            }
        }
    },
    {
        id: 5,
        name: 'TopPrograms',
        key: 'top_programs',
        path: "components/home/TopPrograms",
        editorConfig: [ 'margin', 'typography' ],
        title: 'Top programs',
        style: {
            typography: {
                fontFamily: 'Poppins',
                fontSize: {
                    value: 22,
                    ext: 'px'
                },
                letterSpacing: {
                    value: 2,
                    ext: 'px'
                },
                textAlign: 'left',
                textDecoration: 'none',
                color: '#000000'
            },
            margin: {
                right: {
                    value: 0,
                    ext: 'px'
                },
                left: {
                    value: 0,
                    ext: 'px'
                },
                top: {
                    value: 0,
                    ext: 'px'
                },
                bottom: {
                    value: 0,
                    ext: 'px'
                }
            }
        },
        parentKey: 'top_programs',
    },
    {
        id: 6,
        name: 'Testimonials',
        key: 'testimonials',
        path: "components/index/testmonial",
        editorConfig: [ 'banner', 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],
        children: [
            {
                title: 'What people are saying',
                editorConfig: [ 'margin', 'typography'],
                style: {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000',
                        variant: 'h2',
                        fontWeight: 600
                    },
                }
            },
            {
                title: 'Ekhool has inspired thousands of people arround the world to learn.',
                editorConfig: [ 'margin', 'typography'],
                style: {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000',
                        variant: 'h4',
                        fontWeight: 400
                    },
                }
            }
        ],
        parentKey: 'testimonials',
    },
    {
        id: 8,
        title: 'Footer',
        key: 'footer1',
        path: "components/footer/footer",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/footer_1.png',
        editorConfig: ['background', 'typography'],
        style: {
            backgroundColor: '#ffffff',
            typography: {
                fontFamily: 'Poppins',
                fontSize: {
                    value: 22,
                    ext: 'px'
                },
                letterSpacing: {
                    value: 2,
                    ext: 'px'
                },
                textAlign: 'left',
                textDecoration: 'none',
                color: '#000000'
            },
        },
        parentKey: 'footer1',
        address: '2nd Floor,Rathi Plaza, opp. gov Hospital, Thuckalay, Tamil Nadu 629175',
        email: 'ekool@resbee.org',
        phone: '9952224098'
    }
]

export const extraComponentsOld = [
    {
        id: 13,
        name: 'SocialMedia',
        key: 'social_media',
        children: [
            {
                id: 1,
                title: 'Social Media',
                key: 'social_media1',
                path: "components/socialMedia/socialMediaComponent1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'typography', 'background'],
                style: {
                    backgroundColor: '#fff',
                    typography: {
                        color: '#000'
                    },
                },
                screens: ['login', 'register', 'home'],
                icons: [
                    {
                        link: 'https://www.youtube.com/',
                        icon: 'vector-selection',
                        key: 'header_bar_facebook_icon',
                        parentKey: 'headerbar',
                        editorConfig: ['typography'],
                        style: {
                            typography: {
                                color: '#ffffff',
                                fontSize: {
                                    value: 25,
                                    ext: 'px'
                                }
                            },
                        }
                    }
                ]
            },
        ]
    },
    {
        id: 13,
        name: 'Ticker',
        key: 'ticker',
        children: [
            {
                id: 1,
                title: 'Ticker',
                key: 'ticker1',
                path: "components/ticker/ticker1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'typography', 'background', 'ticker'],
                style: {
                    backgroundColor: '#fff',
                    typography: {
                        color: '#000'
                    },
                    dimensions: {
                        height: {
                            value: 32,
                            ext: 'px'
                        },
                        width: {
                            value: 98,
                            ext: '%'
                        },
                        maxHeight: {
                            value: 32,
                            ext: 'px'
                        },
                        maxWidth: {
                            value: 100,
                            ext: 'vw'
                        }
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 25,
                            ext: 'px'
                        },
                        bottom: {
                            value: 25,
                            ext: 'px'
                        }
                    },
                },
                screens: ['login', 'register', 'home'],
                data: [
                    {
                        id: 0,
                        content: 'e-khool LMS software provide AI integrated platform in web, tab and mobile.',
                        link: ''
                    },
                    {
                        id: 0,
                        content: 'We are serving 200+ online academies to conduct their courses online.',
                        link: ''
                    }
                ]
            },
        ]
    },
    {
        id: 13,
        name: 'Login',
        key: 'login',
        children: [
            {
                id: 1,
                title: 'LoginForm',
                key: 'loginform1',
                path: "components/login/loginComponent1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
                style: {},
                inputs: [
                    {
                        name: 'Phone number',
                        type: 'phoneNumber',
                        mandatory: false,
                        maxChar: 25,
                        minChar: 5,
                        secureEntry: false,
                        key: 'phoneNumber',
                        label: 'phoneNumber'
                    }
                ],
                screens: ['login', 'register'],
            },
            {
                id: 1,
                title: 'LoginForm',
                key: 'loginform2',
                path: "components/login/loginComponent2",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
                style: {},
                inputs: [
                    {
                        name: 'Phone number',
                        type: 'phoneNumber',
                        mandatory: false,
                        maxChar: 25,
                        minChar: 5,
                        secureEntry: false,
                        key: 'phoneNumber',
                        label: 'phoneNumber'
                    }
                ],
                screens: ['login', 'register'],
            },
        ]
    },
    {
        id: 12,
        name: 'Promotion banner',
        key: 'promotion_banner',
        children: [
            {
                id: 0,
                name: 'Promotion banner 1',
                key: 'promotion_banner_1',
                path: 'components/programPromotion/programpromotion1',
                title: 'Program name here',
                description: 'Program description here',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                editorConfig: ['background', 'typography', 'singleprogram'],
                style: {
                    backgroundColor: '#00AB4F',
                    typography: {
                        color: '#fff'
                    }
                },
                screens: ['login', 'register', 'home'],
                programId: null
            }
        ]
    },
    {
        id: 11,
        name: 'About',
        key: 'about',
        children: [
            {
                id: 0,
                name: 'About 1',
                key: 'about1',
                path: "components/about/about1",
                title: 'Future-ready skills on your schedule | Learn on iOS, Android, and more.',
                description: 'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table.',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'about',
                editorConfig: ['background'],
                style: {
                    backgroundColor: 'red',
                    typography: {
                        color: '#4b4b4b'
                    }
                },
                screens: ['login', 'register', 'home'],
                children: [
                    {
                        id: 1,
                        icon: '',
                        title: 'Pre-Built Websites',
                        description: 'Reduce website design time by using pixel perfect ready to use website demos from our library of starter templates.'
                    },
                    {
                        id: 2,
                        icon: '',
                        title: 'Customize Without Code',
                        description: 'Change the design through a range of options in the WordPress Customizer. No coding knowledge necessary!'
                    },
                    {
                        id: 3,
                        icon: '',
                        title: 'Made for Page Builders',
                        description: 'Change the design through a range of options in the WordPress Customizer. No coding knowledge necessary!'
                    },
                    {
                        id: 4,
                        icon: '',
                        title: 'Super Fast Performance',
                        description: 'Astra is made for speed. It is the most lightweight theme available in the market and offers unmatched performance.'
                    },
                    {
                        id: 5,
                        icon: '',
                        title: 'Pre-Built Websites',
                        description: 'Reduce website design time by using pixel perfect ready to use website demos from our library of starter templates.'
                    },
                    {
                        id: 6,
                        icon: '',
                        title: 'Customize Without Code',
                        description: 'Change the design through a range of options in the WordPress Customizer. No coding knowledge necessary!'
                    },
                ],
            },
            {
                id: 0,
                name: 'About 2',
                key: 'about2',
                path: "components/about/about2",
                title: 'Future-ready skills on your schedule | Learn on iOS, Android, and more.',
                description: 'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table.',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'about',
                editorConfig: ['background'],
                style: {
                    backgroundColor: 'red',
                    typography: {
                        color: '#4b4b4b'
                    }
                },
                screens: ['login', 'register', 'home']
            }
        ]
    },
    {
        id: 10,
        name: 'Heros',
        key: 'heros',
        children: [
            {
                id: 0,
                name: 'Hero 1',
                key: 'hero1',
                path: "components/heros/hero1",
                title: 'Future-ready skills on your schedule | Learn on iOS, Android, and more.',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'heros',
                editorConfig: ['background'],
                style: {
                    backgroundColor: 'red',
                    typography: {
                        color: '#4b4b4b'
                    }
                },
                screens: ['login', 'register', 'home'],
                children: [
                    {
                        id: 1,
                        image_url: 'https://raw.githubusercontent.com/bavith-ekhool/demo/main/acadcsdsdsd.png'
                    },
                    {
                        id: 2,
                        image_url: 'https://raw.githubusercontent.com/bavith-ekhool/demo/main/dcsdcsdsdsd.png'
                    },
                    {
                        id: 3,
                        image_url: 'https://raw.githubusercontent.com/bavith-ekhool/demo/main/ddcsdcsd.png'
                    },
                    {
                        id: 4,
                        image_url: 'https://raw.githubusercontent.com/bavith-ekhool/demo/main/dfadfdfsd.png'
                    },
                    {
                        id: 5,
                        image_url: 'https://raw.githubusercontent.com/bavith-ekhool/demo/main/dcsdcsdsdsd.png'
                    }
                ],
            },
            {
                id: 1,
                name: 'Hero',
                key: 'hero3',
                path: "components/heros/hero3",
                title: 'Gain an advantage over others and transform your life with a very valuable skill: the power of languages. At present, individuals all around the world wish to prosper. If you want to learn new languages, discover diverse cultures, and be a part of the most vibrant group, CommCult is the place to be!',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'heros',
                editorConfig: ['background'],
                style: {
                    backgroundColor: 'red',
                    typography: {
                        color: '#4b4b4b'
                    }
                },
                screens: ['login', 'register', 'home'],
                image: 'https://cdn.pixabay.com/photo/2019/10/09/07/28/development-4536630_960_720.png'
            },
            {
                id: 1,
                name: 'Hero',
                key: 'hero4',
                path: "components/heros/hero4",
                title: 'Gain an advantage over others and transform your life with a very valuable skill: the power of languages. At present, individuals all around the world wish to prosper. If you want to learn new languages, discover diverse cultures, and be a part of the most vibrant group, CommCult is the place to be!',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'heros',
                editorConfig: ['background'],
                style: {
                    backgroundColor: 'red',
                    typography: {
                        color: '#4b4b4b'
                    }
                },
                screens: ['login', 'register', 'home'],
                image: 'https://cdn.pixabay.com/photo/2019/10/09/07/28/development-4536630_960_720.png'
            }
        ]
    },
    {
        id: 7,
        name: 'Marketing',
        key: 'marketing',
        children: [
            {
                id: 0,
                name: 'Header Announcement',
                key: 'header_announcement_1',
                path: "components/headerAnnouncement/headerAnnouncement",
                title: 'Future-ready skills on your schedule | Learn on iOS, Android, and more.',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'banner_popup',
                config: {
                    start_date: null,
                    end_date: null,
                    can_dismiss: true
                },
                editorConfig: ['calendar'],
                style: {
                    backgroundColor: 'red',
                    shadow: '0px 16px 32px 0px rgba(0,0,0,0.2)',
                    typography: {
                        color: '#ffffff'
                    }
                },
                screens: ['login', 'register', 'home']
            }
        ]
    },
    {
        id: 7,
        name: 'Popup',
        key: 'banner_popup',
        children: [
            {
                id: 0,
                name: 'Banner Popup',
                key: 'banner_popup_1',
                path: "components/popup/BannerPopup",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'banner_popup',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
                style: {
                    backgroundColor: 'red',
                    shadow: '0px 16px 32px 0px rgba(0,0,0,0.2)'
                },
                screens: ['home']
            }
        ]
    },
    {
        id: 8,
        name: 'Slider',
        key: 'slider',
        children: [
            {
                id: 0,
                name: 'Slider',
                key: 'slider1',
                path: "components/slider/slider1",
                editorConfig: ['slider', 'padding'],
                sliderConfig: {
                    canSwipe: false,
                    arrows: false,
                    indicators: false,
                    infinite: false,
                    duration: 2000,
                    trasitionDuration: 500
                },
                children: [
                    {
                        id: 1,
                        title: 'Founder message',
                        description: 'Welcome to Suresh Paadasaalai! I feel privileged to be able to connect to you all. I believe that your time at Suresh Paadasaalai! is going to be the most pleasant learning experience ever. Your successful career will start from here.',
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/1/data/images/suresh1.webp?v=3'
                    },
                    {
                        id: 2,
                        title: 'Our Mission',
                        description: 'Our mission is to provide a mentored learning program that focuses on each individual learning and provides the student with a personalized guidance and mentoring feedback on a regular basis.',
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/1/data/images/suresh2.webp?v=3'
                    }
                ],
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/stats_1.png',
                parentKey: 'stats',
                child: {
                    editorConfig: ['dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius'],
                    key: 'slider1_child',
                    style: {
                        typography: {
                            fontFamily: 'Poppins',
                            fontSize: {
                                value: 22,
                                ext: 'px'
                            },
                            letterSpacing: {
                                value: 2,
                                ext: 'px'
                            },
                            textAlign: 'left',
                            textDecoration: 'none',
                            color: '#000000'
                        },
                        padding: {
                            right: {
                                value: 50,
                                ext: 'px'
                            },
                            left: {
                                value: 50,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        margin: {
                            right: {
                                value: 0,
                                ext: 'px'
                            },
                            left: {
                                value: 0,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        borderRadius: {
                            topRight: {
                                value: 10,
                                ext: 'px'
                            },
                            topLeft: {
                                value: 10,
                                ext: 'px'
                            },
                            bottomRight: {
                                value: 10,
                                ext: 'px'
                            },
                            bottomLeft: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        backgroundColor: '#ff9d9d'
                    }
                },
                style: {
                    backgroundColor: '#4d56ff',
                    padding: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 10,
                            ext: 'px'
                        },
                        bottom: {
                            value: 10,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 10,
                            ext: 'px'
                        },
                        bottom: {
                            value: 10,
                            ext: 'px'
                        }
                    },
                    borderRadius: {
                        topRight: {
                            value: 2,
                            ext: 'px'
                        },
                        topLeft: {
                            value: 2,
                            ext: 'px'
                        },
                        bottomRight: {
                            value: 2,
                            ext: 'px'
                        },
                        bottomLeft: {
                            value: 2,
                            ext: 'px'
                        }
                    },
                },
                screens: ['home']
            }
        ],
    },
    {
        id: 7,
        name: 'Header',
        key: 'header',
        children: [
            {
                id: 0,
                title: 'Header',
                key: 'header1',
                path: "components/header/header",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'header',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
                style: {
                    backgroundColor: 'red',
                    shadow: '0px 16px 32px 0px rgba(0,0,0,0.2)'
                },
                screens: ['login', 'register', 'home']
            },
            {
                id: 2,
                title: 'Header',
                key: 'header2',
                path: "components/header/header2",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'header',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
                style: {
                    backgroundColor: 'red',
                    shadow: '0px 16px 32px 0px rgba(0,0,0,0.2)'
                },
                screens: ['login', 'register', 'home'],
                headerButtons: [
                    {
                        id: 1,
                        url: 'https://demo.ekhool.com/',
                        title: 'Starter Templates',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 2,
                        url: 'https://demo.ekhool.com/',
                        title: 'Pro',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 3,
                        url: 'https://demo.ekhool.com/',
                        title: 'Features',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 4,
                        url: 'https://demo.ekhool.com/',
                        title: 'WooCommerce',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 5,
                        url: 'https://demo.ekhool.com/',
                        title: 'Testimonials',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 6,
                        url: 'https://demo.ekhool.com/',
                        title: 'Pricing',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    }
                ]
            },
            {
                id: 3,
                title: 'Header',
                key: 'header4',
                path: "components/header/header4",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'header',
                editorConfig: [ 'dimensions', 'margin', 'padding', 'shadow'],
                style: {
                    backgroundColor: 'red',
                    shadow: '0px 16px 32px 0px rgba(0,0,0,0.2)'
                },
                screens: ['login', 'register', 'home'],
                headerButtons: [
                    {
                        id: 1,
                        url: 'https://demo.ekhool.com/',
                        title: 'Starter Templates',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 2,
                        url: 'https://demo.ekhool.com/',
                        title: 'Pro',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 3,
                        url: 'https://demo.ekhool.com/',
                        title: 'Features',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 4,
                        url: 'https://demo.ekhool.com/',
                        title: 'WooCommerce',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 5,
                        url: 'https://demo.ekhool.com/',
                        title: 'Testimonials',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    },
                    {
                        id: 6,
                        url: 'https://demo.ekhool.com/',
                        title: 'Pricing',
                        style: {
                            typography: {
                                color: '#fff'
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        id: 8,
        name: 'Program Listing',
        key: 'program_listing',
        children: [
            {
                id: 0,
                title: 'Program Listing',
                key: 'program_listing',
                path: "components/programlisting/programlisting",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'program_listing',
                editorConfig: ['programs'],
                style: {
                    backgroundColor: 'red'
                },
                programs: [],
                screens: ['home']
            }
        ]
    },
    {
        id: 9,
        name: 'Current affairs',
        key: 'current_affairs',
        children: [
            {
                id: 4,
                name: 'CurrentAffairsHomeCard',
                key: 'current_affairs',
                path: "components/currentaffairs/currentaffairs",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                editorConfig: ['typography', 'padding'],
                title: 'Current affairs',
                parentKey: 'current_affairs',
                style: {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000'
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                },
                children: [
                    {
                        title: 'Current affairs',
                        editorConfig: ['typography'],
                        style: {
                            typography: {
                                fontFamily: 'Poppins',
                                fontSize: {
                                    value: 22,
                                    ext: 'px'
                                },
                                letterSpacing: {
                                    value: 2,
                                    ext: 'px'
                                },
                                textAlign: 'left',
                                textDecoration: 'none',
                                color: '#000000',
                                fontWeight: 700,
                                variant: 'h5'
                            },
                        },
                    },
                    {
                        title: 'Get an instant update of GK and insights of daily current affairs',
                        editorConfig: ['typography'],
                        style: {
                            typography: {
                                fontFamily: 'Poppins',
                                fontSize: {
                                    value: 22,
                                    ext: 'px'
                                },
                                letterSpacing: {
                                    value: 2,
                                    ext: 'px'
                                },
                                textAlign: 'left',
                                textDecoration: 'none',
                                color: '#000000',
                                fontWeight: 600,
                                variant: 'body2'
                            },
                        },
                    },
                    {
                        title: 'Glimse of recent current events by the compilation of top faculties of Suresh Paadasaalai. Its completely free!!',
                        editorConfig: ['typography'],
                        style: {
                            typography: {
                                fontFamily: 'Poppins',
                                fontSize: {
                                    value: 22,
                                    ext: 'px'
                                },
                                letterSpacing: {
                                    value: 2,
                                    ext: 'px'
                                },
                                textAlign: 'left',
                                textDecoration: 'none',
                                color: '#000000',
                                variant: 'caption'
                            },
                        },
                    },
                ],
                button: {
                    name: 'Current affairs see all button',
                    key: 'current_affairs_see_all_button',
                    editorConfig: [ 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],
                    title: 'See all',
                    style: {
                        typography: {
                            fontFamily: 'Poppins',
                            fontSize: {
                                value: 22,
                                ext: 'px'
                            },
                            letterSpacing: {
                                value: 2,
                                ext: 'px'
                            },
                            textAlign: 'left',
                            textDecoration: 'none',
                            color: '#000000'
                        },
                        padding: {
                            right: {
                                value: 12,
                                ext: 'px'
                            },
                            left: {
                                value: 12,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        margin: {
                            right: {
                                value: 0,
                                ext: 'px'
                            },
                            left: {
                                value: 0,
                                ext: 'px'
                            },
                            top: {
                                value: 0,
                                ext: 'px'
                            },
                            bottom: {
                                value: 0,
                                ext: 'px'
                            }
                        },
                        borderRadius: {
                            topRight: {
                                value: 50,
                                ext: 'px'
                            },
                            topLeft: {
                                value: 50,
                                ext: 'px'
                            },
                            bottomRight: {
                                value: 50,
                                ext: 'px'
                            },
                            bottomLeft: {
                                value: 50,
                                ext: 'px'
                            }
                        },
                        backgroundColor: '#ffffff'
                    }
                },
                screens: ['home']
            },
        ]
    },
    {
        id: 6,
        name: 'Header bar',
        key: 'headerbar1',
        path: "components/headerbar/headerbar1",
        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_bar_1.png',
        parentKey: 'headerbar',
        editorConfig: [ ],
        children: [
            {
                id: 0,
                title: 'Header bar',
                key: 'headerbar1',
                path: "components/headerbar/headerbar1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_bar_1.png',
                parentKey: 'headerbar',
                editorConfig: [ 'banner', 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],
                style: {
                    backgroundColor: 'red'
                },
                icons: [
                    {
                        title: 'facebook',
                        icon: 'facebook',
                        link: 'https://demo.ekhool.com',
                        key: 'header_bar_facebook_icon',
                        parentKey: 'headerbar',
                        editorConfig: ['typography'],
                        style: {
                            typography: {
                                color: '#ffffff',
                                fontSize: {
                                    value: 25,
                                    ext: 'px'
                                }
                            },
                        }
                    },
                    {
                        title: 'instagram',
                        icon: 'instagram',
                        link: 'https://accenture.e-khool.com',
                        key: 'header_bar_instagram_icon',
                        parentKey: 'headerbar',
                        editorConfig: ['typography'],
                        style: {
                            typography: {
                                color: '#ffffff',
                                fontSize: {
                                    value: 25,
                                    ext: 'px'
                                }
                            },
                        }
                    }
                ],
                screens: ['login', 'register', 'home']
            }
        ]
    },
    {
        id: 0,
        name: 'Banner',
        key: 'banner',
        children: [
            {
                id: 0,
                name: 'Banner',
                key: 'banner1',
                path: "components/root/banners/banner1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_1.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [ 'banner', 'dimensions', 'margin', 'borderRadius'],
                image_url: 'https://lwfiles.mycourse.app/629d23be2ed929780cb2e82e-public/3eb2c525059bb95f2c4f61a233ebc781.png',
                screens: ['login', 'register', 'home']
            },
            {
                id: 0,
                name: 'Banner',
                key: 'banner1',
                path: "components/root/banners/banner1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_1.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [ 'banner', 'dimensions', 'margin', 'borderRadius'],
                image_url: 'https://lwfiles.mycourse.app/629d23be2ed929780cb2e82e-public/3eb2c525059bb95f2c4f61a233ebc781.png',
                style: {
                    dimensions: {
                        height: {
                            value: 32,
                            ext: 'vw'
                        },
                        width: {
                            value: 98,
                            ext: '%'
                        },
                        maxHeight: {
                            value: 32,
                            ext: 'vw'
                        },
                        maxWidth: {
                            value: 100,
                            ext: 'vw'
                        }
                    },
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none'
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 25,
                            ext: 'px'
                        },
                        bottom: {
                            value: 25,
                            ext: 'px'
                        }
                    },
                    borderRadius: {
                        topRight: {
                            value: 10,
                            ext: 'px'
                        },
                        topLeft: {
                            value: 10,
                            ext: 'px'
                        },
                        bottomRight: {
                            value: 10,
                            ext: 'px'
                        },
                        bottomLeft: {
                            value: 10,
                            ext: 'px'
                        }
                    }
                },
                screens: ['login', 'register', 'home']
            },
            {
                id: 1,
                name: 'Banner',
                key: 'banner2',
                path: "components/root/banners/banner2",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_2.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [ 'banner', 'dimensions', 'margin', 'borderRadius' ],
                screens: ['login', 'register', 'home'],
                children: [
                    {
                        id: 0,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/101/data/images/banner/7fe234bfb86436114217b73ce9ffe376.jpg'
                    },
                    {
                        id: 2,
                        image_url: 'https://d1n2g0pp9xoy51.cloudfront.net/uploads/70/data/images/banner/beca949964042ec7621e71a3fca8200e.png'
                    },
                    {
                        id: 3,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/uploads/101/data/images/banner/7fe234bfb86436114217b73ce9ffe376.jpg'
                    }
                ],
                style: {
                    dimensions: {
                        height: {
                            value: 32,
                            ext: 'vw'
                        },
                        width: {
                            value: 98,
                            ext: '%'
                        },
                        maxHeight: {
                            value: 32,
                            ext: 'vw'
                        },
                        maxWidth: {
                            value: 100,
                            ext: 'vw'
                        }
                    },
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none'
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 25,
                            ext: 'px'
                        },
                        bottom: {
                            value: 25,
                            ext: 'px'
                        }
                    },
                    borderRadius: {
                        topRight: {
                            value: 10,
                            ext: 'px'
                        },
                        topLeft: {
                            value: 10,
                            ext: 'px'
                        },
                        bottomRight: {
                            value: 10,
                            ext: 'px'
                        },
                        bottomLeft: {
                            value: 10,
                            ext: 'px'
                        }
                    }
                }
            },
            {
                id: 2,
                name: 'Banner',
                key: 'banner3',
                path: "components/root/banners/banner3",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_3.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [],
                children: [ ],
                screens: ['login', 'register', 'home'],
                title: 'Before they sold out readymade gluten',
                description: 'Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.',
                button: {
                    name: 'Button',
                    key: 'banner3_button',
                    editorConfig: [ 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius' ],
                    title: 'View more',
                    style: {
                        typography: {
                            fontFamily: 'Poppins',
                            fontSize: {
                                value: 22,
                                ext: 'px'
                            },
                            letterSpacing: {
                                value: 2,
                                ext: 'px'
                            },
                            textAlign: 'left',
                            textDecoration: 'none',
                            color: '#000000'
                        },
                        padding: {
                            right: {
                                value: undefined,
                                ext: 'px'
                            },
                            left: {
                                value: undefined,
                                ext: 'px'
                            },
                            top: {
                                value: undefined,
                                ext: 'px'
                            },
                            bottom: {
                                value: undefined,
                                ext: 'px'
                            }
                        },
                        margin: {
                            right: {
                                value: 0,
                                ext: 'px'
                            },
                            left: {
                                value: 0,
                                ext: 'px'
                            },
                            top: {
                                value: 0,
                                ext: 'px'
                            },
                            bottom: {
                                value: 0,
                                ext: 'px'
                            }
                        },
                        borderRadius: {
                            topRight: {
                                value: 6,
                                ext: 'px'
                            },
                            topLeft: {
                                value: 6,
                                ext: 'px'
                            },
                            bottomRight: {
                                value: 6,
                                ext: 'px'
                            },
                            bottomLeft: {
                                value: 6,
                                ext: 'px'
                            }
                        },
                        backgroundColor: 'indigo'
                    }
                }
            },
            {
                id: 3,
                name: 'Banner',
                key: 'banner4',
                path: "components/root/banners/banner4",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_4.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [ 'background', 'typography' ],
                screens: ['login', 'register', 'home'],
                children: [ ],
                title: 'Before they sold out readymade gluten',
                description: 'Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.',
                style: {
                    backgroundColor: '#1e40af',
                    typography: {
                        color: '#ffffff'
                    }
                }
            },
            {
                id: 4,
                name: 'Banner',
                key: 'banner5',
                path: "components/root/banners/banner5",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_5.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [ 'background' ],
                children: [ ],
                title: 'Let us find your',
                screens: ['login', 'register', 'home'],
                description: 'Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.',
                style: {
                    backgroundColor: '#1e40af'
                }
            },
            {
                id: 0,
                name: 'Banner',
                key: 'banner6',
                path: "components/root/banners/banner6",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/banner_1.png',
                parentKey: 'banner',
                visible: true,
                editorConfig: [ 'banner', 'dimensions', 'margin', 'borderRadius'],
                image_url: 'https://lwfiles.mycourse.app/629d23be2ed929780cb2e82e-public/3eb2c525059bb95f2c4f61a233ebc781.png',
                style: {
                    dimensions: {
                        height: {
                            value: 32,
                            ext: 'vw'
                        },
                        width: {
                            value: 98,
                            ext: '%'
                        },
                        maxHeight: {
                            value: 32,
                            ext: 'vw'
                        },
                        maxWidth: {
                            value: 100,
                            ext: 'vw'
                        }
                    },
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none'
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 25,
                            ext: 'px'
                        },
                        bottom: {
                            value: 25,
                            ext: 'px'
                        }
                    },
                    borderRadius: {
                        topRight: {
                            value: 10,
                            ext: 'px'
                        },
                        topLeft: {
                            value: 10,
                            ext: 'px'
                        },
                        bottomRight: {
                            value: 10,
                            ext: 'px'
                        },
                        bottomLeft: {
                            value: 10,
                            ext: 'px'
                        }
                    }
                },
                screens: ['login', 'register', 'home']
            },
        ]
    },
    {
        id: 1,
        name: 'Top categories',
        key: 'top_categories',
        children: [
            {
                id: 0,
                title: 'Top categories',
                key: 'top_categories1',
                path: "components/root/topcategories/topcategories1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/top_categories_1.png',
                parentKey: 'top_categories',
                style : {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000'
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 2,
                            ext: 'px'
                        },
                        top: {
                            value: 4,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    backgroundColor: colors.appPrimaryColor
                },
                child: {
                    editorConfig: ['banner', 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius'],
                    key: 'top_categories_child',
                    style: {
                        typography: {
                            fontFamily: 'Poppins',
                            fontSize: {
                                value: 22,
                                ext: 'px'
                            },
                            letterSpacing: {
                                value: 2,
                                ext: 'px'
                            },
                            textAlign: 'left',
                            textDecoration: 'none',
                            color: '#000000'
                        },
                        padding: {
                            right: {
                                value: 10,
                                ext: 'px'
                            },
                            left: {
                                value: 10,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        margin: {
                            right: {
                                value: 10,
                                ext: 'px'
                            },
                            left: {
                                value: 10,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        borderRadius: {
                            topRight: {
                                value: 2,
                                ext: 'px'
                            },
                            topLeft: {
                                value: 2,
                                ext: 'px'
                            },
                            bottomRight: {
                                value: 2,
                                ext: 'px'
                            },
                            bottomLeft: {
                                value: 2,
                                ext: 'px'
                            }
                        },
                        backgroundColor: '#ffffff'
                    }
                },
                screens: ['home']
            },
            {
                id: 1,
                title: 'Top categories',
                key: 'top_categories2',
                path: "components/root/topcategories/topcategories2",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/top_categories_2.png',
                parentKey: 'top_categories',
                style : {
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000'
                    },
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 2,
                            ext: 'px'
                        },
                        top: {
                            value: 4,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    backgroundColor: colors.appPrimaryColor
                },
                child: {
                    editorConfig: ['banner', 'dimensions', 'margin', 'padding', 'typography', 'background', 'borderRadius'],
                    key: 'top_categories_child',
                    style: {
                        typography: {
                            fontFamily: 'Poppins',
                            fontSize: {
                                value: 22,
                                ext: 'px'
                            },
                            letterSpacing: {
                                value: 2,
                                ext: 'px'
                            },
                            textAlign: 'left',
                            textDecoration: 'none',
                            color: '#000000'
                        },
                        padding: {
                            right: {
                                value: 10,
                                ext: 'px'
                            },
                            left: {
                                value: 10,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        margin: {
                            right: {
                                value: 10,
                                ext: 'px'
                            },
                            left: {
                                value: 10,
                                ext: 'px'
                            },
                            top: {
                                value: 10,
                                ext: 'px'
                            },
                            bottom: {
                                value: 10,
                                ext: 'px'
                            }
                        },
                        borderRadius: {
                            topRight: {
                                value: 2,
                                ext: 'px'
                            },
                            topLeft: {
                                value: 2,
                                ext: 'px'
                            },
                            bottomRight: {
                                value: 2,
                                ext: 'px'
                            },
                            bottomLeft: {
                                value: 2,
                                ext: 'px'
                            }
                        },
                        backgroundColor: '#ffffff'
                    }
                },
                screens: ['home']
            }
        ]
    },
    {
        id: 2,
        name: 'Stats',
        key: 'stats',
        children: [
            {
                id: 0,
                name: 'Stats',
                key: 'stats1',
                path: "components/stats/stats",
                editorConfig: ['background', 'margin', 'padding', 'borderRadius', 'stats'],
                children: [
                    {
                        id: 1,
                        title: 'TNPSC',
                        description: '4972',
                        image_url: 'http://sureshpaadasaalai.com/themes/suresh/static/media/tnpsc.8752debd.webp'
                    },
                    {
                        id: 2,
                        title: 'BANKING',
                        description: '6124',
                        image_url: 'http://sureshpaadasaalai.com/themes/suresh/static/media/bank.751ddf14.webp'
                    },
                    {
                        id: 3,
                        title: 'SSC',
                        description: '340',
                        image_url: 'http://sureshpaadasaalai.com/themes/suresh/static/media/ssc.8b594a17.webp'
                    },
                    {
                        id: 4,
                        title: 'RAILWAY',
                        description: '2863',
                        image_url: 'http://sureshpaadasaalai.com/themes/suresh/static/media/railway.ed4521b2.webp'
                    },
                    {
                        id: 5,
                        title: 'POLICE',
                        description: '3129',
                        image_url: 'http://sureshpaadasaalai.com/themes/suresh/static/media/railway.ed4521b2.webp'
                    },
                    {
                        id: 5,
                        title: 'TRB/TET',
                        description: '2260',
                        image_url: 'http://sureshpaadasaalai.com/themes/suresh/static/media/tet.0af9ea6b.webp'
                    }
                ],
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/stats_1.png',
                parentKey: 'stats',
                style: {
                    backgroundColor: '#4d56ff',
                    padding: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 10,
                            ext: 'px'
                        },
                        bottom: {
                            value: 10,
                            ext: 'px'
                        }
                    },
                    margin: {
                        right: {
                            value: 10,
                            ext: 'px'
                        },
                        left: {
                            value: 10,
                            ext: 'px'
                        },
                        top: {
                            value: 10,
                            ext: 'px'
                        },
                        bottom: {
                            value: 10,
                            ext: 'px'
                        }
                    },
                    borderRadius: {
                        topRight: {
                            value: 2,
                            ext: 'px'
                        },
                        topLeft: {
                            value: 2,
                            ext: 'px'
                        },
                        bottomRight: {
                            value: 2,
                            ext: 'px'
                        },
                        bottomLeft: {
                            value: 2,
                            ext: 'px'
                        }
                    },
                },
                screens: ['home']
            }
        ]
    },
    {
        id: 3,
        name: 'Category Programs',
        key: 'category_programs',
        children: [
            {
                id: 1,
                name: 'Category Programs',
                key: 'category_programs_1',
                path: "components/categoryPrograms/categoryPrograms1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/category_programs_1.png',
                parentKey: 'category_programs',
                visible: true,
                editorConfig: [ 'background' ],
                style: {
                    backgroundColor: '#000'
                },
                childrenConfig: [
                    {
                        id: 1,
                        path: 'programcard/ProgramCard',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/program_card_1.png',
                        key: 'program_card_1',
                        name: 'Program card 1',
                        unique_key: 'program_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 2,
                        path: 'bundlecard/bundlecard2',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/bundle_card_2.png',
                        key: 'bundle_card_2',
                        name: 'Bundle card 2',
                        unique_key: 'bundle_card',
                        parentKey: 'category_programs'
                    }
                ],
                components : [
                    {
                        id: 1,
                        path: 'programcard/ProgramCard',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/program_card_1.png',
                        key: 'program_card_1',
                        name: 'Program card 1',
                        unique_key: 'program_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 2,
                        path: 'programcard/ProgramCard2',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/program_card_1.png',
                        key: 'program_card_2',
                        name: 'Program card 2',
                        unique_key: 'program_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 3,
                        path: 'bundlecard/bundlecard2',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/bundle_card_1.png',
                        key: 'bundle_card_2',
                        name: 'Bundle card 2',
                        unique_key: 'bundle_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 3,
                        path: 'bundlecard/bundlecard3',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/bundle_card_2.png',
                        key: 'bundle_card_3',
                        name: 'Bundle card 3',
                        unique_key: 'bundle_card',
                        parentKey: 'category_programs'
                    }
                ],
                screens: ['home']
            },
            {
                id: 1,
                name: 'Category Programs',
                key: 'category_programs_1',
                path: "components/categoryPrograms/categoryprograms2",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/category_programs_1.png',
                parentKey: 'category_programs',
                visible: true,
                editorConfig: [ 'background' ],
                style: {
                    backgroundColor: '#000'
                },
                childrenConfig: [
                    {
                        id: 1,
                        path: 'programcard/ProgramCard',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/program_card_1.png',
                        key: 'program_card_1',
                        name: 'Program card 1',
                        unique_key: 'program_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 2,
                        path: 'bundlecard/bundlecard2',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/bundle_card_2.png',
                        key: 'bundle_card_2',
                        name: 'Bundle card 2',
                        unique_key: 'bundle_card',
                        parentKey: 'category_programs'
                    }
                ],
                components : [
                    {
                        id: 1,
                        path: 'programcard/ProgramCard',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/program_card_1.png',
                        key: 'program_card_1',
                        name: 'Program card 1',
                        unique_key: 'program_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 2,
                        path: 'programcard/ProgramCard2',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/program_card_1.png',
                        key: 'program_card_2',
                        name: 'Program card 2',
                        unique_key: 'program_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 3,
                        path: 'bundlecard/bundlecard2',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/bundle_card_1.png',
                        key: 'bundle_card_2',
                        name: 'Bundle card 2',
                        unique_key: 'bundle_card',
                        parentKey: 'category_programs'
                    },
                    {
                        id: 3,
                        path: 'bundlecard/bundlecard3',
                        preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/bundle_card_2.png',
                        key: 'bundle_card_3',
                        name: 'Bundle card 3',
                        unique_key: 'bundle_card',
                        parentKey: 'category_programs'
                    }
                ],
                screens: ['home']
            },
        ]
    },
    {
        id: 4,
        name: 'Youtube',
        key: 'youtube',
        children: [
            {
                id: 0,
                title: 'Youtube',
                key: 'youtube_component_1',
                path: "components/youtube/youtubecomponent1",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/youtube_1.png',
                parentKey: 'youtube',
                apiKey: '',
                playListId: '',
                channelUrl: '',
                editorConfig: ['padding'],
                style : {
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    }
                },
                screens: ['home']
            }
        ]
    },
    {
        id: 5,
        name: 'Achievers',
        key: 'achivers',
        children: [
            {
                id: 7,
                name: 'Achievers',
                key: 'achivers1',
                path: "components/achivers/achivers1",
                title: 'OUR SUPER ACHIEVERS',
                description: 'Subscribe now | Join our super achievers club',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/achievers_1.png',
                parentKey: 'achivers',
                editorConfig: ['padding'],
                style : {
                    padding: {
                        right: {
                            value: 0,
                            ext: 'px'
                        },
                        left: {
                            value: 0,
                            ext: 'px'
                        },
                        top: {
                            value: 0,
                            ext: 'px'
                        },
                        bottom: {
                            value: 0,
                            ext: 'px'
                        }
                    },
                    typography: {
                        color: '#000'
                    }
                },
                screens: ['home']
            }
        ]
    },
    {
        id: 8,
        name: 'Footer',
        key: 'footer',
        children: [
            {
                id: 8,
                title: 'Footer',
                key: 'footer1',
                path: "components/footer/footer",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/footer_1.png',
                editorConfig: ['background', 'typography', 'footer'],
                style: {
                    backgroundColor: '#ffffff',
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000'
                    },
                },
                parentKey: 'footer',
                address: '2nd Floor,Rathi Plaza, opp. gov Hospital, Thuckalay, Tamil Nadu 629175',
                email: 'ekool@resbee.org',
                phone: '9952224098',
                screens: ['login', 'register', 'home'],
                footerMenus: [],
                footerConfig: {
                    showAddress: false
                }
            },
            {
                id: 8,
                title: 'Footer',
                key: 'footer2',
                path: "components/footer/footer2",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/footer_1.png',
                editorConfig: ['background', 'typography', 'footer'],
                style: {
                    backgroundColor: '#ffffff',
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000'
                    },
                },
                parentKey: 'footer',
                title1: 'About',
                title2: 'Contact us',
                title3: 'Contact Details',
                content1: 'Suresh’ paadasaalai is a online centre for effective learning through organized and methodical video sessions that enables a candidate to cross the barrier with ease. Suresh’ paadasaalai is the online learning platform of Suresh IAS Academy which is most trusted coaching center for TNPSC and Bank Exams.',
                address: '2nd Floor,Rathi Plaza, opp. gov Hospital, Thuckalay, Tamil Nadu 629175',
                email: 'ekool@resbee.org',
                phone: '9952224098',
                screens: ['login', 'register', 'home'],
                footerMenus: [],
                footerConfig: {
                    showAddress: false
                }
            },
            {
                id: 8,
                title: 'Footer',
                key: 'footer3',
                path: "components/footer/footer3",
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/footer_1.png',
                editorConfig: ['background', 'typography', 'footer'],
                style: {
                    backgroundColor: '#ffffff',
                    typography: {
                        fontFamily: 'Poppins',
                        fontSize: {
                            value: 22,
                            ext: 'px'
                        },
                        letterSpacing: {
                            value: 2,
                            ext: 'px'
                        },
                        textAlign: 'left',
                        textDecoration: 'none',
                        color: '#000000'
                    },
                },
                parentKey: 'footer',
                address: '2nd Floor,Rathi Plaza, opp. gov Hospital, Thuckalay, Tamil Nadu 629175',
                email: 'ekool@resbee.org',
                phone: '9952224098',
                screens: ['login', 'register', 'home'],
                footerMenus: [],
                footerConfig: {
                    showAddress: false
                }
            },
        ]
    },
    {
        id: 10,
        name: 'Feature',
        key: 'feature',
        children: [
            {
                id: 0,
                name: 'Feature 1',
                key: 'feature1',
                path: "components/feature/feature1",
                title: 'Future-ready skills on your schedule | Learn on iOS, Android, and more.',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/theme_builder/preview_images/header_1.png',
                parentKey: 'heros',
                editorConfig: ['background'],
                style: {
                    backgroundColor: 'red',
                    typography: {
                        color: '#4b4b4b'
                    }
                },
                screens: ['login', 'register', 'home'],
            }
        ]
    },
]

const { IMAGES, DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, BORDER, CONTENT, COLOR, COMPONENTS, TABS, YOUTUBE, EMBEDDED } = editorConfig;

export const extraComponents = [
    {
        id: 1,
        name: 'Banner',
        key: 'banner',
        children: [
            {
                id: 1,
                name: 'Single Banner',
                key: 'banner1',
                path: 'components/root/banners/banner1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/banner1.png',
                editorConfig: [IMAGES, DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, BORDER],
                screens: ['login', 'register', 'home'],
                children: [
                    {
                        id: 1,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/banner1_default.png',
                        type: customComponentTypes.image
                    }
                ],
                settings: {
                    maxChildren: 1,
                    maxImages: 1
                }
            },
            
            {
                id: 2,
                name: 'Multiple banners',
                key: 'banner2',
                path: 'components/root/banners/banner2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/banner2.png',
                editorConfig: [IMAGES, DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, BORDER],
                screens: ['login', 'register', 'home'],
                children: [
                    {
                        id: 1,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/banner1_default.png',
                        type: customComponentTypes.image
                    },
                    {
                        id: 2,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/banner1_default.png',
                        type: customComponentTypes.image
                    }
                ],
                settings: {
                    minChildren: 2,
                    minImages: 2
                }
            },
            {
                id: 3,
                name: 'Banner',
                key: 'banner3',
                path: 'components/root/banners/banner3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/banner1.png',
                editorConfig: [IMAGES, DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, BORDER],
                screens: ['login', 'register', 'home'],
                children: [
                    {
                        id: 1,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/banner1_default.png',
                        type: customComponentTypes.image
                    }
                ],
                settings: {
                    maxChildren: 1,
                    maxImages: 1
                }
            },
        ]
    },
    {
        id: 2,
        name: 'About',
        key: 'about',
        children: [
            {
                id: 1,
                name: 'About',
                key: 'about2',
                path: 'components/about/about2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/about2.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, CONTENT, COLOR, BORDER],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h5>The best learning tools you can get</h5>',
                    content: 'Example content'
                },
                style: {
                    color: "#fff"
                }
            },
            {
                id: 2,
                name: 'About',
                key: 'about3',
                path: 'components/about/about3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/about2.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, CONTENT, COLOR, BORDER],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h5>The best learning tools you can get</h5>',
                    content: 'Example content'
                },
                style: {
                    color: "#fff"
                }
            },
        ]
    },
    {
        id: 3,
        name: 'Tabs',
        key: 'tabs',
        children: [
            {
                id: 1,
                name: 'Muliti tabs',
                key: 'tab1',
                path: 'components/tab/tab1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/tab1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, CONTENT, COLOR, TABS, BORDER],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h3>Know the basic language levels</h3>'
                },
                children: [
                    {
                        id: 1,
                        title: 'Tab label 1',
                        content: 'Example content 1'
                    },
                    {
                        id: 2,
                        title: 'Tab label 2',
                        content: 'Example content 2'
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        name: 'Category programs',
        key: 'categoryPrograms',
        children: [
            {
                id: 1,
                name: 'Category programs',
                key: 'categoryPrograms1',
                path: 'components/categoryPrograms/categoryPrograms1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, COLOR, CONTENT],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h3>Category program listing</h3>'
                }
            }
        ]
    },
    {
        id: 5,
        name: 'Header Bar',
        key: 'headerBar',
        children: [
            {
                id: 1,
                name: 'Header bar',
                key: 'headerBar1',
                path: 'components/headerbar/headerbar1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/headerBar1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS],
                screens: ['login', 'register', 'home'],
            }
        ]
    },
    {
        id: 6,
        name: 'Header',
        key: 'header',
        children: [
            {
                id: 1,
                name: 'Header 1',
                key: 'header3',
                path: 'components/header/header3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 1,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 2,
                name: 'Header 2',
                key: 'header4',
                path: 'components/header/header4',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 2,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 3, 
                name: 'Header 3',
                key: 'header5',
                path: 'components/header/header5',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 3,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 4, 
                name: 'Header 4',
                key: 'header6',
                path: 'components/header/header6',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 4,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 5, 
                name: 'Header 5',
                key: 'header7',
                path: 'components/header/header7',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 5,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            
            {
                id: 6, 
                name: 'Header 6',
                key: 'header8',
                path: 'components/header/header8',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 6,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 7, 
                name: 'Header 7',
                key: 'header9',
                path: 'components/header/header9',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 7,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 8, 
                name: 'Header 8',
                key: 'header10',
                path: 'components/header/header10',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 8,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 9, 
                name: 'Header 9',
                key: 'header',
                path: 'components/header/header',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home' ],
                settings: {
                    minImages: 1,
                    maxImages: 2
                },
                children: [
                    {
                        id: 9,
                        image_url: 'https:d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            }
        ]
    },
    {
        id: 7,
        name: 'Hero',
        key: 'hero',
        children: [
            {
                id: 1,
                name: 'Hero 1',
                key: 'hero1',
                path: 'components/heros/hero1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 1,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 2,
                name: 'Hero 2',
                key:'hero2',
                path: 'components/heros/hero2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 2,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 3,
                name: 'Hero 3',
                key: 'hero3',
                path: 'components/heros/hero3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, COMPONENTS],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 3,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ]
            },
            {
                id: 4,
                name: 'Hero 4',
                key: 'hero4',
                path: 'components/heros/hero4',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 4,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 5,
                name: 'Hero 5',
                key: 'hero5',
                path: 'components/heros/hero5',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 5,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 6,
                name: 'Hero 6',
                key: 'hero6',
                path: 'components/heros/hero3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 6,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 7,
                name: 'Hero 7',
                key: 'hero7',
                path: 'components/heros/hero7',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 7,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 8,
                name: 'Hero 8',
                key: 'hero8',
                path: 'components/heros/hero3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 8,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 9,
                name: 'Hero 9',
                key: 'hero9',
                path: 'components/heros/hero9',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 9,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 10,
                name: 'Hero 10',
                key: 'hero10',
                path: 'components/heros/hero10',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 10,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 11,
                name: 'Hero 11',
                key: 'hero11',
                path: 'components/heros/hero11',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 11,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 12,
                name: 'Hero 12',
                key: 'hero12',
                path: 'components/heros/hero12',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 12,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 13,
                name: 'Hero 13',
                key: 'hero13',
                path: 'components/heros/hero13',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 13,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 14,
                name: 'Hero 14',
                key: 'hero14',
                path: 'components/heros/hero14',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                settings: {
                    minImages: 1,
                    maxImages: 1
                },
                children: [
                    {
                        id: 1,
                        image_url: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/default_images/addlogo.png',
                        type: customComponentTypes.image
                    }
                ],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
        ]
    },
    {
        id: 8,
        name: 'Feature',
        key: 'feature',
        children: [
            {
                id: 1,
                name: 'Feature',
                key: 'feature1',
                path: 'components/feature/feature1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT, COMPONENTS],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
            {
                id: 2,
                name: 'Feature',
                key: 'fearure2',
                path: 'components/feature/feature3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h3>Content</h3>',
                    content: '<p>Dummy content</p>'
                }
            },
        ]
    },
    {
        id: 9,
        name: 'Listing',
        key: 'listing',
        children: [
            {
                id: 1,
                name: 'Listing',
                key: 'listing1',
                path: 'components/listing/listing1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/header3.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, IMAGES, CONTENT, COMPONENTS],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h3>Content</h3>'
                }
            }
        ]
    },
    {
        id: 10,
        name: 'Layout',
        key: 'layout',
        children: [
            {
                id: 1,
                name: 'Column',
                key: 'columnLayout',
                path: 'components/layouts/columnLayout',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
            {
                id: 2,
                name: 'Row',
                key: 'rowLayout',
                path: 'components/layouts/rowLayout',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },  
            {
                id: 3,
                name: 'Layout 1',
                key: 'layout1',
                path: 'components/layouts/layout1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
            {
                id: 2,
                name: 'Layout 2',
                key: 'layout2',
                path: 'components/layout/layout2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },
            {
                id: 3,
                name: 'Layout 3',
                key: 'layout3',
                path: 'components/layout/layout3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },            
            {
                id: 5,
                name: 'Layout 5',
                key: 'layout5',
                path: 'components/layout/layout5',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },
            {
                id: 6,
                name: 'Layout 6',
                key: 'layout6',
                path: 'components/layout/layout6',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },
            {
                id: 7,
                name: 'Layout 7',
                key: 'layout7',
                path: 'components/layout/layout7',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },
            {
                id: 8,
                name: 'Layout 8',
                key: 'layout8',
                path: 'components/layout/layout8',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },
            {
                id: 9,
                name: 'Layout 9',
                key: 'layout9',
                path: 'components/layout/layout9',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            },           
        ]
    },
    {
        id: 11,
        name: 'Youtube',
        key: 'youtube',
        children: [
            {
                id: 1,
                name: 'Youtube',
                key: 'youtube1',
                path: 'components/youtube/youtubecomponent1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'],
                content: {
                    title: '<h3>Watch youtube videos</h3>'
                }
            },
            {
                id: 2,
                name: 'Youtube',
                key: 'youtube2',
                path: 'components/youtube/youtubeComponent2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE, CONTENT],
                screens: ['login', 'register', 'home'],
            }
        ]
    },
    {
        id: 12,
        name: 'Course Card',
        key: 'courseCard',
        children: [
            {
                id: 1,
                name: 'Course Card 1',
                key: 'courseCard1',
                path: 'components/courseCard/courseCard1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
            {
                id: 2,
                name: 'Course Card 2',
                key: 'courseCard2',
                path: 'components/courseCard/courseCard2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
            {
                id: 3,
                name: 'Course Card 3',
                key: 'courseCard3',
                path: 'components/courseCard/courseCard3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
            {
                id: 4,
                name: 'Course Card 4',
                key: 'courseCard4',
                path: 'components/courseCard/courseCard4',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
            {
                id: 5,
                name: 'Course Card 5',
                key: 'courseCard5',
                path: 'components/courseCard/courseCard5',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/column.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, SHADOW, COLOR, BORDER, COMPONENTS, IMAGES],
                screens: ['login', 'register', 'home'],
            },
        ]
    },
    {
        id: 13,
        name: 'Footer',
        key: 'footer',
        children: [
            {
                id: 1,
                name: 'Footer 1',
                key: 'footer2',
                path: 'components/footer/footer2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },
            {
                id: 2,
                name: 'Footer 2',
                key: 'footer3',
                path: 'components/footer/footer3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },
            {
                id: 3,
                name: 'Footer 3',
                key: 'footer4',
                path: 'components/footer/footer4',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },            
            {
                id: 4,
                name: 'Footer 4',
                key: 'footer7',
                path: 'components/footer/footer7',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },           
            {
                id: 5,
                name: 'Footer 5',
                key: 'footer9',
                path: 'components/footer/footer9',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },
            {
                id: 6,
                name: 'Footer 6',
                key: 'footer10',
                path: 'components/footer/footer10',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },
            {
                id: 7,
                name: 'Footer 7',
                key: 'footer11',
                path: 'components/footer/footer11',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },
            {
                id: 8,
                name: 'Footer 8',
                key: 'footer12',
                path: 'components/footer/footer12',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']                
            },
                       
        ]
    },
    {
        id: 14,
        name: 'Blog',
        key: 'blog',
        children: [
            {
                id: 1,
                name: 'Blog',
                key: 'blog1',
                path: 'components/blog/blog1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
            {
                id: 2,
                name: 'Blog',
                key: 'blog2',
                path: 'components/blog/blog2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home'] 
            }
        ]
    },
    {
        id: 15,
        name: 'Contact',
        key: 'contact',
        children: [
            {
                id: 1,
                name: 'Contact 1',
                key: 'contact1',
                path: 'components/contact/contact1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
            {
                id: 2,
                name: 'Contact 2',
                key: 'contact2',
                path: 'components/contact/contact2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
        ]
    },
    {
        id: 16,
        name: 'Statistic',
        key: 'statistic',
        children: [
            {
                id: 1,
                name: 'Statistic 1',
                key: 'statistic1',
                path: 'components/statistic/statistic1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
            {
                id: 2,
                name: 'Statistic 2',
                key: 'statistic2',
                path: 'components/statistic/statistic2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
            {
                id: 3,
                name: 'Statistic 3',
                key: 'statistic3',
                path: 'components/statistic/statistic3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
        ]
    },
    {
        id: 17,
        name: 'Team',
        key: 'team',
        children: [
            {
                id: 1,
                name: 'Team 1',
                key: 'team1',
                path: 'components/team/team1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
            {
                id: 2,
                name: 'Team 2',
                key: 'team2',
                path: 'components/team/team2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },            
        ]
    },
    {
        id: 18,
        name: 'Testimonial',
        key: 'testimonial',
        children: [
            {
                id: 1,
                name: 'Testimonial 1',
                key: 'testimonial1',
                path: 'components/testimonial/testimonial1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },
            {
                id: 2,
                name: 'Testimonial 2',
                key: 'testimonial2',
                path: 'components/testimonial/testimonial2',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },   
            {
                id: 3,
                name: 'Testimonial 3',
                key: 'testimonial3',
                path: 'components/testimonial/testimonial3',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },           
        ]
    },
    {
        id: 19,
        name: 'Pricing Card',
        key: 'pricing',
        children: [
            {
                id: 1,
                name: 'Pricing 1',
                key: 'pricing1',
                path: 'components/testimonial/testimonial1',
                preview_image: 'https://d2b7aqiel0gukx.cloudfront.net/site_builder/preview_images/categoryPrograms1.png',
                editorConfig: [DIMENSIONS, SPACING, BORDER_RADIUS, COLOR, BORDER, YOUTUBE],
                screens: ['login', 'register', 'home']    
            },                       
        ]
    },
]
