import { useState } from 'react';
import { Box, Button, ButtonBase, Collapse, Divider, Icon, MenuItem, Stack, Typography } from "@mui/material";
import MenuPopover from '../components/extras/MenuPopover';
import { ArrowDropDown } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useDevice from '../hooks/useDevice'

const CustomPopover = ({ item }) => {

    const [open, setOpen] = useState(null);
    const [expanded, setExpanded] = useState(false)

    const { push } = useRouter();
    
    const handleOpen = (event) => {
      setOpen(event.currentTarget);
    };

    const handleClose = () => {
      setOpen(null);
    };

    const children = item?.children;
    const variant = item?.props?.variant;

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder } = reducer;

    const doNothing = () => {}

    const { isMobile, isTablet } = useDevice()

    const handleOpenMobile = () => {
        setExpanded(!expanded)
    }

    if(isMobile)
    {
        return (
            <Stack
                // border={1}
                borderRadius={1}
                // p={2}
                borderColor={'divider'}
            >
                {/* <Stack direction={'row'} {...item.props} component={ButtonBase} onClick={!isSiteBuilder ? handleOpenMobile : doNothing} mb={1} justifyContent={'flex-start'} >
                    <Typography>{item?.label}</Typography>
                    <ArrowDropDown/>
                </Stack>

                <Divider/> */}

                <Collapse in={true} >
                {
                    children?.map((item, index) => {

                        return (
                            <MenuItem 
                                key={index} 
                                onClick={() => {
                                    if(!isSiteBuilder)
                                    {
                                        if(typeof item?.url === 'object')
                                        {
                                            if(typeof item?.url?.path === 'string')
                                            {
                                                push(item?.url?.path)
                                            }
                                        }
                                        else
                                        {
                                            if(typeof item?.path === 'string')
                                            {
                                                push(item?.path)
                                            }
                                        }
                                    }
                                }}
                                sx={{
                                    pl: 0
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        )
                    })
                }
                </Collapse>

            </Stack>
        )
    }

    return (
        <>

            {
                variant === 'icon' ? 
                <Box
                    borderRadius={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    onClick={!isSiteBuilder ? handleOpen : doNothing}
                    sx={{  
                        cursor: 'pointer'
                    }}
                >
                    <Icon {...item?.props} fontSize={'small'} className={`mdi mdi-${open ? item?.props?.focusedIcon : item?.props?.unFocusedIcon}`} sx={{ height: '100%', width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', color: 'text.primary' }} />
                </Box>
                :
                // <Button onClick={!isSiteBuilder ? handleOpen : doNothing} endIcon={<ArrowDropDown/>} >{item?.label}</Button>
                <Stack direction={'row'} {...item.props} component={ButtonBase} onClick={!isSiteBuilder ? handleOpen : doNothing} justifyContent={'flex-start'} >
                    <Typography>{item?.label}</Typography>
                    <ArrowDropDown/>
                </Stack>
            }

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
            
                {
                    children?.map((item, index) => {
                        return (
                            <MenuItem 
                                key={index} 
                                onClick={() => {
                                    if(!isSiteBuilder)
                                    {
                                        if(typeof item?.url === 'object')
                                        {
                                            if(typeof item?.url?.path === 'string')
                                            {
                                                push(item?.url?.path)
                                            }
                                        }
                                    }
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        )
                    })
                }

            </MenuPopover>

        </>
    )
};

export default CustomPopover;