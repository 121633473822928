
import {HTTP_REST_URLS} from "../config/urls";

export const useAllPrograms = (headers = {}, query = '', hasBundle = false) => new Promise((res, req) => {

    const url = HTTP_REST_URLS.GET_ALL_PROGRAMS + query

    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((res) => (res.json()))
        .then(async(response) => {

            if(!response.error)
            {
                const { programs } = response;
                const programList = programs.program_list
                const bundleList = programs?.bundle_list;

                const result = [...programList, ...bundleList]

                if(hasBundle)
                {
                    res(result)
                }
                else
                {
                    if(Array.isArray(programList))
                    {
                        res(programList)
                    }
                }
            }
            else
            {
                res([])
            }

        })

})
