import { TextField } from "@mui/material"
import { useState } from "react";

const CustomFormTextField = ({ item, index, ...props }) => {

    const [inputValue, setInputValue] = useState('')

    return (
        <TextField
            label={item?.placeHolder || 'Input'}
            required={item?.required || false}
            onChange={(e) => {
                setInputValue(e.target.value)
            }}
            onBlur={() => {
                item['value'] = inputValue
            }}
            {...props}
        />
    )
};

export default CustomFormTextField;