import axios from "axios";
import { HTTP_REST_URLS } from "../config/urls"
import { useApiMiddleware } from "./useApiMiddleware";

export const useFileUpload = (headers, data) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.UPLOAD_FILE;
    
    axios.post(url, data, {
        headers: headers,       
    })
        .then(async(res) => {

            const resultValue = await useApiMiddleware(res.data)
                    
            if(!resultValue.error)
            {
                const url = resultValue?.url
                resolve(url)
            }

        })
        .catch((error) => {
        })

})