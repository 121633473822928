export const icons = {

    CLOSE               : 'ion:close',
    FACEBOOK            : 'ion:logo-facebook',
    TWITTER             : 'ant-design:twitter-outlined',
    WHATSAPP            : 'ri:whatsapp-fill',
    LINKED_ID           : 'ri:linkedin-box-fill',
    MAIL                : 'mdi:email',
    HOME                : 'ic:round-home',
    LOGIN               : 'material-symbols:login-rounded',
    ARROW_UP            : 'ph:arrow-up-bold',
    BACK                : 'ic:round-arrow-back',
    TEXT_FIELD          : 'material-symbols:text-fields-rounded',
    PARAGRAPH           : 'material-symbols:short-text-rounded',
    RADIO_BUTTON        : 'ic:outline-radio-button-checked',
    CHECK_BOX           : 'fluent:checkbox-checked-20-filled',
    DROP_DOWN           : 'material-symbols:expand-circle-down-rounded',
    FORM                : 'fluent:form-20-filled',
    INFO                : 'material-symbols:info-outline' ,
    ERROR_OUTLINE       : 'ic:twotone-error-outline',
    ADD_FILLED_OUTLINED : 'material-symbols:add-circle',
    CHEVRON_DOWN        : 'material-symbols:keyboard-arrow-down-rounded',
    DISPLAY             : 'material-symbols:smart-display-outline-rounded',
    LIST_OUTLINED       : 'ri:file-list-2-line',
    PLAY_LESSON_OUTLINED: 'ic:outline-play-lesson',
    CLOCK_OUTLINED      : 'ic:outline-access-time', 
    GROUP_PERSON        : 'mdi:person-group-outline',
    STAR_RATING         : 'material-symbols:star-outline-rounded',
    RELOAD_TIME_OUTLINED: 'ant-design:reload-time-outline',
    PLAY_LESSON_FILLED  : 'material-symbols:play-lesson',
    LESSROUND           : 'material-symbols:expand-less-rounded',
    MOREROUND           :'material-symbols:expand-more-rounded',
    PDF                 :'ant-design:file-pdf-outlined',
    DOCUMENT            :'carbon:document',
    YOUTUBE             : 'ant-design:youtube-outlined',
    PPT                 :'ant-design:file-ppt-outlined',
    XLS                 :'bi:file-earmark-spreadsheet',
    VIDEO               :'ic:baseline-ondemand-video',
    LIVECLASS           :"ic:sharp-live-tv",
    ASSIGNMENT          :'material-symbols:assignment-outline-rounded',
    EXAM                :'ri:lightbulb-flash-line',
    SCROM               :'la:gift',
    MUSIC               :'material-symbols:audio-file-outline-sharp',
    FLIPBOOK            :"mdi:book-open-page-variant-outline",
    HTML                :"mdi:web",
    ARROW_DROP_UP       :'ic:twotone-arrow-drop-up',
    ARROW_DROP_DOWN     :'ic:twotone-arrow-drop-down',
    PLAYER_ROUND        :'arcticons:mediaplayer', 
    CLOSE_OUTLINED      :'carbon:close-outline',
    ARROW_RIGHT         :'ic:baseline-keyboard-arrow-right',
    ARROW_LEFT          :'ic:baseline-keyboard-arrow-left',
    DOWNLOAD_LINE       : 'material-symbols:download-rounded',
    INFINITY            : 'ph:infinity',
    RIGHT_ARROW_OUTLINED: 'material-symbols:arrow-forward-ios-rounded',
    SHARE               : 'material-symbols:share',
    SIMPLE_FUNNEL       : 'ph:funnel-simple',
    DURATION            : 'ic:sharp-access-time',
    RATING              : 'ic:outline-star-border',
    GROUP_PERSON_FILLED : 'mdi:account-group-outline',
    CERTIFICATE_OUTLINE : 'ph:certificate',
    COURSE              : 'fluent-mdl2:publish-course',
    RIGHT_ARROW_OUTLINED: 'material-symbols:arrow-forward-ios-rounded', 
    RIGHT_ARROW         : 'material-symbols:arrow-right',
    ADD_ICON            : 'material-symbols:add',      
    PLAY_ICON_FILLED    : 'material-symbols:play-circle'    ,  
    TELEGRAM            : 'ic:baseline-telegram'                                                                     
}