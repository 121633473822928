// export const useProgramPricing = (item) => {

// //   return false; 

// let monthPayment = item?.program_detail?.pb_month_payment;

//     if(monthPayment && monthPayment != '[]')
//     {

//         const parsedMonthPayment = JSON.parse(monthPayment);
//         if(Array.isArray(parsedMonthPayment) && parsedMonthPayment?.length > 0)
//         {            
//             const priceToShow = parsedMonthPayment?.filter((i) => (parseInt(i.show) === 1));
//             const filteredPriceToShow = priceToShow?.length > 0 ? priceToShow[0] : parsedMonthPayment[0];

//             let object = {}

//             object['isMonthlyPayment'] = true
//             object['priceToShow'] = filteredPriceToShow
//             object['paymentData'] = parsedMonthPayment

//             return(object)

//         }
//         else
//         {
//             let object = {}
//             object['priceToShow'] = price
//             object['isMonthlyPayment'] = false
//             return(object)
//         }
//     }
//     else
//     {
//         if(data)
//         {
//             const parsedData            = JSON.parse(item?.program_detail?.pb_discount_json);
                
//             // const discountType          = parseInt(parsedData?.discount_type);
//             // const discountPrice         = parsedData?.price_after_discount;
//             const programPrice          = item?.program_detail?.pb_price;
//             // const discountValue         = parseInt(parsedData?.discount_value);
//             // const hasDiscount           = discountType === 1 || discountType === 2 && discountValue > 0;
//             // const p                     = hasDiscount && discountValue > 0 ? parseInt(discountPrice) : parseInt(programPrice);
//             const originalPrice         = parsedData ? parsedData?.price : price;

//             const couponJson            = couponData?.coupon_json
//             const parsedCouponJson      = couponJson && JSON.parse(couponJson)
//             const hasCoupon             = couponJson != null
//             const Price                 = couponData && parsedCouponJson ? parseInt(parsedCouponJson?.price_after_discount) : p

//             const extraCharges          = programExtraCharges
//             const parsedExtraCharges    = extraCharges && JSON.parse(extraCharges)
//             const hasGst                = parsedExtraCharges?.gst == 1
//             const totalCgst             = parsedExtraCharges && parseInt(parsedExtraCharges.cgst)
//             const totalSgst             = parsedExtraCharges && parseInt(parsedExtraCharges.sgst)
//             const totalGst              = totalCgst && totalSgst ? totalCgst + totalSgst : 0
//             const delivaryCharges       = parsedExtraCharges && parsedExtraCharges.delivary_charges ? parseInt(parsedExtraCharges.delivary_charges) : 0;

//             const object = {
//                 hasCoupon,
//                 price: Price,t = item?.program_detail?.pb_month_payment;
//                 hasGst,
//                 totalGst,
//                 delivaryCharges,
//                 isMonthlyPayment: false,
//                 originalPrice,
//                 hasDiscount
//             }

//             return(object);

//         }
//     }

// }

export const useProgramPricing = (item) => {

    const { program_detail }    = item || {};
    const { bundle_detail }     = item || {};
    const { pb_discount_json    = null, pb_price = 0, pb_month_payment = null, pb_discount_value } = program_detail || {};
    const { bb_discount_json    = null, bb_price = 0, bb_month_payment = null, bb_discount_value } = bundle_detail || {};
  

    let priceData = {}
    // priceData['strikeOutPrice']     = parseInt(pb_price) || 0;
    priceData['originalPrice']      = parseInt(pb_price) || 0;
    priceData['originalPrice']      = parseInt(bb_price) || 0;
       
    priceData['isMonthlyPayment']   = false;
       
    if(pb_month_payment && JSON.parse(pb_month_payment)?.length > 0)
    {
        const parsedMonthPayment    = JSON.parse(pb_month_payment)
        const priceToShow           = parsedMonthPayment?.filter((i) => (parseInt(i.show) === 1));
        const filteredPriceToShow   = priceToShow?.length > 0 ? priceToShow[0] : parsedMonthPayment[0];

        priceData['priceToShow']        = filteredPriceToShow;
        priceData['paymentData']        = parsedMonthPayment;
        priceData['isMonthlyPayment']   = true;

    }
    if(bb_month_payment && JSON.parse(bb_month_payment)?.length > 0)
    {
        const parsedMonthPayment    = JSON.parse(bb_month_payment)
        const priceToShow           = parsedMonthPayment?.filter((i) => (parseInt(i.show) === 1));
        const filteredPriceToShow   = priceToShow?.length > 0 ? priceToShow[0] : parsedMonthPayment[0];

        priceData['priceToShow']        = filteredPriceToShow;
        priceData['paymentData']        = parsedMonthPayment;
        priceData['isMonthlyPayment']   = true;

    }
    else
    {
        if(pb_discount_json != null)
        {
            const parsedDiscountJson    = pb_discount_json ? JSON?.parse(pb_discount_json) : {}
            const hasDiscount           = parseInt(parsedDiscountJson?.discount_value) > 0
            priceData['hasStrike']      = hasDiscount

            if(hasDiscount)
            {
                priceData['originalPrice']  = parseFloat(parsedDiscountJson?.price_after_discount);
                priceData['strikeOutPrice'] = parseInt(parsedDiscountJson?.discount_value );

                priceData['originalPrice']  = parseInt(pb_price) || 0;
                priceData['strikeOutPrice'] = parseInt(parsedDiscountJson?.discount_value );
                   
            }
            if(!hasDiscount)
            {
                priceData['originalPrice'] = parseInt(pb_price) || 0;
                priceData['strikeOutPrice'] = parseInt(parsedDiscountJson?.discount_value );
            }
        }
        if(bb_discount_json != null)
        {
            const parsedDiscountJson    = bb_discount_json ? JSON?.parse(bb_discount_json) : {}
            const hasDiscount           = parseInt(parsedDiscountJson?.discount_value) > 0
            priceData['hasStrike']      = hasDiscount
              
            if(hasDiscount)
            {
                priceData['originalPrice']  = parseFloat(parsedDiscountJson?.price_after_discount);
                priceData['strikeOutPrice'] = parseInt(parsedDiscountJson?.discount_value );

                priceData['originalPrice']  = parseInt(bb_price) || 0;
                priceData['strikeOutPrice'] = parseInt(parsedDiscountJson?.discount_value );
            }            
        }
  
    }


    return priceData

}