import { ArrowBackIosRounded, ArrowForwardIosRounded, HomeRounded } from "@mui/icons-material";
import { Divider, Stack, styled, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import EkhoolImage from "../../components/ekhoolImage/ekhoolImage";
import { devices, NO_IMAGE } from "../../config/config";
import { useTestimonialsData } from '../../hooks/useTestimonialsData'

const IconContainer = styled(Stack)(({ theme }) => ({
    height: 60,
    width: 60,
    borderRadius: 30,
    backgroundColor: 'rgba(0,0,0,0.4)',
    cursor: 'pointer',
    transition: '300ms ease-out',
    color: 'white',
    '&:hover': {
        backgroundColor: 'white',
        color: 'black'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999999,
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}))

const TesitimonailCardSkeleton = () => {
    return (
        <Stack
            height={180}
            minWidth={460}
            maxWidth={460}
            direction={'row'}
            p={1.5}
            borderRadius={1}
            border={1}
            borderColor={'divider'}
            gap={2}
        >

            <Stack
                height={'100%'}
                width={125}
                bgcolor={'divider'}
                borderRadius={1}
                position={'relative'}
                overflow={'hidden'}
                minWidth={125}
            >

                <EkhoolImage src={NO_IMAGE} objectFit={'cover'} />

            </Stack>

            <Stack gap={1} >
                <Typography variant={'subtitle1'} >What is Lorem Ipsum?</Typography>
                <Divider/>
                <Typography variant={'body2'} >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
            </Stack>

        </Stack>
    )
}

const TesitimonailCard = ({ item, index }) => {
    
    const { t_image, t_name, t_text } = item;

    return (
        <Stack
            height={180}
            minWidth={460}
            maxWidth={460}
            direction={'row'}
            p={1.5}
            borderRadius={1}
            border={1}
            borderColor={'divider'}
            gap={2}
        >

            <Stack
                height={'100%'}
                width={125}
                bgcolor={'divider'}
                borderRadius={1}
                position={'relative'}
                overflow={'hidden'}
                minWidth={125}
            >

                <EkhoolImage src={t_image} objectFit={'cover'} />

            </Stack>

            <Stack gap={1} >
                <Typography variant={'subtitle1'} >{t_name}</Typography>
                <Divider/>
                <Typography variant={'body2'} >{t_text}</Typography>
            </Stack>

        </Stack>
    )
}

const CustomTestimonials1 = ({ item, ...props }) => {

    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder, device } = reducer;

    const [testimonialData, setTestmonialData] = useState([])

    const  getData = async() =>{
        const result = await useTestimonialsData()
        setTestmonialData(result)
    }

    useEffect(() => {
        if(!isSiteBuilder)
        {
            getData()
        }
    }, [isSiteBuilder])

    const scrollRef = useRef(null);

    const onScroll = (scrollOffset) => {
        return scrollRef ? (scrollRef.current.scrollLeft += scrollOffset) : null;
    };

    const isMobile  = device === devices.MOBILE
    const isTablet = device === devices.TABLET

    return (
        <Stack {...props} justifyContent={'center'} >

            {
                testimonialData?.length > 3 && !isMobile &&
                <IconContainer
                    position={'absolute'}
                    left={60}
                    onClick={() => {
                        onScroll(-1200)
                    }}
                >
                    <ArrowBackIosRounded/>
                </IconContainer>
            }
        
            <Stack direction={'row'} gap={2} overflow={'hidden'} sx={{ overflowX: 'scroll', scrollBehavior: 'smooth' }} ref={scrollRef} >

                {
                    isSiteBuilder && Array(5).fill(1).map((item, index) => {
                        return (
                            <TesitimonailCardSkeleton key={index} />
                        )
                    })
                }

                {
                    !isSiteBuilder && testimonialData?.map((item, index) => {
                        return (
                            <TesitimonailCard item={item} key={index} />
                        )
                    })
                }

            </Stack>

            {
                testimonialData?.length > 3 && !isMobile &&
                <IconContainer
                    position={'absolute'}
                    right={60}
                    onClick={() => {
                        onScroll(1200)
                    }}
                >
                    <ArrowForwardIosRounded/>
                </IconContainer>
            }

        </Stack>
    )
};

export default CustomTestimonials1;