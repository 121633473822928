import { Stack, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import AppLogo from "../logo/Logo";

const HomeFooter = () => {

    const { reducer } = useSelector((state) => state);
    const { appData } = reducer;

    return (
        <Stack alignItems={'center'} width={'100%'} mt={6}>

            <Stack maxWidth={'xl'} width={'100%'} >

                <Stack
                    height={2}
                    width={'100%'}
                    bgcolor={'primary.main'}
                />

                <Stack direction={'row'} alignItems={'center'}  justifyContent={'space-between'} py={4} pb={6} >

                    <AppLogo/>

                    <Typography><span>Copyright</span> © <span>{new Date().getFullYear()}</span> <span>{appData?.application_title}</span>. All rights reserved.</Typography>

                </Stack>

            </Stack>

        </Stack>
    )
};

export default HomeFooter;