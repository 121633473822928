import { Box, Stack, useTheme } from "@mui/material";
import Image from "next/image"
import EkhoolImage from "../components/ekhoolImage/ekhoolImage";
import { Resizable } from 're-resizable'
import { useSelector } from "react-redux";
import { de, devices } from '../config/config'
import useDevice from "../hooks/useDevice";

const CustomImage = ({ item, ...props }) => {

    const { reducer } = useSelector((state) => state)
    const { devices } = reducer;
   
    const { isMobile, isTablet } = useDevice()
   


    //item?.style || { width: 320, height: 200 }

    return (
        <Box 
            {...props} 
            style={{
                ...item.style,

                // width: item?.style?.width || 320,
                // height: item?.style?.height || 200
               
                width: isMobile ? "100%" : item?.style?.width || '100%',
                height: isMobile ? "" : item?.style?.height || '100%',
                position: 'relative'

                // width: isTablet ? 420 : item?.style?.width,
                // height: isTablet ? 380 : item?.style?.height,
            }}
            // px={isMobile ? 2 : 0}
        >
            <EkhoolImage layout={'fill'} src={item?.src} objectFit={isMobile?"contain":"contain"} {...props} />
        </Box>
    )
};

export default CustomImage;