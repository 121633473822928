import { HTTP_REST_URLS } from "../config/urls"

export const useFormData = (id) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.GET_FORM_DATA + '?id=' + id + '&single=true';

    let formResponseObject = {};
    
    fetch(url)
        .then((res) => (res.json()))
        .then((response) => {
            
            if(!response.error)
            {
                formResponseObject['formPath'] = response?.form_template_path + response?.form_templates?.ft_file;
                formResponseObject['formName'] = response?.form_templates?.ft_name;
                formResponseObject['error'] = false;
                formResponseObject['message'] = response?.message;
                resolve(formResponseObject)
            }
            else
            {
                formResponseObject['error'] = true;
                formResponseObject['message'] = response?.message;
                resolve(formResponseObject)
            }

        })
        .catch((error) => {

            formResponseObject['error'] = true;
            formResponseObject['message'] = 'Something went wrong.'
            resolve(formResponseObject)
            
        })

})