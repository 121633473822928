export const ERRORS = {

    HOST_EMPTY: {

        name: 'HOST_EMPTY',
        code: '0x001'

    },

    APPLICATION_DATA_ERROR: {

        name: 'APPLICATION_DATA_ERROR',
        code: '0x002'

    },

    SITE_BUILDER_FILE_ERROR: {

        name: 'SITE_BUILDER_FILE_ERROR',
        code: '0x003'

    },

    SITE_BUILDER_FILE_RESPONSE_ERROR: {

        name: 'SITE_BUILDER_FILE_RESPONSE_ERROR',
        code: '0x004'

    },

    JSON_ERROR: {

        name: 'Unexpected token < in JSON at position 0',
        code: '0x005'

    },

    FILE_NOT_FOUND: {

        name: 'Request failed with status code 403',
        code: '0x006'

    },

    LAYOUT_JSON_ERROR: {

        name: 'LAYOUT_JSON_ERROR',
        code: '0x007'

    }

};

export default ERRORS;