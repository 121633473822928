import {HTTP_REST_URLS} from "../config/urls";
import { useApiMiddleware } from "./useApiMiddleware";

export const useSearchCourse = (headers, query) => new Promise((res, rej) => {

    const url = HTTP_REST_URLS.SEARCH_COURSE + query + '&get_bundles=true';

    const responseObject = {};

    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((res) => (res.json()))
        .then(async(response) => {

            const resultValue = await useApiMiddleware(response) 

            if(!resultValue.error)
            {
                const programs = resultValue.programs;
                const programList = Array.isArray(programs?.program_list) ? programs?.program_list : [];
                const bundleList = Array.isArray(programs?.bundle_list) ? programs?.bundle_list : [];

                responseObject['programs'] = programList;
                responseObject['bundles'] = bundleList;
                
                res(responseObject)

            }
            else
            {
                responseObject['programs'] = [];
                responseObject['bundles'] = [];
                res(responseObject)
            }

        })

})
