import { isUndefined } from "lodash";
import AlertDialog from "../components/dialog/dialog"

export const useApiMiddleware = (data) => new Promise((resolve, req) => {

        const sessionValue = data?.sessionValid

        if (typeof window === 'object'  && sessionValue == false && typeof window.sessionpopup === 'function')  {
                window.sessionpopup(true)
                // console.log('sessionValue');
        }

        resolve(data)

})
