import { AdjustRounded } from "@mui/icons-material";
import { Avatar, Box, Icon, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { useSelector } from "react-redux";
import { customComponentTypes } from "./types";
import CustomTypography from "./CustomTypography";
import useDevice from "../hooks/useDevice";
import EkhoolImage from "../components/ekhoolImage/ekhoolImage";
import { DEFAULT_IMAGE } from "../config/config";

// const CustomJumpCard = ({ item,maxLines, ...props }) => {
const CustomImageCard = ({layout = 'fill', src, objectFit, item, ...props }) => {
  const { content } = item;
  
  const { push } = useRouter();
  
  const maxLines = 2;
  
  const { reducer } = useSelector((state) => state);
  const [isHovered, setIsHovered] = useState(false);
  const { isSiteBuilder, pageJson, siteBuilderSelectedChild ,themebuilderSelectedItem} = reducer;



  const { isMobile, isTablet } = useDevice();

  if (!content) {
    return <></>;
  }

  const { icon } = content;

  const BrokenImage = DEFAULT_IMAGE;

  const imageOnError = (event) => {
    event.currentTarget.src = BrokenImage;
    event.currentTarget.className = "error";
  };



  const stackStyles = {
    backgroundColor: props?.style2?.color,

    padding: "1rem",
    transition: "transform 0.2s",
    borderRadius: (() => {
      switch (props?.borderRadius) {
        case "Full":
          return "100px";
        case "Medium":
          return "40px";
        case "Low":
          return "20px";
        case "None":
          return "0px";
        default:
          return "  0px";
      }
    })(),
  };



  // circle   icon background
  const boxStyles = {
    width: (() => {
      switch (props.fontSize) {
        case "large":
          return "25%";
        case "medium":
          return "21%";
        case "small":
          return "15%";
        case "none":
          return "0%";
        default:
          return "25%";
      }
    })(),

    background: props?.style3?.color,
    backgroundColor: props?.style3?.color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    // margin: "auto",
    height: (() => {
      switch (props.fontSize) {
        case "large":
          return "110px";
        case "medium":
          return "90px";
        case "small":
          return "70px";
        default:
          return "110px";
      }
    })(),
    // background: props?.style?.color,
  };

  const textStyles = {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  };

  const shadowStyles = {
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)", // Standard shadow color

    // backgroundColor:props?.style2?.color,
    borderRadius: (() => {
      switch (props?.borderRadius) {
        case "Full":
          return "100px";
        case "Medium":
          return "40px";
        case "Low":
          return "20px";
        case "None":
          return "0px";
        default:
          return "  0px";
      }
    })(),
    // backgroundColor:'red'
    // padding: '1rem',
  };

  const nonShadowStyles = {
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)", // Standard shadow color

    // backgroundColor:props?.style2?.color,
    borderRadius: (() => {
      switch (props?.borderRadius) {
        case "Full":
          return "100px";
        case "Medium":
          return "40px";
        case "Low":
          return "20px";
        case "None":
          return "0px";
        default:
          return "  0px";
      }
    })(),
    // backgroundColor:'red'
    // padding: '1rem',
  };

  return (
    <Stack
      // {...props}
      width={"100%"}
      height={"100%"}
      onClick={() => {
        if (!isSiteBuilder) {
          if (typeof item?.props?.path === "object") {
            push(item?.props?.path?.path);
          } else {
            // push(item?.props?.path)
          }
        }
      }}


      sx={{
        ...(isHovered ? nonShadowStyles : shadowStyles),
        ...(!isSiteBuilder &&
          item?.props?.path && {
            cursor: "pointer",
          }),
      }}
    >

      <Box
        component="img"
        sx={{
          ...(isHovered ? nonShadowStyles : nonShadowStyles),
          height: "100%",
          width: "100%",
          objectFit:'fill'
        }}
        // alt="The house from the offer."
        src={item.props.style4.picture}

      />
    

    </Stack>
  );
};

export default CustomImageCard;
