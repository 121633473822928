import { useApiMiddleware } from "./useApiMiddleware";

export const useUnsplashImages = (query, color) => new Promise((resolve, reject) => {

    const url = `https://api.unsplash.com/search/photos?page=1&query=${query}&client_id=${process.env.UNSPLASH_ACCESS_KEY}&per_page=50&color=${color}&orientation=landscape`;

    fetch(url, {
        method: 'GET'
    })
        .then((res) => (res.json()))
        .then(async(response) => {
            const resultValue = await useApiMiddleware(response) 

            if(Array.isArray(resultValue?.results))
            {
                resolve(resultValue.results)
            }

        })

})