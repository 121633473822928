const customComponentTypes = {

    button: 'button',

    icon: 'icon',

    image: 'image',

    accordion: 'accordion',

    typography: 'typography', 

    content: 'content',

    popover: 'popover',

    spacer: 'spacer',

    content2: 'content2',

    feature1: 'feature1',

    image1: 'image1',

    icon1: 'icon1',

    typography1: 'typography1',
    
    tab: 'tab',

    content3: 'content3',

    list: 'list',

    customProgramListing: 'customProgramListing',

    form: 'form',

    textField: 'textField',

    articles: 'articles',

    testimonials: {
        type1: 'testimonials1'
    },

    html: 'html',

    logo: 'logo'

};

export {
    customComponentTypes
}