import { CART_KEY } from "../config/key"

export const useAddToCart = (id) => new Promise((resolve, reject) => {   

    const result = localStorage.getItem(CART_KEY);
      
    if(result != null)
    
    {
        const parsedResult = JSON.parse(result)       
        if(Array.isArray(parsedResult))
        {
            parsedResult.push(id);
            localStorage.setItem(CART_KEY, JSON.stringify(parsedResult));
            resolve(true);
        }
        else
        {
            let arr = [];
            arr.push(id);
            localStorage.setItem(CART_KEY, JSON.stringify(arr));
            resolve(true);
        }
    }
    else
    {
        let arr = [];
        arr.push(id);
        localStorage.setItem(CART_KEY, JSON.stringify(arr));
        resolve(true);
    }

})