import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// layouts

// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
import PageNotFoundIllustration from "../assets/illustration_404";
import { HomeRounded } from '@mui/icons-material';
import { getString } from '../functions/getString';
// assets


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

const Page404 = () => {
    return (
        <Page title="404 Page Not Found" sx={{ height: 1 }}>
            <RootStyle>
                <Container component={MotionContainer}>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <m.div variants={varBounce().in}>
                            <Typography variant="h3" paragraph>
                                {getString('Sorry, page not found')}!
                            </Typography>
                        </m.div>
                        <Typography sx={{ color: 'text.secondary' }}>
                            {getString("Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling")}.
                        </Typography>
                        <m.div variants={varBounce().in}>
                            <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                        </m.div>
                        <NextLink href="/" passHref>
                            <Button startIcon={<HomeRounded/>} variant="contained">
                            {getString('Go to Home')}
                            </Button>
                        </NextLink>
                    </Box>
                </Container>
            </RootStyle>
        </Page>
    );
};

export default Page404;