import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material"
import { NO_IMAGE } from "../../config/config";

const ProgramCardSkeleton = ({ index = 0 }) => {
    return (
        <Card
            sx={{
                borderRadius: 1
            }}
        >
            <CardMedia
                image={NO_IMAGE}
                component="img"
                height={170}
            />
            <CardContent>
                <Typography variant={'h6'} mb={0.5} >Program name {index + 1}</Typography>
                <Typography variant={'caption'} >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</Typography>

                <Stack alignItems={'flex-end'} width={'100%'} mt={1} >
                    <Typography variant={'h6'} mb={0.5} >₹ 9XX9</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
};

export default ProgramCardSkeleton;