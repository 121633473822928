import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Iconify from '../../components/Iconify'
import { icons } from "../../config/icons";

const CustomFormMenuFields = ({ item, index }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState('')

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (i) => {
      setAnchorEl(null);
      setInputValue(i?.value)
      item['value'] = i?.value
    };

    return (
        <Stack>
            <Typography variant="body1" >{item?.placeHolder}</Typography>

            <Stack mt={2} >

                <Stack
                    p={1}
                    border={1}
                    width={'fit-content'}
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    borderColor={'text.secondary'}
                    onClick={handleClick}
                    sx={{
                        cursor: 'pointer'
                    }}
                >
                    <Typography>{inputValue || 'Select'}</Typography>
                    <Iconify
                        icon={icons.DROP_DOWN}
                    />
                    
                </Stack>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {
                        item?.options?.map((i, ind) => {
                            return (
                                <MenuItem 
                                    onClick={() => {
                                        handleClose(i)
                                    }}
                                >{i?.value}</MenuItem>
                            )
                        })
                    }
                </Menu>
            </Stack>

        </Stack>
    )
}

export default CustomFormMenuFields;