import { Image } from "@mui/icons-material";
import { Stack } from "@mui/material"
import { useSelector } from "react-redux";
import { customComponentTypes } from "./types";
import { useState } from "react";
import dynamic from "next/dynamic";

const HtmlEditor = dynamic(() => import('../components/editor/HtmlEditor'), {
    ssr: false
})

const CustomTailwindComponent = ({ item, ...props }) => {

    const { reducer } = useSelector((state) => state);
    const { themebuilderSelectedItem } = reducer;

    const [value, setValue] = useState('')

    return (
        <Stack>

            {/* <HtmlEditor
            	simple={true}
                onChange={(val) => {
                    // setValue(val)
                }}
                value={value}
            />

            <div
                dangerouslySetInnerHTML={{
                    __html: item?.content || ''
                }}
                onMouseLeave={(e) => {
                    // e.target.innerHTML = value
                }}
                onClick={(e) => {
                    e.target.id = e.target.id ? e.target.id : `text-id-${Math.random() * 1000}`
                    console.log(e.target)
                }}
            /> */}

<div class="py-8 ...">py-8</div>

        </Stack>
    )
};

export default CustomTailwindComponent