import {HTTP_REST_URLS} from "./urls";

let colors = {

    borderColor: '#E5E5E5',
    appPrimaryColor: '#101727',
    grayText: '#A3A6AD',
    white: '#ffffff',
    primaryBackground: '#ffffff',
    inActiveColor: '#787A89',
    activeColor: '#7A3DF4',
//    primaryText: '#3C4852'
    primaryText: '#000',
    documentColors          : {

        pdf : {
            primary : '#C9A9FF',
            secondary : '#9385FF',
            icon : '#C4B8FE',
        },

        doc : {
            primary : '#A1E0FF',
            secondary : '#38B8FF',
            icon : '#C5ECFF'
        },

        docx : {
            primary : '#A1E0FF',
            secondary : '#38B8FF',
            icon : '#C5ECFF'
        },

        ppt : {
            primary : '#e95555',
            secondary : '#ec4040',
            icon : '#eaa8a8'
        },

        pptx : {
            primary : '#FF9D7E',
            secondary : '#FF6D68',
            icon : '#FEADA9'
        },

        xls : {
            primary : '#72E0CB',
            secondary : '#08B8A4',
            icon : '#B3FEF3'
        },

        assignment : {
            primary : '#f4978e',
            secondary : '#f08080',
            icon : '#f8edeb'
        },

        exam : {
            primary : '#66B1FA',
            secondary : '#5090FE',
            icon : '#98CAFF'
        },

        music : {
            primary : '#ffc860',
            secondary : '#ffb769',
            icon : '#FFCA97'
        },

        zip : {
            primary : '#f26a4f',
            secondary : '#ef3c2d',
            icon : '#f29479'
        },

        html : {
            primary : '#ffad19',
            secondary : '#ffad19',
            icon : '#fcbc4e'
        },

        scrom : {
            primary : '#ff758f',
            secondary : '#ff4d6d',
            icon : '#ffb3c1'
        },

        video: {
            primary: '#ea98da',
            secondary: '#5b6cf9',
            icon: '#ffd6ef'
        },

        youtube: {
            primary: '#FA7170',
            secondary: '#FA7170',
            icon: '#FA7170'
        },

        topic : {
            primary: '#13B1B8',
            secondary: '#13B1B8',
            icon: '#13B1B8'
        },

        vimeo: {
            primary: '#7ad3f7',
            secondary: '#1ab7ea',
            icon: '#bbe6fa'
        }

    },

};

export {
    colors
}
