const inputTypes = {

    textField: 'textFiled',

    inputArea: 'inputArea',

    checkbox: 'checkbox',

    dropDown: 'dropDown',

    radioButton: 'radioButton'

};

export {
    inputTypes
}