import dynamic from "next/dynamic"
import DynamicHeroWrapper from "./dynamicWrapper";

/* Add new component key to interface sectionKey customComponents/ComponentSection.tsx */
const heroes = Array.from({ length: 20 }, (_, i) => {
    const index = i === 0 ? '' : `.${i}`; 
    return {
        [`hero${i + 1}${index}`]: () => <DynamicHeroWrapper heroKey={`hero${i + 1}${index}`} />,
    };
}).reduce((acc, curr) => Object.assign(acc, curr), {});

// This will include hero1 to hero20, including the sub-heroes like hero1.1, hero1.2, ..., hero20.20

const dynamicComponents = {

    'header1': dynamic(() => import('../components/siteComponents/headers/header1')),
    'header2': dynamic(() => import('../components/siteComponents/headers/header2')),
    'header3': dynamic(() => import('../components/siteComponents/headers/header3')),
    'header4': dynamic(() => import('../components/siteComponents/headers/header4')),
    'header5': dynamic(() => import('../components/siteComponents/headers/header5')),
    'header6': dynamic(() => import('../components/siteComponents/headers/header6')),
    'header7': dynamic(() => import('../components/siteComponents/headers/header7')),
    'header8': dynamic(() => import('../components/siteComponents/headers/header8')),
    'header9': dynamic(() => import('../components/siteComponents/headers/header9')),
    'header10': dynamic(() => import('../components/siteComponents/headers/header10')),
    'header11': dynamic(() => import('../components/siteComponents/headers/header11')),
    'header13': dynamic(() => import('../components/siteComponents/headers/header13')), 
    'header12': dynamic(() => import('../components/siteComponents/headers/header12')), 

    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`infoheader1${index}`]: () => <DynamicHeroWrapper heroKey={`infoheader1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
   

    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner1${index}`]: () => <DynamicHeroWrapper heroKey={`banner1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner2${index}`]: () => <DynamicHeroWrapper heroKey={`banner2${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner3${index}`]: () => <DynamicHeroWrapper heroKey={`banner3${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner4${index}`]: () => <DynamicHeroWrapper heroKey={`banner4${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner5${index}`]: () => <DynamicHeroWrapper heroKey={`banner5${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner6${index}`]: () => <DynamicHeroWrapper heroKey={`banner6${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner7${index}`]: () => <DynamicHeroWrapper heroKey={`banner7${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`banner8${index}`]: () => <DynamicHeroWrapper heroKey={`banner8${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
  

    'carousel1': dynamic(() => import('../components/siteComponents/banners/carousel')),
    'carousel2': dynamic(() => import('../components/siteComponents/banners/carousel2')),

    'programs3': dynamic(() => import('../components/siteComponents/programs/programs3')), 
    'programs4': dynamic(() => import('../components/siteComponents/categories/programs4')),

    'category3': dynamic(() => import('../components/siteComponents/categories/category3')),
    'category4': dynamic(() => import('../components/siteComponents/categories/category4')),
    'category5': dynamic(() => import('../components/siteComponents/categories/category5')),
    'category6': dynamic(() => import('../components/siteComponents/categories/category6')),
    'category7': dynamic(() => import('../components/siteComponents/categories/Category7')),






  
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero1${index}`]: () => <DynamicHeroWrapper heroKey={`hero1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero2${index}`]: () => <DynamicHeroWrapper heroKey={`hero2${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
     ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero3${index}`]: () => <DynamicHeroWrapper heroKey={`hero3${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero4${index}`]: () => <DynamicHeroWrapper heroKey={`hero4${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero5${index}`]: () => <DynamicHeroWrapper heroKey={`hero5${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero6${index}`]: () => <DynamicHeroWrapper heroKey={`hero6${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero7${index}`]: () => <DynamicHeroWrapper heroKey={`hero7${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero8${index}`]: () => <DynamicHeroWrapper heroKey={`hero8${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero9${index}`]: () => <DynamicHeroWrapper heroKey={`hero9${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero10${index}`]: () => <DynamicHeroWrapper heroKey={`hero10${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`hero11${index}`]: () => <DynamicHeroWrapper heroKey={`hero11${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),

    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`contact1${index}`]: () => <DynamicHeroWrapper heroKey={`contact1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`contact2${index}`]: () => <DynamicHeroWrapper heroKey={`contact2${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),


    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`about1${index}`]: () => <DynamicHeroWrapper heroKey={`about1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`about2${index}`]: () => <DynamicHeroWrapper heroKey={`about2${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),

    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`ticker1${index}`]: () => <DynamicHeroWrapper heroKey={`ticker1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`layoutGrid${index}`]: () => <DynamicHeroWrapper heroKey={`layoutGrid${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`LayoutFooter${index}`]: () => <DynamicHeroWrapper heroKey={`layoutFooter${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`layoutFooter2${index}`]: () => <DynamicHeroWrapper heroKey={`layoutFooter2${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`layoutFooter1${index}`]: () => <DynamicHeroWrapper heroKey={`layoutFooter1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),

    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`layout1${index}`]: () => <DynamicHeroWrapper heroKey={`layout1${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`layout2${index}`]: () => <DynamicHeroWrapper heroKey={`layout2${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`layout3${index}`]: () => <DynamicHeroWrapper heroKey={`layout3${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`columnLayout${index}`]: () => <DynamicHeroWrapper heroKey={`columnLayout${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`cardLayout${index}`]: () => <DynamicHeroWrapper heroKey={`cardLayout${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`rowLayout${index}`]: () => <DynamicHeroWrapper heroKey={`rowLayout${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
    ...Array.from({ length: 20 }, (_, i) => {
        const index = i === 0 ? '' : `.${i}`; 
        return {
            [`cardRowLayout${index}`]: () => <DynamicHeroWrapper heroKey={`cardRowLayout${index}`} />,
        };
    }).reduce((acc, curr) => Object.assign(acc, curr), {}),
   
  
    
   
    'feature1': dynamic(() => import('../components/siteComponents/features/feature1')),
    'feature2': dynamic(() => import('../components/siteComponents/features/feature2')),
    'feature3': dynamic(() => import('../components/siteComponents/features/feature3')),
    'feature4': dynamic(() => import('../components/siteComponents/features/feature4')),

    'footer2': dynamic(() => import('../components/siteComponents/footer/footer2')),
    'footer3': dynamic(() => import('../components/siteComponents/footer/footer3')),
    'footer4': dynamic(() => import('../components/siteComponents/footer/footer4')),
    'footer5': dynamic(() => import('../components/siteComponents/footer/footer5')),
    'footer6': dynamic(() => import('../components/siteComponents/footer/footer6')),
    'footer7': dynamic(() => import('../components/siteComponents/footer/footer7')),
    'footer8': dynamic(() => import('../components/siteComponents/footer/footer8')),
    'footer9': dynamic(() => import('../components/siteComponents/footer/footer9')),
    'footer10': dynamic(() => import('../components/siteComponents/footer/footer10')),
    'footer13': dynamic(() => import('../components/siteComponents/footer/footer13')),
    'footer14': dynamic(() => import('../components/siteComponents/footer/footer14')),
    'footer15': dynamic(() => import('../components/siteComponents/footer/footer15')),
    'footer16': dynamic(() => import('../components/siteComponents/footer/footer16')),

    'footerInfo1': dynamic(() => import('../components/siteComponents/footerInfo/footerInfo')),

    
    
    'list1': dynamic(() => import('../components/siteComponents/list/list1')),
    'list2': dynamic(() => import('../components/siteComponents/list/list2')),
    'list3': dynamic(() => import('../components/siteComponents/list/list3')),


    'team1': dynamic(() => import('../components/siteComponents/team/team1')),
    'team2': dynamic(() => import('../components/siteComponents/team/team2')),

    'statistic1': dynamic(() => import('../components/siteComponents/statistic/statistic1')),
    'statistic2': dynamic(() => import('../components/siteComponents/statistic/statistic2')),
    'statistic3': dynamic(() => import('../components/siteComponents/statistic/statistic3')),

    'testimonial1': dynamic(() => import('../components/siteComponents/testimonial/testimonial1')),
    'testimonial2': dynamic(() => import('../components/siteComponents/testimonial/testimonial2')),
   
    'cta1': dynamic(() => import('../components/siteComponents/cta/cta1')),

    'promotion1': dynamic(() => import('../components/siteComponents/promotions/promotion1')),
    'promotion2': dynamic(() => import('../components/siteComponents/promotions/promotion2')),

    'content1': dynamic(() => import('../components/siteComponents/content/content1')),
    'content3': dynamic(() => import('../components/siteComponents/content/content3')),
  
   

    'youtube1': dynamic(() => import('../components/siteComponents/youtube/youtube1')),
    'youtube2': dynamic(() => import('../components/siteComponents/youtube/youtube2')),
    'youtube3': dynamic(() => import('../components/siteComponents/youtube/youtube3')),

    'programs1': dynamic(() => import('../components/siteComponents/programs/programs1')),
    'programs2': dynamic(() => import('../components/siteComponents/programs/programs2')),
    
    
        
    'tab1': dynamic(() => import('../components/siteComponents/tabs/tabs1')),
    
    'categoryPrograms1': dynamic(() => import('../components/siteComponents/categoryPrograms/categoryPrograms1')),
    'categoryPrograms3': dynamic(() => import('../components/siteComponents/categoryPrograms/categoryPrograms3')),
    'programGridCard': dynamic(() => import('../components/siteComponents/categoryPrograms/programGridCard')),

    'articles1': dynamic(() => import('../components/siteComponents/articles/articles1')),
    'articles2': dynamic(() => import('../components/siteComponents/articles/articles2')),

    'achievers1': dynamic(() => import('../components/siteComponents/achievers/achievers1')),

    

    // 'statistics1': dynamic(() => import('../components/siteComponents/statistics/statistics1'))

};

export {
    dynamicComponents
}

// export default dynamicComponents;