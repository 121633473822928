import { ShoppingCartRounded } from "@mui/icons-material";
import { Badge, Box, Button, Card, CardHeader, Divider, IconButton, Link, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Tab, Tabs, Typography, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";


import { useDispatch, useSelector } from "react-redux";
import { TOKEN_KEY } from "../../config/key";
import { useAllBundles } from "../../hooks/useAllBundles";
import { useAllPrograms } from "../../hooks/useAllPrograms";
import { useProgramPricing } from "../../hooks/useProgramPricing";
import { IconButtonAnimate } from "../animate";
import MenuPopover from "../extras/MenuPopover";
import {withAlphaValue} from "tailwindcss/lib/util/withAlphaVariable";
import EkhoolImage from "../ekhoolImage/ekhoolImage";
import { useCart } from "../../hooks/useCart";
import { reduxHelper } from "../../config/reduxHelper";
import { getString } from "../../functions/getString";

const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CartProgramCard = ({item, index}) => {

    const programImage = item?.pb_image;
    const programName = item?.pb_title;
    const finalPrice = item?.finalPrice;
    const programLanguages = item?.pb_language;

    const theme = useTheme();

    const PRIMARY_BACKGROUND = theme.palette.background.default;

    const { reducer } = useSelector((state) => state);
    const { appData } = reducer;
    
    const priceSymbol = appData?.amount_details?.symbol;

    return (
        <Box width={'100%'} >
            <ListItemButton>
                <ListItem sx={{ p: 0 }} >
                    <ListItemIcon>
                        <Box 
                            height={50} 
                            width={80}
                            sx={{ backgroundColor: PRIMARY_BACKGROUND }}
                            borderRadius={0.5}
                            position={'relative'}
                            overflow={'hidden'}
                        >
                            <EkhoolImage src={programImage} layout={'fill'} />
                        </Box>
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant={'body2'} >
                            {programName}
                        </Typography>
                        {
                            programLanguages?.map((i, ind) => {
                                return (
                                    <Typography variant={'caption'} color={'text.disabled'} >{i?.pl_lang_name}</Typography>
                                )
                            })
                        }
                        <Typography variant={'body2'} fontWeight={600} >{priceSymbol} {finalPrice}</Typography>
                    </ListItemText>
                </ListItem>
            </ListItemButton>
            {/* <Divider/> */}
        </Box>
    )
}

const CartBundleCard = ({item, index}) => {

    const bundleImage = item?.bb_image;
    const bundleName  = item?.bb_title;

    const programs = item?.programs;
    const parsedPrograms = JSON.parse(programs);
    const programsArray = Object.values(parsedPrograms);
    const totalPrograms = programsArray?.length;
    const finalPrice = item?.finalPrice;

    const theme = useTheme();

    const PRIMARY_BACKGROUND = theme.palette.background.default

    const { reducer } = useSelector((state) => state);
    const { appData } = reducer;
    
    const priceSymbol = appData?.amount_details?.symbol;

    return (
        <Box 
            position={'relative'}  
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            width={'90%'}
        >

            <Card
                sx={{
                    height: 85,
                    width: '100%',
                    backgroundColor: PRIMARY_BACKGROUND,
                    borderRadius: 1,
                    zIndex: 10,
                    overflow: 'hidden',
                    display: 'flex'
                }}
            >

                <Box
                    height={'100%'}
                    width={125}
                    sx={{
                        backgroundColor: PRIMARY_BACKGROUND
                    }}
                    position={'relative'}
                >
                    <EkhoolImage
                        src={bundleImage}
                        layout={'fill'}
                    />
                </Box>

                <Box 
                    pl={2}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                >

                    <Typography variant={'body2'} >{bundleName}</Typography>
                    <Typography variant={'caption'} >{totalPrograms} programs</Typography>

                    <Typography variant={'body2'} fontWeight={600} >{priceSymbol} {finalPrice}</Typography>

                </Box>

            </Card>

            <Card
                sx={{
                    height: 85,
                    width: '90%',
                    borderRadius: 1,
                    position: 'absolute',
                    top: 6,
                    zIndex: 1,
                    backgroundColor: withAlphaValue(PRIMARY_BACKGROUND, 0.7)
                }}
            />

            <Card
                sx={{
                    height: 85,
                    width: '80%',
                    borderRadius: 1,
                    position: 'absolute',
                    top: 12,
                    zIndex: 0,
                    backgroundColor: withAlphaValue(PRIMARY_BACKGROUND, 0.5)
                }}
            />

        </Box>
    )
}

const CartPopover = (props) => {

    const [open, setOpen] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [programsOnCart, setProgramsOnCart] = useState([]);
    const [bundlesOnCart, setBundlesOnCart] = useState([]);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };
    
    const handleClose = () => {
        setOpen(null);
    };

    const { reducer } = useSelector((state) => state);
    const { cartItems, appData, headers } = reducer;

    const getAllPrograms = async () => {
        const result = await useAllPrograms(headers);
        
        // if(Array.isArray(result))
        // {
        //     const filteredPrograms = result?.filter((i) => (cartItems?.includes(i)));

        //     let nPriceAmount = 0;

        //     await filteredPrograms?.map( async (item) => {
        //         const result = await useProgramPricing(item?.pb_discount_json, item?.pb_price, null);
        //         item['finalPrice'] = result.price;
        //         nPriceAmount = nPriceAmount + result.price
        //     })

        //     setTotalAmount((priceAmount) => priceAmount + nPriceAmount)
        //     setProgramsOnCart(filteredPrograms)
        // }
        
    }
    // const showPricing = appData?.enable_learner_purchase
    const getAllBundles = async () => {

        const result = await useAllBundles(headers)
        const filteredPrograms = result?.filter((i) => (cartItems.includes(i.id)));

        let nPriceAmount = 0;

        await filteredPrograms?.map( async (item) => {
            const result = await useProgramPricing(item?.bb_discount_json, item?.bb_price, null);
            item['finalPrice'] = result.price;
            nPriceAmount = nPriceAmount + result.price
        })

        setTotalAmount((priceAmount) => priceAmount + nPriceAmount)
        setBundlesOnCart(filteredPrograms)

    }

    useEffect(() => {
        getAllPrograms()
        getAllBundles()
    }, []);

    const theme = useTheme();
    const router = useRouter();

    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;

    const priceSymbol = appData?.amount_details?.symbol;

    const cartPathName = '/cart';
    const isCartPage = router?.pathname === cartPathName;

    const [value, setValue] = useState(0);

    const dispatch = useDispatch()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const PRIMARY_BACKGROUND = theme.palette.background.default

    const getCartCount = async () => {
        const result = await useCart()
        dispatch({
            type: reduxHelper.UPDATE_CART_ITEMS,
            payload: result
        })
    }

    useEffect(() => {
        getCartCount()
    }, [])

    if(!appData?.enable_learner_purchase)
    {
        return <></>
    }
  
    return (
        <>
            {/* <Link href="/cart" underline="none">  */}
            <IconButton color={open ? 'primary' : 'default'} onClick={() => { router.push('/cart') }} sx={{ width: 40, height: 40 }} {...props} >
                <Badge badgeContent={cartItems?.length} color={'primary'}>
                    <ShoppingCartRounded fontSize={'small'} />                   
                </Badge>
            </IconButton>
            {/* </Link>  */}

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
            >
                
                <Box p={1} >

                    <CardHeader
                        title={getString('Cart')}
                        subheader={`${cartItems?.length} Programs`}
                        sx={{ pt: 1, pl: 2 }}
                    />

                    <Divider sx={{ mt: 2 }} />

                    <Stack 
                        mb={2} 
                        gap={1} 
                        maxHeight={440}
                        minHeight={440}
                        overflow={'hidden'}
                        sx={{
                            overflowY: 'scroll'
                        }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >

                        <TabsWrapperStyle
                            sx={{
                                mt: 1,
                                borderBottom: 1,
                                borderBottomColor: 'divider',
                                mb: 1
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                sx={{
                                    width: '100% !important',
                                }}
                            >
                                <Tab
                                    disableRipple
                                    key={0}
                                    label={getString('Programs')}
                                    {...a11yProps(0)}
                                    sx={{
                                        width: '44%'
                                    }}
                                />
                                <Tab
                                    disableRipple
                                    key={1}
                                    label={getString('Bundles')}
                                    {...a11yProps(1)}
                                    sx={{
                                        width: '44%'
                                    }}
                                />
                            </Tabs>
                        </TabsWrapperStyle>

                        {
                            value === 0 &&
                            <>
                                {
                                    programsOnCart?.map((item, index) => {
                                        return (
                                            <CartProgramCard item={item} index={index} />
                                        )
                                    })
                                }
                            </>
                        }

                        {
                            value === 1 &&
                            <>
                                {
                                    bundlesOnCart?.map((item, index) => {
                                        return (
                                            <CartBundleCard item={item} index={index} />
                                        )
                                    })
                                }
                            </>
                        }
                        
                    </Stack>

                    <Divider/>

                    <Box
                        p={1}
                        pl={2}
                    >

                        <Typography variant={'h5'} mb={2} >Total: {priceSymbol} {totalAmount}</Typography>

                        {
                            !isCartPage &&
                            <Stack direction={'row'} gap={2} >
                                <Button 
                                    variant={'contained'} 
                                    fullWidth 
                                    sx={{
                                        backgroundColor: `${PRIMARY_MAIN} !important`,
                                        '&:hover': {
                                            backgroundColor: `${PRIMARY_DARK} !important`
                                        }
                                    }}
                                    onClick={() => {
                                        
                                    }}
                                >{getString('Checkout')}</Button>
                                
                                <Link href="/cart" underline='none'>
                                    <Button 
                                        variant={'contained'} 
                                        fullWidth 
                                        sx={{
                                            backgroundColor: `${PRIMARY_MAIN} !important`,
                                            '&:hover': {
                                                backgroundColor: `${PRIMARY_DARK} !important`
                                            }
                                        }}
                                        onClick={() => {
                                            router.push('/cart')
                                        }}
                                    >{getString('Go to cart')}</Button>
                                </Link>
                                
                            </Stack>
                        }

                    </Box>

                </Box>

            </MenuPopover>
        </>
    )
};

export default CartPopover;