import { HTTP_REST_URLS } from "../config/urls"
import { useApiMiddleware } from "./useApiMiddleware";

export const useSubCategoryArticles = (id = '', all = false) => new Promise((resolve, reject) => {

    const url = HTTP_REST_URLS.GET_ARTICLES + id;

    fetch(url)
    .then((res) => (res.json()))
    .then(async(response) => {
        const resultValue = await useApiMiddleware(response)  

        let obj = {}
        
        if(!resultValue.error)
        {
            const { articles, sub_category_detail, cloudfront_url } = resultValue;
            if(Array.isArray(articles))
            {
                let res = articles
                
                if(!all)
                {
                    res = articles?.filter((i) => (i.a_status == 1))
                }

                res?.map((i) => {
                    i['a_image'] = i?.a_image?.replace('[CLOUDFRONT_URL]', cloudfront_url)
                })
                obj['data'] = res;
                obj['detail'] = sub_category_detail
            }
        }

        resolve(obj)

    })

})