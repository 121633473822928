import { CART_KEY } from "../config/key"

export const useCheckCart = (id) => new Promise((resolve, reject) => {
   
    const result = localStorage.getItem(CART_KEY) 
  
    if(result != null)
    {
        const parsedResult = JSON.parse(result)
       
        if(Array.isArray(parsedResult))
        {
            const filteredResult = parsedResult?.filter((i) => (i == id))  
          
            if(filteredResult?.length > 0)
            {
                resolve(true)
            }
            else
            {
                resolve(false)
            }
        }
        else
        {
            resolve(false)
        }
    }
    else
    {
        resolve(false)
    }

})