import { CART_KEY } from "../config/key"

export const useCart = () => new Promise((resolve, reject) => {

    const result = localStorage.getItem(CART_KEY)  

    if(result != null)
    {
        const parsedResult = JSON.parse(result);
       
        if(parsedResult?.length > 0)
        {
            resolve(parsedResult)
        }
        else
        {
            resolve([])
        }
    }
    else
    {
        resolve([])
    }

})