import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import useDevice from "../../hooks/useDevice";

const HomeAccountPopover = ({ isHome = true }) => {
  const { reducer } = useSelector((state) => state);
  const { device, user } = reducer;
  const { isMobile } = useDevice();
  const router = useRouter();

  const { product_menu_url } = user || {};
  const { assets_url } = user || {};

  const widget_url = `${product_menu_url}&userDivId=account-data-menu&&productDivId=product-div-id&active=lms`;
  const ethiopian_url = `${assets_url}admin/js/ethiopian_calendar_script.js`;
  

  useEffect(() => {
    if (user) {
        // Ethiopian Calendar Section
        const existingEthiopianScript = document.getElementById('ethiopian-calendar-script');
        if (existingEthiopianScript) existingEthiopianScript.remove();
        
        const ethiopianCalendarScript = document.createElement('script');
        ethiopianCalendarScript.type = 'text/javascript';
        ethiopianCalendarScript.src = ethiopian_url;
        ethiopianCalendarScript.id = 'ethiopian-calendar-script';
        document.body.appendChild(ethiopianCalendarScript);

        // Account URL Script Section
        const existingAccountScript = document.getElementById('account-url-script');
        if (existingAccountScript) existingAccountScript.remove();
        
        const accountScript = document.createElement('script');
        accountScript.type = 'text/javascript';
        accountScript.src = widget_url;
        accountScript.id = 'account-url-script';

        // Ensure functions execute after script loads
        accountScript.onload = () => {
            start();
            getSysnotify();
            if (typeof translateScript === "function") 
            {
              translateScript();
            }
            
        };

        document.body.appendChild(accountScript);
    }
}, [user]);

  if (!user) {
    return <></>;
  }

  return (
    <>
      {!isMobile && (
        <>
           <Stack direction={'row'} alignItems={'center'} gap={2} >

          {/* <LanguagePopover/> */}

            <Stack id={'account-data-menu'} >
              
            </Stack>

            <Stack 
              id={'product-div-id'} 
            >
            
          </Stack>

          </Stack>
        </>
      )}
    </>
  );
};

export default HomeAccountPopover;
