import { Box, Card, CardContent, Icon } from "@mui/material"

const CustomContent = ({item,  ...iconProps}) => {

    const { content } = item;
    return (
        <Card
            sx={{
                height: '100%',
                // width:350
            }}
        >
            <style>
                {"\ .ql-editor{\  padding:0px;\ }\ "}
            </style>
            <CardContent
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    // justifyContent:'center',
                    // alignItems:'center',
                    gap: 1
                }}
            >
                <Icon {...iconProps} className={`mdi mdi-${content?.icon}`} style={{ height: '100%' }} fontSize={'large'} color={'primary'} />
                <div dangerouslySetInnerHTML={{ __html: content?.title }} style={{fontWeight:'bold'}}/>
                <div dangerouslySetInnerHTML={{ __html: content?.content }} style={{ marginTop: 10, fontSize:18 }} />
            </CardContent>
        </Card>
    )
};

export default CustomContent;