import { Stack, useTheme } from "@mui/material"
import Script from "next/script";
import { useSelector } from "react-redux";

const ProductList = () => {

    const { reducer } = useSelector((state) => state);
    const { user } = reducer;
    const { product_menu_url } = user || {}

    const theme = useTheme();

    if(!user)
    {
        return <></>
    }

    return (
        <>
            <Stack
                // height={'100vh'}
                // width={56}
                // position={'fixed'}
                // right={0}
                // top={0}
                id={'product-div-id'}
                // sx={{
                //     '.product-block': {
                //         // right: 0,
                //     },
                //     '.product-div': {
                //         gap: 1,
                //     },
                //     '.product': {
                //         color: 'text.secondary',
                //     },
                //     '.widget-svg': {
                //         fill: theme.palette.text.secondary
                //     },
                // }}
                // style={{
                //     marginTop: '70px'
                // }}
                // bgcolor={'background.neutral'}
            >

            </Stack>

            {/* <Script src={`${product_menu_url}&productDivId=product-div-id`} /> */}
        </>
    );
};

export default ProductList;