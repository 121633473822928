import { Badge, Icon } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxHelper } from '../config/reduxHelper';
import { useCart } from '../hooks/useCart'

const CustomIcon = ({ item, ...props }) => {

    const { push } = useRouter();
    const { reducer } = useSelector((state) => state);
    const { isSiteBuilder, cartItems } = reducer;

    const dispatch = useDispatch()

    const isCart = item?.url?.path == '/cart'
    
    const getCartCount = async () => {
        const result = await useCart()
        dispatch({
            type: reduxHelper.UPDATE_CART_ITEMS,
            payload: result
        })
    }

    useEffect(() => {
        if(!isSiteBuilder)
        {
            if(isCart == true)
            {
                getCartCount()
            }
        }
    }, [isCart, isSiteBuilder])
    
    return (
        <Badge badgeContent={isCart ? cartItems?.length : null} color={'primary'} >
            <Icon 
                onClick={() => { 
                    if(!isSiteBuilder)
                    {
                        if(item?.url)
                        {
                            if(typeof item?.url == 'string')
                            {
                                push(item?.url)
                            }
                            else
                            {
                                push(item?.url?.path)
                            }
                        }
                    }
                }} 
                className={`mdi mdi-${item?.children}`} 
                sx={{ height: '100% !important', cursor: item?.url ? 'pointer' : undefined }} 
                {...props} 
            />
        </Badge>
    )
};

export default CustomIcon;